import React, { useEffect, useState } from 'react'
import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import PropTypes from 'prop-types'
import { deleteFilter } from '@redux/themeFilters.js'

export default function DeleteFilterModal ({ id, show, onSuccess, onClose }) {
  const [text, setText] = useState('')
  const [confirmed, setConfirmed] = useState(false)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (text.toLowerCase() === 'delete') {
      setConfirmed(true)
    } else {
      setConfirmed(false)
    }
  })
  function handleDelete () {
    if (!confirmed) {
      return
    }
    dispatch(
      deleteFilter({
        id: id
      })
    )
      .then(() => {
        onSuccess()
        onClose()
        setText('')
        setConfirmed(false)
        navigate('/theme-filters')
      })
      .catch(error => {
        console.log('error on delete filter: ', error)
      })
  }

  return (
    <Modal show={show} className='pda-filters__add-modal'>
      <Header onClose={onClose}>
        Are you sure you want to permanently delete this filter?
      </Header>
      <Body className='pda-filters__add-modal__body'>
        <p>Type <i>Delete</i> to confirm</p>
        <TextInput
          labelClassName='pda-filters__form__label'
          Placeholder='Delete'
          value={text}
          onChange={event => setText(event.currentTarget.value)}
        />
      </Body>
      <Footer className='pda-filters__add-modal__footer'>
        <Button onClick={handleDelete} danger={confirmed} disabled={!confirmed}>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteFilterModal.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
