import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Label from './Label.jsx'

import './TextInput.scss'

export default function TextInput (props) {
  const {
    inputRef,
    tabIndex,

    className,
    type,
    name,
    value,
    required,
    placeholder,

    disabled,
    readOnly,

    label,
    labelClassName,

    multiline,
    resize,
    small,

    // for type=file
    accept,

    subText,
    error
  } = props
  const {
    onClick,
    onChange,
    onBlur,
    onFocus,
    autoFocus
  } = props

  const C = multiline ? 'textarea' : 'input'

  const control = (
    <C
      ref={inputRef}
      tabIndex={tabIndex}
      name={name}
      className={classnames('hc-input', {
        'hc-input--error': error,
        'hc-input--small': small,
        'hc-input--multiline': multiline > 0,
        'hc-input--resize': multiline > 0 && resize
      }, className)}
      type={type}
      value={value}
      required={required}
      placeholder={placeholder}
      disabled={disabled}
      onClick={onClick}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      readOnly={readOnly}
      autoFocus={autoFocus}
      rows={multiline > 0 ? multiline : undefined}
      onKeyDown={props.onKeyDown}
      accept={type === 'file' ? accept : undefined}
      resize={multiline > 0 && resize ? undefined : 'none'}
    />
  )

  let sub = null
  if (subText) {
    sub = (
      <span
        className={classnames('hc-input__sub', {
          'hc-input__sub--error': error
        })}
      >
        {subText}
      </span>
    )
  }

  if (label) {
    return (
      <Label className={labelClassName}>
        <span>{label}</span>
        {control}
        {sub}
      </Label>
    )
  }

  return (
    <>
      {control}
      {sub}
    </>
  )
}

TextInput.defaultProps = {
  type: 'text',
  resize: true
}

TextInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.any,
  multiline: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  resize: PropTypes.bool,

  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  readOnly: PropTypes.bool,

  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelClassName: PropTypes.string,

  subText: PropTypes.string,
  error: PropTypes.any,

  accept: PropTypes.string,

  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  tabIndex: PropTypes.number,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func
}
