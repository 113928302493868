import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { fetchThemes } from '@redux/themes.js'

import SearchBar from '@components/SearchBar'
import Button from '@components/Button'

import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import ThemeModal from './ThemeModal'

import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'
import DropdownList from '@components/DropdownList'
import DotMenu from '@components/DotMenu'

import './List.scss'

const themeLimit = 15

export default function ThemeList () {
  const listRef = useRef()
  const searchTimeout = useRef(null)
  const [totalThemes, themes] = useSelector(({ themes }) => [themes.list.total, themes.list.data])

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '')
  const [search, setSearch] = useState(searchVal)
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')

  const dispatch = useDispatch()

  const pagination = makePagination(totalThemes, page, themeLimit)

  useEffect(() => {
    setSearchParams({ page: 1, search, status })
  }, [search, status])

  useEffect(() => {
    loadThemes()
  }, [page, search, status])

  function loadThemes () {
    dispatch(fetchThemes({
      search,
      status,
      offset: pagination.offset,
      limit: pagination.limit,
      withDeveloper: true,
      withRating: true
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
      case 'view':
        break
    }
  }

  function handleThemeModalSuccess () {
    setShowModal('')
    setSelected(null)
    loadThemes()
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page, search, status })
  }

  return (
    <div className='pda-theme-page' ref={listRef}>
      <ThemeModal
        show={showModal !== ''}
        type={showModal}
        theme={selected}
        onClose={() => {
          setShowModal('')
          setSelected(null)
        }}
        onSuccess={handleThemeModalSuccess}
      />

      <div className='pda-theme-page__title'>
        Shopify Themes
      </div>

      <div className='pda-theme-page__list'>
        <div className='pda-theme-page__toolbar'>
          <SearchBar
            className='pda-theme-page__search'
            placeholder='Search themes'
            value={searchVal}
            onChange={handleSearchChange}
          />

          <div className='pda-theme-page__action'>
            <Link to='/theme-filters'>
              Filters
            </Link>
          </div>

          <div className='pda-theme-page__action'>
            <Button onClick={() => setShowModal('add')} primary>
              Add Theme
            </Button>
          </div>
        </div>
        <div className='pda-theme-page__status'>
          <Button
            primary={status === ''}
            disabled={status === ''}
            onClick={() => setStatus('')}
          >
            All
          </Button>
          <Button
            primary={status === 'published'}
            disabled={status === 'published'}
            onClick={() => setStatus('published')}
          >
            Published
          </Button>
          <Button
            primary={status === 'unpublished'}
            disabled={status === 'unpublished'}
            onClick={() => setStatus('unpublished')}
          >
            Unpublished
          </Button>
          <Button
            primary={status === 'pending'}
            disabled={status === 'pending'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
        </div>

        <div>
          Total results: {totalThemes}
        </div>

        <ListRow
          className='pda-theme-page__list__items'
          headers={(
            <>
              <ListRowHeader className='pda-theme-page__list__name'>
                Theme
              </ListRowHeader>
              <ListRowHeader className='pda-theme-page__list__developer'>
                Developer
              </ListRowHeader>
              <ListRowHeader className='pda-theme-page__list__thumbnail'>
                Thumbnail
              </ListRowHeader>
              <ListRowHeader className='pda-theme-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={themes.map(theme => (
            <ListRowItem key={theme.id}>
              <ListRowColumn className='pda-theme-page__list__name'>
                <Link
                  to={`/themes/${theme.id}`}
                >
                  {theme.name}
                </Link>
                <br />
                <small className='pda-theme-page__list__slug'>
                  <a
                    href={`${import.meta.env.VITE_SITE_URL}/themes/reviews/${theme.slug}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {theme.slug}
                  </a>
                </small>
              </ListRowColumn>
              <ListRowColumn className='pda-theme-page__list__developer'>
                {theme.developer
                  ? (
                    <Link to={`/developers?id=${theme.developer.id}`}>
                      {theme.developer.name}
                    </Link>)
                  : 'N/A'}
              </ListRowColumn>
              <ListRowColumn className='pda-theme-page__list__thumbnail'>
                <a
                  href={theme.thumbnail_url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img className='pda__theme-img-preview' src={theme.thumbnail_url} alt={theme.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='pda-theme-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'view',
                    label: 'View',
                    value: theme,
                    to: `/themes/${theme.id}`
                  }, {
                    key: 'edit',
                    label: 'Edit',
                    value: theme
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            handlePageChange(page, event)
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}
