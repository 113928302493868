import React, { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import Answer from '@pages/Partners/Partner/Theme/Questions/Answer.jsx'
const AnswerForm = lazy(() => import('@pages/Partners/Partner/Theme/Questions/AnswerForm.jsx'))

export default function QuestionCard ({ question }) {
  const canEditAnswer = question.status !== 'pending'
  const hasDevAnswer = question.answers?.some(answer => answer.developer_id)

  function handleCancelAnswer () {
    return null
  }

  return (
    <div key={question.id} className='theme-reviews-qa__qa__card'>
      <div className='theme-reviews-qa__qa__card__header'>
        <p className='theme-reviews-qa__qa__card__asked-by'>
          Question asked by: {question.name}
        </p>
        <p className='theme-reviews-qa__qa__card__date'>
          Posted: {DateTime.fromISO(question.created_at).toFormat('dd MMM, y')}
        </p>
      </div>
      <div className='theme-reviews-qa__qa__card__question'>
        {question.question}
      </div>
      {canEditAnswer && !hasDevAnswer && (
        <Suspense fallback=''>
          <AnswerForm
            questionId={question.id}
            editingAnswer={false}
            canEditAnswer={canEditAnswer}
            onCancelAnswer={handleCancelAnswer}
          />
        </Suspense>
      )}
      {question.answers?.map(answer => (
        <Answer
          key={answer.id}
          answer={answer}
          question={question}
        />
      ))}
    </div>
  )
}

QuestionCard.prototype = {
  question: PropTypes.object
}
