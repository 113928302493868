import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { addThemeStyle, updateThemeStyle } from '@redux/themes.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  name: '',
  pageUrl: '',
  demoUrl: '',
  priority: '0',
  color: '',
  colorLight: '',

  desktopImage: null,
  mobileImage: null
}

export default function StyleModal ({ type, style, show, onClose, onSuccess, themeId }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const desktopImageRef = useRef(null)
  const mobileImageRef = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !style || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: style.name,
      pageUrl: style.page_url || '',
      demoUrl: style.demo_url || '',
      priority: style.priority || 0,
      color: style.color || '',
      colorLight: style.color_light || ''
    })
  }, [show, style?.id])

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})

      if (desktopImageRef.current) {
        desktopImageRef.current.value = ''
      }
      if (mobileImageRef.current) {
        mobileImageRef.current.value = ''
      }
    }
  }, [show])

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  function handleSave () {
    let promise

    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      page_url: fields.pageUrl,
      demo_url: fields.demoUrl,
      priority: fields.priority,
      color: fields.color,
      color_light: fields.colorLight,
      desktop: fields.desktopImage,
      mobile: fields.mobileImage
    }).forEach(([key, value]) => {
      if (!value) {
        return
      }

      data.set(key, value)
    })

    if (type === 'edit') {
      promise = updateThemeStyle
    } else {
      promise = addThemeStyle
    }

    dispatch(promise({
      id: style?.id,
      themeId,
      data: data
    }))
      .then(() => {
        setErrors({})
        onSuccess()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log(`error @${type} style`)
        }
      })
  }

  return (
    <Modal
      className='pda-theme-view__style_modal'
      show={show}
    >
      <Header onClose={onClose}>
        {type === 'edit' ? 'Edit Style' : 'Add Style'}
      </Header>
      <Body className='pda-theme-view__style-modal__body'>
        <TextInput
          labelClassName='pda-theme-view__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />

        <TextInput
          labelClassName='pda-theme-view__form__label'
          label='Page URL'
          value={fields.pageUrl}
          onChange={event => setFields({ ...fields, pageUrl: event.currentTarget.value })}
          error={errors.page_url}
          subText={errors.page_url}
        />
        <TextInput
          labelClassName='pda-theme-view__form__label'
          label='Demo URL'
          value={fields.demoUrl}
          onChange={event => setFields({ ...fields, demoUrl: event.currentTarget.value })}
          error={errors.demo_url}
          subText={errors.demo_url}
        />
        <TextInput
          labelClassName='pda-theme-view__form__label'
          label='Priority'
          value={fields.priority}
          onChange={event => setFields({ ...fields, priority: event.currentTarget.value })}
          error={errors.priority}
          subText={errors.priority}
        />
        <TextInput
          labelClassName='pda-theme-view__form__label '
          label={(
            <div className='pda-theme-view__form__color'>
              Color:
              {fields.color && (
                <div className='pda-theme-view__style__color__preview' style={{ backgroundColor: fields.color }} />
              )}
            </div>
          )}
          value={fields.color}
          onChange={event => setFields({ ...fields, color: event.currentTarget.value })}
          error={errors.color}
          subText={errors.color}
        />
        <TextInput
          labelClassName='pda-theme-view__form__label'
          label={(
            <div className='pda-theme-view__form__color'>
              Color Light:
              {fields.colorLight && (
                <div className='pda-theme-view__style__color__preview' style={{ backgroundColor: fields.colorLight }} />
              )}
            </div>
          )}
          value={fields.colorLight}
          onChange={event => setFields({ ...fields, colorLight: event.currentTarget.value })}
          error={errors.color_light}
          subText={errors.color_light}
        />

        <div className='pda-theme__form__group'>
          <TextInput
            inputRef={desktopImageRef}
            type='file'
            labelClassName='pda-theme-view__form__label'
            label='Desktop Image'
            onChange={handleFileChange('desktopImage')}
            accept='image/*'
            error={errors.thumbnail}
            subText={errors.thumbnail ? errors.thumbnail : ''}
          />

          {style?.desktop_image_url && !fields.desktopImage && (
            <img
              className='pda__theme-img-preview'
              src={style?.desktop_image_url}
              alt=''
            />
          )}
        </div>

        <div className='pda-theme__form__group'>
          <TextInput
            inputRef={mobileImageRef}
            type='file'
            labelClassName='pda-theme-view__form__label'
            label='Mobile Image'
            onChange={handleFileChange('mobileImage')}
            accept='image/*'
            error={errors.thumbnail}
            subText={errors.thumbnail ? errors.thumbnail : ''}
          />

          {style?.mobile_image_url && !fields.mobileImage && (
            <img
              className='pda__theme-img-preview'
              src={style?.mobile_image_url}
              alt=''
            />
          )}
        </div>

      </Body>
      <Footer className='pda-theme-view__style-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

StyleModal.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  themeId: PropTypes.string
}
