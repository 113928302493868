import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadCommissions, loadPrograms } from '@redux/referrals/programs'
import { addPartner } from '@redux/referrals/partners'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import TextInput from '@components/Inputs/TextInput'
import SelectInput from '@components/Inputs/SelectInput'

const initialFields = {
  name: '',
  email: '',
  companyName: '',
  companyURL: '',

  programSlug: '',
  customCode: '',
  anchoredCommissionSlug: ''
}

class AddPartnerModal extends React.Component {
  state = {
    fields: { ...initialFields }
  }

  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleProgramChange = this.handleProgramChange.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.show !== prevProps.show && this.props.show
    ) {
      this.loadPrograms()
    }
  }

  loadPrograms () {
    this.props
      .loadPrograms({ status: 'active' })
      .catch(error => {
        console.log('load programs error:', error)
      })
  }

  handleProgramChange (programSlug) {
    this.setState(old => ({
      ...old,
      fields: {
        ...old.fields,
        programSlug
      }
    }), () => {
      this.props.loadCommissions({
        programSlug,
        tierGroup: 'standard'
      })
    })
  }

  handleChangeField (field) {
    return event => {
      const val = event.currentTarget.value

      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: val
        }
      }))
    }
  }

  handleChangeSelect (field) {
    return value => {
      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: value
        }
      }))
    }
  }

  handleClose () {
    this.setState(old => ({
      ...old,
      fields: { ...initialFields }
    }))

    this.props.onClose && this.props.onClose()
  }

  handleSubmit () {
    const { fields } = this.state

    this.props
      .addPartner({
        name: fields.name,
        email: fields.email,
        companyName: fields.companyName,
        companyURL: fields.companyURL,

        programSlug: fields.programSlug,
        customCode: fields.customCode,
        anchoredCommissionSlug: fields.anchoredCommissionSlug
      })
      .then(() => {
        this.setState(old => ({
          ...old,
          fields: { ...initialFields }
        }))

        this.props.onSubmit && this.props.onSubmit()
      })
      .catch(error => {
        console.log('add partner error:', error)
      })
  }

  getCommissions () {
    const { commissions } = this.props

    const com = commissions[this.state.fields.programSlug]

    if (!com || !(com.data?.length)) {
      return []
    }

    return com.data
  }

  render () {
    const { show } = this.props
    const commissions = this.getCommissions()

    return (
      <Modal show={show} small>
        <ModalHeader onClose={this.handleClose}>
          Add partner
        </ModalHeader>
        <ModalBody>
          <TextInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='Company name'
            type='text'
            onChange={this.handleChangeField('companyName')}
            value={this.state.fields.companyName}
          />

          <TextInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='Company URL'
            type='text'
            onChange={this.handleChangeField('companyURL')}
            value={this.state.fields.companyURL}
          />

          <TextInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='User name'
            type='text'
            onChange={this.handleChangeField('name')}
            value={this.state.fields.name}
          />

          <TextInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='Email address'
            type='email'
            onChange={this.handleChangeField('email')}
            value={this.state.fields.email}
          />

          <SelectInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='Program'
            name='program'
            value={this.state.fields.programSlug}
            onChange={this.handleProgramChange}
            placeholder='Select Program'
            options={this.props.programs.map(p => ({
              key: p.id,
              value: p.slug,
              label: p.name
            }))}
          />

          <SelectInput
            labelClassName='admin__partners__add-partner-modal__input-label'
            label='Anchored Commission Level (optional)'
            name='program'
            value={this.state.fields.anchoredCommissionSlug}
            onChange={this.handleChangeSelect('anchoredCommissionSlug')}
            placeholder={(
              commissions.length > 0
                ? 'Select commission level'
                : `No commission levels${!this.state.fields.programSlug ? ' (select program)' : ''}`
            )}
            options={commissions.map(c => ({
              key: c.id,
              value: c.slug,
              label: c.name
            }))}
          />

          <div className='pd__modals__two-columns'>
            <Button primary className='pd__modals__two-columns__button' onClick={this.handleSubmit}>
              Save changes
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.handleClose}>
              Cancel
            </Button>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

AddPartnerModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  programLoading: PropTypes.bool,
  addPartner: PropTypes.func,
  loadPrograms: PropTypes.func,
  loadCommissions: PropTypes.func,
  programs: PropTypes.array,
  commissions: PropTypes.object
}

const mapStateToProps = ({ referrals: { partners, programs } }) => ({
  loading: partners.saveLoading,
  programs: programs.data,
  programLoading: programs.loading,
  commissions: programs.commissions
})

const mapDispatchToProps = dispatch => ({
  addPartner: payload => dispatch(addPartner(payload)),
  loadPrograms: payload => dispatch(loadPrograms(payload)),
  loadCommissions: payload => dispatch(loadCommissions(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddPartnerModal)
