import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import selectArrow from '@assets/icons/select-arrow.svg'

import './MonthGrid.scss'

class MonthGrid extends React.Component {
  state = {
    ready: false,
    month: null,
    picking: false,

    grid: []
  }

  componentDidMount () {
    const { start, end, month } = this.props

    this.buildWeekGrid({ start, end, month })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.start !== prevProps.start ||
      this.props.end !== prevProps.end ||
      this.props.month !== prevProps.month
    ) {
      this.buildWeekGrid({
        start: this.props.start,
        end: this.props.end,
        month: this.props.month
      })
    }
  }

  handleDayClick (day) {
    return () => {
      let start = null
      let end = null

      if (!this.state.picking) {
        start = day
      } else {
        start = this.props.start

        // if (start && start.hasSame(day, 'day')) {
        //   // dont pick same day
        //   return
        // }

        // if picks an "older" date than "start"
        // then swap them
        if (day < start) {
          end = this.props.start
          start = day
        } else {
          end = day
        }

        start = start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        end = end.set({ hour: 23, minute: 59, second: 59 })
      }

      this.setState(old => ({
        picking: !old.picking
      }), () => {
        this.props.onChange && this.props.onChange({
          start: start,
          end: end
        })
      })
    }
  }

  handleChangeMonth (forward) {
    return () => {
      const month = this.props.month.plus({ month: forward ? 1 : -1 })

      this.props.onChangeMonth({ month })
    }
  }

  buildWeekGrid ({ month, start, end }) {
    // 5 weeks
    const grid = []
    let days = 7 * 5

    if (month.weekday + 31 > 36) {
      // add extra week
      days = days + 7
    }

    let day = month.toUTC().set({ day: 1 }).set({ weekday: 1 })
    const nMonth = month.month

    for (let i = 0; i < days; i++) {
      const isStart = (start && day.hasSame(start, 'day'))
      const isEnd = (end && day.hasSame(end, 'day'))
      const selected = isStart || isEnd
      const ranged = start && end && start < day && day < end

      grid.push({
        dt: day,
        off: day.month !== nMonth,
        selected: selected,
        ranged: ranged,
        isStart,
        isEnd
      })

      day = day.plus({ day: 1 })
    }

    this.setState(old => ({
      ...old,
      grid,
      ready: true
    }))
  }

  render () {
    if (!this.state.ready) {
      return null
    }

    return (
      <div className='hc-date-picker__month-grid'>
        <div className='hc-date-picker__month-selector'>
          <div
            className='hc-date-picker__arrow hc-date-picker__arrow--prev'
            onClick={this.handleChangeMonth(false)}
          >
            <img src={selectArrow} />
          </div>
          <div className='hc-date-picker__month'>
            {this.props.month.toFormat('MMMM y')}
          </div>
          <div
            className='hc-date-picker__arrow hc-date-picker__arrow--next'
            onClick={this.handleChangeMonth(true)}
          >
            <img src={selectArrow} />
          </div>
        </div>

        <div className='hc-date-picker__week-grid'>
          {this.state.grid.map(day => (
            <div
              key={day.dt}
              className={classnames('hc-date-picker__week-day', {
                'hc-date-picker__week-day--off': day.off,
                'hc-date-picker__week-day--selected': day.selected,
                'hc-date-picker__week-day--ranged': day.ranged,
                'hc-date-picker__week-day--start': day.isStart,
                'hc-date-picker__week-day--end': day.isEnd
              })}
              onClick={this.handleDayClick(day.dt)}
            >
              {day.dt.toFormat('d')}
            </div>
          ))}
        </div>
      </div>
    )
  }
}

MonthGrid.propTypes = {
  // luxon DateTime
  start: PropTypes.object,
  end: PropTypes.object,
  month: PropTypes.object,

  onChange: PropTypes.func,
  onChangeMonth: PropTypes.func
}

export default MonthGrid
