import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import {
  loadBalance,
  loadWithdrawalDraft,
  loadWithdrawalList,
  requestWithdrawal,
  toggleAffiliateModal
} from '@redux/referrals/withdrawals'

import Modal, { Body as ModalBody, Header as ModalHeader, Footer as ModalFooter } from '@components/Modal'
import Button from '@components/Button'

import trendUp from '@assets/icons/trend-up.svg'

import './AffiliateWithdrawalModal.scss'

function AffiliateWithdrawalModal (props) {
  const [errorMessage, setErrorMessage] = useState('')
  const [lockWithdrawal, setLockWithdrawal] = useState(false)

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!props.open) {
      return
    }

    props.loadWithdrawalDraft()
  }, [props.open])

  function computeBalance () {
    const { items } = props

    const balance = items.reduce((acc, curr) => {
      if (!curr.commission) {
        return curr
      }

      return acc + Number(curr.commission)
    }, 0)

    return balance.toFixed(2)
  }

  function handleClose () {
    const { toggleModal } = props

    toggleModal(false)

    setErrorMessage('')
    setLockWithdrawal(false)
  }

  function handleWithdraw () {
    const { items, requestWithdrawal } = props

    return requestWithdrawal({ items })
      .then(() => {
        const { toggleModal, loadWithdrawals } = props

        toggleModal(false)
        dispatch(loadBalance())

        if (
          matchPath({
            path: '/settings'
          }, location.pathname)
        ) {
          loadWithdrawals({ limit: 10 })
        }
      })
      .catch(error => {
        if (!error.response || !error.response.data) {
          console.log('make withdrawal request error', error)
          return
        }

        const { code, message } = error.response.data

        if (
          code === 'pending_withdrawal' ||
          code === 'empty_withdrawal'
        ) {
          setLockWithdrawal(true)
          setErrorMessage(message)
        }
      })
  }

  const { open, requestLoading, draftLoading } = props
  // const { errorMessage, lockWithdrawal } = this.state

  return (
    <Modal className='pa_withdrawal-modal' show={open}>
      <ModalHeader
        className='pa_withdrawal-modal__header'
        onClose={requestLoading ? undefined : handleClose}
      />
      <ModalBody className='pa_withdrawal-modal__body'>
        <div className='pa_withdrawal-modal__title'>
          Withdraw your affiliate balance
        </div>

        <div className='pa_withdrawal-modal__desc'>
          You are about to cash out on your affiliate commission
          from referrals sent to HeyCarson.
        </div>

        <div className='pa_withdrawal-modal__earnings__label'>
          Total earnings (USD)
        </div>
        <div className='pa_withdrawal-modal__earnings__amount'>
          {draftLoading ? '...' : computeBalance()}
        </div>

        <div className='pa_withdrawal-modal__info'>
          Please click the button below to proceed, and we will process
          your request within 24-48 hours.
        </div>

        {errorMessage && (
          <div className='pa_withdrawal-modal__error'>
            {errorMessage}
          </div>
        )}
      </ModalBody>
      <ModalFooter className='pa_withdrawal-modal__footer'>
        <Button
          disabled={requestLoading}
          className='pa_withdrawal-modal__footer__discard'
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          success
          disabled={requestLoading || lockWithdrawal}
          className='pa_withdrawal-modal__footer__approve'
          onClick={handleWithdraw}
        >
          <img
            className='pa_withdrawal-modal__footer__icon'
            src={trendUp}
            alt='withdraw'
          />
          Withdraw
        </Button>
      </ModalFooter>
    </Modal>
  )
}

AffiliateWithdrawalModal.propTypes = {
  open: PropTypes.bool,
  toggleModal: PropTypes.func,

  draftLoading: PropTypes.bool,
  items: PropTypes.array,
  loadWithdrawalDraft: PropTypes.func,

  requestLoading: PropTypes.bool,
  requestWithdrawal: PropTypes.func,

  loadWithdrawals: PropTypes.func
}

const mapStateToProps = ({ referrals: { withdrawals: base } }) => ({
  open: base.affiliateModalOpen,

  // draft
  items: base.draft.items,
  draftLoading: base.draft.loading,

  requestLoading: base.withdrawal.loading
})
const mapDispatchToProps = dispatch => ({
  toggleModal: open => dispatch(toggleAffiliateModal(open)),
  loadWithdrawals: payload => dispatch(loadWithdrawalList(payload)),
  loadWithdrawalDraft: payload => dispatch(loadWithdrawalDraft(payload)),
  requestWithdrawal: payload => dispatch(requestWithdrawal(payload))
})

const ConnectedWithdrawalModal = connect(mapStateToProps, mapDispatchToProps)(AffiliateWithdrawalModal)

export default ConnectedWithdrawalModal
