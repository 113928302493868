import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  addWithdrawalItem,
  loadWithdrawal,
  removeWithdrawalItem,
  updateWithdrawalItem
} from '@redux/referrals/withdrawals'

import Modal, { Body as ModalBody, Header as ModalHeader } from '@components/Modal'
import Button from '@components/Button'
import Dropdown from '@components/DropdownList'
import TextInput from '@components/Inputs/TextInput'

import editIcon from '@assets/icons/edit.svg'

import './EditWithdrawalModal.scss'

class EditWithdrawalModal extends React.Component {
  state = {
    ready: false,
    showRemoveModal: false,
    showItemModal: false,
    action: '',
    fields: {
      title: '',
      description: '',
      amount: '0.00'
    },
    error: '',
    withdrawalId: '',
    itemId: ''
  }

  constructor (props) {
    super(props)

    this.handleClickOption = this.handleClickOption.bind(this)
    this.handleCloseRemove = this.handleCloseRemove.bind(this)
    this.handleConfirmRemoveItem = this.handleConfirmRemoveItem.bind(this)

    this.handleClickItem = this.handleClickItem.bind(this)
    this.handleCloseItem = this.handleCloseItem.bind(this)
    this.handleConfirmItem = this.handleConfirmItem.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.withdrawalId &&
      this.props.withdrawalId !== prevProps.withdrawalId
    ) {
      this.loadWithdrawal()
    }
  }

  loadWithdrawal () {
    this.props.loadWithdrawal({
      withdrawalId: this.props.withdrawalId,
      withPartner: true,
      withItems: true
    }).then(() => {
      this.setState(old => ({
        ...old,
        ready: true
      }))
    }).catch(error => {
      console.log('load withdrawal error: ', error)
    })
  }

  handleInputChange (field) {
    return event => {
      const val = event.currentTarget.value

      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: val
        }
      }))
    }
  }

  handleClickOption ({ option, onClose }) {
    switch (option.key) {
      case 'remove':
        onClose()
        this.handleClickRemoveItem({ wi: option.value })
        break
      case 'edit':
        onClose()
        this.handleClickItem({ wi: option.value, action: 'edit' })
        break
      default:
        onClose()
        break
    }
  }

  handleClickRemoveItem ({ wi, onClose }) {
    this.setState(old => ({
      ...old,
      showRemoveModal: true,
      action: 'remove',
      withdrawalId: wi.withdrawal_id,
      itemId: wi.id
    }))
  }

  handleCloseRemove () {
    this.setState(old => ({
      ...old,
      showRemoveModal: false,
      withdrawalId: null,
      itemId: null
    }))
  }

  handleConfirmRemoveItem () {
    this.props.removeItem({
      withdrawalId: this.state.withdrawalId,
      itemId: this.state.itemId
    }).then(() => {
      this.loadWithdrawal()
      this.handleCloseRemove()
    })
  }

  handleClickItem ({ wi, action } = {}) {
    const { withdrawal } = this.props
    let data
    let fields = {
      title: '',
      description: '',
      amount: ''
    }

    if (action === 'add') {
      data = {
        sourceId: '',
        sourceType: 'manual',
        itemId: null,
        withdrawalId: withdrawal.id
      }
    } else if (action === 'edit') {
      data = {
        sourceId: wi.source_id,
        sourceType: wi.source_type,
        itemId: wi?.id,
        withdrawalId: wi?.withdrawal_id
      }
      fields = {
        title: wi.title,
        description: wi.description,
        amount: wi.amount
      }
    }

    this.setState(old => ({
      ...old,
      showItemModal: true,
      action: action,
      withdrawalId: wi?.withdrawal_id,
      ...data,
      fields: {
        ...old.fields,
        ...fields
      }
    }))
  }

  handleCloseItem () {
    this.setState(old => ({
      ...old,
      showItemModal: false,
      action: '',
      withdrawalId: null,
      itemId: null
    }))
  }

  handleConfirmItem () {
    const { action, fields, withdrawalId, itemId, sourceId, sourceType } = this.state
    let promise

    const data = {
      withdrawalId,
      itemId,

      title: fields.title,
      description: fields.description,
      amount: fields.amount,
      sourceId,
      sourceType
    }

    if (action === 'add') {
      promise = this.props.addItem
    } else if (action === 'edit') {
      promise = this.props.updateItem
    }

    promise(data)
      .then(() => {
        this.loadWithdrawal()
        this.handleCloseItem()
      })
      .catch(error => {
        console.log(`${action} withdrawal item error: `, error)
      })
  }

  render () {
    const { show, withdrawal, loading, items } = this.props
    const { ready } = this.state

    return (
      <>
        <Modal
          show={show}
          className='pd__modals'
        >
          <ModalHeader
            className='pd__modals__header'
            onClose={this.props.onClose}
          >
            Edit withdrawal request
          </ModalHeader>
          <ModalBody className='pd__modals__body withdrawal-edit-modal'>
            {(loading && !ready) && (
              <span>
                Loading...
              </span>
            )}

            {ready && withdrawal && (
              <>
                <div className='withdrawal-edit-modal__details'>
                  <div className='withdrawal-edit-modal__withdrawal'>
                    Partner: <br />
                    <strong>{withdrawal.partner?.name}</strong>
                  </div>
                  <div className='withdrawal-edit-modal__amount'>
                    Amount: <br />
                    <strong>${withdrawal.commission}</strong>
                  </div>
                </div>
              </>
            )}

            <div className='withdrawal-edit-modal__separator' />

            <div className='withdrawal-edit-modal__withdrawals'>
              {items.map(wi => (
                <div key={wi.id} className='withdrawal-edit-modal__item'>
                  <div className='withdrawal-edit-modal__item__title'>
                    {wi.title}

                    {wi.description && (
                      <div className='withdrawal-edit-modal__item__desc'>
                        {wi.description}
                      </div>
                    )}
                  </div>

                  <div className='withdrawal-edit-modal__item__amount'>
                    ${wi.amount}
                  </div>

                  <Dropdown
                    right
                    onClose={() => {}}
                    activator={({ onClick }) => (
                      <div className='withdrawal-edit-modal__item__button' onClick={onClick}>
                        <img src={editIcon} alt='edit' />
                      </div>

                    )}
                    options={({ open, onClose }) => [{
                      key: 'edit',
                      label: 'Edit',
                      value: wi
                    }, {
                      key: 'remove',
                      label: 'Remove',
                      value: wi
                    }]}
                    onClickOption={this.handleClickOption}
                  />
                </div>
              ))}
            </div>

            <div className='withdrawal-edit-modal__separator' />

            <div className='pd__modals__two-columns'>
              <Button
                primary
                className='pd__modals__two-columns__button'
                onClick={() => this.handleClickItem({ action: 'add' })}
              >
                Add Item
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal show={this.state.showItemModal}>
          <ModalHeader
            className='pd__modals__header'
            onClose={this.handleCloseItem}
          >
            Add/Edit withdrawal Item
          </ModalHeader>
          <ModalBody>
            <div>
              Title:
              <TextInput
                onChange={this.handleInputChange('title')}
                value={this.state.fields.title}
              />
            </div>

            <br />
            <div>
              Description:
              <TextInput
                onChange={this.handleInputChange('description')}
                value={this.state.fields.description}
              />
            </div>

            <br />
            <div>
              Amount:
              <TextInput
                onChange={this.handleInputChange('amount')}
                value={this.state.fields.amount}
              />
            </div>

            <br />
            <br />

            <div className='pd__modals__two-columns'>
              <Button
                primary
                className='pd__modals__two-columns__button'
                onClick={this.handleConfirmItem}
              >
                Confirm
              </Button>
              <Button secondary className='pd__modals__two-columns__button' onClick={this.handleCloseItem}>
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal show={this.state.showRemoveModal}>
          <ModalHeader
            className='pd__modals__header'
            onClose={this.handleCloseRemove}
          >
            Remove withdrawal Item
          </ModalHeader>
          <ModalBody>
            Are you sure want to remove this withdrawal item?

            <br />
            <br />

            <div className='pd__modals__two-columns'>
              <Button primary className='pd__modals__two-columns__button' onClick={this.handleConfirmRemoveItem}>
                Confirm
              </Button>
              <Button secondary className='pd__modals__two-columns__button' onClick={this.handleCloseRemove}>
                Close
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </>
    )
  }
}

EditWithdrawalModal.propTypes = {
  withdrawalId: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  loadWithdrawal: PropTypes.func,

  withdrawal: PropTypes.object,
  items: PropTypes.array,
  loading: PropTypes.bool,

  removeItem: PropTypes.func,
  addItem: PropTypes.func,
  updateItem: PropTypes.func
}

const mapStateToProps = ({ referrals: { withdrawals: base } }) => ({
  withdrawal: base.withdrawal.data,
  loading: base.withdrawal.loading,
  items: base.withdrawal.items
})
const mapDispatchToProps = dispatch => ({
  loadWithdrawal: payload => dispatch(loadWithdrawal(payload)),
  addItem: payload => dispatch(addWithdrawalItem(payload)),
  updateItem: payload => dispatch(updateWithdrawalItem(payload)),
  removeItem: payload => dispatch(removeWithdrawalItem(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditWithdrawalModal)
