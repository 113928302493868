import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import close from '@assets/icons/close-gray.svg'
import infoIcon from '@assets/icons/alert-icon-info.svg'
import dangerIcon from '@assets/icons/alert-icon-danger.svg'
import warningIcon from '@assets/icons/alert-icon-warning.svg'
import successIcon from '@assets/icons/alert-icon-success.svg'

import './Toastr.scss'

const getIconType = type => {
  switch (type) {
    case 'danger':
      return dangerIcon
    case 'warning':
      return warningIcon
    case 'success':
      return successIcon
    case 'info':
      return infoIcon
    default:
      return ''
  }
}

export default function Toastr (props) {
  const [show, setShow] = useState(false)
  const fadeTimeout = useRef(0)

  useEffect(() => {
    if (!window) {
      return
    }

    const timeOut = props.timeOut || 3000

    if (timeOut > 0) {
      // remove toastr after set timeout
      setTimeout(() => {
        setShow(true)

        fadeTimeout.current = setTimeout(handleHide, timeOut)
      }, 50)
    } else {
      setShow(true)
    }

    return () => {
      clearTimeout(fadeTimeout.current)
    }
  }, [])

  function handleHide () {
    clearTimeout(fadeTimeout.current)

    setShow(false)

    props.onClose && props.onClose()
  }

  return (
    <div className={classnames(
      `usi-toastr__container usi-toastr__container--${props.color}`,
      props.position ? `usi-toastr__container--${props.position}` : '',
      { 'usi-toastr__container--show': show }
    )}
    >
      <div className='usi-toastr__head'>
        <div className='usi-toastr__icon'>
          <img src={getIconType(props.color)} alt='' />
        </div>

        <div className='usi-toastr__title'>
          {props.title}
        </div>

        <div className='usi-toastr__close-container' onClick={handleHide}>
          <img className='usi-toastr__close' src={close} alt='close' />
        </div>
      </div>

      {props.text && (
        <div className='usi-toastr__body'>
          <div className='usi-toastr__text'>
            {props.text}
          </div>
        </div>
      )}
    </div>
  )
}

Toastr.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string,
  timeOut: PropTypes.number,
  onClose: PropTypes.func
}
