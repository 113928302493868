import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { connect } from 'react-redux'

import { loadBalance } from '@redux/referrals/withdrawals'

import UpdatePartnerModal from '@pages/Partners/Admin/Partners/UpdatePartnerModal'

import editIcon from '@assets/icons/edit.svg'

import './AccountDetails.scss'

class PartnerAccountDetails extends React.Component {
  state = {
    showEditModal: false,
    ready: false
  }

  constructor (props) {
    super(props)

    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.handleCloseEdit = this.handleCloseEdit.bind(this)
  }

  componentDidMount () {
    if (this.props.partner) {
      this.setState(old => ({ ...old, ready: true }))
      this.loadBalance()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.partner &&
      this.props.partner.id !== prevProps.partner?.id
    ) {
      this.setState(old => ({ ...old, ready: true }))
      this.loadBalance()
    }
  }

  loadBalance () {
    this.props.loadBalance({
      partnerId: this.props.partner.id
    }).catch(error => {
      console.error('loadBalance@error', error)
    })
  }

  handleOpenEdit () {
    this.setState(old => ({
      showEditModal: true
    }))
  }

  handleCloseEdit () {
    this.setState(old => ({
      showEditModal: false
    }))
  }

  render () {
    const { partner } = this.props
    if (!partner) {
      return null
    }

    return (
      <div className='admin-pview__details'>
        <div className='admin-pview__details__top'>
          <div className='admin-pview__details__title'>
            Details
          </div>

          <div className='admin-pview__details__edit' onClick={this.handleOpenEdit}>
            <img src={editIcon} alt='edit' />
          </div>
        </div>

        <div className='admin-pview__details__items'>
          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Company / Web
            </div>
            <div className='admin-pview__details__item__value'>
              {partner.name} <br />
              {partner.url}
            </div>
          </div>
          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              City / Country
            </div>
            <div className='admin-pview__details__item__value'>
              {!partner.city && !partner.country && (
                <span>N/A</span>
              )}
              {partner.city}
              {partner.country && (
                <span>, <br /> {partner.country}</span>
              )}
            </div>
          </div>
          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Pending Payments
            </div>
            <div className='admin-pview__details__item__value'>
              {this.props.balance} USD
            </div>
          </div>
          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Registered
            </div>
            <div className='admin-pview__details__item__value'>
              {DateTime.fromISO(partner.created_at).toFormat('LLL d, y')}
            </div>
          </div>
        </div>

        <UpdatePartnerModal
          partner={this.props.partner}
          show={this.state.showEditModal}
          onClose={this.handleCloseEdit}
        />
      </div>
    )
  }
}

PartnerAccountDetails.propTypes = {
  partner: PropTypes.object,
  balance: PropTypes.string,
  loadBalance: PropTypes.func
}

const mapStateToProps = ({ referrals: { withdrawals } }) => ({
  balance: withdrawals.balance
})
const mapDispatchToProps = dispatch => ({
  loadBalance: payload => dispatch(loadBalance(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerAccountDetails)
