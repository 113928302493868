import ReferralsAPI from '@util/referrals-api'
import { getSymbol } from '@util/currency'

import level1Purple from '@assets/icons/partner-level-1-purple.svg'
import level1 from '@assets/icons/partner-level-1.svg'
import level2Purple from '@assets/icons/partner-level-2-purple.svg'
import level2 from '@assets/icons/partner-level-2.svg'
import level3Purple from '@assets/icons/partner-level-3-purple.svg'
import level3 from '@assets/icons/partner-level-3.svg'
import level4Purple from '@assets/icons/partner-level-4-purple.svg'
import level4 from '@assets/icons/partner-level-4.svg'

// ordered lowest to highest
export const partnerLevels = [{
  name: 'Level 1',
  slug: 'level-1',
  number: 1,
  requirement: 0,
  commission: '10%',
  text: 'Up to $15k referral volume, you earn 10% commission for every first purchase.',
  image: level1,
  imagePurple: level1Purple
}, {
  name: 'Level 2',
  slug: 'level-2',
  number: 2,
  requirement: 15000,
  commission: '12.5%',
  text: 'Up to $25k referral volume, you earn 12.5% commission for every first purchase.',
  image: level2,
  imagePurple: level2Purple
}, {
  name: 'Level 3',
  slug: 'level-3',
  number: 3,
  requirement: 25000,
  commission: '15%',
  text: 'Up to $50k referral volume, you earn 15% commission for every first purchase.',
  image: level3,
  imagePurple: level3Purple
}, {
  name: 'Level 4',
  slug: 'level-4',
  number: 4,
  requirement: 50000,
  commission: '18%',
  text: 'Above $50k referral volume, you earn 18% commission for every first purchase and 5% lifetime commission.',
  image: level4,
  imagePurple: level4Purple
}].sort((p, n) => {
  if (p.requirement < n.requirement) {
    return -1
  } else if (p.requirement > n.requirement) {
    return 1
  }

  return 0
})

export const getLevel = slug => partnerLevels.find(l => l.slug === slug)

export const getPaymentInfo = metadata => {
  if (!metadata?.payment_type) {
    return ''
  }

  let type = ''
  let desc = metadata.purchase_description || ''

  switch (metadata.payment_type) {
    case 'subscription':
      type = 'Subscription'
      break
    case 'credit':
      type = 'Credit'
      break
    case 'catalog-task':
      type = 'Task'
      break
    default:
      return ''
  }

  if (desc.length > 25) {
    desc = desc.slice(0, 22).trim() + '...'
  }

  if (desc) {
    desc = ` [${desc}]`
  }

  return `${type}${desc}`
}

export const getCommissionInfo = (conversion, currency = '') => {
  if (!currency) {
    return 'Unknown Currency'
  }

  let type = ''
  let off = ''
  let percent = ''

  switch (conversion.commission_type) {
    case 'percentage':
      percent = '%'
      break
    case 'flat':
      type = getSymbol(currency)
      off = ' OFF'
  }

  return `${type}${conversion.commission_amount}${off}${percent}`
}

export const getConversionStatus = status => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'approved':
      return 'Approved'
    case 'declined':
      return 'Declined'
    case 'expired':
      return 'Expired'
    case 'paid':
      return 'Paid'
    default:
      return 'Unknown'
  }
}
export const getRefCodeStatus = status => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'active':
      return 'Active'
    case 'paused':
      return 'Paused'
    default:
      return 'Unknown'
  }
}
export const getWithdrawalStatus = status => {
  switch (status) {
    case 'pending':
      return 'Pending'
    case 'declined':
      return 'Declined'
    case 'paid':
      return 'Paid'
    default:
      return 'Unknown'
  }
}

const refApi = new ReferralsAPI({
  endpoint: import.meta.env.VITE_PARTNERS_API_URL,
  cookieDomain: import.meta.env.VITE_REF_COOKIE_DOMAIN,
  accountId: import.meta.env.VITE_REF_ACCOUNT_ID,
  apiKey: import.meta.env.VITE_REF_PUBLIC_KEY
})

export default refApi
