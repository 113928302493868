import React, { useEffect, useState } from 'react'
import qs from 'qs'

import Button from '@components/Button'
import ClickListContainer from '@containers/PartnerDashboard/ClickList'

import './Clicks.scss'
import { useLocation, useNavigate } from 'react-router-dom'

function AdminClicksPage () {
  const [referralIds, setReferralIds] = useState([])

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const search = qs.parse(location.search || '', { ignoreQueryPrefix: true })
    const referralIds = (search.referral_id || '').split(',').filter(v => v)

    setReferralIds(referralIds)
  }, [location.search])

  function handleRemovePartner () {
    const query = qs.stringify({
      ...(qs.parse(location.search, { ignoreQueryPrefix: true })),
      referral_id: undefined
    }, { ignoreQueryPrefix: true })

    navigate(`/clicks?${query}`)
  }

  return (
    <div className='admin-clkl'>
      <div className='admin-clkl__title'>
        Clicks
      </div>

      {referralIds.length > 0 && (
        <>
          <Button
            className='admin-clkl__remove-button'
            onClick={handleRemovePartner}
          >
            Remove Partner Filter
          </Button>
        </>
      )}

      <ClickListContainer
        limit={15}
        referralIds={referralIds}
      />
    </div>
  )
}

AdminClicksPage.propTypes = {}

export default AdminClicksPage
