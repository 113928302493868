import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { updateProfile } from '@redux/developers'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index'
import TextInput from '@components/Inputs/TextInput'
import SelectInput from '@components/Inputs/SelectInput'
import Button from '@components/Button/index'
import Label from '@components/Inputs/Label.jsx'
import CheckBox from '@components/Inputs/CheckBox.jsx'

import './DeveloperModal.scss'
import classnames from 'classnames'

const initialFields = {
  name: '',
  url: '',
  description: '',
  meta_title: '',
  meta_description: '',
  referral_query: '',
  visible: null,
  is_claimed: null,
  capabilities: []
}

export default function DeveloperModal ({ show, type, onSuccess, developer, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const [submitting] = useSelector(({ developers }) => [developers.submitting])

  const dispatch = useDispatch()
  const imageRef = useRef(null)
  const coverRef = useRef(null)

  useEffect(() => {
    if (!show || !developer || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: developer.name || '',
      url: developer.url || '',
      description: developer.description || '',
      meta_title: developer.meta_title || '',
      meta_description: developer.meta_description || '',
      referral_query: developer.referral_query || '',
      visible: developer.visible ? '1' : '0',
      is_claimed: developer.is_claimed ? '1' : '0',
      capabilities: developer.capabilities || []
    })
  }, [show, developer])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      if (imageRef.current) {
        imageRef.current.value = ''
      }
      if (coverRef.current) {
        coverRef.current.value = ''
      }
    }
  }, [show])

  function handleSave () {
    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      url: fields.url,
      description: fields.description,
      meta_title: fields.meta_title,
      meta_description: fields.meta_description,
      referral_query: fields.referral_query,
      visible: fields.visible,
      is_claimed: fields.is_claimed,
      capabilities: fields.capabilities.join(',')
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    if (!fields.capabilities.length) {
      setErrors(old => ({ ...old, capabilities: 'Please select at least one capability' }))
      return
    }

    if (fields.image) {
      data.set('image', fields.image)
    }
    if (fields.cover) {
      data.set('cover', fields.cover)
    }

    dispatch(updateProfile({
      id: developer.id,
      data
    }))
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'profile_not_found'
        ) {
          setErrors(old => ({ ...old, developer_id: error.response.data.message }))
        } else {
          console.log(`error @${type} developer`)
        }
      })
  }

  function handleChangeCapabilities (cap) {
    return checked => {
      const capabilities = fields.capabilities.filter(capability => capability !== cap)

      if (checked) {
        capabilities.push(cap)
      }

      setFields({ ...fields, capabilities })
    }
  }

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  return (
    <Modal show={show} className='pda-theme__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Developer
      </Header>
      <Body className='pda-theme__add-modal__body'>
        <TextInput
          labelClassName='pda-theme__form__label'
          label='Name *'
          placeholder='Developer Name'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />
        <TextInput
          labelClassName='pda-theme__form__label'
          label='URL *'
          placeholder='https://example.com'
          value={fields.url}
          onChange={event => setFields({ ...fields, url: event.currentTarget.value })}
          error={errors.url}
          subText={errors.url}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Description'
          value={fields.description}
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
          subText={errors.description}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Meta Title'
          value={fields.meta_title}
          onChange={event => setFields({ ...fields, meta_title: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />

        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Meta Description'
          value={fields.meta_description}
          onChange={event => setFields({ ...fields, meta_description: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />
        <TextInput
          labelClassName='pda-theme__form__label'
          label='Referral query'
          value={fields.referral_query}
          placeholder='?ref=ambassador'
          onChange={event => setFields({ ...fields, referral_query: event.currentTarget.value })}
          error={errors.referral_query}
          subText={errors.referral_query}
        />
        <SelectInput
          label='Visible *'
          labelClassName='pda-theme__form__label'
          value={fields.visible}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, visible: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: '1'
          }, {
            key: '0',
            label: 'No',
            value: '0'
          }]}
        />

        <Label className='pda-theme__form__label'>
          <span>Capabilities* (Theme/App dev. Or both)</span>

          <Columns>
            <div>
              Themes:&nbsp;
              <CheckBox
                className='pda-developer-modal__checkbox'
                checked={fields.capabilities.includes('themes')}
                onChange={handleChangeCapabilities('themes')}

              />
            </div>

            <div>
              Apps:&nbsp;
              <CheckBox
                className='pda-developer-modal__checkbox'
                checked={fields.capabilities.includes('apps')}
                onChange={handleChangeCapabilities('apps')}
              />
            </div>
          </Columns>

          {errors.capabilities && (
            <span
              className={classnames('hc-select__sub', {
                'hc-select__sub--error': errors.capabilities
              })}
            >
              {errors.capabilities}
            </span>
          )}
        </Label>

        <SelectInput
          label='Claimed *'
          labelClassName='pda-theme__form__label'
          value={fields.is_claimed}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, is_claimed: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: '1'
          }, {
            key: '0',
            label: 'No',
            value: '0'
          }]}
        />

        <Columns>
          <div className='pda-theme__form__group_image'>
            <TextInput
              inputRef={imageRef}
              type='file'
              labelClassName='pda-theme__form__label'
              label='Image *'
              onChange={handleFileChange('image')}
              accept='image/*'
            />
            {(developer?.image_url || fields.image) && (
              <img
                className='pda__theme-img-preview__large'
                src={fields.image ? URL.createObjectURL(fields.image) : developer.image_url}
                alt=''
              />
            )}
          </div>
          <div className='pda-theme__form__group-image'>
            <TextInput
              inputRef={coverRef}
              type='file'
              labelClassName='pda-theme__form__label'
              label='Cover Image'
              onChange={handleFileChange('cover')}
              accept='image/*'
            />
            {(developer?.cover_url || fields.cover) && (
              <img
                className='pda__theme-img-preview__large'
                src={fields.cover ? URL.createObjectURL(fields.cover) : developer.cover_url}
                alt=''
              />
            )}
          </div>
        </Columns>
      </Body>
      <Footer className='pda-theme__add-modal__footer'>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeveloperModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  review: PropTypes.object
}
