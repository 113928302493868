import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { shortAmount } from '@util/currency'
import { loadAggregatedStats } from '@redux/referrals/dashboard'

import './DateView.scss'

class GeneralView extends React.Component {
  state = {}

  componentDidMount () {
    this.loadStats()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { period, aggregate } = this.props

    if (
      prevProps.period.start !== period.start || prevProps.period.end !== period.end ||
      prevProps.aggregate !== aggregate
    ) {
      this.loadStats()
    }
  }

  loadStats () {
    const { period, aggregate } = this.props

    this.props.loadAggregatedStats({
      aggregate,
      start: period.start.toISO(),
      end: period.end.toISO()
    })
  }

  getStats (stats) {
    const transformStat = (current) => {
      const s = {
        ...current,
        raw: current.value
      }

      if (
        current.name === 'conversion_amount' ||
        current.name === 'conversion_commission' ||
        current.name === 'first_conversion_amount'
      ) {
        s.value = shortAmount(Number(current.value) || 0, 'USD')
      }

      return s
    }
    const reduceStats = (acc, current) => {
      acc[current.name] = current

      return acc
    }

    const baseStat = stats.map(transformStat, {})

    return baseStat.reduce(reduceStats, {})
  }

  render () {
    return (
      <div>
        <table className='pah__hero__aggregates' cellSpacing={0} cellPadding={0}>
          <thead>
            {/* @formatter:off */}
            <tr className='pah__hero__aggregate'>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header pah__hero__aggregate__column--date'>Date</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>total traffic<br /> (clicks)</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>total<br /> signups</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>submitted<br /> projects</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>FIRST<br /> projects</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>completed<br /> projects</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>canceled<br /> projects</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>total purchases/<br />amount</td>
              <td className='pah__hero__aggregate__column pah__hero__aggregate__column--header'>first purchases/<br />amount</td>
            </tr>
            {/* @formatter:on */}
          </thead>

          <tbody>
            {this.props.stats.map(period => {
              const stat = this.getStats(period.data)
              // respect UTC
              const start = DateTime.fromISO(period.start, { setZone: true })
              const end = DateTime.fromISO(period.end, { setZone: true })

              const singleDay = start.hasSame(end, 'day')

              return (
                <tr key={`${period.start}-${period.end}`} className='pah__hero__aggregate'>
                  <td className='pah__hero__aggregate__column pah__hero__aggregate__title'>
                    {start.toFormat('MMM dd, yy')}
                    {!singleDay && (
                      <span>
                        &nbsp;~ {end.toFormat('MMM dd, yy')}
                      </span>
                    )}
                  </td>

                  <td className='pah__hero__aggregate__column'>
                    {stat.new_click.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.new_customer.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.task_submitted.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.task_submitted_first.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.task_completed.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.task_cancelled.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.new_conversion.value} / {stat.conversion_amount.value}
                  </td>
                  <td className='pah__hero__aggregate__column'>
                    {stat.first_conversion.value} / {stat.first_conversion_amount.value}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

GeneralView.propTypes = {
  period: PropTypes.object,
  aggregate: PropTypes.string,
  stats: PropTypes.array,
  loading: PropTypes.bool,
  loadAggregatedStats: PropTypes.func
}

const mapStateToProps = ({ referrals: { dashboard } }) => ({
  stats: dashboard.aggregatedStats.data,
  loading: dashboard.aggregatedStats.loading
})

const mapDispatchToProps = dispatch => ({
  loadAggregatedStats: payload => dispatch(loadAggregatedStats(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralView)
