import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { nanoid } from 'nanoid'

import { addToastr } from '@redux/toastr.js'
import { TOASTR_DANGER } from '@hooks/toastr.js'

import { loadCommissions } from '@redux/referrals/programs'
import { loadReferralCodes, updateReferralCode } from '@redux/referrals/refCodes'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import TextInput from '@components/Inputs/TextInput'
import SelectInput from '@components/Inputs/SelectInput'

const initialFields = {
  referralId: '',
  customCode: '',
  anchoredCommissionSlug: ''
}

class UpdateReferralModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      programSlug: '',
      fields: { ...initialFields }
    }

    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCodeChange = this.handleCodeChange.bind(this)
    this.getAnchoredOptions = this.getAnchoredOptions.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.show !== prevProps.show && this.props.show
    ) {
      this.loadReferrals()
    }

    if (
      prevState.programSlug !== this.state.programSlug &&
      this.state.programSlug
    ) {
      this.props.loadCommissions({
        programSlug: this.state.programSlug,
        tierGroup: 'standard'
      })
    }
  }

  loadReferrals () {
    this.props
      .loadReferrals({
        partnerId: this.props.partner.id,
        withProgram: true
      })
      .then(() => {
        const { referralId } = this.state.fields
        let code

        if (referralId) {
          code = this.props.codes.find(c => c.id === referralId)
        } else {
          code = this.props.codes.find(c => c)
        }

        if (!code) {
          return
        }

        this.handleCodeChange({ referralId: code.id })
      })
      .catch(error => {
        console.log('load programs error:', error)
      })
  }

  handleCodeChange ({ referralId }) {
    const code = this.props.codes.find(c => c.id === referralId)

    if (!code || !code.program) {
      return
    }

    this.setState(old => ({
      ...old,
      referralId,
      programSlug: code.program.slug,
      fields: {
        ...old.fields,
        referralId,
        customCode: code.custom_code,
        anchoredCommissionSlug: code.anchored_commission || ''
      }
    }), () => {

    })
  }

  handleChangeField (field) {
    return event => {
      const val = event.currentTarget.value

      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: val
        }
      }))
    }
  }

  handleChangeSelect (field) {
    return value => {
      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: value
        }
      }))
    }
  }

  handleClose () {
    this.setState(old => ({
      ...old,
      fields: { ...initialFields }
    }))

    this.props.onClose && this.props.onClose()
  }

  handleSubmit () {
    const { fields } = this.state

    this.props
      .updateReferral({
        referralId: fields.referralId,
        customCode: fields.customCode,
        anchoredCommissionSlug: fields.anchoredCommissionSlug
      })
      .then(() => {
        this.loadReferrals()
        this.props.onClose && this.props.onClose()
      })
      .catch(error => {
        if (error?.response?.status === 400) {
          this.props.addToastr({
            id: nanoid(4),
            type: TOASTR_DANGER,
            title: 'Bad Request',
            message: error.response.data.message,
            options: {
              timeout: 3000
            }
          })
        }

        console.log('update referral error:', error)
      })
  }

  getCommissions () {
    const { commissions } = this.props

    const com = commissions[this.state.programSlug]

    if (!com || !(com.data?.length)) {
      return []
    }

    return com.data
  }

  getAnchoredOptions () {
    const commissions = this.getCommissions()
    const { fields: { anchoredCommissionSlug } } = this.state
    let result = []

    if (anchoredCommissionSlug) {
      result = [{
        key: 'none',
        value: '',
        label: 'Remove anchored level'
      }]
    }

    return result.concat(
      commissions.map(c => ({
        key: c.id,
        value: c.slug,
        label: c.name
      }))
    )
  }

  render () {
    const { show } = this.props

    return (
      <Modal
        show={show}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={this.handleClose}
        >
          Update Referral Codes
        </ModalHeader>
        <ModalBody className='pd__modals__body'>
          <SelectInput
            label='Program - Code'
            name='program'
            className='pd__modals__select'
            labelClassName='pd__modals__label'
            value={this.state.fields.referralId}
            onChange={this.handleCodeChange}
            placeholder='Select Referral Code'
            options={this.props.codes.filter(c => c.program).map(c => ({
              key: c.id,
              value: c.id,
              label: `${c.program.name} - ${c.code}`
            }))}
          />

          <TextInput
            label='Custom referral code'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('customCode')}
            value={this.state.fields.customCode}
          />

          <SelectInput
            label='Anchored Commission Level (optional)'
            name='program'
            className='pd__modals__select'
            labelClassName='pd__modals__label'
            value={this.state.fields.anchoredCommissionSlug}
            onChange={this.handleChangeSelect('anchoredCommissionSlug')}
            placeholder='Select commission level'
            options={this.getAnchoredOptions()}
          />

          <div className='pd__modals__two-columns'>
            <Button
              primary
              className='pd__modals__two-columns__button'
              onClick={this.handleSubmit}
              disabled={this.props.updateLoading}
            >
              Save changes
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.handleClose}>
              Cancel
            </Button>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

UpdateReferralModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,

  updateReferral: PropTypes.func,

  loadReferrals: PropTypes.func,
  codes: PropTypes.array,
  codeLoading: PropTypes.bool,
  updateLoading: PropTypes.bool,

  loadCommissions: PropTypes.func,
  commissions: PropTypes.object,
  addToastr: PropTypes.func
}

const mapStateToProps = ({ referrals: { programs, refCodes } }) => ({
  codes: refCodes.data,
  codeLoading: refCodes.loading,
  uploadLoading: refCodes.updateLoading,
  programs: programs.data,
  programLoading: programs.loading,
  commissions: programs.commissions
})

const mapDispatchToProps = dispatch => ({
  updateReferral: payload => dispatch(updateReferralCode(payload)),
  loadReferrals: payload => dispatch(loadReferralCodes(payload)),
  loadCommissions: payload => dispatch(loadCommissions(payload)),
  addToastr: payload => dispatch(addToastr(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateReferralModal)
