import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const LOGIN_PENDING = 'checkout/account/LOGIN_PENDING'
const LOGIN_FULFILLED = 'checkout/account/LOGIN_FULFILLED'
const LOGIN_REJECTED = 'checkout/account/LOGIN_REJECTED'

const SIGN_UP_PENDING = 'checkout/account/SIGN_UP_PENDING'
const SIGN_UP_FULFILLED = 'checkout/account/SIGN_UP_FULFILLED'
const SIGN_UP_REJECTED = 'checkout/account/SIGN_UP_REJECTED'

const UPDATE_SELF_USER_PENDING = 'checkout/account/UPDATE_SELF_USER_PENDING'
const UPDATE_SELF_USER_FULFILLED = 'checkout/account/UPDATE_SELF_USER_FULFILLED'
const UPDATE_SELF_USER_REJECTED = 'checkout/account/UPDATE_SELF_USER_REJECTED'

const CHANGE_PASSWORD_PENDING = 'checkout/account/CHANGE_PASSWORD_PENDING'
const CHANGE_PASSWORD_FULFILLED = 'checkout/account/CHANGE_PASSWORD_FULFILLED'
const CHANGE_PASSWORD_REJECTED = 'checkout/account/CHANGE_PASSWORD_REJECTED'

const FORGOT_PASSWORD_PENDING = 'checkout/account/FORGOT_PASSWORD_PENDING'
const FORGOT_PASSWORD_FULFILLED = 'checkout/account/FORGOT_PASSWORD_FULFILLED'
const FORGOT_PASSWORD_REJECTED = 'checkout/account/FORGOT_PASSWORD_REJECTED'

const RESET_PASSWORD_PENDING = 'checkout/account/RESET_PASSWORD_PENDING'
const RESET_PASSWORD_FULFILLED = 'checkout/account/RESET_PASSWORD_FULFILLED'
const RESET_PASSWORD_REJECTED = 'checkout/account/RESET_PASSWORD_REJECTED'

const SIGN_OUT_PENDING = 'checkout/account/SIGN_OUT_PENDING'
const SIGN_OUT_FULFILLED = 'checkout/account/SIGN_OUT_FULFILLED'

const CHANGE_STORE = 'checkout/account/CHANGE_STORE'
const CHANGE_AVATAR_PENDING = 'checkout/account/CHANGE_AVATAR_PENDING'
const CHANGE_AVATAR_FULFILLED = 'checkout/account/CHANGE_AVATAR_FULFILLED'
const CHANGE_AVATAR_REJECTED = 'checkout/account/CHANGE_AVATAR_REJECTED'

const LOAD_WS_INVITES_PENDING = 'checkout/account/LOAD_WS_INVITES_PENDING'
const LOAD_WS_INVITES_FULFILLED = 'checkout/account/LOAD_WS_INVITES_FULFILLED'
const LOAD_WS_INVITES_REJECTED = 'checkout/account/LOAD_WS_INVITES_REJECTED'

const SEND_WS_INVITE_PENDING = 'checkout/account/SEND_WS_INVITE_PENDING'
const SEND_WS_INVITE_FULFILLED = 'checkout/account/SEND_WS_INVITE_FULFILLED'
const SEND_WS_INVITE_REJECTED = 'checkout/account/SEND_WS_INVITE_REJECTED'

const REMOVE_WS_INVITE_PENDING = 'checkout/account/REMOVE_WS_INVITE_PENDING'
const REMOVE_WS_INVITE_FULFILLED = 'checkout/account/REMOVE_WS_INVITE_FULFILLED'
const REMOVE_WS_INVITE_REJECTED = 'checkout/account/REMOVE_WS_INVITE_REJECTED'

const RESEND_WS_INVITE_PENDING = 'checkout/account/RESEND_WS_INVITE_PENDING'
const RESEND_WS_INVITE_FULFILLED = 'checkout/account/RESEND_WS_INVITE_FULFILLED'
const RESEND_WS_INVITE_REJECTED = 'checkout/account/RESEND_WS_INVITE_REJECTED'

const CHECK_WS_INVITE_PENDING = 'checkout/account/CHECK_WS_INVITE_PENDING'
const CHECK_WS_INVITE_FULFILLED = 'checkout/account/CHECK_WS_INVITE_FULFILLED'
const CHECK_WS_INVITE_REJECTED = 'checkout/account/CHECK_WS_INVITE_REJECTED'

const ACCEPT_WS_INVITE_PENDING = 'checkout/account/ACCEPT_WS_INVITE_PENDING'
const ACCEPT_WS_INVITE_FULFILLED = 'checkout/account/ACCEPT_WS_INVITE_FULFILLED'
const ACCEPT_WS_INVITE_REJECTED = 'checkout/account/ACCEPT_WS_INVITE_REJECTED'

const TOGGLE_BACK_WEBSITE = 'checkout/account/TOGGLE_BACK_WEBSITE'
const TOGGLE_BACK_LOGIN = 'checkout/account/TOGGLE_BACK_LOGIN'

// auxiliary call
export const checkToken = (token, silent = false) => async dispatch => {
  if (!token) {
    const tokenMeta = document.querySelector('meta[name="jwt_token"]')

    if (tokenMeta) {
      token = tokenMeta.getAttribute('content')
    }
  }

  if (!token && import.meta.env.VITE_APP_ENV === 'local') {
    token = window && (window.localStorage.getItem('ref_jwt_token') || null)
  }

  try {
    await signIn({ token, withCookie: true, silent })(dispatch)
  } catch (error) {} // no error
}

const signInPending = () => ({
  type: LOGIN_PENDING
})
const signInFulfilled = payload => ({
  type: LOGIN_FULFILLED,
  payload
})
const signInRejected = () => ({
  type: LOGIN_REJECTED
})
export const signIn = args => async dispatch => {
  try {
    const { email, password, workspace, renew } = args
    let { isLogin, token } = args

    dispatch(signInPending())

    if ((email && password) || isLogin) {
      // check auth by Email and Password
      const loginRes = await axios.post(getRefApiUrl('v1', '/auth/login'), {
        email,
        password
      }, {
        withCredentials: true,
        params: {
          partners: '1',
          with_cookie: '1'
        }
      })

      token = loginRes.data?.data?.jwt
    }

    // check auth by Token
    const promise = axios.get(getRefApiUrl('v1', '/auth/verify'), {
      withCredentials: true,
      params: {
        partners: '1',
        resource_id: workspace?.id,
        resource_type: workspace?.type,
        renew: renew ? '1' : undefined
      },
      headers: {
        Authorization: (token && `Bearer ${token}`) || undefined,
        'X-Silent-Exception': args.silent ? '1' : undefined
      }
    })

    // extract response.data.data
    const { data: { data } } = await promise

    // eslint-disable-next-line
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.jwt}`

    if (import.meta.env.VITE_APP_ENV === 'local') {
      window && window.localStorage.setItem('ref_jwt_token', data.jwt)
    }

    const payload = {
      token: data.jwt,

      user: {
        id: data.user.id,
        email: data.user.email,
        name: data.user.name,
        avatar: data.user.avatar
      },

      workspaces: data.workspaces || [],
      workspace: data.workspace || null
    }

    if (!isLogin) {
      dispatch(signInFulfilled(payload))
    }

    return payload
  } catch (error) {
    // eslint-disable-next-line
    axios.defaults.headers.common['Authorization'] = undefined

    dispatch(signInRejected())
    throw error
  }
}
export const changeWorkspace = payload => async (dispatch, getState) => {
  const { account } = getState()

  return dispatch(signIn({
    token: account.token,
    workspace: payload.workspace || undefined
  }))
}

const signUpPending = () => ({
  type: SIGN_UP_PENDING
})
const signUpFulfilled = () => ({
  type: SIGN_UP_FULFILLED
})
const signUpRejected = () => ({
  type: SIGN_UP_REJECTED
})
export const signUp = payload => async dispatch => {
  try {
    dispatch(signUpPending())

    await axios.post(getRefApiUrl('v1', '/partners'), {
      user_name: payload.name,
      email: payload.email,
      password: payload.password,

      program_slug: import.meta.env.VITE_REF_PROGRAM_SLUG,
      company_name: payload.company_name,
      company_url: payload.company_url
    }, {
      params: {
        'g-recaptcha-response': encodeURIComponent(payload.gRecaptchaToken)
      }
    })

    const resPayload = {}

    dispatch(signUpFulfilled(resPayload))

    return resPayload
  } catch (error) {
    dispatch(signUpRejected())
    throw error
  }
}

export const changePassword = payload => async (dispatch, getState) => {
  try {
    const { account } = getState()

    dispatch({
      type: CHANGE_PASSWORD_PENDING
    })

    await axios.post(getRefApiUrl('v1', '/auth/change-password'), {
      email: account.user.email,
      password: payload.password,
      new_password: payload.new_password,
      confirm_new_password: payload.confirm_new_password
    })

    const pl = {}

    dispatch({
      type: CHANGE_PASSWORD_PENDING,
      payload: pl
    })

    return pl
  } catch (error) {
    dispatch({
      type: CHANGE_PASSWORD_REJECTED
    })

    throw error
  }
}

export const signOut = () => async dispatch => {
  dispatch({
    type: SIGN_OUT_PENDING
  })

  try {
    if (import.meta.env.VITE_APP_ENV === 'local') {
      window && window.localStorage.removeItem('ref_jwt_token')
    }

    await axios.post(getRefApiUrl('v1', '/auth/logout'), null, {
      withCredentials: true
    })
  } catch (error) {}

  // eslint-disable-next-line
  axios.defaults.headers.common['Authorization'] = undefined

  dispatch({
    type: SIGN_OUT_FULFILLED
  })
}

export const resetPassword = payload => async dispatch => {
  try {
    dispatch({
      type: RESET_PASSWORD_PENDING
    })

    await axios.post(getRefApiUrl('v1', '/auth/reset-password'), {
      email: payload.email,
      password: payload.password,
      confirm_password: payload.confirmPassword,
      token: payload.token
    })

    const pl = {}

    dispatch({
      type: RESET_PASSWORD_FULFILLED,
      payload: pl
    })

    return pl
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_REJECTED
    })

    throw error
  }
}

export const forgotPassword = payload => async dispatch => {
  try {
    dispatch({
      type: FORGOT_PASSWORD_PENDING
    })

    await axios.post(getRefApiUrl('v1', '/auth/forgot-password'), {
      email: payload.email
    })

    const pl = {}

    dispatch({
      type: FORGOT_PASSWORD_FULFILLED,
      payload: pl
    })

    return pl
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_REJECTED
    })

    throw error
  }
}

export const updateSelfUser = payload => async dispatch => {
  try {
    dispatch({
      type: UPDATE_SELF_USER_PENDING
    })

    const res = await axios.post(getRefApiUrl('v1', '/auth/self'), {
      name: payload.name
    })

    const data = {
      user: res.data
    }

    dispatch({
      type: UPDATE_SELF_USER_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: UPDATE_SELF_USER_REJECTED
    })

    throw error
  }
}

export const changeAvatar = (payload) => async dispatch => {
  dispatch({ type: CHANGE_AVATAR_PENDING })

  try {
    const res = await axios.post(getRefApiUrl('v1', '/auth/avatar'), payload.data)

    const data = res.data.avatar_url

    dispatch({
      type: CHANGE_AVATAR_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({ type: CHANGE_AVATAR_REJECTED })

    throw error
  }
}

export const toggleBackWebsite = payload => ({
  type: TOGGLE_BACK_WEBSITE,
  payload
})
export const toggleBackLogin = payload => ({
  type: TOGGLE_BACK_LOGIN,
  payload
})

export const loadInvites = payload => async dispatch => {
  try {
    dispatch({
      type: LOAD_WS_INVITES_PENDING
    })

    const res = await axios.get(getRefApiUrl('v1', '/auth/user-invites'), {
      params: {
        limit: payload.limit || undefined,
        offset: payload.offset || undefined
      }
    })

    const data = {
      total: res.data.total,
      invites: res.data.data
    }

    dispatch({
      type: LOAD_WS_INVITES_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_WS_INVITES_REJECTED
    })

    throw error
  }
}

export const sendInvite = payload => async dispatch => {
  try {
    dispatch({
      type: SEND_WS_INVITE_PENDING
    })

    const res = await axios.post(getRefApiUrl('v1', '/auth/user-invites'), {
      email: payload.email,
      scopes: payload.scopes
    })

    const data = {
      invite: res.data
    }

    dispatch({
      type: SEND_WS_INVITE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: SEND_WS_INVITE_REJECTED
    })

    throw error
  }
}

export const removeInvite = payload => async dispatch => {
  try {
    dispatch({
      type: REMOVE_WS_INVITE_PENDING
    })

    await axios.delete(getRefApiUrl('v1', `/auth/user-invites/${payload.inviteId}`))

    const data = {
      id: payload.inviteId
    }

    dispatch({
      type: REMOVE_WS_INVITE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: REMOVE_WS_INVITE_REJECTED
    })

    throw error
  }
}

export const resendInvite = payload => async dispatch => {
  try {
    dispatch({
      type: RESEND_WS_INVITE_PENDING
    })

    await axios.post(getRefApiUrl('v1', `/auth/user-invites/${payload.inviteId}:resend`))

    const data = {
      id: payload.inviteId
    }

    dispatch({
      type: RESEND_WS_INVITE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: RESEND_WS_INVITE_REJECTED
    })

    throw error
  }
}

export const checkInvite = payload => async dispatch => {
  try {
    dispatch({
      type: CHECK_WS_INVITE_PENDING
    })

    const res = await axios.get(getRefApiUrl('v1', '/auth/user-invites:check'), {
      params: {
        token: payload.token,
        email: payload.email
      }
    })

    const data = {
      userExist: res.data.exist,
      workspaceName: res.data.resource_name
    }

    dispatch({
      type: CHECK_WS_INVITE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: CHECK_WS_INVITE_REJECTED
    })

    throw error
  }
}
export const acceptInvite = payload => async dispatch => {
  try {
    dispatch({
      type: ACCEPT_WS_INVITE_PENDING
    })

    await axios.post(getRefApiUrl('v1', '/auth/user-invites:accept'), {
      email: payload.email,
      token: payload.token,
      password: payload.password,
      name: payload.name
    })

    const data = {}

    dispatch({
      type: ACCEPT_WS_INVITE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: ACCEPT_WS_INVITE_REJECTED
    })

    throw error
  }
}

const initialState = {
  backToWebsite: true,
  backToLogin: false,
  isLoggedIn: false,
  token: '',
  user: null,
  avatarLoading: false,
  updateLoading: false,

  stores: [],
  store: null,

  workspaces: [],
  workspace: null,

  authPending: false,
  passPending: false,

  // TODO move partners's users here
  users: {
    total: 0,
    data: [],
    loading: false
  },
  invites: {
    total: 0,
    data: [],
    loading: false
  },
  invite: {
    loading: false,
    userExist: false,
    workspaceName: ''
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_STORE:
      return {
        ...state,
        store: state.stores.find(s => s.id === action.payload) || state.store
      }

    case SIGN_UP_PENDING:
      return {
        ...state,
        authPending: true
      }
    case SIGN_UP_FULFILLED:
      return {
        ...state,
        authPending: false
      }
    case SIGN_UP_REJECTED:
      return {
        ...state,
        authPending: false
      }

    case LOGIN_PENDING:
      return {
        ...state,
        authPending: true
      }
    case LOGIN_FULFILLED:
      return {
        ...state,
        user: action.payload.user,
        token: action.payload.token,

        // merchant
        store: action.payload.store || null,
        stores: action.payload.stores || [],

        // new auth
        workspace: action.payload.workspace || null,
        workspaces: action.payload.workspaces || [],

        authPending: false,
        isLoggedIn: true
      }
    case LOGIN_REJECTED:
      return {
        ...state,
        authPending: false
      }

    case CHANGE_PASSWORD_PENDING:
    case FORGOT_PASSWORD_PENDING:
    case RESET_PASSWORD_PENDING:
      return {
        ...state,
        passPending: true
      }
    case CHANGE_PASSWORD_FULFILLED:
    case CHANGE_PASSWORD_REJECTED:
    case FORGOT_PASSWORD_FULFILLED:
    case FORGOT_PASSWORD_REJECTED:
    case RESET_PASSWORD_FULFILLED:
    case RESET_PASSWORD_REJECTED:
      return {
        ...state,
        passPending: false
      }

    case SIGN_OUT_PENDING:
      return {
        ...state,
        authPending: true
      }
    case SIGN_OUT_FULFILLED:
      return {
        ...initialState
      }

    case CHANGE_AVATAR_PENDING:
      return {
        ...state,
        avatarLoading: true
      }
    case CHANGE_AVATAR_FULFILLED:
      return {
        ...state,
        avatarLoading: false,
        user: {
          ...state.user,
          avatar: action.payload
        }
      }
    case CHANGE_AVATAR_REJECTED:
      return {
        ...state,
        avatarLoading: false
      }

    case UPDATE_SELF_USER_PENDING:
      return {
        ...state,
        updateLoading: true
      }
    case UPDATE_SELF_USER_FULFILLED:
      return {
        ...state,
        updateLoading: false,
        user: {
          ...state.user,
          ...action.payload.user
        }
      }
    case UPDATE_SELF_USER_REJECTED:
      return {
        ...state,
        updateLoading: false
      }

    case TOGGLE_BACK_WEBSITE:
      return {
        ...state,
        backToWebsite: action.payload
      }
    case TOGGLE_BACK_LOGIN:
      return {
        ...state,
        backToLogin: action.payload
      }

    case LOAD_WS_INVITES_PENDING:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: true
        }
      }
    case LOAD_WS_INVITES_FULFILLED:
      return {
        ...state,
        invites: {
          ...state.invites,
          total: action.payload.total,
          data: action.payload.invites,
          loading: false
        }
      }
    case LOAD_WS_INVITES_REJECTED:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: false
        }
      }

    case SEND_WS_INVITE_PENDING:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: true
        }
      }
    case SEND_WS_INVITE_FULFILLED:
      return {
        ...state,
        invites: {
          ...state.invites,
          total: state.invites.total + 1,
          data: [action.payload.invite, ...state.invites.data],
          loading: false
        }
      }
    case SEND_WS_INVITE_REJECTED:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: false
        }
      }
    case REMOVE_WS_INVITE_PENDING:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: true
        }
      }
    case REMOVE_WS_INVITE_FULFILLED:
      return {
        ...state,
        invites: {
          ...state.invites,
          total: state.invites.total - 1,
          data: state.invites.data.filter(i => i.id !== action.payload.id),
          loading: false
        }
      }
    case REMOVE_WS_INVITE_REJECTED:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: false
        }
      }

    case CHECK_WS_INVITE_PENDING:
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: true
        }
      }
    case CHECK_WS_INVITE_FULFILLED:
      return {
        ...state,
        invite: {
          ...state.invite,
          userExist: action.payload.userExist,
          email: action.payload.email,
          token: action.payload.token,
          workspaceName: action.payload.workspaceName,
          loading: false
        }
      }
    case CHECK_WS_INVITE_REJECTED:
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false
        }
      }

    case ACCEPT_WS_INVITE_PENDING:
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: true
        }
      }
    case ACCEPT_WS_INVITE_FULFILLED:
    case ACCEPT_WS_INVITE_REJECTED:
      return {
        ...state,
        invite: {
          ...state.invite,
          loading: false
        }
      }
    default:
      return state
  }
}
