import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { DateTime } from 'luxon'

import { fetchDashAppOverview, fetchDashAppStats } from '@redux/developers.js'

import InfoCard from '@components/InfoCard/index.jsx'
import DashStats from '@components/DashStats/index.jsx'
import PeriodPicker from '@components/DatePicker/PeriodPicker.jsx'
import openNewTab from '@assets/icons/new-tab-purple.svg'

const statsToObject = stats => stats.reduce((acc, stat) => {
  acc[stat.name] = stat.value
  return acc
}, {})

export default function AppHero ({ app, appId }) {
  const [[start, end], setDates] = useState([])
  const [overview, setOverview] = useState({})
  const [stats, setStats] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    const end = DateTime.now()
    const start = end.minus({ days: 30 })

    setDates([start.toISO(), end.toISO()])

    return () => {
      setStats({})
      setOverview({})
    }
  }, [])

  useEffect(() => {
    // || statHash === `app-${appId}`
    if (!start || !end || !appId) {
      return
    }

    dispatch(fetchDashAppStats({ start, end, hash: `app-${appId}`, appId }))
      .then(({ stats }) => {
        setStats(statsToObject(stats))
      })
      .catch(error => {
        console.log('error fetching dash stats', error)
      })

    return () => {
      setStats({})
    }
  }, [start, end, appId])
  useEffect(() => {
    // || overviewHash === `app-${appId}`
    if (!appId) {
      return
    }

    dispatch(fetchDashAppOverview({ hash: `app-${appId}`, appId }))
      .then(({ stats }) => {
        stats = statsToObject(stats)

        if (
          Number(stats.overall_rank_max) > 0 &&
          Number(stats.overall_rank) < 100000 &&
          Number(stats.overall_rank) > 0
        ) {
          stats.overall_rank = `${stats.overall_rank} / ${stats.overall_rank_max}`
        } else {
          stats.overall_rank = '0'
        }

        setOverview(stats)
      })
      .catch(error => {
        console.log('error fetching dash overview', error)
      })

    return () => {
      setOverview({})
    }
  }, [appId])

  function handleChangeDates ({ start, end }) {
    setDates([start.toISO(), end.toISO()])
  }

  return (
    <div className='app-page__hero'>
      <div className='app-page__hero__top'>
        <h1 className='app-page__hero__top__title'>{app?.name || '...'} App Reviews</h1>
        <div className='app-page__hero__top__date-range'>
          <PeriodPicker
            start={start}
            end={end}
            right
            onChange={handleChangeDates}
          />
        </div>
      </div>
      <div className='app-page__hero__top__link'>
        <a
          href={`${import.meta.env.VITE_SITE_URL}/apps/reviews/${app?.slug}`}
          target='_blank'
          rel='noopener noreferrer'
        >
          Open app page <img src={openNewTab} alt='Open app page in new tab' />
        </a>
      </div>

      <DashStats
        items={[
          <InfoCard
            key='app_review'
            value={overview.app_review || '0'}
            description='Number of reviews'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Number of reviews indicates the total of times customers
                have independently reviewed one of your listings
              </span>
            )}
          />,
          <InfoCard
            key='app_visit'
            value={stats.app_visit || '0'}
            description='Page visits'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Page visits indicates the total of times your listing pages
                have been previewed.
              </span>
            )}
          />,
          <InfoCard
            key='app_click'
            value={stats.app_click || '0'}
            description='Click throughs'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Click throughs refers to the total of times a customer has accessed
                the Shopify page of one of your apps through your HeyCarson profile.
              </span>
            )}
          />,
          <InfoCard
            key='overall_rank'
            value={overview.overall_rank || '0'}
            description='Overall rank'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                The Overall Rank is based on the customer experience and
                evaluates how your app compares to similar apps.
                We rank apps using a score which combines average app
                ratings, total number of reviews and % of customers which
                are “very likely” to recommend the app.
              </span>
            )}
          />,
          <InfoCard
            key='overall_rating'
            value={overview.overall_rating || '0.0'}
            description='Overall rating'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                The overall rating value is the average of all the numeric
                evaluations left by the customers while reviewing your app(s).
              </span>
            )}
          />,
          <InfoCard
            key='likely_recommend'
            value={overview.likely_recommend > 0 ? `${overview.likely_recommend}%` : '0%'}
            description='Customers likely to recommend'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                When leaving a app review, the customers are asked “How likely
                are you to recommend this app”. The “customers likely to
                recommend” metric is the percentage of people that have selected
                the “Very likely” option.
              </span>
            )}
          />
        ]}
      />
    </div>
  )
}

AppHero.propTypes = {
  appId: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string
}
