import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Tooltip from '@components/Tooltip'

import questionMark from '@assets/icons/question-circle-light.svg'
import questionMarkDark from '@assets/icons/question-circle-dark.svg'

import './InfoCard.scss'

class InfoCard extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hover: false
    }
  }

  render () {
    const { value, description, className, toolTip, labelTooltip, customContent } = this.props
    const { compare, positive, subValue } = this.props
    const { toolTipClassName } = this.props
    let { toolTipHTML } = this.props

    if (toolTip) {
      toolTipHTML = (
        <Tooltip
          top
          center
          className={toolTipClassName}
          activator={({ elRef }) => (
            <img
              ref={elRef}
              className='info-card__question-mark'
              src={this.state.hover ? questionMarkDark : questionMark}
            />
          )}
        >
          {toolTip}
        </Tooltip>
      )
    }

    if (labelTooltip) {
      return (
        <div className={classnames('info-card', className)}>
          <div
            className={classnames('info-card__value', {
              'info-card__value--compare': compare
            })}
          >
            {value}
          </div>
          {subValue && (
            <div
              className={classnames('info-card__sub-value', {
                'info-card__sub-value--negative': compare && !positive,
                'info-card__sub-value--positive': compare && positive
              })}
            >
              {subValue}
            </div>
          )}
          <div className='info-card__description info-card__description--label-tooltip'>
            {description}
            {toolTipHTML}
          </div>
        </div>
      )
    }

    return (
      <div className={classnames('info-card', className)}>
        {toolTipHTML}
        <div className='info-card__content'>
          {value && (
            <div
              className={classnames('info-card__value', {
                'info-card__value--compare': compare
              })}
            >
              {value}
            </div>
          )}
          {subValue && (
            <div
              className={classnames('info-card__sub-value', {
                'info-card__sub-value--negative': compare && !positive,
                'info-card__sub-value--positive': compare && positive
              })}
            >
              {subValue}
            </div>
          )}
          {description && (
            <div className='info-card__description'>
              {description}
            </div>
          )}
          {customContent && customContent}
        </div>
      </div>
    )
  }
}

InfoCard.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subValue: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  toolTipClassName: PropTypes.string,
  toolTip: PropTypes.node,
  toolTipHTML: PropTypes.node,
  labelTooltip: PropTypes.bool
}

export default InfoCard
