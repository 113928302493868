import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGroups } from '@redux/themeFilters.js'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import DropdownList from '@components/DropdownList/index.jsx'
import DotMenu from '@components/DotMenu/index.jsx'
import Button from '@components/Button'
import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'
import FilterGroupModal from './FilterGroupModal'
import DeleteFilterGroupModal from './DeleteFilterGroupModal'

export default function FilterGroupList () {
  const listRef = useRef()
  const [groups] = useSelector(({ themeFilters }) => [themeFilters.groups.data])
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    loadGroups()
  }, [])

  function loadGroups () {
    dispatch(fetchGroups({
      limit: 15,
      offset: 0
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }

  function handleAdd () {
    setSelected(null)
    setShowModal('add')
  }
  function handleFilterGroupModalSuccess () {
    setShowModal('')
    setSelected(null)
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
      case 'delete':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowDeleteModal('delete')
        break
    }
  }
  function handleDeleteModalSuccess () {
    setShowDeleteModal('')
  }

  return (
    <div className='pda-filters-page' ref={listRef}>
      <FilterGroupModal
        show={showModal !== ''}
        type={showModal}
        group={selected}
        onClose={() => setShowModal('')}
        onSuccess={handleFilterGroupModalSuccess}
      />
      <DeleteFilterGroupModal
        show={showDeleteModal !== ''}
        id={selected?.id}
        onClose={() => setShowDeleteModal('')}
        onSuccess={handleDeleteModalSuccess}
      />
      <div className='pda-filters-page__header'>
        <div className='pda-filters-page__title'>
          Filters Groups
        </div>
        <Button primary onClick={handleAdd}>
          Add filter group
        </Button>
      </div>

      <div className='pda-filters-page__list'>
        <div className='pda-filters-page__go-to-filters'>
          <Link to='/theme-filters'>
            Back to Filters
          </Link>
        </div>
        <ListRow
          className='pda-filters-page__list__items'
          headers={(
            <>
              <ListRowHeader className='pda-filters-page__list__name'>
                Group
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__visible'>
                visible
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__actions'>
                Action
              </ListRowHeader>
            </>
          )}
          items={groups.map(group => (
            <ListRowItem key={group.id}>
              <ListRowColumn className='pda-filters-page__list__name'>
                <Link
                  to='#'
                >
                  {group.name}
                </Link>
                <br />
                <small className='pda-filters-page__list__slug'>
                  <a
                    href='#'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {group.slug}
                  </a>
                </small>
              </ListRowColumn>
              <ListRowColumn className='pda-filters-page__list__visible'>
                <div>
                  {group.visible ? 'Yes' : 'No'}
                </div>
              </ListRowColumn>
              <ListRowColumn className='trs-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: group
                  }, {
                    key: 'delete',
                    label: 'Delete',
                    value: group
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>
    </div>
  )
}
