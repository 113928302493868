import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import { removeInvite } from '@redux/account.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

import './DeleteInviteModal.scss'

export default function DeleteInviteModal ({ open, onClose, onSuccess, invite }) {
  const dispatch = useDispatch()
  const toastr = useToastr()

  function handleOnSubmit () {
    dispatch(removeInvite({
      inviteId: invite.id
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Invite removed successfully'
        })

        onSuccess()
      })
      .catch(error => {
        let message = 'Try again later.'

        if (
          error.response?.status === 400 &&
          error.response?.data?.message
        ) {
          message = error.response.data.message
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
      })
  }

  return (
    <Modal show={open} className='pd-invite-user-modal' small>
      <Header onClose={onClose}>
        Remove Teammate
      </Header>
      <Body>
        <p>Are you sure you want to remove <strong>({invite?.email})</strong> invite?</p>
      </Body>
      <Footer className='pd-invite-user-modal__footer'>
        <Button onClick={onClose} secondary>Cancel</Button>
        <Button onClick={handleOnSubmit} danger>
          Delete
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
  partnerId: PropTypes.string
}
