import React from 'react'
import { Link, useLocation, matchPath } from 'react-router-dom'
import classnames from 'classnames'

import './Sidebar.scss'

function AdminPartnersSidebar (props) {
  const location = useLocation()

  function isActive (path, exact = false) {
    return matchPath({
      path: path,
      end: exact
    }, location.pathname)
  }

  return (
    <div className='pda__sidebar'>
      <div className='pda__sidebar__links'>
        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/', true)
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/' className='pda__sidebar__link'>
            Overview
          </Link>
        </div>
        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/conversions')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/conversions' className='pda__sidebar__link'>
            Conversions
          </Link>
        </div>
        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/partners')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/partners' className='pda__sidebar__link'>
            Partners
          </Link>
        </div>
        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/referral-programs')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/referral-programs' className='pda__sidebar__link'>
            Partner Programs
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/clicks')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/clicks' className='pda__sidebar__link'>
            Clicks
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/referrals')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/referrals' className='pda__sidebar__link'>
            Referrals
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/withdrawals')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/withdrawals' className='pda__sidebar__link'>
            Withdrawals
          </Link>
        </div>

        <div className='pda__sidebar__separator' />

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/themes')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/themes' className='pda__sidebar__link'>
            Themes
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/theme-reviews')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/theme-reviews' className='pda__sidebar__link'>
            Reviews
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/theme-questions')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/theme-questions' className='pda__sidebar__link'>
            Questions
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/developers')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/developers' className='pda__sidebar__link'>
            Developers
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/theme-stores')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/theme-stores' className='pda__sidebar__link'>
            Stores
          </Link>
        </div>
        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/theme-filters')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/theme-filters' className='pda__sidebar__link'>
            Filters
          </Link>
        </div>

        <div className='pda__sidebar__separator' />

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/apps')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/apps' className='pda__sidebar__link'>
            Apps
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/app-reviews')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/app-reviews' className='pda__sidebar__link'>
            App Reviews
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/app-questions')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/app-questions' className='pda__sidebar__link'>
            App Questions
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/app-categories')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/app-categories' className='pda__sidebar__link'>
            App Categories
          </Link>
        </div>

        <div
          className={classnames('pda__sidebar__item', {
            'pda__sidebar__item--active': isActive('/app-profile-claims')
          })}
        >
          <div className='pda__sidebar__item__marker' />
          <Link to='/app-profile-claims' className='pda__sidebar__link'>
            App Profile Claims
          </Link>
        </div>

      </div>
    </div>
  )
}

AdminPartnersSidebar.propTypes = {}

export default AdminPartnersSidebar
