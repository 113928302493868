import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { fetchStores } from '@redux/themeStores.js'

import SearchBar from '@components/SearchBar'
import Button from '@components/Button'
import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'

import StoreModal from './StoreModal'

import './List.scss'

const storeLimit = 15

export default function StoreList () {
  const listRef = useRef()
  const searchTimeout = useRef(null)
  const [totalStores, stores] = useSelector(({ themeStores }) => [
    themeStores.list.total,
    themeStores.list.data
  ])
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [search, setSearch] = useState('')
  const [searchVal, setSearchVal] = useState('')
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')

  const dispatch = useDispatch()
  const pagination = makePagination(totalStores, page, storeLimit)

  useEffect(() => {
    setSearchParams({ page: 1, search, status })
  }, [search, status])

  useEffect(() => {
    dispatch(fetchStores({
      search: search || undefined,
      offset: pagination.offset,
      limit: pagination.limit,
      status
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }, [page, search, status])

  function handleEdit (store) {
    setSelected(store)
    setShowModal('edit')
  }

  function handleAdd () {
    setSelected(null)
    setShowModal('add')
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  function handleStoreModalSuccess () {
    setShowModal('')
    setSelected(null)
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page, search, status })
  }

  return (
    <div className='tss-page' ref={listRef}>
      <div className='tss-page__title'>
        Theme Stores
      </div>

      <div className='tss-page__list'>
        <StoreModal
          show={showModal !== ''}
          type={showModal}
          store={selected}
          onClose={() => setShowModal('')}
          onSuccess={handleStoreModalSuccess}
        />
        <div className='tss-page__toolbar'>
          <SearchBar
            className='tss-page__search'
            placeholder='Search stores'
            value={searchVal}
            onChange={handleSearchChange}
          />
          <div className='tss-page__action'>
            <Button primary onClick={handleAdd}>
              Add Store
            </Button>
          </div>
        </div>
        <div className='tss-page__status'>
          <Button
            primary={status === ''}
            disabled={status === ''}
            onClick={() => setStatus('')}
          >
            All
          </Button>
          <Button
            primary={status === 'published'}
            disabled={status === 'published'}
            onClick={() => setStatus('published')}
          >
            Published
          </Button>
          <Button
            primary={status === 'unpublished'}
            disabled={status === 'unpublished'}
            onClick={() => setStatus('unpublished')}
          >
            Unpublished
          </Button>
          <Button
            primary={status === 'pending'}
            disabled={status === 'pending'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
        </div>
        <div>
          Total results: {totalStores}
        </div>

        <ListRow
          className='tss-page__list__items'
          headers={(
            <>
              <ListRowHeader className='tss-page__list__theme'>
                Theme
              </ListRowHeader>
              <ListRowHeader className='tss-page__list__name'>
                Store Name / URL
              </ListRowHeader>
              <ListRowHeader className='tss-page__list__status'>
                Status
              </ListRowHeader>
              <ListRowHeader className='tss-page__list__thumbnail'>
                Image/Thumbnail/Cover
              </ListRowHeader>
              <ListRowHeader className='tss-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={stores.map(store => (
            <ListRowItem key={store.id}>
              <ListRowColumn className='tss-page__list__theme'>
                <div>
                  {store.theme_name}
                </div>
                <br />

              </ListRowColumn>
              <ListRowColumn className='tss-page__list__name'>
                <div>
                  {store.name}
                </div>
                <small className='tss-page__list__slug'>
                  <a
                    href={`${import.meta.env.VITE_SITE_URL}/themes/showcase/${store.slug}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {store.slug}
                  </a>
                </small>
              </ListRowColumn>
              <ListRowColumn className='tss-page__list__status'>
                <div>
                  {store.status}
                </div>
              </ListRowColumn>
              <ListRowColumn className='tss-page__list__thumbnail'>
                <a
                  href={store.image_url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img className='pda__theme-img-preview' src={store.image_url} alt={store.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='tss-page__list__actions'>
                <Link to='#' onClick={() => handleEdit(store)}>
                  Edit
                </Link>
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            handlePageChange(page, event)
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}
