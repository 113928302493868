import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { loadPartners } from '@redux/referrals/partners'

import SearchBar from '@components/Inputs/SearchBar'
import CursorPagination from '@components/Pagination/Cursor'

import avatar from '@assets/images/partners-dashboard/sample-avatar.png'

import './Partners.scss'
import { formatAmount } from '@util/currency'
import { getLevel } from '@util/referrals'

const pageLimit = 6

class DashPartners extends React.Component {
  constructor (props) {
    super(props)

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount () {
    this.loadPartners()
  }

  loadPartners ({ before, after } = {}) {
    return this.props.loadPartners({
      status: 'active',
      withProgram: true,
      withTotals: true,
      withLevel: true,
      before,
      after,
      limit: pageLimit
    })
  }

  handlePageChange ({ before, after }) {
    return this.loadPartners({ before, after })
  }

  render () {
    return (
      <div className='pah__partners'>
        <div className='pah__partners__title'>
          Partners overview
        </div>

        <SearchBar
          searchBarClassName='pah__partners__search-bar'
          inputPlaceHolder='Search partners'
        />

        <div className='pah__partners__list'>
          {this.renderListHeader()}

          {this.renderItems()}

          {this.renderPagination()}
        </div>
      </div>
    )
  }

  renderListHeader () {
    return (
      <div className='pah__partners__list__headers'>
        <div
          className='pah__partners__list__header pah__partners__list__header--partner'
        >
          Partner name
        </div>
        <div
          className='pah__partners__list__header pah__partners__list__header--conversions-amount'
        >
          Conversions (count)
        </div>
        <div
          className='pah__partners__list__header pah__partners__list__header--purchases'
        >
          Conversions (Amount)
        </div>
        <div
          className='pah__partners__list__header pah__partners__list__header--conversions-volume'
        >
          Conversions (Commission)
        </div>
        <div
          className='pah__partners__list__header pah__partners__list__header--actions'
        />
      </div>
    )
  }

  renderItems () {
    return this.props.partners.map(p => {
      let totalAggregate = { count: 0, amount: 0, commission: 0, level: '' }
      let level

      if (p.referral_codes?.length > 0) {
        totalAggregate = p.referral_codes.reduce((acc, current) => {
          acc.count += current.conversion_count || 0
          acc.amount += current.conversion_amount || 0
          acc.commission += current.conversion_commission || 0

          return acc
        }, { ...totalAggregate })

        level = getLevel(p.referral_codes.find(rc => rc.level)?.level)
      }

      return (
        <div key={p.id} className='pah__partners__item'>
          <div className='pah__partners__item__details'>
            {false && (<img className='pah__partners__item__details__picture' src={avatar} alt='avatar' />)}
            <div>
              <Link to={`/partners/v/${p.slug}`} className='pah__partners__item__details__name'>
                {p.name}
              </Link>
              <div className='pah__partners__item__details__level'>
                {level ? level.name : ''}
              </div>
            </div>
          </div>

          <div className='pah__partners__item__number'>
            <div className='pah__partners__item__number__total'>
              {totalAggregate.count}
            </div>
            <div className='pah__partners__item__number__label'>
              Total
            </div>
          </div>

          <div className='pah__partners__item__number'>
            <div className='pah__partners__item__number__total'>
              {formatAmount(totalAggregate.amount, 'USD')}
            </div>
            <div className='pah__partners__item__number__label'>
              Total
            </div>
          </div>

          <div className='pah__partners__item__number'>
            <div className='pah__partners__item__number__total'>
              {formatAmount(totalAggregate.commission, 'USD')}
            </div>
            <div className='pah__partners__item__number__label'>
              Total
            </div>
          </div>

          <div className='pah__partners__item__actions'>
            <Link to={`/conversions?partner_id=${p.id}`}>
              See conversions
            </Link>
          </div>
        </div>
      )
    })
  }

  renderPagination () {
    return (
      <div className='pah__partners__pagination'>
        <CursorPagination
          direction='next'
          items={this.props.partners}
          cursor='created_at'
          onPageChange={this.handlePageChange}
          showDetails
          pageLimit={pageLimit}
          totalResults={this.props.totalResults}
          remainingResults={this.props.remainingResults || 0}
        />
      </div>
    )
  }
}

DashPartners.propTypes = {
  partners: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number,
  loadPartners: PropTypes.func
}

const mapStateToProps = ({ referrals: { partners } }) => ({
  partners: partners.data,
  totalResults: partners.totalResults,
  remainingResults: partners.remainingResults,
  loading: partners.loading
})
const mapDispatchToProps = dispatch => ({
  loadPartners: payload => dispatch(loadPartners(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(DashPartners)
