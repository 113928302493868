import React from 'react'

import WelcomeMessage from './Welcome'
import Reports from './Reports'
import Partners from './Partners'
import Conversions from './Conversions'

class AdminPartnersDashboard extends React.Component {
  render () {
    return (
      <>
        <WelcomeMessage />
        <Reports />
        <Partners />
        <Conversions />
      </>
    )
  }
}

export default AdminPartnersDashboard
