import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import './Soft.scss'

export const makePagination = (total, page, limit) => {
  let offset = 0

  if (page > 0) {
    // eg:
    // if limit = 15 and page = 2
    // offset ((2-1 * 15) +1) = 16
    // start from position 16
    // if page = 3 then
    // start position 31
    // page = 1 (0, 15)
    // page = 2 (16, 30)
    offset = ((page - 1) * limit)
  }

  return {
    totalResults: total,
    offset,
    limit
  }
}

class SoftPagination extends Component {
  getValues (props) {
    const { pageButtons, fixedPageButtons } = props
    const { totalResults, limit } = props.pagination
    const pagination = {
      ...props.pagination
    }

    const totalPages = this.getTotalPages(totalResults, limit)
    const currentPage = this.getCurrentPage(totalPages, pagination)
    const minMax = this.getMinMax(currentPage, pagination)
    const pages = this.getPagesArray(currentPage, {
      pageButtons,
      fixedPageButtons,
      totalPages
    })

    return {
      totalPages,
      currentPage,
      min: minMax.init,
      max: minMax.max,
      pages
    }
  }

  // util
  getTotalPages (results, pageSize) {
    if (results <= 0) {
      return 0
    } else if (results < pageSize) {
      return 1
    } else if ((results % pageSize) > 0) {
      return Math.floor(results / pageSize) + 1
    } else {
      return results / pageSize
    }
  }

  getCurrentPage (totalPages, pagination) {
    const { limit, offset, totalResults } = pagination

    if (offset === 0) {
      return 1
    } else if (totalResults - offset <= limit) {
      return totalPages
    }

    for (let page = 1; page < totalPages; page++) {
      if (((page - 1) * limit) === offset) {
        return page
      }
    }
  }

  getMinMax (currentPage, pagination) {
    const { totalResults, limit, offset } = pagination
    const calc = {
      init: 1,
      max: 1
    }

    if (!totalResults) {
      return calc
    }

    // because normies don't count from 0
    calc.init = offset + 1
    calc.max = offset + limit

    if (calc.max > totalResults) {
      calc.max = totalResults
    }

    return calc
  }

  getPagesArray (currentPage, params) {
    const { pageButtons, fixedPageButtons, totalPages } = params

    if (totalPages < 1 || pageButtons % 2 === 0) {
      return []
    }

    const odd = Math.floor(pageButtons / 2)

    if ((currentPage - odd) > 0 && (currentPage + odd) <= totalPages) {
      return this.range(currentPage - odd, currentPage + odd)
    }

    if (totalPages >= pageButtons) {
      if (currentPage - odd <= 0) {
        const max = fixedPageButtons
          ? pageButtons
          : odd + 1

        return this.range(1, max)
      }

      const min = fixedPageButtons
        ? totalPages - (pageButtons - 1)
        : totalPages - odd

      return this.range(min, totalPages)
    }

    return this.range(1, totalPages)
  }

  range (init, max) {
    const arr = []

    for (let i = init; i <= max; i++) {
      arr.push(i)
    }

    return arr
  }

  // render
  getPrevious (state) {
    const { onChange, pagination } = this.props
    const { totalResults } = pagination
    const { currentPage, totalPages } = state

    if (totalPages === 1 || currentPage === 1 || totalResults === 0) {
      return
    }

    const to = this.props.buildURL ? this.props.buildURL(currentPage - 1) : '#'
    const onClick = event => onChange && onChange(currentPage - 1, event)

    if (totalPages < 4) {
      return null
    }

    return (
      <Link to={to} onClick={onClick} className='soft-pagination__page soft-pagination__page--previous'>
        Previous
      </Link>
    )
  }

  getNext (state) {
    const { onChange, pagination } = this.props
    const { totalResults } = pagination
    const { currentPage, totalPages } = state

    if (totalPages === 1 || currentPage === totalPages || totalResults === 0) {
      return null
    }

    const to = (this.props.buildURL && this.props.buildURL(currentPage + 1)) || '#'
    const onClick = event => onChange && onChange(currentPage + 1, event)

    if (totalPages < 4) {
      return null
    }

    return (
      <Link to={to} onClick={onClick} className='soft-pagination__page soft-pagination__page--next'>
        Next
      </Link>
    )
  }

  getPages (state) {
    const { onChange } = this.props
    const { currentPage, pages } = state
    const onClick = page => event => onChange && onChange(page, event)
    const buildURL = this.props.buildURL || (() => '#')

    return pages.map(page => {
      const active = currentPage === page
      const prevent = active && !(this.props.buildURL instanceof Function)

      return (
        <Link
          key={page}
          className={classnames('soft-pagination__page', {
            'soft-pagination__page--active': active
          })}
          onClick={!active ? onClick(page, active) : prevent ? event => event.preventDefault() : undefined}
          to={buildURL(page)}
        >
          {page}
        </Link>
      )
    })
  }

  render () {
    const state = this.getValues(this.props)

    return (
      <div className='soft-pagination'>
        {this.getPrevious(state)}
        {this.getPages(state)}
        {this.getNext(state)}
      </div>
    )
  }
}

SoftPagination.propTypes = {
  onChange: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,

  pageButtons: PropTypes.number,
  fixedPageButtons: PropTypes.bool,

  buildURL: PropTypes.func
}

export default SoftPagination
