import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchInvites } from '@redux/themeInvites.js'

import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'
import SearchBar from '@components/SearchBar/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import ListRow from '@components/ListRow/index.jsx'

const pageLimit = 10

export default function InvitesSent ({ themeId, listHash }) {
  const [search, setSearch] = useState('')
  const searchTimeout = useRef(null)
  const [searchParams] = useSearchParams()
  const page = Number(searchParams.get('invite_page')) || 1
  const listRef = useRef(null)

  const [invites, totalInvites, loading] = useSelector(({ themeInvites: { invites } }) => [
    invites.data,
    invites.total,
    invites.loading
  ])

  const dispatch = useDispatch()
  const pagination = makePagination(totalInvites, page, pageLimit)

  useEffect(() => {
    if (!themeId) {
      return
    }

    dispatch(fetchInvites({
      themeId,
      search,
      limit: pagination.limit,
      offset: pagination.offset
    }))
      .then(() => {
        if (!searchParams.has('invite_page') || !listRef.current) {
          return
        }

        // scroll to listRef smoothly
        listRef.current.scrollIntoView({
          behavior: 'smooth'
        })
      })
      .catch(error => {
        console.log('error fetching invites', error)
      })
  }, [themeId, listHash, page, search])

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearch(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  return (
    <div ref={listRef} className='pr-invites__bottom'>
      <div className='pr-invites__bottom__title'>
        Invites sent
      </div>
      <SearchBar
        className='pr-invites__bottom__search'
        placeholder='Search customers'
        value={search}
        onChange={handleSearchChange}
      />
      <ListRow
        className='pr-invites__bottom__items'
        empty={!loading && !totalInvites}
        loading={loading}
        headers={(
          <>
            <ListRowHeader className='pr-invites__bottom__customer pr-invites__bottom__customer--padding'>
              CUSTOMER
            </ListRowHeader>
            <ListRowHeader className='pr-invites__bottom__date'>
              SENT DATE
            </ListRowHeader>
            <ListRowHeader className='pr-invites__bottom__date'>
              CLICK DATE
            </ListRowHeader>
            <ListRowHeader className='pr-invites__bottom__status'>
              STATUS
            </ListRowHeader>
          </>
        )}
        items={invites.map(item => (
          <ListRowItem key={item.id}>
            <ListRowColumn
              className={classnames('pr-invites__bottom__customer', {
                'pr-invites__bottom__customer--padding': !item.avatar_url
              })}
            >
              {item.avatar_url && <img className='pr-invites__bottom__profile' src={item.avatar_url} alt={item.name} />}
              <div>
                <div className='pr-invites__bottom__name'>{item.name}</div>
                <div className='pr-invites__bottom__email'>{item.email}</div>
              </div>
            </ListRowColumn>
            <ListRowColumn className='pr-invites__bottom__date'>
              {DateTime.fromISO(item.created_at).toFormat('dd MMM, y HH:mm')}
            </ListRowColumn>
            <ListRowColumn className='pr-invites__bottom__date'>
              {item.clicked_at ? DateTime.fromISO(item.clicked_at).toFormat('dd MMM, y HH:mm') : 'N/A'}
            </ListRowColumn>
            <ListRowColumn className='pr-invites__bottom__status'>
              <div className={`pr-invites__bottom__button
                pr-invites__bottom__button--${item.status.toLowerCase()}`}
              >
                {item.status}
              </div>
            </ListRowColumn>
          </ListRowItem>
        ))}
      />

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <SoftPagination
          onChange={() => {}}
          pagination={pagination}
          pageButtons={3}
          fixedPageButtons={5}
          buildURL={page => `/reviews/${themeId}?invite_page=${page}`}
        />
      </div>
    </div>
  )
}

InvitesSent.propTypes = {
  themeId: PropTypes.string.isRequired,
  listHash: PropTypes.string.isRequired
}
