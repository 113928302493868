import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { addPricingPlan, updatePricingPlan } from '@redux/apps.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  name: '',
  slug: '',
  price: '',
  features: ''
}

export default function PricingPlanModal ({ type, pricingPlan, show, onClose, onSuccess, appId }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !pricingPlan || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: pricingPlan.name,
      slug: pricingPlan.slug,
      price: pricingPlan.price,
      features: pricingPlan.features.length > 0 ? pricingPlan.features.join(',') : ''
    })
  }, [show, pricingPlan?.id])

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  function handleSave () {
    let promise

    const data = {
      name: fields.name,
      slug: fields.slug,
      price: fields.price,
      features: fields.features
    }

    if (type === 'edit') {
      promise = updatePricingPlan
    } else {
      promise = addPricingPlan
    }

    dispatch(promise({
      id: pricingPlan?.id,
      appId,
      data
    }))
      .then(() => {
        setErrors({})
        onSuccess()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log('error pricing plan')
        }
      })
  }

  return (
    <Modal
      className='pda-app-view__pricing-plan_modal'
      show={show}
    >
      <Header onClose={onClose}>
        {type === 'edit' ? 'Edit Pricing Plan' : 'Add Pricing Plan'}
      </Header>
      <Body className='pda-app-view__pricing-plan-modal__body'>
        <TextInput
          labelClassName='pda-app-view__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />

        <TextInput
          labelClassName='pda-app-view__form__label'
          label='Slug *'
          value={fields.slug}
          onChange={event => setFields({ ...fields, slug: event.currentTarget.value })}
          error={errors.slug}
          subText={errors.slug}
          disabled={type === 'edit'}
        />

        <TextInput
          labelClassName='pda-app-view__form__label'
          label='Price'
          value={fields.price}
          onChange={event => setFields({ ...fields, price: event.currentTarget.value })}
          error={errors.price}
          subText={errors.price}
        />

        <TextInput
          labelClassName='pda-app-view__form__label'
          label='Features (CSV)'
          value={fields.features}
          onChange={event => setFields({ ...fields, features: event.currentTarget.value })}
          error={errors.features}
          subText={errors.features}
          multiline={3}
        />

      </Body>
      <Footer className='pda-app-view__pricing-plan-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

PricingPlanModal.propTypes = {
  type: PropTypes.string,
  feature: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  appId: PropTypes.string
}
