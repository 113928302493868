import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { addAppCategory, updateAppCategory } from '@redux/appCategories.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  name: '',
  slug: '',
  parent: '',
  tagline: '',
  meta_title: '',
  meta_description: ''
}

export default function CategoryModal ({ show, type, parent, category, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  useEffect(() => {
    if (!show) {
      return
    }

    if (type === 'add' && parent) {
      setFields({
        ...initialFields,
        parent: parent.slug || ''
      })
    } else if (type === 'edit' && category) {
      setFields({
        ...initialFields,
        name: category.name,
        slug: category.slug,
        parent: category.parent || '',
        tagline: category.tagline || '',
        meta_title: category.meta_title || '',
        meta_description: category.meta_description || ''
      })
    }
  }, [show, type, category, parent])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  function handleSave () {
    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      slug: fields.slug,
      parent: fields.parent,
      tagline: fields.tagline,
      meta_title: fields.meta_title,
      meta_description: fields.meta_description
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    if (type === 'add') {
      data.set('long', fields.slug)
    }

    let promise

    if (type === 'add' || type === 'addChild') {
      promise = addAppCategory
    } else {
      promise = updateAppCategory
    }

    dispatch(promise({
      id: category?.id,
      data
    }))
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'category_not_found'
        ) {
          setErrors(old => ({ ...old, developer_id: error.response.data.message }))
        } else {
          console.log('error category')
        }
      })
  }

  function setModalTitle (type) {
    switch (type) {
      case 'add':
        return parent ? 'Add Child' : 'Add Root'
      case 'edit':
        return 'Edit'
      default:
        return ''
    }
  }

  return (
    <Modal show={show} className='pda-app-categories__add-modal'>
      <Header onClose={onClose}>
        {setModalTitle(type)} Category
      </Header>
      <Body className='pda-app-categories__add-modal__body'>
        <TextInput
          labelClassName='pda-app-categories__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />
        {type !== 'add' && (
          <TextInput
            labelClassName='pda-app-categories__form__label'
            label='Slug'
            value={fields.slug}
            onChange={event => setFields({ ...fields, slug: event.currentTarget.value })}
            error={errors.slug}
            subText={errors.slug}
            disabled
          />
        )}

        <TextInput
          labelClassName='pda-app-categories__form__label'
          label='Parent'
          value={fields.parent || 'Root'}
          error={errors.parent}
          subText={errors.parent}
          disabled
        />
        <TextInput
          labelClassName='pda-app-categories__form__label'
          label='Tagline *'
          value={fields.tagline}
          onChange={event => setFields({ ...fields, tagline: event.currentTarget.value })}
          error={errors.tagline}
          subText={errors.tagline}
        />

        <TextInput
          labelClassName='pda-app-categories__form__label'
          label='Meta Title'
          value={fields.meta_title}
          onChange={event => setFields({ ...fields, meta_title: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />

        <TextInput
          labelClassName='pda-app-categories__form__label'
          label='Meta Description'
          value={fields.meta_description}
          onChange={event => setFields({ ...fields, meta_description: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />
      </Body>
      <Footer className='pda-app-categories__add-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

CategoryModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  parent: PropTypes.object,
  category: PropTypes.object,
  onSuccess: PropTypes.func,
  onClose: PropTypes.func
}
