import React, { lazy, Suspense, useState } from 'react'
import { Link } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import sanitizeHtml from 'sanitize-html'

import defaultAvatar from '@assets/images/default-avatar.png'
const AnswerForm = lazy(() => import('@pages/Partners/Partner/App/Questions/AnswerForm.jsx'))

export default function AppAnswer ({ answer, question }) {
  const [editingAnswer, setEditingAnswer] = useState(false)
  const [liveAnswer, setLiveAnswer] = useState('')

  const [user, companyName, submitting] = useSelector(({ account, appReviews }) => [
    account.user,
    account.workspace.name,
    appReviews.questions.submittingAnswer
  ])

  const isDevAnswer = answer.developer_id
  const canEditAnswer = (!isDevAnswer || editingAnswer) && question.status !== 'pending'

  function handleEditAnswer (event, answer) {
    event.preventDefault && event.preventDefault()
    setEditingAnswer(true)
    setLiveAnswer(answer?.answer)
  }

  function handleCancelAnswer () {
    setEditingAnswer(false)
    setLiveAnswer('')
  }

  function renderDevAnswer () {
    return (
      <>
        <div className='apps-qa__answers__title'>
          Your answer
        </div>
        <div className='app-reviews-qa__qa__card__dev-answer'>
          <img
            src={user.avatar || defaultAvatar} alt='avatar'
            loading='lazy'
            className='app-reviews-qa__qa__card__dev-answer__profile'
          />
          <div className='app-reviews-qa__qa__card__dev-answer__content'>
            <div className='app-reviews-qa__qa__card__dev-answer__top'>
              <div>
                <p className='app-reviews-qa__qa__card__dev-answer__name'>
                  {companyName}
                </p>
                <p className='app-reviews-qa__qa__card__dev-answer__desc'>
                  app developer's answer
                </p>
              </div>
              <p className='app-reviews-qa__qa__card__dev-answer__date'>
                {DateTime.fromISO(answer.created_at).toFormat('dd MMM, y')}
              </p>
            </div>
            <div
              className='apps-qa__answers__text'
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(editingAnswer ? (liveAnswer || '&nbsp;') : answer?.answer)
              }}
            />
          </div>
        </div>
        <div className='apps-qa__answers__edit-row'>
          {editingAnswer && (<span className='apps-qa__answers__edit'>Editing...</span>)}
          {!editingAnswer && (
            <Link
              to='#'
              className='apps-qa__answers__edit'
              onClick={!editingAnswer ? () => handleEditAnswer(answer) : undefined}
              disabled={editingAnswer}
            >
              {editingAnswer ? 'Editing...' : 'Edit your answer'}
            </Link>
          )}
        </div>
        {canEditAnswer && editingAnswer && (
          <Suspense fallback=''>
            <AnswerForm
              answer={answer}
              questionId={question.id}
              submitting={submitting}
              editingAnswer={editingAnswer}
              onLiveAnswer={content => setLiveAnswer(content)}
              canEditAnswer={canEditAnswer}
              onCancelAnswer={handleCancelAnswer}
            />
          </Suspense>
        )}
      </>
    )
  }
  function renderUserAnswer () {
    return (
      <div className='app-reviews-qa__qa__card__user-answer'>
        <div className='app-reviews-qa__qa__card__user-answer__content'>
          <div className='app-reviews-qa__qa__card__user-answer__top'>
            <div>
              <p className='app-reviews-qa__qa__card__user-answer__name'>
                {answer.name}
              </p>
              <p className='app-reviews-qa__qa__card__user-answer__desc'>
                {answer.website_url}
              </p>
            </div>
            <p className='app-reviews-qa__qa__card__user-answer__date'>
              {DateTime.fromISO(answer.created_at).toFormat('dd MMM, y')}
            </p>
          </div>
          <p className='app-reviews-qa__qa__card__user-answer__answer'>
            {answer.answer}
          </p>
        </div>
      </div>
    )
  }
  return (
    <div>
      {isDevAnswer && renderDevAnswer()}
      {!isDevAnswer && renderUserAnswer()}
    </div>
  )
}

AppAnswer.prototype = {
  answer: PropTypes.object,
  question: PropTypes.object
}
