import React from 'react'

import archetype from '@assets/images/partners-logos/archetype.svg'
import pixelunion from '@assets/images/partners-logos/pixel-union.svg'
import cleanCanvas from '@assets/images/partners-logos/clean-canvas.svg'
import grounpthoughts from '@assets/images/partners-logos/groupthoughts.svg'
import oots from '@assets/images/partners-logos/oots.svg'
import pagefly from '@assets/images/partners-logos/pagefly.svg'
import shogun from '@assets/images/partners-logos/shogun.svg'
import troopthemes from '@assets/images/partners-logos/troopthemes.svg'
import underground from '@assets/images/partners-logos/underground.svg'
import debutify from '@assets/images/partners-logos/debutify.svg'
import eight from '@assets/images/partners-logos/eight.svg'

function RightPanel () {
  return (
    <div className='partners-auth__right'>
      <h1 className='partners-auth__right__title'>
        Let’s maximize your customer loyalty!
      </h1>
      <p className='partners-auth__right__desc'>
        Whether you build Shopify themes or run a Shopify agency, your customers
        may need Shopify help with things that aren’t your primary focus or that
        require additional resources. We are here to help you.
      </p>

      <div className='partners-auth__right__partners'>
        <img src={archetype} alt='archetype' />
        <img src={pixelunion} alt='pixelunion' />
        <img src={oots} alt='out of the sand box' />
        <img src={shogun} alt='shotgun' />
        <img src={pagefly} alt='pagefly' />
        <img src={cleanCanvas} alt='clean canvas' />
        <img src={grounpthoughts} alt='grounp thoughts' />
        <img src={troopthemes} alt='troopthemes' />
        <img src={underground} alt='underground' />
        <img src={debutify} alt='Debutify' />
        <img src={eight} alt='eight themes' />
      </div>
    </div>
  )
}

export default RightPanel
