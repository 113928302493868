import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import TextInput from '@components/Inputs/TextInput'
import { loadPartner, updatePartner } from '@redux/referrals/partners'

const initialFields = {
  name: '',
  url: ''
}

class UpdatePartnerModal extends React.Component {
  state = {
    programSlug: '',
    fields: { ...initialFields }
  }

  constructor (props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.show !== prevProps.show && this.props.show
    ) {
      this.prefillState()
    }
  }

  prefillState () {
    const { partner } = this.props

    this.setState(old => ({
      fields: {
        ...initialFields,
        name: partner.name,
        url: partner.url,
        paypal_email: partner.paypal_email,
        phone: partner.phone,
        country: partner.country,
        city: partner.city,
        street: partner.street,
        postal_code: partner.postal_code
      }
    }))
  }

  handleChangeField (field) {
    return event => {
      const val = event.currentTarget.value

      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: val
        }
      }))
    }
  }

  handleChangeSelect (field) {
    return value => {
      this.setState(old => ({
        ...old,
        fields: {
          ...old.fields,
          [field]: value
        }
      }))
    }
  }

  handleClose () {
    this.setState(old => ({
      ...old,
      fields: { ...initialFields }
    }))

    this.props.onClose && this.props.onClose()
  }

  handleSubmit () {
    const { fields } = this.state

    this.props
      .updatePartner({
        slug: this.props.partner.slug,
        isSlug: true,

        name: fields.name,
        url: fields.url,
        paypal_email: fields.paypal_email,
        phone: fields.phone,
        country: fields.country,
        city: fields.city,
        street: fields.street,
        postal_code: fields.postal_code,
        status: this.props.partner.status
      })
      .then(() => {
        this.props.loadPartner({ slug: this.props.partner.slug, isSlug: true })
        this.props.onClose && this.props.onClose()
      })
      .catch(error => {
        console.log('update referral error:', error)
      })
  }

  render () {
    const { show } = this.props

    return (
      <Modal
        show={show}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={this.handleClose}
        >
          Update Partner
        </ModalHeader>
        <ModalBody className='pd__modals__body'>
          <TextInput
            label='Name *'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('name')}
            value={this.state.fields.name}
          />
          <TextInput
            label='URL'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('url')}
            value={this.state.fields.url}
          />
          <TextInput
            label='Slug'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            disabled
            value={this.props.partner.slug}
          />

          <TextInput
            label='PayPal Email *'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('paypal_email')}
            value={this.state.fields.paypal_email}
          />
          <TextInput
            label='Phone'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('phone')}
            value={this.state.fields.phone}
          />
          <TextInput
            label='Country'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('country')}
            value={this.state.fields.country}
          />
          <TextInput
            label='City'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('city')}
            value={this.state.fields.city}
          />
          <TextInput
            label='Street'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('street')}
            value={this.state.fields.street}
          />
          <TextInput
            label='Postal Code'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
            onChange={this.handleChangeField('postal_code')}
            value={this.state.fields.postal_code}
          />

          <div className='pd__modals__two-columns'>
            <Button
              primary
              className='pd__modals__two-columns__button'
              onClick={this.handleSubmit}
              disabled={this.props.loading}
            >
              Save changes
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.handleClose}>
              Cancel
            </Button>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

UpdatePartnerModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  partner: PropTypes.object,
  loading: PropTypes.bool,
  loadPartner: PropTypes.func,
  updatePartner: PropTypes.func
}

const mapStateToProps = ({ referrals: { partners } }) => ({})

const mapDispatchToProps = dispatch => ({
  loadPartner: payload => dispatch(loadPartner(payload)),
  updatePartner: payload => dispatch(updatePartner(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePartnerModal)
