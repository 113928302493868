import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ShadowBox from '@components/ShadowBox/index.jsx'

import banner from './images/banner_1.png'
import gear from './images/gear.svg'
import controls from './images/controls.svg'
import refresh from './images/refresh.svg'
import brush from './images/brush.svg'
import filter from './images/filter.svg'

const features = [
  {
    image: gear,
    description: 'Premium theme setups'
  },
  {
    image: controls,
    description: 'Small and Complex Theme Customizations'
  },
  {
    image: refresh,
    description: 'Theme version updates'
  },
  {
    image: brush,
    description: 'Custom design'
  },
  {
    image: filter,
    description: 'Conversion Optimization for Shopify merchants across all categories, with revenues between $250k to $2M/year.'
  }
]

export default function BestSection () {
  const [refCode, setRefCode] = useState('')

  const [refCodes] = useSelector(({ referrals: { refCodes } }) => [
    refCodes.data
  ])

  useEffect(() => {
    if (!refCodes?.length) {
      return
    }

    setRefCode(refCodes[0].custom_code)
  }, [refCodes?.length])

  return (
    <section className='p-resources-page__section'>
      <div className='p-resources-page__section__top'>
        <div className='p-resources-page__section__title'>What we do Best</div>
      </div>

      <div className='p-resources-page__section__description'>
        We handle small to very large premium theme projects.
      </div>

      <div className='p-resources-page__section__split' />

      <div className='p-resources-page__best'>
        <ShadowBox className='p-resources-page__best__features'>
          {features.map((feature) => (
            <div key={feature.description} className='p-resources-page__best__feature'>
              <div className='p-resources-page__best__feature__logo'>
                <img src={feature.image} alt='feature' />
              </div>
              <div className='p-resources-page__best__feature__description'>
                {feature.description}
              </div>
            </div>
          ))}
        </ShadowBox>

        <div className='p-resources-page__best__banner'>
          <a href={`${import.meta.env.VITE_SITE_URL}/ambassadors/?ref=${refCode}`} target='_blank' rel='noopener noreferrer'>
            <img src={banner} alt='banner' />
          </a>
        </div>
      </div>
    </section>
  )
}
