import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Label.scss'

const Label = forwardRef(({ children, className }, ref) => (
  <label ref={ref} className={classnames('hc-input-label', className)}>
    {children}
  </label>
))

export default Label

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}
