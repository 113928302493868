import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { matchPath, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { clearApp, fetchApp } from '@redux/apps.js'
import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import ContentPadding from '@components/ContentPadding/index.jsx'
import AppHero from '@pages/Partners/Partner/App/Hero.jsx'
import ReviewList from '@pages/Partners/Partner/App/List.jsx'
import AppQuestion from '@pages/Partners/Partner/App/Questions/index.jsx'
import ReviewInvites from '@pages/Partners/Partner/App/Invites.jsx'
import ReviewWidgets from './Widgets.jsx'

import './App.scss'

export default function AppPage () {
  let tab = 'invites'
  const [app] = useSelector(({ apps }) => [apps.app.data])

  const params = useParams()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const match = matchPath({ path: '/app-reviews/:id/:tab' }, location.pathname)
  if (match?.params?.tab) {
    tab = match.params.tab
  }

  useBreadcrumbs({
    label: 'Reviews',
    to: '/reviews'
  })
  useBreadcrumbs({
    label: app?.name || '',
    to: `/app-reviews/${params.id}`
  })

  useEffect(() => {
    if (!params.id) {
      return
    }

    dispatch(fetchApp({
      id: params.id
    }))

    return () => {
      dispatch(clearApp())
    }
  }, [params.id])

  return (
    <>
      <Helmet>
        <title>{app?.name || ''} | App Reviews</title>
      </Helmet>

      <ContentPadding>
        <div className='app-reviews-page'>
          <AppHero
            app={app}
            appId={app?.id}
          />
          <div className="app-page__tabs">
            <button
              className={classnames('app-page__button', {
                'app-page__button--active': tab === 'invites'
              })}
              onClick={() => navigate('')}
            >
              Get reviews
            </button>
            <button
              className={classnames('app-page__button', {
                'app-page__button--active': tab === 'list'
              })}
              onClick={() => navigate('list')}
            >
              Replies
            </button>
            <button
              className={classnames('app-page__button', {
                'app-page__button--active': tab === 'questions'
              })}
              onClick={() => navigate('questions')}
            >
              Q&A
            </button>
            <button
              className={classnames('theme-page__button', {
                'theme-page__button--active': tab === 'widgets'
              })}
              onClick={() => navigate('widgets')}
            >
              Widget
            </button>
          </div>

          <Routes>
            <Route index element={<ReviewInvites appId={app?.id} />} />
            <Route path='list' element={<ReviewList appId={app?.id} />} />
            <Route path='questions' element={<AppQuestion app={app} />} />
            <Route path='widgets' element={<ReviewWidgets appSlug={app?.slug} />} />
          </Routes>
        </div>
      </ContentPadding>
    </>
  )
}
