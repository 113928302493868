import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '@components/Button'

import arrowLeft from '@assets/icons/arrows/arrow-left.svg'
import arrowLeftLight from '@assets/icons/arrows/arrow-left-light.svg'
import arrowRight from '@assets/icons/arrows/arrow-right.svg'
import arrowRightLight from '@assets/icons/arrows/arrow-right-light.svg'

import './Cursor.scss'

class CursorPagination extends Component {
  state = {
    direction: 'next'
  }

  constructor (props) {
    super(props)

    this.state = {
      ...this.state,
      direction: props.direction || this.state.direction
    }

    this.handlePageChange = this.handlePageChange.bind(this)
  }

  getDetails () {
    const { direction } = this.state
    const { pageLimit, totalResults, remainingResults } = this.props

    if (!totalResults) {
      return { from: 0, to: 0 }
    }

    let to
    let from

    if (direction === 'next') {
      to = totalResults - remainingResults
      from = to - (totalResults < pageLimit ? totalResults : pageLimit) + 1
    } else if (direction === 'previous') {
      from = remainingResults + 1
      to = remainingResults + pageLimit
    }

    if (to > totalResults) {
      to = totalResults
    }

    return { from: from || 0, to: to || 0 }
  }

  getHasPage () {
    const { direction } = this.state
    const { pageLimit, totalResults, remainingResults } = this.props

    let next
    let previous

    if (direction === 'next') {
      previous = (remainingResults + pageLimit) < totalResults
      next = remainingResults > 0
    } else if (direction === 'previous') {
      previous = remainingResults > 0
      next = (remainingResults + pageLimit) < totalResults
    }

    return {
      previous,
      next
    }
  }

  handlePageChange (direction) {
    return () => {
      let before
      let after

      const len = this.props.items.length

      if (!len) {
        return
      }
      const { order } = this.state

      // list is ordered newest to oldest
      if (direction === 'previous') {
        after = this.props.items[0][this.props.cursor]
      } else if (direction === 'next') {
        before = this.props.items[len - 1][this.props.cursor]
      }

      // invert order
      if (order === 'oldest') {
        const b = before
        before = after
        after = b
      }

      this.props
        .onPageChange({ before, after, direction })
        .then(() => {
          this.setState(old => ({ ...old, direction }))
        })
    }
  }

  render () {
    const { totalResults, showDetails } = this.props

    const details = showDetails ? this.getDetails() : null
    const has = this.getHasPage()

    return (

      <div
        className={classnames('cursor-pagination', {
          'cursor-pagination--with-details': showDetails
        })}
      >
        {details && (
          <div className='cursor-pagination__details'>
            Showing {details.from > 0 && details.to > 0 ? `${details.from}-${details.to}` : '0'}  of {totalResults || 0}
          </div>
        )}

        <div className='cursor-pagination__actions'>
          <Button
            small
            className='cursor-pagination__action'
            secondary
            disabled={!has.previous}
            onClick={this.handlePageChange('previous')}
          >
            <img src={has.previous ? arrowLeft : arrowLeftLight} alt='previous' />
          </Button>

          <Button
            small
            className='cursor-pagination__action'
            secondary
            disabled={!has.next}
            onClick={this.handlePageChange('next')}
          >
            <img src={has.next ? arrowRight : arrowRightLight} alt='next' />
          </Button>
        </div>
      </div>
    )
  }
}

CursorPagination.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  direction: PropTypes.string,
  showDetails: PropTypes.bool,
  cursor: PropTypes.string,
  onPageChange: PropTypes.func.isRequired,

  pageLimit: PropTypes.number.isRequired,
  totalResults: PropTypes.number.isRequired,
  remainingResults: PropTypes.number.isRequired
}

export default CursorPagination
