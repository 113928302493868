import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import classnames from 'classnames'

import { declineWithdrawal, loadWithdrawalList, payWithdrawal } from '@redux/referrals/withdrawals'

import Dropdown from '@components/DropdownList'
import CursorPagination from '@components/Pagination/Cursor'

import StatusChangeModal from '@pages/Partners/Admin/Withdrawals/StatusChangeModal'
import EditWithdrawalModal from '@pages/Partners/Admin/Withdrawals/EditWithdrawalModal'

import avatar from '@assets/images/partners-dashboard/sample-avatar.png'
import editIcon from '@assets/icons/edit.svg'

import './List.scss'

const pageLimit = 15

class AdminWithdrawalsList extends React.Component {
  state = {
    status: 'pending',
    currentPage: 1,
    pagination: {},
    showPartnersAddModal: false,

    showEditModal: false,
    editContext: {},

    showStatusUpdateModal: false,
    statusUpdateContext: {},
    transactionId: ''
  }

  constructor (props) {
    super(props)

    this.handleToggleAddPartnersModal = this.handleToggleAddPartnersModal.bind(this)

    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)

    this.handleClickEdit = this.handleClickEdit.bind(this)
    this.handleCloseEditModal = this.handleCloseEditModal.bind(this)

    this.renderPendingOptions = this.renderPendingOptions.bind(this)
    this.handleClickOptions = this.handleClickOptions.bind(this)

    this.handleCloseUpdateStatusModal = this.handleCloseUpdateStatusModal.bind(this)
    this.handleClickConfirmStatusModal = this.handleClickConfirmStatusModal.bind(this)
    this.handleChangeTransactionId = this.handleChangeTransactionId.bind(this)
  }

  componentDidMount () {
    this.loadWithdrawals()
  }

  handleToggleAddPartnersModal () {
    this.setState({ showPartnersAddModal: !this.state.showPartnersAddModal })
  }

  handleChangeStatus (status) {
    return () => {
      this.setState(old => ({
        ...old,
        status
      }), () => {
        this.loadWithdrawals()
      })
    }
  }

  handlePageChange ({ before, after }) {
    return this.loadWithdrawals({ before, after })
  }

  handleClickOptions ({ option, onClose }) {
    switch (option.key) {
      case 'pay':
      case 'decline':
        onClose()
        this.handleClickStatusOption({
          action: option.key,
          withdrawal: option.value
        })
        break
      case 'edit':
        onClose()
        this.handleClickEdit({ withdrawal: option.value })
        break
    }
  }

  handleClickStatusOption ({ action, withdrawal }) {
    this.setState(old => ({
      ...old,
      showStatusUpdateModal: true,
      statusUpdateContext: {
        action,
        withdrawal
      }
    }))
  }

  handleChangeTransactionId (event) {
    const val = event.currentTarget.value

    this.setState(old => ({
      ...old,
      transactionId: val
    }))
  }

  handleCloseUpdateStatusModal () {
    this.setState(old => ({
      ...old,
      showStatusUpdateModal: false,
      statusUpdateContext: {},
      transactionId: ''
    }))
  }

  handleClickConfirmStatusModal () {
    const { action, withdrawal } = this.state.statusUpdateContext

    if (!withdrawal) {
      return
    }

    if (action === 'pay') {
      this.handlePayWithdrawal()
    } else if (action === 'decline') {
      this.handleDeclineWithdrawal()
    }
  }

  handlePayWithdrawal () {
    const { withdrawal } = this.state.statusUpdateContext

    this.props.payWithdrawal({
      withdrawalId: withdrawal.id,
      transactionId: this.state.transactionId
    }).then(() => {
      this.loadWithdrawals()

      this.setState(old => ({
        ...old,
        showStatusUpdateModal: false,
        statusUpdateContext: {},
        transactionId: ''
      }))
    }).catch(error => {
      if (error.response && (
        error.response.status === 404 ||
        error.response.status === 400
      )) {
        let message = error.response.data.message
        const fields = Object.entries(error.response.data?.params || {})

        if (fields.length > 0) {
          message = `Invalid parameters: ${fields[0][0]} ${fields[0][1]}`
        }

        this.setState(old => ({
          ...old,
          statusUpdateContext: {
            ...old.statusUpdateContext,
            error: message
          }
        }))
      }
      console.log('pay withdrawal error:', error)
    })
  }

  handleDeclineWithdrawal () {
    const { withdrawal } = this.state.statusUpdateContext

    this.props.declineWithdrawal({
      withdrawalId: withdrawal.id
    }).then(() => {
      this.loadWithdrawals()

      this.setState(old => ({
        ...old,
        showStatusUpdateModal: false,
        statusUpdateContext: {}
      }))
    }).catch(error => {
      if (error.response && error.response.status === 404) {
        this.setState(old => ({
          ...old,
          statusUpdateContext: {
            ...old.statusUpdateContext,
            error: error.response.data.message
          }
        }))
      }

      console.log('decline withdrawal error:', error)
    })
  }

  handleClickEdit ({ withdrawal }) {
    this.setState(old => ({
      ...old,
      showEditModal: true,
      editContext: {
        withdrawalId: withdrawal.id
      }
    }))
  }

  handleCloseEditModal () {
    this.setState(old => ({
      ...old,
      showEditModal: false,
      editContext: {}
    }))

    this.loadWithdrawals()
  }

  loadWithdrawals ({ before, after } = {}) {
    const { status } = this.state

    return this.props.loadWithdrawals({
      status,
      withPartner: true,
      limit: pageLimit,
      before: before,
      after: after
    }).catch(error => {
      console.log('load withdrawal error: ', error)
    })
  }

  render () {
    return (
      <>
        <div className='admin__withdrawals'>
          <div className='admin__withdrawals__title'>
            Withdrawals
          </div>

          {this.renderHeader()}

          <div className='admin__withdrawals__list'>
            {this.renderListHeader()}

            {this.renderItems()}

            {this.renderPagination()}
          </div>
        </div>

        <StatusChangeModal
          show={this.state.showStatusUpdateModal}
          withdrawal={this.state.statusUpdateContext.withdrawal}
          action={this.state.statusUpdateContext.action}
          onClose={this.handleCloseUpdateStatusModal}
          onConfirm={this.handleClickConfirmStatusModal}
          transactionId={this.state.transactionId}
          onChangeTransactionId={this.handleChangeTransactionId}
          error={this.state.statusUpdateContext.error}
        />

        <EditWithdrawalModal
          show={this.state.showEditModal}
          withdrawalId={this.state.editContext.withdrawalId}
          onClose={this.handleCloseEditModal}
        />
      </>
    )
  }

  renderHeader () {
    const { status } = this.state

    return (
      <div className='admin__withdrawals__header'>
        <div className='admin__withdrawals__header__tabs'>
          <div
            className={classnames('admin__withdrawals__header__tab', {
              'admin__withdrawals__header__tab--selected': status === 'pending'
            })}
            onClick={this.handleChangeStatus('pending')}
          >
            Pending
          </div>

          <div
            className={classnames('admin__withdrawals__header__tab', {
              'admin__withdrawals__header__tab--selected': status === 'paid'
            })}
            onClick={this.handleChangeStatus('paid')}
          >
            Paid
          </div>

          <div
            className={classnames('admin__withdrawals__header__tab', {
              'admin__withdrawals__header__tab--selected': status === 'declined'
            })}
            onClick={this.handleChangeStatus('declined')}
          >
            Declined
          </div>
        </div>
      </div>
    )
  }

  renderListHeader () {
    return (
      <div className='admin__withdrawals__list__headers'>
        <div
          className='admin__withdrawals__list__header admin__withdrawals__list__header--partner'
        >
          Partner
        </div>

        <div
          className='admin__withdrawals__list__header admin__withdrawals__list__header--amount'
        >
          Amount
        </div>

        <div
          className='admin__withdrawals__list__header admin__withdrawals__list__header--commission'
        >
          Commission
        </div>

        <div
          className='admin__withdrawals__list__header admin__withdrawals__list__header--submitted'
        >
          Submitted
        </div>
        <div
          className='admin__withdrawals__list__header admin__withdrawals__list__header--options'
        >
          &nbsp;
        </div>
      </div>
    )
  }

  renderItems () {
    return this.props.withdrawals.map(withdrawal => {
      let renderOptions

      if (withdrawal.status === 'pending') {
        renderOptions = this.renderPendingOptions
      }

      return (
        <div key={withdrawal.id} className='admin__withdrawals__item'>
          <div className='admin__withdrawals__item__details'>
            <img className='admin__withdrawals__item__details__picture' src={avatar} alt='avatar' />
            <div className='admin__withdrawals__item__details__name'>
              {withdrawal.partner && withdrawal.partner.name}
            </div>
          </div>

          <div className='admin__withdrawals__item__amount'>
            ${Number(withdrawal.amount).toFixed(2)}
          </div>

          <div className='admin__withdrawals__item__commission'>
            ${Number(withdrawal.commission).toFixed(2)}
          </div>

          <div className='admin__withdrawals__item__submitted'>
            {DateTime.fromISO(withdrawal.created_at).toFormat('dd MMM, y')}
          </div>

          <div className='admin__withdrawals__item__options'>
            {renderOptions && (
              <Dropdown
                onClose={() => {}}
                activator={({ onClick }) => (
                  <div className='admin__withdrawals__list-option-activator' onClick={onClick}>
                    <img src={editIcon} alt='options' />
                  </div>
                )}
                bodyClassName='admin__withdrawals__list-options'
                options={renderOptions(withdrawal)}
                onClickOption={this.handleClickOptions}
              />
            )}
          </div>
        </div>
      )
    })
  }

  renderPagination () {
    return (
      <div className='admin__withdrawals__list__pagination'>
        <CursorPagination
          direction='next'
          items={this.props.withdrawals}
          cursor='created_at'
          onPageChange={this.handlePageChange}
          showDetails
          pageLimit={pageLimit}
          totalResults={this.props.totalResults}
          remainingResults={this.props.remainingResults}
        />
      </div>
    )
  }

  renderPendingOptions (withdrawal) {
    return ({ open }) => [{
      key: 'edit',
      label: 'Edit',
      value: withdrawal
    }, {
      key: 'pay',
      label: 'Pay',
      value: withdrawal
    }, {
      key: 'decline',
      label: 'Decline',
      value: withdrawal
    }]
  }
}

AdminWithdrawalsList.propTypes = {
  loadWithdrawals: PropTypes.func,
  withdrawals: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number,

  payWithdrawal: PropTypes.func,
  declineWithdrawal: PropTypes.func
}

const mapStateToProps = ({ referrals: { withdrawals: base } }) => ({
  withdrawals: base.withdrawals.data,
  totalResults: base.withdrawals.total,
  remainingResults: base.withdrawals.remaining,
  loading: base.withdrawals.loading
})

const mapDispatchToProps = dispatch => ({
  loadWithdrawals: payload => dispatch(loadWithdrawalList(payload)),
  payWithdrawal: payload => dispatch(payWithdrawal(payload)),
  declineWithdrawal: payload => dispatch(declineWithdrawal(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminWithdrawalsList)
