import React from 'react'
import classnames from 'classnames'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import './Link.scss'

export default function Link (props) {
  return (
    <RouterLink
      {...props}
      className={classnames('usi-link', props.className)}
    >
      {props.children}
    </RouterLink>
  )
}

Link.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string
}
