import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { generateDevProfile, loadPartners, updatePartner } from '@redux/referrals/partners'
import { checkToken } from '@redux/account.js'

import ModalsPartnersAdd from './AddPartnerModal'
import UpdateReferralModal from './UpdateReferralModal'

import StatusUpdateModal from '@components/Partners/Admin/Modals/Partners/StatusUpdateModal'
import Dropdown from '@components/Dropdown'
import CursorPagination from '@components/Pagination/Cursor'
import SearchBar from '@components/SearchBar'
import Button from '@components/Button'

import avatar from '@assets/images/partners-dashboard/sample-avatar.png'
import editIcon from '@assets/icons/edit.svg'

import './List.scss'
import GenDevProfileModal from '@pages/Partners/Admin/Partners/GenDevProfileModal.jsx'

const pageLimit = 15

class AdminPartnersList extends React.Component {
  constructor (props) {
    super(props)

    this.handleToggleAddPartnersModal = this.handleToggleAddPartnersModal.bind(this)
    this.handleAddPartner = this.handleAddPartner.bind(this)

    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleClickStatusOption = this.handleClickStatusOption.bind(this)
    this.renderPendingOptions = this.renderPendingOptions.bind(this)
    this.renderActiveOptions = this.renderActiveOptions.bind(this)
    this.handleCloseUpdateStatusModal = this.handleCloseUpdateStatusModal.bind(this)
    this.handleSaveUpdateStatusModal = this.handleSaveUpdateStatusModal.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleClickFilter = this.handleClickFilter.bind(this)
    this.handleCloseGenDevProfileModal = this.handleCloseGenDevProfileModal.bind(this)
    this.handleClickGenerateDevProfile = this.handleClickGenerateDevProfile.bind(this)

    this.state = {
      status: 'active,paused',
      totals: {},
      prev: {},
      currentPage: 1,
      showPartnersAddModal: false,
      hasNext: false,
      hasPrev: false,
      search: '',
      order: 'newest',
      direction: 'next',

      showManageReferralCodeModal: false,
      referralCodeContext: {},

      showStatusUpdateModal: false,
      statusUpdateContext: {},

      genDevProfileModal: false,
      genDevProfileContext: {}
    }
    this.searchTimeout = null

    this.handleCloseManageReferralCode = this.handleCloseManageReferralCode.bind(this)
  }

  componentDidMount () {
    this.loadPartners({})
  }

  handleToggleAddPartnersModal () {
    this.setState({ showPartnersAddModal: !this.state.showPartnersAddModal })
  }

  handleAddPartner () {
    this.setState({ showPartnersAddModal: false })
    this.loadPartners({})
  }

  handleChangeStatus (status) {
    return () => {
      this.setState(old => ({
        ...old,
        status
      }), () => {
        this.loadPartners({})
      })
    }
  }

  handlePageChange ({ direction, before, after }) {
    return this.loadPartners({ before, after, pageChange: direction })
  }

  handleClickStatusOption ({ partner, onClose, status }) {
    this.setState(old => ({
      ...old,
      showStatusUpdateModal: true,
      statusUpdateContext: {
        status,
        partner
      }
    }), () => {
      // close dropdown
      onClose()
    })
  }

  handleCloseUpdateStatusModal () {
    this.setState(old => ({
      ...old,
      showStatusUpdateModal: false,
      statusUpdateContext: {}
    }))
  }

  handleSaveUpdateStatusModal ({ withWelcome, withPassword } = {}) {
    const { partner, status } = this.state.statusUpdateContext

    this.props.updatePartner({
      isSlug: true,
      ...partner,
      status,

      withWelcome,
      withPassword
    }).then(() => {
      this.setState(old => ({
        ...old,
        showStatusUpdateModal: false,
        statusUpdateContext: {}
      }))

      this.loadPartners({})
    }).catch(error => {
      // show error in modal
      console.log(error)
    })
  }

  handleSearchChange (event) {
    const val = event.currentTarget.value

    clearTimeout(this.searchTimeout)

    this.setState(old => ({
      ...old,
      search: val
    }))

    this.searchTimeout = setTimeout(() => {
      this.loadPartners({})
    }, 400)
  }

  handleClickFilter ({ onClose, option }) {
    onClose()

    switch (option.key) {
      case 'newest':
      case 'oldest':
        this.handleChangeOrder(option.value)
        break
    }
  }

  handleChangeOrder (order) {
    this.setState(old => ({
      ...old,
      order
    }), () => {
      this.loadPartners({})
    })
  }

  handleClickManageReferralCode ({ partner, onClose }) {
    onClose()

    this.setState(old => ({
      ...old,
      showManageReferralCodeModal: true,
      referralCodeContext: {
        partner
      }
    }))
  }

  handleCloseManageReferralCode () {
    this.setState(old => ({
      ...old,
      showManageReferralCodeModal: false,
      referralCodeContext: {}
    }))
  }

  loadPartners ({ before, after } = {}) {
    const { status, search, order } = this.state

    return this.props.loadPartners({
      search,
      order,
      status,
      before,
      after,
      limit: pageLimit,
      withOwner: true,
      withProgram: true,
      withDeveloper: true
    }).then(() => {
      this.setState(old => ({
        ...old,
        totals: {
          ...old.totals,
          [status]: this.props.totalResults
        }
      }))
    })
  }

  handleClickGenerateDevProfile (partner, onClose) {
    return event => {
      onClose()

      if (partner.developer_profile?.slug) {
        return
      }

      event.preventDefault()

      this.setState(old => ({
        ...old,
        genDevProfileModal: true,
        genDevProfileContext: { partner }
      }))
    }
  }

  handleCloseGenDevProfileModal () {
    this.setState(old => ({
      ...old,
      genDevProfileModal: false,
      genDevProfileContext: {}
    }))
  }

  render () {
    return (
      <>
        <div className='admin__partners'>
          <div className='admin__partners__title'>
            Partners
          </div>

          {this.renderHeader()}

          <SearchBar
            className='admin__partners__search-bar'
            placeholder='Search partners'
            onChange={this.handleSearchChange}
            value={this.state.search}
            filters={[{
              key: 'newest',
              label: 'Newest',
              value: 'newest'
            }, {
              key: 'oldest',
              label: 'Oldest',
              value: 'oldest'
            }]}
            onClickFilter={this.handleClickFilter}
          />

          <div className='admin__partners__list'>
            {this.renderListHeader()}

            {this.renderItems()}

            {this.renderPagination()}
          </div>
        </div>
        <ModalsPartnersAdd
          show={this.state.showPartnersAddModal}
          onSubmit={this.handleAddPartner}
          onClose={this.handleToggleAddPartnersModal}
        />
        <StatusUpdateModal
          show={this.state.showStatusUpdateModal}
          partner={this.state.statusUpdateContext.partner}
          status={this.state.statusUpdateContext.status}
          onSave={this.handleSaveUpdateStatusModal}
          onClose={this.handleCloseUpdateStatusModal}
        />
        <UpdateReferralModal
          show={this.state.showManageReferralCodeModal}
          partner={this.state.referralCodeContext.partner}
          onClose={this.handleCloseManageReferralCode}
        />
        <GenDevProfileModal
          onClose={this.handleCloseGenDevProfileModal}
          partner={this.state.genDevProfileContext.partner}
          open={this.state.genDevProfileModal}
        />
      </>
    )
  }

  renderHeader () {
    return (
      <div className='admin__partners__header'>
        <div className='admin__partners__header__tabs'>
          <div
            className={classnames('admin__partners__header__tab', {
              'admin__partners__header__tab--selected': this.state.status === 'active,paused'
            })}
            onClick={this.handleChangeStatus('active,paused')}
          >
            Active partners
          </div>

          <div
            className={classnames('admin__partners__header__tab', {
              'admin__partners__header__tab--selected': this.state.status === 'pending'
            })}
            onClick={this.handleChangeStatus('pending')}
          >
            Pending approval
          </div>

          <div
            className={classnames('admin__partners__header__tab', {
              'admin__partners__header__tab--selected': this.state.status === 'declined'
            })}
            onClick={this.handleChangeStatus('declined')}
          >
            Declined
          </div>
        </div>

        <Button primary onClick={this.handleToggleAddPartnersModal}>
          + Add New Partner
        </Button>
      </div>
    )
  }

  renderListHeader () {
    return (
      <div className='admin__partners__list__headers'>
        <div
          className='admin__partners__list__header admin__partners__list__header--partner'
        >
          Partner name
        </div>
        <div
          className='admin__partners__list__header admin__partners__list__header--company'
        >
          Company
        </div>
        <div
          className='admin__partners__list__header admin__partners__list__header--location'
        >
          City / Country
        </div>
        <div
          className='admin__partners__list__header admin__partners__list__header--registered'
        >
          Registered
        </div>
        <div
          className='admin__partners__list__header admin__partners__list__header--program'
        >
          Program
        </div>
        <div
          className='admin__partners__list__header admin__partners__list__header--options'
        >
          &nbsp;
        </div>
      </div>
    )
  }

  renderItems () {
    return this.props.partners.map(p => {
      const user = (p.users || []).find(u => u.is_owner)
      const programs = p.programs || []
      let renderOptions

      if (p.status === 'pending') {
        renderOptions = payload => this.renderPendingOptions({ ...payload, user })
      } else if (['active', 'paused'].includes(p.status)) {
        renderOptions = payload => this.renderActiveOptions({ ...payload, user })
      }

      return (
        <div key={p.id} className='admin__partners__item'>
          <div className='admin__partners__item__details'>
            {false && (<img className='admin__partners__item__details__picture' src={avatar} alt='avatar' />)}
            <div className='admin__partners__item__details__name'>
              {user?.name && (
                <Link to={`/partners/v/${p.slug}`}>{user.name}</Link>
              )}
              {!user?.name && (
                <span>User N/A</span>
              )}
            </div>
          </div>

          <div className='admin__partners__item__company'>
            <Link to={`/partners/v/${p.slug}`}>{p.name}</Link>
          </div>

          <div className='admin__partners__item__location'>
            {p.city || ''}{p.country ? `, ${p.country}` : ''}
          </div>

          <div className='admin__partners__item__registered'>
            {DateTime.fromISO(p.created_at).toFormat('dd MMM, y')}
          </div>

          <div className='admin__partners__item__program'>
            {programs.map(p => (
              <div key={p.id}>
                {p.name}
              </div>
            ))}
            <div className='admin__partners__item__status'>
              {p.status}
            </div>
          </div>

          <div className='admin__partners__item__options'>
            {renderOptions && (
              <Dropdown
                onClose={() => {}}
                activator={({ open, onClick }) => (
                  <div className='admin__partners__list-option-activator' onClick={onClick}>
                    <img src={editIcon} alt='options' />
                  </div>
                )}
                bodyClassName='admin__partners__list-options'
                body={({ onClose }) => renderOptions({ partner: p, onClose })}
              />
            )}
          </div>
        </div>
      )
    })
  }

  renderPagination () {
    return (
      <div className='admin__partners__list-pagination'>
        <CursorPagination
          direction='next'
          items={this.props.partners}
          cursor='created_at'
          onPageChange={this.handlePageChange}
          showDetails
          pageLimit={pageLimit}
          totalResults={this.props.totalResults}
          remainingResults={this.props.remainingResults}
        />
      </div>
    )
  }

  renderPendingOptions ({ partner, user, onClose }) {
    const hasDevProfile = partner.developer_profile?.slug?.length > 0

    return (
      <>
        <a
          href='#'
          className='admin__partners__list-option'
          onClick={() => this.handleClickStatusOption({ partner, onClose, status: 'active' })}
        >
          Approve
        </a>
        <a
          href='#'
          className='admin__partners__list-option'
          onClick={() => this.handleClickStatusOption({ partner, onClose, status: 'declined' })}
        >
          Decline
        </a>
        <Link
          to={hasDevProfile ? `/developers?search=${partner.developer_profile.slug}` : '#'}
          className='admin__partners__list-option'
          onClick={this.handleClickGenerateDevProfile(partner, onClose)}
        >
          {hasDevProfile ? 'Go to developer profile' : 'Generate developer profile'}
        </Link>
      </>
    )
  }

  renderActiveOptions ({ partner, user, onClose }) {
    const status = partner.status === 'active' ? 'paused' : 'active'
    const referralIds = partner.referral_codes.map(rc => rc.id).join(',')
    const hasDevProfile = partner.developer_profile?.slug?.length > 0

    return (
      <>
        <a
          href='#'
          className='admin__partners__list-option'
          onClick={() => this.handleClickStatusOption({ partner, onClose, status })}
        >
          {partner.status === 'active' ? 'Pause' : 'Activate'}
        </a>
        <a
          href='#'
          className='admin__partners__list-option'
          onClick={() => this.handleClickManageReferralCode({ partner, onClose })}
        >
          Manage Referral Codes
        </a>
        <Link
          to={`/conversions?partner_id=${partner.id}`}
          className='admin__partners__list-option'
          onClick={() => {}}
        >
          See conversions
        </Link>
        <Link
          to={`/clicks?referral_id=${referralIds}`}
          className='admin__partners__list-option'
          onClick={() => {}}
        >
          See clicks
        </Link>
        <Link
          to={hasDevProfile ? `/developers?search=${partner.developer_profile.slug}` : '#'}
          className='admin__partners__list-option'
          onClick={this.handleClickGenerateDevProfile(partner, onClose)}
        >
          {hasDevProfile ? 'Go to developer profile' : 'Generate developer profile'}
        </Link>
      </>
    )
  }
}

AdminPartnersList.propTypes = {
  loadPartners: PropTypes.func,
  updatePartner: PropTypes.func,
  partners: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number,
  checkToken: PropTypes.func,
  generateDevProfile: PropTypes.func
}

const mapStateToProps = ({ referrals: { partners } }) => ({
  partners: partners.data,
  totalResults: partners.totalResults,
  remainingResults: partners.remainingResults,
  loading: partners.loading
})

const mapDispatchToProps = dispatch => ({
  loadPartners: payload => dispatch(loadPartners(payload)),
  updatePartner: payload => dispatch(updatePartner(payload)),
  checkToken: payload => dispatch(checkToken(payload)),
  generateDevProfile: payload => dispatch(generateDevProfile(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminPartnersList)
