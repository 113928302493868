import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import ContentPadding from '@components/ContentPadding/index.jsx'

import UserInfo from '@pages/Partners/Partner/Settings/UserInfo.jsx'
import Team from '@pages/Partners/Partner/Settings/Team.jsx'
import Withdrawals from '@pages/Partners/Partner/Settings/Withdrawals.jsx'
import Notifications from '@pages/Partners/Partner/Settings/Notifications.jsx'

import './Settings.scss'

export default function SettingsPage () {
  const [workspace] = useSelector(({ account }) => [account.workspace])

  useBreadcrumbs({
    label: 'Settings',
    to: '/settings'
  })

  const isOwner = workspace.scopes.includes('partner:owner')
  const isBilling = workspace.scopes.includes('partner:billing:read')

  return (
    <>
      <Helmet>
        <title>Settings</title>
      </Helmet>

      <ContentPadding>
        <div className='settings_page'>
          <h1 className='settings_page__title'>
            Settings
          </h1>

          <div className='settings_page__sections'>
            <UserInfo />
            {isOwner && <Team />}
            {isBilling && <Withdrawals />}
            <Notifications />
          </div>
        </div>
      </ContentPadding>
    </>
  )
}
