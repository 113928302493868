import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Button.scss'

const Button = ({
  tabIndex,
  type,
  className,
  onClick,
  children,

  // modifiers
  large,
  small,

  expanded,
  hollow,
  drop,
  dropReversed,

  primary,
  secondary,
  success,
  danger,

  disabled
}) => (
  <button
    tabIndex={tabIndex}
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={classnames('button', {
      'button--expanded': expanded,
      'button--hollow': hollow,
      'button--drop': drop,
      'button--drop-reversed': dropReversed,

      'button--primary': primary,
      'button--secondary': secondary,
      'button--success': success,
      'button--danger': danger,

      'button--large': large,
      'button--small': small,

      'button--disabled': disabled
    }, className)}
  >
    {children}
  </button>
)

Button.propTypes = {
  tabIndex: PropTypes.number,
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  element: PropTypes.string,
  onClick: PropTypes.func,

  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  success: PropTypes.bool,

  hollow: PropTypes.bool,

  danger: PropTypes.bool,

  large: PropTypes.bool,
  small: PropTypes.bool,

  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
  drop: PropTypes.bool
}

export default Button
