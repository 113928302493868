export function formatUrl (url) {
  if (!url) {
    return {}
  }

  let full = url

  if (!/https?:\/\//.test(full)) {
    full = 'https://' + full
  }

  return {
    clean: url.replace(/https?:\/\//, '').replace(/^www./, ''),
    full
  }
}

export function getBusinessType (type) {
  switch (type) {
    case 'Shopify Brand':
      return 'Shopify Brand:'
    case 'Shopify Plus Brand':
      return 'Shopify Plus Brand:'
    case 'Shopify Partner':
      return 'Shopify Partner:'
    default:
      return 'Website Address:'
  }
}

export function getAppBusinessType (type) {
  switch (type) {
    case 'Shopify Brand':
      return 'Shopify Brand:'
    case 'Shopify Plus Brand':
      return 'Shopify Plus Brand:'
    case 'Shopify Expert':
      return 'Shopify Expert:'
    default:
      return 'Website Address:'
  }
}
