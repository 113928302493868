import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function ListRowItem (props) {
  return (
    <div
      className={classnames('hc-list-row__item-container', {
        'hc-list-row__item-container--border': props.border
      })}
    >
      <div
        className={classnames('hc-list-row__columns', {
          'hc-list-row__columns--auto': props.auto
        }, props.className)}
      >
        {props.children}
      </div>
    </div>
  )
}

ListRowItem.defaultProps = {
  border: true
}
ListRowItem.propTypes = {
  className: PropTypes.string,
  auto: PropTypes.bool,
  border: PropTypes.bool
}
