import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateTheme } from '@redux/themes.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function RemoveFilterModal ({ theme, filter, show, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    const data = new window.FormData()

    Object.entries(theme).forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0
      } else if (!value) {
        return
      }

      data.set(key, value)
    })

    const filters = theme.filters.map(f => f.slug || f).filter(f => f !== filter)

    if (filters.length) {
      data.set('filters', filters.join(','))
    } else {
      data.set('filters', '')
    }

    dispatch(
      updateTheme({
        id: theme.id,
        data
      })
    )
      .then(onSuccess)
      .catch(error => {
        console.log('error adding filter:', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Remove theme filter
      </Header>

      <Body className='pda-theme-view__filter-modal__body'>
        Are you sure you want to remove this filter?
      </Body>

      <Footer className='pda-theme-view__filter-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

RemoveFilterModal.propTypes = {
  theme: PropTypes.object,
  filter: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
