const ADD = 'toastr/ADD'
const REMOVE = 'toastr/REMOVE'

export const addToastr = payload => ({
  type: ADD,
  payload
})

export const removeToastr = id => ({
  type: REMOVE,
  payload: {
    id
  }
})

const initialState = {
  items: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD: {
      const exist = state.items.find(t => t.id === action.payload.id)

      if (exist) {
        return state
      }

      return {
        ...state,
        items: [
          ...state.items, {
            id: action.payload.id,
            type: action.payload.type,
            title: action.payload.title,
            message: action.payload.message,
            options: action.payload.options
          }
        ]
      }
    }
    case REMOVE:
      return {
        ...state,
        items: state.items.filter(t => t.id !== action.payload.id)
      }
    default:
      return state
  }
}
