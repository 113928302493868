import React from 'react'
import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import { signOut } from '@redux/account.js'

import AdminTopbar from '@components/Partners/Admin/Topbar'

function AdminTopbarContainer (props) {
  const dispatch = useDispatch()

  function onSignOut () {
    dispatch(signOut()).then(() => {
      window.location.href = '/login'
    })
  }

  return (
    <AdminTopbar
      name={props.name}
      onSignOut={onSignOut}
    />
  )
}

AdminTopbarContainer.propTypes = {
  name: PropTypes.string
}

const mapStateToProps = ({ account: { user } }) => ({
  name: user?.name
})

const ConnectedAdminTopbar = connect(mapStateToProps)(AdminTopbarContainer)

export default ConnectedAdminTopbar
