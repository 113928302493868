import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { deleteAnswer } from '@redux/appReviews.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

export default function AppDeleteAnswerModal ({ id, show, onSuccess, onClose }) {
  const [text, setText] = useState('')
  const [confirmed, setConfirmed] = useState(false)

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    if (text.toLowerCase() === 'delete') {
      setConfirmed(true)
    } else {
      setConfirmed(false)
    }
  })
  function handleDelete () {
    if (!confirmed) {
      return
    }
    dispatch(
      deleteAnswer({
        id
      })
    )
      .then(() => {
        onSuccess()
        onClose()
        setText('')
        setConfirmed(false)
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Answer deleted successfully.'
        })
      })
      .catch(error => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Error',
          message: 'Error on delete answer.'
        })
        console.log('error on delete answer: ', error)
      })
  }

  return (
    <Modal show={show} className='pda-app__add-modal'>
      <Header onClose={onClose}>
        Are you sure you want to permanently delete this Answer?
      </Header>
      <Body className='pda-app__add-modal__body'>
        <p>Type <i>Delete</i> to confirm</p>
        <TextInput
          labelClassName='pda-app__form__label'
          Placeholder='Delete'
          value={text}
          onChange={event => setText(event.currentTarget.value)}
        />
      </Body>
      <Footer className='pda-app__add-modal__footer'>
        <Button onClick={handleDelete} danger={confirmed} disabled={!confirmed}>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

AppDeleteAnswerModal.propTypes = {
  show: PropTypes.bool,
  id: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
