import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import homeIcon from '@assets/icons/home-icon.svg'

import './Breadcrumbs.scss'

export default function Breadcrumbs ({ withHome }) {
  const [crumbs] = useSelector(({ breadcrumbs }) => [breadcrumbs.crumbs])

  if (!crumbs.length) {
    return null
  }

  return (
    <div className='breadcrumbs'>
      {withHome && (
        <Link to='/' className='breadcrumbs__crumb'>
          <img src={homeIcon} alt='home' className='pd-layout__home-breadcrumb' />
        </Link>
      )}

      {crumbs.map((crumb, index) => (
        <React.Fragment key={crumb.id}>
          {(index > 0 || withHome) && <span className='breadcrumbs__separator'>/</span>}
          <Link to={crumb.to} className='breadcrumbs__crumb'>
            {crumb.label}
          </Link>
        </React.Fragment>
      ))}
    </div>
  )
}

Breadcrumbs.propTypes = {
  withHome: PropTypes.bool
}
