import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { addThemeFeature, updateThemeFeature } from '@redux/themes.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

const initialFields = {
  name: '',
  type: '',
  description: ''
}

export default function FeatureModal ({ type, feature, show, onClose, onSuccess, themeId }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !feature || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: feature.name,
      type: feature.type,
      description: feature.description
    })
  }, [show, feature?.id])

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  function handleSave () {
    let promise

    const data = {
      name: fields.name,
      type: fields.type,
      description: fields.description
    }

    if (type === 'edit') {
      promise = updateThemeFeature
    } else {
      promise = addThemeFeature
    }

    dispatch(promise({
      id: feature?.id,
      themeId,
      data: data
    }))
      .then(() => {
        setErrors({})
        onSuccess()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log(`error @${type} style`)
        }
      })
  }

  return (
    <Modal
      className='pda-theme-view__feature_modal'
      show={show}
    >
      <Header onClose={onClose}>
        {type === 'edit' ? 'Edit Style' : 'Add Style'}
      </Header>
      <Body className='pda-theme-view__feature-modal__body'>
        <SelectInput
          labelClassName='pda-theme-view__form__label'
          label='Type *'
          value={fields.type}
          onChange={value => setFields({ ...fields, type: value })}
          placeholder='Select type...'
          options={[{
            key: 'list',
            label: 'List',
            value: 'list'
          }, {
            key: 'descriptive',
            label: 'Descriptive',
            value: 'descriptive'
          }]}
        />

        <TextInput
          labelClassName='pda-theme-view__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />

        <TextInput
          multiline={4}
          labelClassName='pda-theme-view__form__label'
          label='Description'
          value={fields.description}
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
          subText={errors.description}
        />

      </Body>
      <Footer className='pda-theme-view__feature-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

FeatureModal.propTypes = {
  type: PropTypes.string,
  feature: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  themeId: PropTypes.string
}
