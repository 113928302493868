import React from 'react'

import ConversionListContainer from '@containers/PartnerDashboard/ConversionList'

import './Conversions.scss'

const pageLimit = 6

class DashConversions extends React.Component {
  render () {
    return (
      <div className='pah__tasks'>
        <div className='pah__tasks__title'>
          Conversions
        </div>

        <ConversionListContainer
          status='pending'
          limit={pageLimit}
        />
      </div>
    )
  }
}

export default DashConversions
