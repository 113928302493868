import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { generateDevProfile } from '@redux/referrals/partners.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import CheckBox from '@components/Inputs/CheckBox.jsx'
import Button from '@components/Button/index.jsx'

import './GenDevProfileModal.scss'

export default function GenDevProfileModal ({ open, partner, onClose }) {
  const [capabilities, setCapabilities] = useState([])

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    return () => {
      setCapabilities([])
    }
  }, [])

  function handleChange (capability) {
    return checked => {
      const caps = capabilities.filter(c => c !== capability)

      if (checked) {
        caps.push(capability)
      }

      setCapabilities(caps)
    }
  }

  function handleSubmit (event) {
    event?.preventDefault && event.preventDefault()

    dispatch(generateDevProfile({
      partnerId: partner.id,
      capabilities
    }))
      .then(() => {
        onClose()

        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Development profile generated'
        })

        const a = document.createElement('a')

        a.setAttribute('href', `/developers?search=${partner.name}`)
        a.setAttribute('target', '_blank')

        a.click()
      })
      .catch((error) => {
        console.error('genDevProfile error', error)
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data
        ) {
          message = error.response.data.message

          if (error?.response?.data.params) {
            const [[field, msg]] = Object.entries(error.response.data.params).slice(0, 1)

            message = `${field}: ${msg}`
          }
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
      })
  }

  return (
    <Modal show={open} medium>
      <Header onClose={onClose}>
        Gen. Development Profile
      </Header>

      <Body>
        <p>Are you sure you want to generate a development profile for <strong>{partner?.name}</strong>?</p>
        <div>
          <strong>Choose Capability/Type:</strong>

          <ul className='gen-dev-profile-modal__capabilities'>
            <li className='gen-dev-profile-modal__capability'>
              Apps:&nbsp;<CheckBox onChange={handleChange('apps')} checked={capabilities.includes('apps')} />
            </li>
            <li className='gen-dev-profile-modal__capability'>
              Themes:&nbsp;<CheckBox onChange={handleChange('themes')} checked={capabilities.includes('themes')} />
            </li>
          </ul>
        </div>
      </Body>

      <Footer>
        <Button secondary onClick={onClose}>Cancel</Button>
        <Button primary onClick={handleSubmit}>Generate</Button>
      </Footer>
    </Modal>
  )
}

GenDevProfileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  partner: PropTypes.object,
  onClose: PropTypes.func.isRequired
}
