import React from 'react'
import { Outlet } from 'react-router-dom'

import Topbar from '@containers/PartnerDashboard/Admin/Topbar'
import Sidebar from '@components/Partners/Admin/Sidebar'

import './Admin.scss'

const PartnerLayout = () => (
  <>
    <Topbar />

    <div className='pda__layout'>
      <Sidebar />

      <div className='pda__content'>
        <Outlet />
      </div>
    </div>
  </>
)

export default PartnerLayout
