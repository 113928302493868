import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { loadClicks } from '@redux/referrals/clicks'

import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import CursorPagination from '@components/Pagination/Cursor.jsx'

import './ClicksList.scss'

export default function ClicksList ({ pageLimit, showPagination = true }) {
  const [params, setParams] = useSearchParams()
  const [isEmpty, setIsEmpty] = useState(false)

  const direction = params.get('direction') || 'next'
  const before = params.get('before')
  const after = params.get('after')

  const [
    loading, clicks,
    totalResults, remainingResults
  ] = useSelector(({ referrals: { clicks } }) => [
    clicks.loading, clicks.data,
    clicks.totalResults, clicks.remainingResults
  ])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadClicks({
      limit: pageLimit,
      withCustomer: true
    }))
      .then(({ totalResults }) => {
        if (!totalResults) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      })
      .catch(error => {
        console.log('@loadClicks error', error)
      })
  }, [before, after, pageLimit])

  async function handlePageChange ({ before, after, direction }) {
    const params = {}

    if (before) {
      params.before = before
    }
    if (after) {
      params.after = after
    }
    if (direction) {
      params.direction = direction
    }

    setParams(params)
  }

  return (
    <div className='pd-clicks-shared__list-container'>
      <ListRow
        className='pd-clicks-shared__list'
        loading={loading}
        empty={isEmpty}
        headers={(
          <>
            <ListRowHeader
              className='pd-clicks-shared__list__referral pd-clicks-shared__list__referral--tab'
            >
              Visitor
            </ListRowHeader>
            <ListRowHeader
              className='pd-clicks-shared__list__click pd-clicks-shared__list__click--tab'
            >
              Visit date
            </ListRowHeader>
          </>
        )}
        items={clicks?.map((click) => {
          return (
            <ListRowItem key={click.id}>
              <ListRowColumn className='pd-clicks-shared__list__referral'>
                <div className='pd-clicks-shared__list__mobile-tab'>Referral:&nbsp;</div>
                {click.customer?.name || 'N/A'}
              </ListRowColumn>
              <ListRowColumn className='pd-clicks-shared__list__click'>
                <div className='pd-clicks-shared__list__mobile-tab'>clicks date:&nbsp;</div>
                {DateTime.fromISO(click.created_at).toFormat('dd MMM, y')}&nbsp;
              </ListRowColumn>
            </ListRowItem>
          )
        })}
      />
      {showPagination && (
        <CursorPagination
          direction={direction}
          cursor='created_at'
          showDetails
          items={clicks}
          onPageChange={handlePageChange}
          pageLimit={pageLimit}
          totalResults={totalResults}
          remainingResults={remainingResults}
        />
      )}
    </div>
  )
}

ClicksList.propTypes = {
  pageLimit: PropTypes.number,
  showPagination: PropTypes.bool
}
