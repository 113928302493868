import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import { loadCommissions, loadPrograms } from '@redux/referrals/programs'

import arrowHeadUp from '@assets/icons/arrow-heads/up.svg'
import edit from '@assets/icons/edit.svg'

import './ReferralPrograms.scss'

class PartnerPrograms extends React.Component {
  state = {
    accordion: ''
  }

  componentDidMount () {
    this.loadPrograms()
  }

  handleAccordion (key) {
    return () => {
      this.setState(old => ({
        ...old,
        accordion: key
      }))

      this.loadCommissions({ programSlug: key })
    }
  }

  loadPrograms () {
    this.props.loadPrograms({
      limit: 6
    })
  }

  loadCommissions ({ programSlug }) {
    this.props.loadCommissions({
      programSlug,
      limit: 10
    })
  }

  render () {
    return (
      <div className='arp'>
        <div className='arp__title'>
          Referral Programs

          <a className='button button--primary' href='/program-settings/add'>
            + Add New Program
          </a>
        </div>

        <div className='arp__headers'>
          <div
            className='arp__header arp__header--title'
          >
            Title
          </div>
          <div
            className='arp__header arp__header--affiliates'
          >
            Affiliates
          </div>
          <div
            className='arp__header arp__header--currency'
          >
            Currency
          </div>
          <div
            className='arp__header arp__header--cookie-time'
          >
            Cookie Time
          </div>
          <div
            className='arp__header arp__header--started'
          >
            Started
          </div>
          <div
            className='arp__header arp__header--actions'
          />
        </div>

        {this.renderItems()}
      </div>
    )
  }

  renderComHeaders () {
    return (
      <div className='arp__program__commissions__headers'>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--title'
        >
          Title
        </div>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--identifier'
        >
          Identifier
        </div>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--tier'
        >
          Tier Req.
        </div>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--recurring'
        >
          Recurring
        </div>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--form'
        >
          Commission Form
        </div>
        <div
          className='arp__program__commissions__header arp__program__commissions__header--amount'
        >
          Commission
        </div>
      </div>
    )
  }

  renderItems () {
    return this.props.programs.map(p => (
      <div key={p.id} className='arp__program'>
        <div className='arp__program__header' onClick={this.handleAccordion(p.slug)}>
          <div className='arp__program__title'>
            <div className=''>
              {p.name}
            </div>
            <div className='arp__program__title__description'>
              {p.slug}
            </div>
          </div>

          <div className='arp__program__affiliates'>
            {p.partner_count || '0'}
          </div>
          <div className='arp__program__currency'>
            {p.currency}
          </div>
          <div className='arp__program__cookie-time'>
            {p.cookie_time} {p.cookie_interval}
          </div>
          <div className='arp__program__started'>
            {DateTime.fromISO(p.created_at).toFormat('dd MMM, y')}
          </div>

          <div className='arp__program__actions'>
            <Link to='/program-settings/1/edit'>
              <img src={edit} />
            </Link>
            <img src={arrowHeadUp} />
          </div>
        </div>

        <div
          className={classnames('arp__program__body', {
            'arp__program__body--open': this.state.accordion === p.slug
          })}
        >
          <hr />

          <div className='arp__program__shareable-link'>
            <div className='arp__program__shareable-link__label'>
              Shareable Link:
            </div>

            <a
              href={`${import.meta.env.VITE_PARTNERS_URL}/sign-up?pgs=${p.slug}`}
              className='arp__program__shareable-link__input'
            >
              {import.meta.env.VITE_PARTNERS_URL}/sign-up?pgs={p.slug}
            </a>
          </div>

          <hr />

          <div className='arp__program__commissions'>
            <div className='arp__program__commissions__title'>
              Commission structure
            </div>

            {this.renderComHeaders()}

            {this.renderComItems(p)}
          </div>
        </div>
      </div>
    ))
  }

  renderComItems (program) {
    const com = this.props.commissions[program.slug]

    if (!com) {
      return null
    }

    // loading?

    // --

    if (!com?.data?.length) {
      return null
    }

    const getAmount = (amount, type) => {
      switch (type) {
        case 'percentage':
          return `${amount}%`
        default:
          return `${program.currency} amount`
      }
    }

    return com.data.map(c => (
      <div key={c.id} className='arp__program__commission'>
        <div className='arp__program__commission__title'>
          {c.name}
        </div>
        <div className='arp__program__commission__identifier'>
          {c.slug}
        </div>
        <div className='arp__program__commission__tier'>
          {c.tier_requirement || '-'}
        </div>
        <div className='arp__program__commission__recurring'>
          {c.recurring ? 'yes' : '-'}
        </div>
        <div className='arp__program__commission__form'>
          {c.type}
        </div>
        <div className='arp__program__commission__amount'>
          {getAmount(c.amount, c.type)}
        </div>
      </div>
    ))
  }
}

PartnerPrograms.propTypes = {
  programs: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  commissions: PropTypes.object,

  loadPrograms: PropTypes.func,
  loadCommissions: PropTypes.func
}

const mapStateToProps = ({ referrals: { programs } }) => ({
  programs: programs.data,
  commissions: programs.commissions,
  loading: programs.loading,
  totalResults: programs.totalResults
})
const mapDispatchToProps = dispatch => ({
  loadPrograms: payload => dispatch(loadPrograms(payload)),
  loadCommissions: payload => dispatch(loadCommissions(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerPrograms)
