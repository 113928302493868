import { combineReducers } from 'redux'

import partners from './partners.js'
import dashboard from './dashboard.js'
import conversions from './conversions.js'
import programs from './programs.js'
import refCodes from './refCodes.js'
import withdrawals from './withdrawals.js'
import settings from './settings.js'
import customers from './customers.js'
import clicks from './clicks.js'

export default combineReducers({
  partners,
  dashboard,
  conversions,
  programs,
  refCodes,
  withdrawals,
  settings,
  customers,
  clicks
})
