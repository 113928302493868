import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import Dropdown from '@components/Dropdown'

import './DropdownList.scss'

function DropdownList (props) {
  return (
    <Dropdown
      {...props}
      bodyClassName={classnames('usi-dropdown__list-options', props.bodyClassName)}
      body={({ open, onClose }) => (
        <>
          {props.options({ open, onClose }).map((option) => {
            const C = !option.external ? Link : 'a'

            return (
              <C
                key={option.key}
                to={option.to || '#'}
                href={option.external && option.to}
                className={classnames('usi-dropdown__list-option', {
                  'usi-dropdown__list-option--active': props.active === option.key
                })}
                // @formatter:off
                onClick={
                  props.onClickOption
                    ? (event) => props.onClickOption({ event, option, onClose })
                    : undefined
                }
                // @formatter:on
              >
                <div>{option.label}</div>
              </C>
            )
          })}
        </>
      )}
    />
  )
}

DropdownList.propTypes = {
  ...Dropdown.propTypes,
  // Similar to Dropdowns.body but must return an array, receives same params
  active: PropTypes.string,
  options: PropTypes.func,
  onClickOption: PropTypes.func
  // --
}

export default DropdownList
