import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_STORES_PENDING = 'themes/FETCH_STORES_PENDING'
const FETCH_STORES_FULFILLED = 'themes/FETCH_STORES_FULFILLED'
const FETCH_STORES_REJECTED = 'themes/FETCH_STORES_REJECTED'

const ADD_STORE_PENDING = 'themes/ADD_STORE_PENDING'
const ADD_STORE_FULFILLED = 'themes/ADD_STORE_FULFILLED'
const ADD_STORE_REJECTED = 'themes/ADD_STORE_REJECTED'

const UPDATE_STORE_PENDING = 'themes/UPDATE_STORE_PENDING'
const UPDATE_STORE_FULFILLED = 'themes/UPDATE_STORE_FULFILLED'
const UPDATE_STORE_REJECTED = 'themes/UPDATE_STORE_REJECTED'
export const fetchStores = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_STORES_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/theme-stores'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined
      }
    })

    const data = {
      total: response.data.total,
      stores: response.data.data
    }

    dispatch({ type: FETCH_STORES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_STORES_REJECTED })
    throw error
  }
}

export const addStore = payload => async dispatch => {
  try {
    dispatch({ type: ADD_STORE_PENDING })

    const response = await axios.post(getRefApiUrl('v1', '/theme-stores'), payload.data)

    const data = {
      store: response.data
    }

    dispatch({ type: ADD_STORE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_STORE_REJECTED })
    throw error
  }
}

export const updateStore = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_STORE_PENDING })

    const response = await axios.post(getRefApiUrl('v1', `/theme-stores/${payload.id}`), payload.data)

    const data = {
      store: response.data
    }

    dispatch({ type: UPDATE_STORE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_STORE_REJECTED })
    throw error
  }
}

const initialState = {
  hydrated: false,
  list: {
    total: 0,
    loading: false,
    data: []
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_STORES_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_STORES_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.stores
        }
      }
    case FETCH_STORES_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }
    default:
      return state
  }
}
