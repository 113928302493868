import React from 'react'
import { Helmet } from 'react-helmet'

import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import ContentPadding from '@components/ContentPadding/index.jsx'

import ReviewsHero from './Hero.jsx'
import ThemeList from './List.jsx'

import './Reviews.scss'

export default function ReviewsPage () {
  useBreadcrumbs({
    label: 'Reviews',
    to: '/reviews'
  })

  return (
    <>
      <Helmet>
        <title>Reviews</title>
      </Helmet>

      <ContentPadding>
        <div className='reviews-page'>
          <ReviewsHero />

          <ThemeList />
        </div>
      </ContentPadding>
    </>
  )
}
