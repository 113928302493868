import React, { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { clearQuestions, fetchQuestions } from '@redux/appReviews.js'

import SearchBar from '@components/SearchBar/index.jsx'
import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'

import Button from '@components/Button/index.jsx'
import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import DropdownList from '@components/DropdownList/index.jsx'
import DotMenu from '@components/DotMenu/index.jsx'

import QuestionModal from './QuestionModal.jsx'

import './Questions.scss'

const questionsLimit = 15

export default function AppQuestionsList () {
  const listRef = useRef()
  const searchTimeout = useRef(null)

  const [questions, totalQuestions, loadingQuestions] = useSelector(({ appReviews }) => [
    appReviews.questions.data,
    appReviews.questions.total,
    appReviews.questions.loading
  ])

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '')
  const [search, setSearch] = useState(searchVal)
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')

  const dispatch = useDispatch()
  const pagination = makePagination(totalQuestions, page, questionsLimit)

  useEffect(() => {
    return () => {
      dispatch(clearQuestions())
    }
  }, [])
  useEffect(() => {
    setSearchParams({ page: 1, search, status })
  }, [search, status])

  useEffect(() => {
    loadQuestions()
  }, [page, search, status])

  function loadQuestions () {
    dispatch(fetchQuestions({
      search: search || undefined,
      status: status || undefined,
      offset: pagination.offset,
      limit: pagination.limit,
      appId: '',
      withAnswer: 1
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
      .catch(error => {
        console.error('Error loading questions', error)
      })
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
      case 'view':
        break
    }
  }

  function handleQuestionModalSuccess () {
    setShowModal('')
    setSelected(null)

    loadQuestions()
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page, search, status })
  }
  return (
    <div className='tqa-page' ref={listRef}>
      <QuestionModal
        show={showModal !== ''}
        type={showModal}
        question={selected}
        onClose={() => setShowModal('')}
        onSuccess={handleQuestionModalSuccess}
      />
      <div className='tqa-page__title'>
        Shopify App Questions and Answers
      </div>

      <div className='tqa-page__list'>
        <div className='tqa-page__toolbar'>
          <SearchBar
            className='td-page__search'
            placeholder='Search question'
            value={searchVal}
            onChange={handleSearchChange}
          />
        </div>
        <div className='tqa-page__status'>
          <Button
            primary={status === ''}
            disabled={status === ''}
            onClick={() => setStatus('')}
          >
            All
          </Button>
          <Button
            primary={status === 'published'}
            disabled={status === 'published'}
            onClick={() => setStatus('published')}
          >
            Published
          </Button>
          <Button
            primary={status === 'unpublished'}
            disabled={status === 'unpublished'}
            onClick={() => setStatus('unpublished')}
          >
            Unpublished
          </Button>
          <Button
            primary={status === 'pending'}
            disabled={status === 'pending'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
        </div>

        <div>
          Total results: {totalQuestions}
        </div>

        <ListRow
          empty={!loadingQuestions && !questions.length}
          loading={loadingQuestions}
          className='tqa-page__list__items'
          headers={(
            <>
              <ListRowHeader className='tqa-page__list__theme'>
                Theme
              </ListRowHeader>
              <ListRowHeader className='tqa-page__list__question'>
                question
              </ListRowHeader>
              <ListRowHeader className='tqa-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={questions.map(question => (
            <ListRowItem key={question.id}>
              <ListRowColumn className='tqa-page__list__theme'>
                <div>{question.status}</div>
                <br />
                <div>{question.app_name}</div>
              </ListRowColumn>
              <ListRowColumn className='tqa-page__list__question'>
                <div className='tqa-page__list__question__by'>
                  <div>
                    Asked by: {question.name}
                  </div>
                  <div>
                    Posted: {DateTime.fromISO(question.created_at).toFormat('dd MMM, y')}
                  </div>
                </div>
                <div className='tqa-page__list__question__question'>
                  {question.question}
                </div>
              </ListRowColumn>
              <ListRowColumn className='tqa-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'view',
                    label: 'View',
                    value: question,
                    to: `/app-questions/${question.id}`
                  }, {
                    key: 'edit',
                    label: 'Edit',
                    value: question
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            handlePageChange(page, event)
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}

AppQuestionsList.propTypes = {}
