// constants
const CHANGE_VIEWPORT = 'viewport/CHANGE_VIEWPORT'

// action creators
export const changeViewport = payload => ({
  type: CHANGE_VIEWPORT,
  payload
})

// reducer
const initialState = {
  hydrated: false,
  height: null,
  width: null
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  if (action.type === CHANGE_VIEWPORT) {
    return {
      ...state,
      ...action.payload
    }
  }

  return state
}
