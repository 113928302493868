import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { addGalleryItem, updateGalleryItem } from '@redux/apps.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  type: '',
  preview: null,
  url: null,
  index: ''
}

export default function GalleryItemModal ({ type, galleryItem, show, onClose, onSuccess, appId }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const previewImageRef = useRef(null)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !galleryItem || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      type: galleryItem.type,
      url: galleryItem.type === 'youtube' ? galleryItem.url : '',
      index: galleryItem.index
    })
  }, [show, galleryItem?.id])

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})

      if (previewImageRef.current) {
        previewImageRef.current.value = ''
      }
    }
  }, [show])

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  function handleSave () {
    let promise

    const data = new window.FormData()

    Object.entries({
      type: fields.type,
      preview: fields.preview,
      url: fields.url,
      index: fields.index
    }).forEach(([key, value]) => {
      if (!value) {
        return
      }

      data.set(key, value)
    })

    if (type === 'edit') {
      promise = updateGalleryItem
    } else {
      promise = addGalleryItem
    }

    dispatch(promise({
      id: galleryItem?.id,
      appId,
      data
    }))
      .then(() => {
        setErrors({})
        onSuccess()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log('error gallery item')
        }
      })
  }

  return (
    <Modal
      className='pda-app-view__gallery-item_modal'
      show={show}
    >
      <Header onClose={onClose}>
        {type === 'edit' ? 'Edit Gallery Item' : 'Add Gallery Item'}
      </Header>
      <Body className='pda-app-view__gallery-item-modal__body'>
        <SelectInput
          labelClassName='pda-app-view__form__label'
          label='Type *'
          value={fields.type}
          onChange={value => setFields({ ...fields, type: value })}
          placeholder='Select type...'
          options={[{
            key: 'youtube',
            label: 'YouTube',
            value: 'youtube'
          }, {
            key: 'image',
            label: 'Image',
            value: 'image'
          }]}
        />

        <div className='pda-app__form__group'>
          {fields.type === 'youtube' && (
            <>
              <TextInput
                labelClassName='pda-app-view__form__label'
                label='YouTube URL'
                value={fields.url}
                onChange={event => setFields({ ...fields, url: event.currentTarget.value })}
                error={errors.url}
                subText={errors.url}
              />
              {galleryItem?.url && !fields.preview && (
                <a href={galleryItem.url} target='_blank' rel='noopener noreferrer'>
                  Watch video
                </a>
              )}
            </>

          )}
          {fields.type === 'image' && (
            <>
              <TextInput
                inputRef={previewImageRef}
                type='file'
                labelClassName='pda-app-view__form__label'
                label='Image'
                onChange={handleFileChange('preview')}
                accept='image/*'
                error={errors.preview_path}
                subText={errors.preview_path ? errors.preview_path : ''}
              />
              {galleryItem?.url && !fields.preview && (
                <img
                  className='pda__app-img-preview'
                  src={galleryItem?.url}
                  alt=''
                />
              )}
            </>
          )}
        </div>
        <div className='pda-app__form__group'>
          <TextInput
            type='number'
            labelClassName='pda-app-view__form__label'
            label='Index'
            value={fields.index}
            onChange={event => setFields({ ...fields, index: event.currentTarget.value })}
            error={errors.index}
          />
        </div>

      </Body>
      <Footer className='pda-app-view__style-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

GalleryItemModal.propTypes = {
  type: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  appId: PropTypes.string
}
