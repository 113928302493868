import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch, useSelector } from 'react-redux'
import { fetchThemes } from '@redux/themes.js'
import { addStore, updateStore } from '@redux/themeStores.js'
import { fetchProfiles } from '@redux/developers.js'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  status: '',
  theme_name: '',
  themeId: '',
  developer_name: '',
  name: '',
  url: '',
  description: '',
  meta_title: '',
  meta_description: ''
}

export default function StoreModal ({ show, type, store, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const themeSearchTimeout = useRef(null)
  const searchDevTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [themes, setThemes] = useState([])
  const [selectedTheme, setSelectedTheme] = useState({})
  const [searchDev, setSearchDev] = useState('')
  const [developers, setDevelopers] = useState([])
  const loadingDevs = useSelector(({ developers }) => developers.list.loading)
  const [submitting, setSubmitting] = useState(false)
  const [loadingThemes] = useSelector(({ themes }) => [themes.list.loading])

  const dispatch = useDispatch()
  const imageRef = useRef(null)
  const thumbnailRef = useRef(null)
  const coverRef = useRef(null)

  useEffect(() => {
    if (!show || !store || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      status: store.status,
      theme_name: store.theme_name || '',
      themeId: store.theme_id || '',
      developer_name: store.developer_name || '',
      name: store.name || '',
      url: store.url || '',
      description: store.description || '',
      meta_title: store.meta_title || '',
      meta_description: store.meta_description || ''
    })

    setSearch(store.theme_name || '')
    setSearchDev(store?.developer_name || '')
  }, [show, store])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setThemes([])
      setSearch('')
      setDevelopers([])
      setSearchDev('')
      if (imageRef.current) {
        imageRef.current.value = ''
      }
      if (thumbnailRef.current) {
        thumbnailRef.current.value = ''
      }
      if (coverRef.current) {
        coverRef.current.value = ''
      }
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, themeId: '' })
      setThemes([])

      return
    }

    themeSearchTimeout.current = setTimeout(() => {
      loadThemes(search)
    }, 400)

    return () => {
      clearTimeout(themeSearchTimeout.current)
    }
  }, [search])

  useEffect(() => {
    if (!searchDev) {
      setFields({ ...fields, developer_name: '' })
      setDevelopers([])

      return
    }

    searchDevTimeout.current = setTimeout(() => {
      loadDevelopers(searchDev)
    }, 400)

    return () => {
      clearTimeout(searchDevTimeout.current)
    }
  }, [searchDev])
  function loadDevelopers (searchDev) {
    dispatch(
      fetchProfiles({
        search: searchDev,
        limit: 10,
        offset: 0
      })
    )
      .then(({ profiles }) => {
        setDevelopers(profiles.map(profile => ({
          key: profile.id,
          label: profile.name,
          value: profile.name
        })))
      })
      .catch(error => {
        console.log('error fetch @developers:', error)
        setDevelopers([])
      })
  }

  function loadThemes (search) {
    dispatch(
      fetchThemes({
        search,
        limit: 10,
        offset: 0
      })
    )
      .then(({ themes }) => {
        setThemes(themes.map(theme => ({
          key: theme.id,
          label: theme.name,
          value: theme.id
        })))
      })
      .catch(error => {
        console.log('error fetch @themes: ', error)
        setThemes([])
      })
  }

  function handleSave () {
    setSubmitting(true)
    const data = new window.FormData()

    Object.entries({
      status: fields.status,
      theme_name: selectedTheme.label || fields.theme_name,
      theme_id: fields.themeId,
      developer_name: fields.developer_name,
      name: fields.name,
      url: fields.url,
      description: fields.description,
      meta_title: fields.meta_title,
      meta_description: fields.meta_description
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    if (fields.image) {
      data.set('image', fields.image)
    }
    if (fields.thumbnail) {
      data.set('thumbnail', fields.thumbnail)
    }
    if (fields.cover) {
      data.set('cover', fields.cover)
    }

    let promise

    if (type === 'add') {
      promise = addStore
    } else {
      promise = updateStore
    }

    dispatch(promise({
      id: store?.id,
      data
    }))
      .then(() => {
        setSubmitting(false)
        onSuccess()
        onClose()
        setSelectedTheme({})
      })
      .catch(error => {
        setSubmitting(false)
        setSelectedTheme({})
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'profile_not_found'
        ) {
          setErrors(old => ({ ...old, store_id: error.response.data.message }))
        } else {
          console.log(`error @${type} store`, error)
        }
      })
  }

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  const renderImage = () => {
    if (!store?.image_url && !fields.image) {
      return null
    }

    return (
      <img
        className='pda__theme-img-preview__large'
        src={fields.image ? URL.createObjectURL(fields.image) : store.image_url}
        alt=''
      />
    )
  }
  const renderThumbnail = () => {
    if (!store?.thumbnail_url && !fields.thumbnail) {
      return null
    }

    return (
      <img
        className='pda__theme-img-preview__large'
        src={fields.thumbnail ? URL.createObjectURL(fields.thumbnail) : store.thumbnail_url}
        alt=''
      />
    )
  }

  const renderCoverImage = () => {
    if (!store?.cover_url && !fields.cover) {
      return null
    }

    return (
      <img
        className='pda__theme-img-preview__large'
        src={fields.cover ? URL.createObjectURL(fields.cover) : store.cover_url}
        alt=''
      />
    )
  }

  return (
    <Modal show={show} large className='pda-theme__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Theme Store
      </Header>
      <Body className='pda-theme__add-modal__body'>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Name *'
            value={fields.name}
            placeholder='Store Name'
            onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
            error={errors.name}
            subText={errors.name}
          />
          <SelectInput
            label='Status *'
            labelClassName='pda-theme__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '2',
              label: 'Pending',
              value: 'pending'
            }, {
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Search Theme By Name'
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />
          <SelectInput
            label='Theme'
            labelClassName='pda-theme__form__label'
            value={fields.themeId}
            placeholder={loadingThemes ? 'Loading...' : !themes.length ? 'No theme found' : 'Select...'}
            onChange={value => {
              setFields({ ...fields, themeId: value })
              setSelectedTheme(themes && themes.find(theme => theme.value === value))
            }}
            options={themes}
            disabled={!themes.length || loadingThemes}
            error={errors.theme_id}
            subText={errors.theme_id}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Search Developer By Name'
            value={searchDev}
            onChange={event => setSearchDev(event.currentTarget.value)}
          />

          <SelectInput
            label='Theme Developer'
            labelClassName='pda-theme__form__label'
            value={fields.developer_name}
            placeholder={loadingDevs ? 'Loading...' : !developers.length ? 'No developer found' : 'Select...'}
            onChange={value => setFields({ ...fields, developer_name: value })}
            options={developers}
            disabled={!developers.length || loadingDevs}
            error={errors.developer_name}
            subText={errors.developer_name}
          />
        </Columns>
        <TextInput
          labelClassName='pda-theme__form__label'
          label='URL *'
          value={fields.url}
          onChange={event => setFields({ ...fields, url: event.currentTarget.value })}
          error={errors.url}
          subText={errors.url}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Description'
          value={fields.description}
          placeholder='The description of the store'
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
          subText={errors.description}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Meta Title'
          value={fields.meta_title}
          placeholder='meta title'
          onChange={event => setFields({ ...fields, meta_title: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />

        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Meta Description'
          value={fields.meta_description}
          placeholder='meta description'
          onChange={event => setFields({ ...fields, meta_description: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />
        <Columns>
          <div className='pda-theme__form__group_image'>
            <TextInput
              inputRef={imageRef}
              type='file'
              labelClassName='pda-theme__form__label'
              label='Image *'
              onChange={handleFileChange('image')}
              accept='image/*'
            />
            {renderImage()}
          </div>
          <div className='pda-theme__form__group-image'>
            <TextInput
              inputRef={thumbnailRef}
              type='file'
              labelClassName='pda-theme__form__label'
              label='Thumbnail Image *'
              onChange={handleFileChange('thumbnail')}
              accept='image/*'
            />
            {renderThumbnail()}
          </div>
          <div className='pda-theme__form__group-image'>
            <TextInput
              inputRef={coverRef}
              type='file'
              labelClassName='pda-theme__form__label'
              label='Cover Image'
              onChange={handleFileChange('cover')}
              accept='image/*'
            />
            {renderCoverImage()}

          </div>
        </Columns>
      </Body>
      <Footer className='pda-theme__add-modal__footer'>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

StoreModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  review: PropTypes.object
}
