import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_CUSTOMERS_PENDING = 'referrals/customers/LOAD_CUSTOMERS_PENDING'
const LOAD_CUSTOMERS_FULFILLED = 'referrals/customers/LOAD_CUSTOMERS_FULFILLED'
const LOAD_CUSTOMERS_REJECTED = 'referrals/customers/LOAD_CUSTOMERS_REJECTED'

export const loadCustomers = ({ limit, withClick, withPartner, before, after }) => async dispatch => {
  dispatch({
    type: LOAD_CUSTOMERS_PENDING
  })

  try {
    const res = await axios.get(getRefApiUrl('v1', '/customers'), {
      params: {
        limit: limit || 15,
        before: before ? encodeURIComponent(before) : undefined,
        after: after ? encodeURIComponent(after) : undefined,
        with_click: withClick ? 1 : undefined,
        with_partner: withPartner ? 1 : undefined
      }
    })

    const p = {
      data: res.data.data,
      totalResults: res.data.total,
      remainingResults: res.data.remaining,
      hasMore: res.data.has_more
    }

    dispatch({
      type: LOAD_CUSTOMERS_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: LOAD_CUSTOMERS_REJECTED
    })

    throw error
  }
}

const initialState = {
  data: [],
  loading: false,
  totalResults: 0,
  remainingResults: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CUSTOMERS_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_CUSTOMERS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        remainingResults: action.payload.remainingResults,
        hasMore: action.payload.hasMore
      }
    case LOAD_CUSTOMERS_REJECTED:
      return {
        ...state,
        loading: false,
        ...initialState
      }

    default:
      return state
  }
}
