import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import { sendInvite } from '@redux/account.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Label from '@components/Inputs/Label.jsx'

import './SendInviteModal.scss'
import { makePartnerAvailableRoles, makePartnerScopes } from '@util/account.js'

const initialFields = {
  email: '',
  scopes: []
}

export default function SendInviteModal ({ open, onClose, onSuccess }) {
  const [roles, setRoles] = useState([])

  const [fields, setFields] = useState(initialFields)
  const [errors, setErrors] = useState({})

  const [availableScopes, loading, workspace] = useSelector(({ referrals: { partners }, account }) => [
    partners.users.availableScopes,
    account.invites.loading,

    // logged auth
    account.workspace
  ])

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    return () => {
      setFields(initialFields)
      setErrors({})
      setRoles([])
    }
  }, [open])

  useEffect(() => {
    if (!open) {
      return
    }

    const available = makePartnerAvailableRoles(workspace, [])

    setRoles(available.roles)
    setFields(old => ({ ...old, scopes: available.scopes }))
  }, [open, availableScopes])

  function handleRoleChange (event) {
    const { value, checked } = event.currentTarget

    const scopes = makePartnerScopes(workspace, fields.scopes, value, checked)

    setFields({ ...fields, scopes })
  }

  function handleSave (event) {
    event?.preventDefault && event.preventDefault()

    dispatch(sendInvite({
      email: fields.email,
      scopes: fields.scopes
    }))
      .then(() => {
        onSuccess()
        onClose()

        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Teammate invited successfully'
        })
      })
      .catch(error => {
        let message = 'Try again later.'

        if (
          error.response?.status === 400 &&
          error.response?.data?.message
        ) {
          message = error.response.data.message
        }
        if (
          error.response?.status === 400 &&
          error.response?.data?.params
        ) {
          setErrors(error.response.data.params)
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
      })
  }

  return (
    <Modal show={open} className='pd-invite-user-modal' small>
      <Header onClose={onClose}>
        Invite Teammate
      </Header>
      <Body className='pd-invite-user-modal__body'>
        <form action='' onSubmit={handleSave}>
          <TextInput
            labelClassName='pd-invite-user-modal__label'
            label='Email'
            value={fields.email}
            onChange={event => setFields({ ...fields, email: event.target.value })}
            error={errors.email}
            subText={errors.email}
          />
          <br />
          <Label className='pd-invite-user-modal__label'>
            <span>Permissions:</span>
          </Label>
          {roles.map(role => (
            <div key={role.name} className='pd-invite-user-modal__role'>
              <div className='pd-invite-user-modal__role__title'>{role.name}</div>
              <div className='pd-invite-user-modal__role__permissions'>
                {role.items.map(item => (
                  <div className='pd-invite-user-modal__role__permission' key={item[0]}>
                    <input
                      type='checkbox'
                      value={item[0]}
                      onChange={handleRoleChange}
                      checked={fields.scopes.includes(item[0])}
                    />
                    {item[1]}
                  </div>
                ))}
              </div>
            </div>
          ))}
          {errors.scopes && (
            <span className='hc-input__sub hc-input__sub--error'>permissions is required</span>
          )}
        </form>
      </Body>
      <Footer className='pd-invite-user-modal__footer'>
        <Button secondary onClick={onClose}>
          Cancel
        </Button>
        <Button primary onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </Footer>
    </Modal>
  )
}

SendInviteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.object,
  partnerId: PropTypes.string
}
