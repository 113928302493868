import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchTheme, fetchThemeFeatures, fetchThemeStyles } from '@redux/themes.js'

import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'
import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'

import DotMenu from '@components/DotMenu/index.jsx'
import DropdownList from '@components/DropdownList/index.jsx'
import Button from '@components/Button/index.jsx'

import StyleModal from '@pages/Partners/Admin/Themes/StyleModal.jsx'
import FeatureModal from '@pages/Partners/Admin/Themes/FeatureModal.jsx'
import DeleteFeatureModal from '@pages/Partners/Admin/Themes/DeleteFeatureModal.jsx'
import DeleteStyleModal from '@pages/Partners/Admin/Themes/DeleteStyleModal.jsx'
import AddFilterModal from '@pages/Partners/Admin/Themes/AddFilterModal.jsx'
import RemoveFilterModal from '@pages/Partners/Admin/Themes/RemoveFilterModal.jsx'

import './View.scss'

export default function ThemeView () {
  const [ready, setReady] = useState(false)

  const [style, setStyle] = useState(null)
  const [showStyleModal, setShowStyleModal] = useState('')
  const [deleteStyleModal, setDeleteStyleModal] = useState(false)

  const [feature, setFeature] = useState(null)
  const [showFeatureModal, setShowFeatureModal] = useState('')
  const [deleteFeatureModal, setDeleteFeatureModal] = useState(false)

  const [addFilterModal, setAddFilterModal] = useState(false)
  const [removeFilterModal, setRemoveFilterModal] = useState(false)
  const [filter, setFilter] = useState(null)

  const [
    theme,
    totalStyles,
    styles,
    totalFeatures,
    features
  ] = useSelector(({ themes }) => [
    themes.view.data,
    themes.styles.total,
    themes.styles.data,
    themes.features.total,
    themes.features.data
  ])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.id) {
      return
    }

    loadTheme({
      id: params.id
    })
  }, [params.id])

  function loadTheme ({ id }) {
    dispatch(fetchTheme({
      id: id,
      filterExpand: true
    }))
      .then(({ theme }) => {
        setReady(true)
        loadStyles(theme.id)
        loadFeatures(theme.id)
      })
      .catch(error => {
        console.error('error view theme: ', error)
      })
  }

  function loadStyles (themeId) {
    dispatch(
      fetchThemeStyles({ id: themeId, clean: true })
    )
      .catch(error => {
        console.log('error loading theme styles: ', error)
      })
  }

  function loadFeatures (themeId) {
    dispatch(
      fetchThemeFeatures({ id: themeId, clean: true })
    )
      .catch(error => {
        console.log('error loading theme features: ', error)
      })
  }

  function handleClickStyle ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setStyle(option.value)
        setShowStyleModal('edit')
        break
      case 'remove':
        setStyle(option.value)
        setDeleteStyleModal(true)
        break
    }
  }

  function handleClickFeature ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setFeature(option.value)
        setShowFeatureModal('edit')
        break
      case 'remove':
        setFeature(option.value)
        setDeleteFeatureModal(true)
        break
    }
  }

  function handleClickFilter ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'remove':
        setFilter(option.value)
        setRemoveFilterModal(true)
        break
    }
  }

  if (!ready || !theme) {
    return null
  }

  const stylePagination = makePagination(totalStyles, 1, 15)
  const featurePagination = makePagination(totalFeatures, 1, 15)

  return (
    <div className='pda-theme-view'>
      <div className='pda-theme-view__title'>Theme: {theme.name}</div>

      <ShadowBox className='pda-theme-view__main'>
        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column'>
            <strong>Thumbnail:</strong>
            <br />
            <a
              href={theme.thumbnail_url}
              target='_blank'
              rel='noreferrer noopener'
            >
              <img className='pda__theme-img-preview' src={theme.thumbnail_url} alt='Thumbnail Image' />
            </a>
          </div>
          <div className='pda-theme-view__main__column'>
            <strong>Cover:</strong>
            <br />
            <a
              href={theme.cover_url}
              target='_blank'
              rel='noreferrer noopener'
            >
              <img className='pda__theme-img-preview' src={theme.cover_url} alt='Cover Image' />
            </a>
          </div>
        </div>

        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column'>
            <strong>Name:</strong>
            <br />
            {theme.name}
          </div>
          <div className='pda-theme-view__main__column'>
            <strong>Slug:</strong>
            <br />
            {theme.slug}
          </div>
        </div>

        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column'>
            <strong>Price:</strong>
            <br />
            {theme.price}
          </div>
          <div className='pda-theme-view__main__column'>
            <strong>Visibility:</strong>
            <br />
            {theme.status}
          </div>
        </div>
        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column'>
            <strong>On Shopify:</strong>
            <br />
            {theme.on_shopify ? 'Yes' : 'No'}
          </div>
          <div className='pda-theme-view__main__column'>
            <strong>In Service:</strong>
            <br />
            {theme.in_service ? 'Yes' : 'No'}
          </div>
        </div>

        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column pda-theme-view__main__column--full'>
            <strong>Description:</strong>
            <br />
            {theme.description}
          </div>
        </div>
        <div className='pda-theme-view__main__row'>
          <div className='pda-theme-view__main__column pda-theme-view__main__column--full'>
            <strong>Summary Description:</strong>
            <br />
            {theme.summary_description}
          </div>
        </div>

      </ShadowBox>

      <StyleModal
        show={showStyleModal !== ''}
        type={showStyleModal}
        style={style}
        themeId={theme.id}
        onClose={() => {
          setShowStyleModal('')
          setStyle(null)
        }}
        onSuccess={() => {
          setShowStyleModal('')
          setStyle(null)
          loadStyles(theme.id)
        }}
      />
      <DeleteStyleModal
        show={deleteStyleModal}
        style={style}
        themeId={theme.id}
        onClose={() => {
          setDeleteStyleModal(false)
          setStyle(null)
        }}
        onSuccess={() => {
          setDeleteStyleModal(false)
          setStyle(null)
          loadStyles(theme.id)
        }}
      />
      <div className='pda-theme-view__section'>
        <div className='pda-theme-view__sub'>
          Styles

          <Button onClick={() => setShowStyleModal('add')} primary>
            Add Style
          </Button>
        </div>
        <ListRow
          headers={(
            <>
              <ListRowHeader className='pda-theme-view__style__name'>Style</ListRowHeader>
              <ListRowHeader className='pda-theme-view__style__color'>Color</ListRowHeader>
              <ListRowHeader className='pda-theme-view__style__desktop'>Desktop</ListRowHeader>
              <ListRowHeader className='pda-theme-view__style__mobile'>Mobile</ListRowHeader>
              <ListRowHeader className='pda-theme-view__style__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={styles.map(style => (
            <ListRowItem key={style.id}>
              <ListRowColumn className='pda-theme-view__style__name'>
                {style.name}
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__style__color'>
                <div className='pda-theme-view__style__color__preview' style={{ backgroundColor: style.color }} />
                <div className='pda-theme-view__style__color__preview' style={{ backgroundColor: style.color_light }} />
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__style__desktop'>
                <a href={style.desktop_image_url} target='_blank' rel='noopener noreferrer'>
                  <img className='pda__theme-img-preview' src={style.desktop_image_url} alt={style.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__style__mobile'>
                <a href={style.mobile_image_url} target='_blank' rel='noopener noreferrer'>
                  <img className='pda__theme-img-preview' src={style.mobile_image_url} alt={style.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__style__options'>
                <DropdownList
                  onClickOption={handleClickStyle}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: style
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: style
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
        <SoftPagination
          onChange={() => {}}
          pagination={stylePagination}
        />
      </div>

      <AddFilterModal
        show={addFilterModal}
        theme={theme}
        onClose={() => setAddFilterModal(false)}
        onSuccess={() => {
          loadTheme({ id: theme.id })
          setAddFilterModal(false)
        }}
      />
      <RemoveFilterModal
        show={removeFilterModal}
        theme={theme}
        filter={filter}
        onClose={() => {
          setRemoveFilterModal(false)
          setFilter(null)
        }}
        onSuccess={() => {
          setRemoveFilterModal(false)
          setFilter(null)
          loadTheme({ id: theme.id })
        }}
      />
      <div className='pda-theme-view__section'>
        <div className='pda-theme-view__sub'>
          Filters

          <Button onClick={() => setAddFilterModal(true)} primary>
            Add Filter
          </Button>
        </div>
        <ListRow
          headers={(
            <>
              <ListRowHeader className='pda-theme-view__filter__name'>Filter</ListRowHeader>
              <ListRowHeader className='pda-theme-view__filter__group'>Group</ListRowHeader>
              <ListRowColumn className='pda-theme-view__filter__space'>&nbsp;</ListRowColumn>
              <ListRowHeader className='pda-theme-view__filter__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={theme.filters.map(filter => (
            <ListRowItem key={filter.id}>
              <ListRowColumn className='pda-theme-view__filter__name'>{filter.name}</ListRowColumn>
              <ListRowColumn className='pda-theme-view__filter__group'>{filter?.group?.name}</ListRowColumn>
              <ListRowColumn className='pda-theme-view__filter__space'>&nbsp;</ListRowColumn>
              <ListRowColumn className='pda-theme-view__filter__options'>
                <DropdownList
                  onClickOption={handleClickFilter}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'remove',
                    label: 'Remove',
                    value: filter.slug
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>

      <FeatureModal
        show={showFeatureModal !== ''}
        type={showFeatureModal}
        feature={feature}
        themeId={theme.id}
        onSuccess={() => {
          setShowFeatureModal('')
          setFeature(null)
          loadFeatures(theme.id)
        }}
        onClose={() => {
          setShowFeatureModal('')
          setFeature(null)
        }}
      />
      <DeleteFeatureModal
        show={deleteFeatureModal}
        feature={feature}
        themeId={theme.id}
        onClose={() => {
          setFeature(null)
          setDeleteFeatureModal(false)
        }}
        onSuccess={() => {
          setDeleteFeatureModal(false)
          setFeature(null)
          loadFeatures(theme.id)
        }}
      />
      <div className='pda-theme-view__section'>
        <div className='pda-theme-view__sub'>
          Features

          <Button onClick={() => setShowFeatureModal('add')} primary>
            Add Feature
          </Button>
        </div>
        <ListRow
          headers={(
            <>
              <ListRowHeader className='pda-theme-view__feature__type'>Type</ListRowHeader>
              <ListRowHeader className='pda-theme-view__feature__name'>Name</ListRowHeader>
              <ListRowHeader className='pda-theme-view__feature__description'>Description</ListRowHeader>
              <ListRowHeader className='pda-theme-view__feature__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={features.map(feature => (
            <ListRowItem key={feature.id}>
              <ListRowColumn className='pda-theme-view__feature__type'>
                {feature.type}{feature.type === 'list' ? ' (csv)' : ''}
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__feature__name'>{feature.name}</ListRowColumn>
              <ListRowColumn className='pda-theme-view__feature__description'>
                {feature.description.length > 70 ? `${feature.description.slice(0, 70)}...` : feature.description}
              </ListRowColumn>
              <ListRowColumn className='pda-theme-view__feature__options'>
                <DropdownList
                  onClickOption={handleClickFeature}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: feature
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: feature
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
        <SoftPagination
          onChange={() => {}}
          pagination={featurePagination}
        />
      </div>

    </div>
  )
}
