import { nanoid } from 'nanoid'
import { useDispatch } from 'react-redux'

import { addToastr } from '@redux/toastr'

const initialOptions = {
  timeOut: 3000,
  position: 'top-right'
}

export const TOASTR_DANGER = 'danger'
export const TOASTR_INFO = 'info'
export const TOASTR_SUCCESS = 'success'
export const TOASTR_WARNING = 'warning'

export const useToastr = () => {
  const dispatch = useDispatch()

  return ({ type, title, message, options = initialOptions }) => {
    const id = nanoid(4)

    dispatch(addToastr({
      id,
      title,
      message,
      type,
      options
    }))

    return id
  }
}
