import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

export const adminTopOffset = 56

export const scrollToElement = (el, { offset = 0 } = {}) => {
  if (!window || !el) {
    return
  }

  const rect = el.getBoundingClientRect()

  window && window.scroll({
    top: rect.top + (window.pageYOffset || document.documentElement.scrollTop) + offset,
    left: 0,
    behavior: 'smooth'
  })
}

export const useScrollTop = () => {
  const location = useLocation()
  const timeoutId = useRef(null)

  useEffect(() => {
    if (!window) {
      return
    }

    clearTimeout(timeoutId.current)

    timeoutId.current = setTimeout(() => {
      window && window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }, [location.pathname])

  return null
}
