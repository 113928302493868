import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { updateTheme } from '@redux/themes.js'
import { fetchFilters, fetchGroups } from '@redux/themeFilters.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

export default function AddFilterModal ({ theme, show, onClose, onSuccess }) {
  const [group, setGroup] = useState('')
  const [filter, setFilter] = useState('')
  const dispatch = useDispatch()

  const [groups, filters] = useSelector(({ themeFilters: { groups, filters } }) => [groups.data, filters.data])

  useEffect(() => {
    dispatch(fetchGroups({
      limit: 100,
      offset: 0
    }))
      .then(() => setGroup(''))
      .catch(error => {
        console.log('error loading groups:', error)
      })
  }, [])

  useEffect(() => {
    if (!group) {
      return
    }

    dispatch(fetchFilters({
      limit: 100,
      offset: 0,
      groupId: group
    }))
      .then(() => setFilter(''))
      .catch(error => {
        console.log('error loading filters:', error)
      })
  }, [group])

  function handleConfirm () {
    const data = new window.FormData()

    Object.entries(theme).forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        value = value ? 1 : 0
      } else if (!value) {
        return
      }

      data.set(key, value)
    })

    data.set('filters', theme.filters.map(f => f.slug || f).concat(filter).join(','))

    dispatch(
      updateTheme({
        id: theme.id,
        data
      })
    )
      .then(onSuccess)
      .catch(error => {
        console.log('error adding filter:', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Add theme filter
      </Header>

      <Body className='pda-theme-view__filter-modal__body'>
        <SelectInput
          labelClassName='pda-theme-view__form__label'
          label='Filter Group'
          placeholder='Select a filter group...'
          value={group}
          options={groups.map(group => ({
            key: group.id,
            label: group.name,
            value: group.id
          }))}
          onChange={value => setGroup(value)}
        />

        <SelectInput
          labelClassName='pda-theme-view__form__label'
          label='Filter'
          placeholder='Select a filter...'
          options={filters.map(filter => ({
            key: filter.id,
            label: filter.name,
            value: filter.slug
          }))}
          value={filter}
          onChange={value => setFilter(value)}
          disabled={!filters.length || !group}
        />
      </Body>

      <Footer className='pda-theme-view__filter-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Add
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

AddFilterModal.propTypes = {
  theme: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
