import React from 'react'

import TextInput from '@components/Inputs/TextInput'
import SelectInput from '@components/Inputs/SelectInput'
import ToggleSwitch from '@components/ToggleSwitch'
import Button from '@components/Button'

import './General.scss'

const currencyOptions = [{
  key: 'usd',
  value: 'usd',
  label: 'USD - $',
  selectedLabel: 'USD - $'
}]

const trackingMethodOptions = [{
  key: 'query',
  value: 'query',
  label: 'Query',
  selectedLabel: 'Query'
}]

class SettingsGeneral extends React.Component {
  render () {
    return (
      <>
        <div className='aps__section'>
          <TextInput
            label='Title'
            type='text'
            placeholder='Our affiliate program'
            className='aps__input'
            labelClassName='aps__label'
          />

          <TextInput
            label='Landing Page URL'
            type='text'
            placeholder='https://heycarson.tapfiliate.com/programs/our-affiliate-program-247/signup/'
            className='aps__input'
            labelClassName='aps__label'
          />

          <div className='aps__section__two-columns'>
            <div className='aps__section__two-columns__column'>
              <SelectInput
                label='Currency'
                name='currency'
                className='aps__select'
                labelClassName='aps__label'
                options={currencyOptions}
                value='usd'
              />
            </div>
            <div className='aps__section__two-columns__column'>
              <TextInput
                label='Cookie Time In Days'
                type='text'
                placeholder='45 days'
                className='aps__input'
                labelClassName='aps__label'
              />
            </div>
          </div>

          <div className='aps__switch'>
            <div className='aps__switch__label'>
              Automatically approve affiliates
            </div>
            <ToggleSwitch
              id='switch'
              checked
            />
          </div>

          <div className='aps__switch'>
            <div className='aps__switch__label'>
              Automatically approve commissions
            </div>
            <ToggleSwitch
              id='switch'
              checked
            />
          </div>

          <hr />

          <div className='aps__section__title'>
            Notification Settings
          </div>

          <div className='aps__switch'>
            <div className='aps__switch__label'>
              Send my affiliates an email when they are approved for a program
            </div>
            <ToggleSwitch
              id='switch'
              checked
            />
          </div>

          <div className='aps__switch'>
            <div className='aps__switch__label'>
              Receive an email whenever a new affiliate signs up
            </div>
            <ToggleSwitch
              id='switch'
            />
          </div>

          <hr />

          <div className='aps__section__title'>
            Advanced Settings
          </div>

          <TextInput
            label='Exposed meta data'
            type='text'
            className='aps__input'
            labelClassName='aps__label'
          />

          <SelectInput
            label='Tracking method'
            name='tracking_method'
            className='aps__select'
            labelClassName='aps__label'
            options={trackingMethodOptions}
            value='query'
          />

          <div className='aps__switch'>
            <div className='aps__switch__label'>
              Referral codes
            </div>
            <ToggleSwitch
              id='switch'
              checked
            />
          </div>
        </div>
        <div className='aps__save-container'>
          <Button primary className='aps__save'>
            Save changes
          </Button>
        </div>
      </>
    )
  }
}

export default SettingsGeneral
