import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { loadWithdrawalList } from '@redux/referrals/withdrawals'

import './Users.scss'

class PartnerWithdrawalsDetails extends React.Component {
  state = {
    ready: false
  }

  componentDidMount () {
    if (this.props.partner) {
      this.loadWithdrawals()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.partner &&
      this.props.partner?.id !== prevProps.partner?.id
    ) {
      this.loadWithdrawals()
    }
  }

  loadWithdrawals () {
    this.props.loadWithdrawals({
      partnerId: this.props.partner.id,
      limit: 9,
      status: 'paid'
    }).then(() => {
      this.setState(old => ({
        ...old,
        ready: true
      }))
    })
  }

  render () {
    if (!this.state.ready) {
      return null
    }

    const paypalEmail = this.props.partner.paypal_email

    return (
      <div className='admin-pview__details admin-pview__programs'>
        <div className='admin-pview__details__top'>
          <div className='admin-pview__details__title'>
            Payouts / Withdrawals
          </div>
        </div>

        <div className='admin-pview__details__method'>
          Method:&nbsp;
          {paypalEmail && (
            <span>PayPal <a href={`mailto:${paypalEmail}`}>{paypalEmail}</a></span>
          )}
          {!paypalEmail && 'N/A'}
        </div>

        <div className='admin-pview__details__items'>
          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Amount / Commission
            </div>

          </div>

          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Date
            </div>
          </div>

          <div className='admin-pview__details__item'>
            <div className='admin-pview__details__item__header'>
              Payout Method
            </div>
          </div>
        </div>

        {this.props.withdrawals.length <= 0 && (
          <div className='admin-pview__details__na'>
            N/A
          </div>
        )}

        {this.props.withdrawals.map(c => (
          <div key={c.id} className='admin-pview__details__items'>
            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__value'>
                ${c.amount} / ${c.commission} USD
              </div>
            </div>

            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__value'>
                {DateTime.fromISO(c.created_at).toFormat('LLL d, y')}
              </div>
            </div>
            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__value'>
                {this.props.partner.paypal_email}
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}

PartnerWithdrawalsDetails.propTypes = {
  partner: PropTypes.object,
  loading: PropTypes.bool,
  loadWithdrawals: PropTypes.func,
  withdrawals: PropTypes.array
}

const mapStateToProps = ({ referrals: { withdrawals: { withdrawals } } }) => ({
  withdrawals: withdrawals.data,
  loading: withdrawals.loading
})

const mapDispatchToProps = dispatch => ({
  loadWithdrawals: payload => dispatch(loadWithdrawalList(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerWithdrawalsDetails)
