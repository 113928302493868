import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'

import { loadCustomers } from '@redux/referrals/customers'

import CursorPagination from '@components/Pagination/Cursor'
import SearchBar from '@components/SearchBar'
import Dropdown from '@components/DropdownList'

import editIcon from '@assets/icons/edit.svg'

import './ReferralList.scss'

function ReferralListContainer (props) {
  const [dates, setDates] = useState({ before: null, after: null })

  const navigate = useNavigate()

  useEffect(() => {
    loadCustomers({ before: dates.before, after: dates.after })
  }, [dates.before, dates.after])

  function loadCustomers ({ before, after } = {}) {
    return props.loadCustomers({
      limit: props.limit,
      before,
      after,
      withPartner: true,
      withClick: true
    })
      .catch(error => {
        console.log('@loadCustomers error', error)
      })
  }

  function handlePageChange ({ before, after }) {
    return new Promise((resolve) => {
      setDates({ before, after })
      resolve()
    })
  }

  function handleClickOption ({ option, onClose }) {
    onClose()

    switch (option.key) {
      case 'see-conversions':
        navigate(`/conversions/?click_id=${option.value.click_id}`)
        break
    }
  }

  function renderOptions ({ customer }) {
    return () => {
      return [{
        key: 'see-conversions',
        label: 'See Conversions',
        value: customer
      }]
    }
  }

  const { items } = props

  return (
    <div className='custlist'>
      <div className='custlist__search'>
        <SearchBar
          placeholder='Search customers'
          onChange={() => {}}
          filters={[{
            name: 'Partner'
          }]}
        />
      </div>

      <div className='custlist__headers'>
        <div className='custlist__header custlist__header--referral'>Name</div>
        <div className='custlist__header custlist__header--partner'>Partner</div>
        <div className='custlist__header custlist__header--click'>Click</div>
        <div className='custlist__header custlist__header--purchase'>Conversions</div>
        <div className='custlist__header custlist__header--date'>Date</div>
        <div className='custlist__header custlist__header--options'>&nbsp;</div>
      </div>
      <div className='custlist__items'>
        {items.map(customer => (
          <div key={customer.id} className='custlist__item'>
            <div className='custlist__column custlist__column--referral'>
              {customer.name}
            </div>
            <div className='custlist__column custlist__column--partner'>
              {customer.partner.name}
            </div>
            <div className='custlist__column custlist__column--click'>
              {DateTime.fromISO(customer.click.created_at).toFormat('dd MMM, y')}
            </div>
            <div className='custlist__column custlist__column--purchase'>
              $0.00
            </div>
            <div className='custlist__column custlist__column--date'>
              {DateTime.fromISO(customer.created_at).toFormat('dd MMM, y')}
            </div>
            <div className='custlist__column custlist__column--options'>
              {renderOptions && (
                <Dropdown
                  onClose={() => {}}
                  activator={({ open, onClick }) => (
                    <div className='admin__partners__list-option-activator' onClick={onClick}>
                      <img src={editIcon} alt='options' />
                    </div>
                  )}
                  bodyClassName='admin__partners__list-options'
                  options={renderOptions({ customer })}
                  onClickOption={handleClickOption}
                />
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='custlist__pagination'>
        <CursorPagination
          direction='next'
          items={props.items}
          cursor='created_at'
          onPageChange={handlePageChange}
          showDetails
          pageLimit={props.limit}
          totalResults={props.totalResults}
          remainingResults={props.remainingResults}
        />
      </div>
    </div>
  )
}

ReferralListContainer.propTypes = {
  limit: PropTypes.number,
  loadCustomers: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number
}

const mapStateToProps = ({ referrals: { customers } }) => ({
  loading: customers.loading,
  items: customers.data,
  totalResults: customers.totalResults,
  remainingResults: customers.remainingResults
})

const mapDispatchToProps = dispatch => ({
  loadCustomers: payload => dispatch(loadCustomers(payload))
})

const ConnectedReferralList = connect(mapStateToProps, mapDispatchToProps)(ReferralListContainer)

export default ConnectedReferralList
