import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { removeToastr } from '@redux/toastr'

import Toastr from '@components/Toastr'

export default function ToastrProvider (props) {
  const [ready, setReady] = useState(false)
  const items = useSelector(({ toastr }) => toastr.items)
  const dispatch = useDispatch()

  const rootEl = useRef(document.createElement('div'))

  useEffect(() => {
    if (!window) {
      return
    }

    window.document.body.appendChild(rootEl.current)

    setReady(true)

    return () => {
      window.document.body.removeChild(rootEl.current)
    }
  }, [])

  if (!ready) {
    return null
  }

  return ReactDOM.createPortal(
    <div className={classnames(
      'usi-toastr__overlay',
      props.position ? `usi-toastr__overlay--${props.position}` : ''
    )}
    >
      {items.map(t => (
        <Toastr
          key={t.id}
          color={t.type}
          title={t.title}
          text={t.message}
          timeOut={t.options.timeOut}
          position={t.options.position}
          onClose={() => dispatch(removeToastr(t.id))}
        />
      ))}
    </div>,
    rootEl.current
  )
}
