import React from 'react'

import List from '@pages/Partners/Admin/Withdrawals/List'

class AdminWithdrawals extends React.Component {
  render () {
    return (
      <List />
    )
  }
}

export default AdminWithdrawals
