import React from 'react'
import Button from '@components/Button/index.jsx'

import ShadowBox from '@components/ShadowBox/index.jsx'

import banner3 from './images/banner_3.png'
import logoPurple from './images/logo-purple.svg'


export default function SquareSection (event) {
  function handleClickDownload () {
    event?.preventDefault && event.preventDefault()

    const a = document.createElement('a')
    a.href = banner3
    a.download = 'square_banner.png'
    a.click()
  }

  function handleDownloadZip (event) {
    event?.preventDefault && event.preventDefault()

    const a = document.createElement('a')
    a.href = '/heycarson-brand-assets.zip'
    a.download = 'heycarson-brand-assets.zip'
    a.click()
  }

  return (
    <div>
      <section className='p-resources-page__section'>
        <div className='p-resources-page__section__top p-resources-page__square__top'>
          <div className='p-resources-page__section__sub-title p-resources-page__square__section-title'>Square banner
          </div>
          <Button
            className='p-resources-page__square__button p-resources-page__square__button--up'
            primary hollow
            onClick={handleClickDownload}
          >
            Download
          </Button>
        </div>
      </section>

      <section className='p-resources-page__square-container'>
        <div className='p-resources-page__section'>
          <img src={banner3} alt='banner' />

          <Button
            className='p-resources-page__square__button p-resources-page__square__button--down'
            primary hollow
            onClick={handleClickDownload}
          >
            Download
          </Button>
        </div>

        <div className='p-resources-page__section p-resources-page__square__custom'>
          <ShadowBox className='p-resources-page__square__box'>
            <img className='p-resources-page__square__logo' src={logoPurple} alt='' />

            <div className='p-resources-page__square__title'>
              Do you have a <i>custom banner design</i> in mind?
            </div>

            <div className='p-resources-page__square__description'>
              Get in touch at <a href='mailto:partners@heycarson.com'>partners@heycarson.com</a> and our expert design
              team will address your needs.
            </div>
          </ShadowBox>

          <div className='p-resources-page__square__description'>
            Would you like to feature Carson as your partner?
          </div>

          <Button primary hollow onClick={handleDownloadZip}>Download our brand assets</Button>
        </div>
      </section>
    </div>
  )
}
