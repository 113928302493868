import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'

import { addCrumbs, removeCrumbs } from '@redux/breadcrumbs.js'

const initialOptions = {
  label: '', // jsx or string
  to: '#'
}

export const useBreadcrumbs = (options = initialOptions) => {
  const dispatch = useDispatch()
  const id = options.id || nanoid()

  useEffect(() => {
    dispatch(addCrumbs({
      ...initialOptions,
      id,
      label: options.label,
      to: options.to
    }))

    return () => {
      dispatch(removeCrumbs(id))
    }
  }, [options.label, options.to])

  return []
}
