import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import ShadowBox from '@components/ShadowBox/index.jsx'
import CheckBox from '@components/Inputs/CheckBox.jsx'

import notifications, { appNotifications, themeNotifications } from './notifications.js'

import { addPartnerSetting, fetchPartnerSettings, updatePartnerSetting } from '@redux/notificationSettings.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

export default function NotificationsSection () {
  const [selected, setSelected] = useState([])
  const [enabled, setEnabled] = useState([])
  const [displayNotifications, setDisplayNotifications] = useState([])

  const dispatch = useDispatch()
  const toastr = useToastr()

  const [partnerNotifications, availableNotifications, workspace] = useSelector(({ notificationSettings, account }) => [
    notificationSettings.partnerSettings.data,
    notificationSettings.partnerSettings.available,
    account.workspace
  ])

  useEffect(() => {
    loadNotifications()
  }, [])

  useEffect(() => {
    let display = notifications

    if (workspace?.scopes?.length) {
      if (workspace.scopes.includes('partner:themes:read')) {
        display = [
          ...themeNotifications,
          ...display
        ]
      }
      if (workspace.scopes.includes('partner:apps:read')) {
        display = [
          ...appNotifications,
          ...display
        ]
      }
    }

    setDisplayNotifications(display)
  }, [workspace?.scopes])

  useEffect(() => {
    setSelected(
      partnerNotifications
        .filter(({ status }) => status === 'active')
        .map(({ name }) => name)
    )
  }, [partnerNotifications])
  useEffect(() => {
    setEnabled(availableNotifications)
  }, [availableNotifications])

  function loadNotifications () {
    dispatch(fetchPartnerSettings())
      .catch(err => {
        console.log('load partner settings error', err)
      })
  }

  function handleToggleNotification (name, checked) {
    if (!enabled.find(e => e === name)) {
      return
    }

    let promise
    const exist = partnerNotifications.find(s => s.name === name)
    const status = checked ? 'active' : 'inactive'

    if (exist) {
      promise = dispatch(updatePartnerSetting({
        id: exist.id,
        status
      }))
    } else {
      promise = dispatch(addPartnerSetting({
        name,
        type: 'email',
        status
      }))
    }

    promise
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Notification setting updated successfully'
        })
      })
      .catch((error) => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message: 'Please try again later.'
        })

        console.log('add partner setting error', error)
      })
  }

  return (
    <>
      <div className='settings_page__notifications'>
        <div className='settings_page__section'>
          <div className='settings_page__section__header'>
            <div className='settings_page__section__title'>
              Email Notifications
            </div>
          </div>

          <ShadowBox>
            <div className='settings_page__section__body'>
              {displayNotifications.map(notification => {
                const available = enabled.find(e => e === notification.name)
                const checked = available && selected.find(e => e === notification.name)

                return (
                  <div key={notification.name} className='settings_page__notification'>
                    <div className='settings_page__notification__left'>
                      <div className='settings_page__notification__title'>
                        {notification.title}
                      </div>
                      <div className='settings_page__notification__description'>
                        {notification.description}
                      </div>
                    </div>

                    <div className='settings_page__notification__right'>
                      <CheckBox
                        disabled={!available}
                        checked={checked}
                        onChange={() => handleToggleNotification(notification.name, !checked)}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
          </ShadowBox>
        </div>
      </div>
    </>
  )
}
