import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReview } from '@redux/appReviews'
import Button from '@components/Button'

import ReviewModal from './ReviewModal'
import DeleteReviewModal from './DeleteReviewModal.jsx'

import './Review.scss'
import { DateTime } from 'luxon'
import linkExternal from '@assets/images/link-external-purple.svg'
import classnames from 'classnames'
import star from '@assets/images/star-orange.svg'

export default function Review () {
  const [showModal, setShowModal] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState('')
  const [review] = useSelector(({ appReviews }) => [appReviews.review.data])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.id) {
      return
    }

    loadReview({
      id: params.id
    })
  }, [params.id])

  function loadReview ({ id }) {
    dispatch(fetchReview({
      id
    }))
      .catch(error => {
        console.error('error fetching review: ', error)
      })
  }

  function handleReviewModalSuccess () {
    setShowModal('')
  }

  function handleDeleteModalSuccess () {
    setShowDeleteModal('')
  }

  return (
    <div className='app-review'>
      <ReviewModal
        show={showModal !== ''}
        type={showModal}
        review={review}
        onClose={() => setShowModal('')}
        onSuccess={handleReviewModalSuccess}
        onloadReview={loadReview}
      />
      <DeleteReviewModal
        show={showDeleteModal !== ''}
        id={review.id}
        onClose={() => setShowDeleteModal('')}
        onSuccess={handleDeleteModalSuccess}
      />
      <div className='app-review__row'>
        <div className='app-review__cta'>
          <Button onClick={() => setShowModal('edit')} primary>
            Edit Review
          </Button>
        </div>
        <Button onClick={() => setShowDeleteModal('delete')} danger>
          Delete Review
        </Button>
        <div className='app-review__date'>
          {review.status === 'pending'
            ? <div className='pr-review__pending'>Review pending approval</div>
            : (<div><span>Posted: </span>{DateTime.fromISO(review.created_at).toRelative()}</div>)}
        </div>
      </div>
      <div className='app-review__top'>
        <div className='app-review__profile-container'>
          <div className='app-review__col'>
            <p className='app-review__name'>
              {review.user_name}
            </p>
            <p className='app-review__item app-review__item--used-one'>
              <span>App name:&nbsp;</span>
              <a href={`${import.meta.env.VITE_SITE_URL}/apps/reviews/${review.app_slug}`} target='_blank' rel='noreferrer noopener nofollow'>
                {review.app_name}&nbsp;
                <img src={linkExternal} alt={review.app_name} />
              </a>
            </p>
            <p className='app-review__item app-review__item--used-one'>
              <span>Theme used on:&nbsp;</span>
              <a
                href={review.store_url}
                target='_blank'
                rel='noreferrer noopener'
              >
                {review.store_url?.replace(/(^\w+:|^)\/\//, '')}&nbsp;
                <img src={linkExternal} alt='store link' />
              </a>
            </p>
            <p className='app-review__item'>
              <span>Recommend this theme:&nbsp;</span>
              <span className={classnames('app-review__item__recommend', {
                'app-review__item__recommend--not': review?.recommended?.includes('Not')
              })}
              >
                {review.recommended}
              </span>
            </p>
          </div>
        </div>
        <div className='app-review__right-col'>
          <p className='app-review__overall'>
            Overall Rating:
          </p>
          <div className='app-review__rating'>
            <img src={star} alt='overall rating' />
            {review.overall_rating && review.overall_rating.toFixed(2)}
          </div>
        </div>
      </div>

      <div className='app-review__features'>
        <div className='app-review__features__item'>
          <p className='app-review__features__title'>
            Setup & Ease of Use
          </p>
          <p className='app-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.ease_rating} /5
          </p>
        </div>
        <div className='app-review__features__item'>
          <p className='app-review__features__title'>
            Features & Functionality
          </p>
          <p className='app-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.features_rating} /5
          </p>
        </div>
        <div className='app-review__features__item'>
          <p className='app-review__features__title'>
            Support Quality
          </p>
          <p className='app-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.support_rating} /5
          </p>
        </div>
        <div className='app-review__features__item'>
          <p className='app-review__features__title'>
            Value for Money
          </p>
          <p className='app-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.value_rating || 5} /5
          </p>
        </div>
      </div>
      <p className='app-review__title'>
        Overall experience
      </p>
      <p className='app-review__desc'>
        {review.overall_experience}
      </p>
    </div>
  )
}
