import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './ShadowBox.scss'

const ShadowBox = ({ className = '', children }) => (
  <div className={classnames('shadow-box', className)}>
    {children}
  </div>
)

ShadowBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default ShadowBox
