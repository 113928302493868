import React from 'react'

import './ContentPadding.scss'

export default function ContentPadding ({ children }) {
  return (
    <div className='usi-content-padding'>
      {children}
    </div>
  )
}
