import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_APP_PROFILE_CLAIMS_PENDING = 'apps/FETCH_APP_PROFILE_CLAIMS_PENDING'
const FETCH_APP_PROFILE_CLAIMS_FULFILLED = 'apps/FETCH_APP_PROFILE_CLAIMS_FULFILLED'
const FETCH_APP_PROFILE_CLAIMS_REJECTED = 'apps/FETCH_APP_PROFILE_CLAIMS_REJECTED'

const CREATE_APP_PROFILE_CLAIMS_PENDING = 'apps/CREATE_APP_PROFILE_CLAIMS_PENDING'
const CREATE_APP_PROFILE_CLAIMS_FULFILLED = 'apps/CREATE_APP_PROFILE_CLAIMS_FULFILLED'
const CREATE_APP_PROFILE_CLAIMS_REJECTED = 'apps/CREATE_APP_PROFILE_CLAIMS_REJECTED'

const UPDATE_APP_PROFILE_CLAIMS_PENDING = 'apps/UPDATE_APP_PROFILE_CLAIMS_PENDING'
const UPDATE_APP_PROFILE_CLAIMS_FULFILLED = 'apps/UPDATE_APP_PROFILE_CLAIMS_FULFILLED'
const UPDATE_APP_PROFILE_CLAIMS_REJECTED = 'apps/UPDATE_APP_PROFILE_CLAIMS_REJECTED'

export const fetchAppProfileClaims = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_APP_PROFILE_CLAIMS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/partner-claims'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined
      }
    })

    const data = {
      total: response.data.total,
      list: response.data.data
    }

    dispatch({ type: FETCH_APP_PROFILE_CLAIMS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_APP_PROFILE_CLAIMS_REJECTED })
    throw error
  }
}

export const createAppProfileClaim = payload => async dispatch => {
  try {
    dispatch({ type: CREATE_APP_PROFILE_CLAIMS_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', '/partner-claims'),
      payload.data
    )

    const data = {
      profileClaim: response.data
    }

    dispatch({ type: CREATE_APP_PROFILE_CLAIMS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: CREATE_APP_PROFILE_CLAIMS_REJECTED })
    throw error
  }
}

export const updateAppProfileClaim = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_APP_PROFILE_CLAIMS_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/partner-claims/${payload.id}`),
      payload.data
    )

    const data = {
      profileClaim: response.data
    }

    dispatch({ type: UPDATE_APP_PROFILE_CLAIMS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_APP_PROFILE_CLAIMS_REJECTED })
    throw error
  }
}

// initial state
const initialState = {
  hydrated: false,
  list: {
    total: 0,
    loading: false,
    data: []
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_APP_PROFILE_CLAIMS_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_APP_PROFILE_CLAIMS_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.list
        }
      }
    case FETCH_APP_PROFILE_CLAIMS_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }

    default:
      return state
  }
}
