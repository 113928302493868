import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_CONVERSIONS_PENDING = 'referrals/conversions/LOAD_CONVERSIONS_PENDING'
const LOAD_CONVERSIONS_FULFILLED = 'referrals/conversions/LOAD_CONVERSIONS_FULFILLED'
const LOAD_CONVERSIONS_REJECTED = 'referrals/conversions/LOAD_CONVERSIONS_REJECTED'

const UPDATE_CONVERSION_PENDING = 'referrals/conversions/UPDATE_CONVERSION_PENDING'
const UPDATE_CONVERSION_FULFILLED = 'referrals/conversions/UPDATE_CONVERSION_FULFILLED'
const UPDATE_CONVERSION_REJECTED = 'referrals/conversions/UPDATE_CONVERSION_REJECTED'

export const loadConversions = payload => async dispatch => {
  dispatch({
    type: LOAD_CONVERSIONS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/conversions'), {
      params: {
        with_customer: payload?.withCustomer ? 1 : undefined,
        with_program: payload?.withProgram ? 1 : undefined,
        with_partner: payload?.withPartner ? 1 : undefined,
        to_approve: payload?.toApprove ? 1 : undefined,

        search: payload?.search,
        status: payload?.status,
        partner_id: payload?.partnerId || undefined,
        click_id: payload?.clickId || undefined,

        before: payload?.before ? encodeURIComponent(payload.before) : undefined,
        after: payload?.after ? encodeURIComponent(payload.after) : undefined,

        limit: payload?.limit
      }
    })

    const p = {
      totalResults: response.data.total,
      remainingResults: response.data.remaining,
      data: response.data.data,
      hasMore: response.data.has_more
    }

    dispatch({
      type: LOAD_CONVERSIONS_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: LOAD_CONVERSIONS_REJECTED
    })
  }
}

export const updateConversion = payload => async dispatch => {
  dispatch({
    type: UPDATE_CONVERSION_PENDING
  })

  try {
    const response = await axios.post(getRefApiUrl('v1', `/conversions/${payload.conversionId}`), {
      status: payload?.status,
      metadata: payload?.metadata || []
    })

    const p = {
      data: response.data
    }

    dispatch({
      type: UPDATE_CONVERSION_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: UPDATE_CONVERSION_REJECTED
    })
  }
}

const initialState = {
  totalResults: 0,
  remainingResults: 0,
  data: [],
  loading: false,

  updateLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CONVERSIONS_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_CONVERSIONS_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        remainingResults: action.payload.remainingResults,
        loading: false
      }
    case LOAD_CONVERSIONS_REJECTED:
      return {
        ...state,
        loading: false
      }

    case UPDATE_CONVERSION_PENDING:
      return {
        ...state,
        updateLoading: true
      }
    case UPDATE_CONVERSION_FULFILLED:
    case UPDATE_CONVERSION_REJECTED:
      return {
        ...state,
        updateLoading: false
      }

    default:
      return state
  }
}
