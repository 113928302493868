import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { editAnswer } from '@redux/appReviews.js'
import { useDispatch } from 'react-redux'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

const initialFields = {
  question_id: '',
  status: '',
  name: '',
  email: '',
  website_url: '',
  answer: '',
  private_user: 0
}

export default function AppAnswerModal ({ show, answer, onClose, onSuccess }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const toastr = useToastr()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !answer) {
      return
    }
    setFields({
      ...initialFields,
      question_id: answer.question_id,
      status: answer.status,
      name: answer.name,
      email: answer.email,
      website_url: answer.website_url || '',
      answer: answer.answer || '',
      private_user: answer.private_user || ''
    })
  }, [show, answer])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  function handleSave () {
    const data = {
      question_id: fields.question_id,
      status: fields.status,
      name: fields.name,
      email: fields.email,
      website_url: fields.website_url,
      answer: fields.answer,
      private_user: fields.private_user ? 1 : 0
    }

    dispatch(editAnswer({
      id: answer?.id,
      data
    }))
      .then(() => {
        onSuccess()
        onClose()
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Answer updated successfully.'
        })
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log('error @edit answer')
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message: error.response?.data?.message || 'try again later.'
        })
      })
  }

  return (
    <Modal show={show} large className='pda-app__add-modal'>
      <Header onClose={onClose}>
        Edit Answer by {answer?.name}
      </Header>
      <Body className='pda-app__add-modal__body'>
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Name'
            value={fields.name}
            onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
            error={errors.name}
            subText={errors.name}
          />
          <SelectInput
            label='Status'
            labelClassName='pda-app__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '2',
              label: 'Pending',
              value: 'pending'
            }, {
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
        </Columns>
        <div />
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Email'
            value={fields.email}
            onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
            error={errors.email}
            subText={errors.email}
          />
          <SelectInput
            label='Publish this answer anonymously?'
            labelClassName='pda-app__form__label'
            value={fields.private_user}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, private_user: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: true
            }, {
              key: '0',
              label: 'No',
              value: false
            }]}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Website URL'
            value={fields.website_url}
            onChange={event => setFields({ ...fields, website_url: event.currentTarget.value })}
            error={errors.website_url}
            subText={errors.website_url}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            multiline={3}
            label='Your answer'
            value={fields.answer}
            onChange={event => setFields({ ...fields, answer: event.currentTarget.value })}
            error={errors.answer}
            subText={errors.answer}
          />
        </Columns>
      </Body>
      <Footer>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

AppAnswerModal.propTypes = {
  show: PropTypes.bool,
  answer: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
