import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { updateApp } from '@redux/apps.js'
import { fetchAppCategories } from '@redux/appCategories.js'

import Modal, { Body, Footer, Header } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function CategoryModal ({ show, type, onClose, onSuccess, app }) {
  const [currentCat, setCurrentCat] = useState('')
  const [errors, setErrors] = useState({})

  const categories = useSelector(({ appCategories }) => appCategories.list.data)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show) {
      return
    }

    loadCategories()

    return () => {
      setCurrentCat('')
    }
  }, [show])

  function handleSave () {
    const data = new window.FormData()

    // add current category to the list of categories
    let cats = app.categories.map(category => category.slug) || []

    // remove it if exists
    cats = cats.filter(category => category.slug !== currentCat)

    cats.push(currentCat)
    // --

    Object.entries({
      categories: cats.length > 0 ? cats.join(',') : '',

      name: app.name,
      description: app.description,
      tagline: app.tagline || '',
      meta_title: app.app_meta_title || '',
      meta_description: app.app_meta_description || '',
      price: app.price || '',
      developer_id: app.developer_id || '',
      status: app.status || 'unpublished',
      url: app.url || '',
      filters: app.filters.length > 0 ? app.filters.join(',') : '',
      languages: app.languages.length > 0 ? app.languages.join(',') : '',
      similar_apps: app.similar_apps.length > 0 ? app.similar_apps.map(s => s.slug || s).join(',') : '',
      is_free: app.pricing_is_free ? '1' : '0',
      has_free_plan: app.pricing_has_free_plan ? '1' : '0',
      has_trial: app.pricing_has_trial ? '1' : '0',
      trial: app.pricing_trial || ''
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    dispatch(updateApp({
      id: app?.id,
      data
    }))
      .then(() => {
        setErrors({})
        onSuccess()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.error('error updating app', error)
        }
      })
  }

  function loadCategories () {
    dispatch(fetchAppCategories({
      flatRoot: true
    }))
      .catch(error => {
        console.error('error loading category tree', error)
      })
  }

  function handleCategorySelection (event) {
    setCurrentCat(event.currentTarget.value)
  }

  return (
    <Modal
      className='pda-app-view__category-modal'
      show={show}
    >
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Category
      </Header>
      <Body className='pda-app-view__feature-modal__body'>
        <select className='pda-app__form__select' value={currentCat} onChange={handleCategorySelection}>
          <option value=''>Select a category</option>
          {categories.map((category) => (
            <optgroup label={category.name} key={category.id}>
              <option value={category.slug}>
                {category.name}
              </option>
              {category.children && category.children.map(child => (
                <option key={child.id} value={child.slug}>
                  {child.name}
                </option>
              ))}
            </optgroup>
          ))}
        </select>
        {errors.slug && (
          <span className='hc-input__sub hc-input__sub--error'>
            {errors.slug}
          </span>
        )}
      </Body>
      <Footer className='pda-app-view__feature-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

CategoryModal.propTypes = {
  type: PropTypes.string,
  category: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  app: PropTypes.object
}
