import * as toolkitRaw from '@reduxjs/toolkit'
import logger from 'redux-logger'

import rootReducer from './modules/index.js'

const { configureStore } = toolkitRaw.default ?? toolkitRaw

export default function configureAppStore (initialState) {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: !import.meta.env.PROD ? getDefaultMiddleware => getDefaultMiddleware().concat(logger) : undefined
  })

  return store
}
