import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_CLICKS_PENDING = 'referrals/customers/LOAD_CLICKS_PENDING'
const LOAD_CLICKS_FULFILLED = 'referrals/customers/LOAD_CLICKS_FULFILLED'
const LOAD_CLICKS_REJECTED = 'referrals/customers/LOAD_CLICKS_REJECTED'

export const loadClicks = payload => async dispatch => {
  const { limit, before, after } = payload
  const { referralIds, withPartner, withCustomer } = payload

  dispatch({
    type: LOAD_CLICKS_PENDING
  })

  try {
    const res = await axios.get(getRefApiUrl('v1', '/clicks'), {
      params: {
        limit: limit || 15,
        before: before ? encodeURIComponent(before) : undefined,
        after: after ? encodeURIComponent(after) : undefined,
        referral_id: referralIds || undefined,
        with_partner: withPartner ? 1 : undefined,
        with_customer: withCustomer ? 1 : undefined
      }
    })

    const p = {
      data: res.data.data,
      totalResults: res.data.total,
      remainingResults: res.data.remaining,
      hasMore: res.data.has_more
    }

    dispatch({
      type: LOAD_CLICKS_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: LOAD_CLICKS_REJECTED
    })

    throw error
  }
}

const initialState = {
  data: [],
  loading: false,
  totalResults: 0,
  remainingResults: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CLICKS_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_CLICKS_FULFILLED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        remainingResults: action.payload.remainingResults,
        hasMore: action.payload.hasMore
      }
    case LOAD_CLICKS_REJECTED:
      return {
        ...state,
        loading: false,
        ...initialState
      }

    default:
      return state
  }
}
