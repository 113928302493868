import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ContentPadding from '@components/ContentPadding/index.jsx'

import OverviewHero from '@pages/Partners/Partner/Overview/Hero.jsx'
import ConversionList from '@containers/Partners/ConversionsList/index.jsx'

import './Overview.scss'

export default function OverviewPage () {
  // To be used to show/hide the see all button
  const [loading, totalResults] = useSelector(({ referrals: { conversions } }) => [
    conversions.loading,
    conversions.totalResults
  ])

  return (
    <>
      <Helmet>
        <title>Overview</title>
      </Helmet>

      <ContentPadding>
        <div className='overview-page'>
          <OverviewHero />
          <ConversionList
            pageLimit={6}
            hash='overview'
            showPagination={false}
          />
          {/* Only show see all button when conversion list contains data */}
          {!loading && totalResults > 0 && (
            <div className='overview-page__see-all'>
              <Link to='/referrals' className='overview-page__see-all__text'>
                See all
              </Link>
            </div>
          )}
        </div>
      </ContentPadding>
    </>
  )
}
