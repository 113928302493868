import React from 'react'
import { DateTime } from 'luxon'
import classnames from 'classnames'

import ComparePicker from '@components/DatePicker/ComparePicker'
import SelectInput from '@components/Inputs/SelectInput'

import GeneralView from '@pages/Partners/Admin/Dashboard/GeneralView'
import DateView from '@pages/Partners/Admin/Dashboard/DateView'

import './Reports.scss'

class DashReports extends React.Component {
  state = {
    view: 'general',
    aggregate: 'week',
    period: {
      start: null,
      end: null
    },
    compare: false,
    comparePeriod: {
      start: null,
      end: null
    }
  }

  constructor (props) {
    super(props)

    const end = DateTime.now().toUTC().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const start = end.minus({ days: 30 })

    this.state = {
      ...this.state,
      period: {
        start,
        end
      }
    }

    this.handleRangeChange = this.handleRangeChange.bind(this)
    this.handleChangeView = this.handleChangeView.bind(this)
    this.handleChangeAggregate = this.handleChangeAggregate.bind(this)
  }

  handleRangeChange ({ period, compare, comparePeriod }) {
    this.setState(old => ({
      ...old,
      period,
      compare,
      comparePeriod
    }))
  }

  handleChangeView (option) {
    this.setState(old => ({
      ...old,
      view: option
    }))
  }

  handleChangeAggregate (aggregate) {
    return event => {
      event.preventDefault()

      this.setState(old => ({
        ...old,
        aggregate
      }))
    }
  }

  render () {
    const viewProps = {
      aggregate: this.state.aggregate,
      compare: this.state.compare,
      period: this.state.period,
      comparePeriod: this.state.comparePeriod
    }

    return (
      <>
        <div className='pah__hero__top'>
          <div className='pah__hero__view-selector'>
            <div className='pah__hero__view-select'>
              <SelectInput
                value={this.state.view}
                options={[{
                  key: 'general',
                  value: 'general',
                  label: 'General view'
                }, {
                  key: 'date',
                  value: 'date',
                  label: 'Date view'
                }]}
                onChange={this.handleChangeView}
              />
            </div>

            {this.state.view === 'date' && (
              <div className='pah__hero__select-aggregators'>
                <a
                  href='#'
                  className={classnames('pah__hero__select-aggregate', {
                    'pah__hero__select-aggregate--selected': this.state.aggregate === 'day'
                  })}
                  onClick={this.handleChangeAggregate('day')}
                >
                  Day
                </a>
                <a
                  href='#'
                  className={classnames('pah__hero__select-aggregate', {
                    'pah__hero__select-aggregate--selected': this.state.aggregate === 'week'
                  })}
                  onClick={this.handleChangeAggregate('week')}
                >
                  Week
                </a>
                <a
                  href='#'
                  className={classnames('pah__hero__select-aggregate', {
                    'pah__hero__select-aggregate--selected': this.state.aggregate === 'month'
                  })}
                  onClick={this.handleChangeAggregate('month')}
                >
                  Month
                </a>
              </div>
            )}
          </div>

          <div className='pah__hero__compare'>
            <ComparePicker
              start={this.state.start && this.state.start.toISO()}
              end={this.state.end && this.state.end.toISO()}
              onChange={this.handleRangeChange}
              preset='last_30'
              right
            />
          </div>
        </div>

        <div className='pah__hero__view-separator' />

        {this.state.view === 'general' && (
          <GeneralView {...viewProps} />
        )}
        {this.state.view === 'date' && (
          <DateView {...viewProps} />
        )}
      </>
    )
  }
}

DashReports.propTypes = {}

export default DashReports
