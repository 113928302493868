import axios from 'axios'
import Cookie from 'js-cookie'
import qs from 'qs'

const initialOptions = {
  endpoint: 'https://referral-api.heycarson.com',
  accountId: '',
  ApiKey: '',
  debug: false,
  cookieName: 'hcid',
  refCookieName: 'hrc',
  cookieDomain: import.meta.env.VITE_REF_COOKIE_DOMAIN
}

class ReferralsAPI {
  constructor (options) {
    this.options = {
      ...initialOptions,
      ...options
    }
  }

  init () {
    const { apiKey, accountId } = this.options

    // new instance
    this.axios = axios.create({
      headers: {
        'X-Api-Key': apiKey,
        'X-Account-Id': accountId
      }
    })
  }

  generateClick (referralCode = '') {
    if (!this.axios) {
      return
    }

    const hcid = this.getClickId()

    if (!referralCode) {
      referralCode = this.getReferralCode()
    }

    if (!referralCode) {
      return new Promise((resolve, reject) => reject(new Error('no referral code')))
    }

    return this.axios.post(
      `${this.options.endpoint}/v1/clicks`,
      null,
      {
        params: {
          click_id: hcid,
          referral_code: referralCode
        },
        withCredentials: this.options.withCookie
      }
    ).then(response => {
      const { data } = response

      if (this.options.debug) {
        console.log('gen click', data)
      }

      window && window.localStorage.setItem(this.options.cookieName, data.click_id)
      window && window.localStorage.setItem(this.options.refCookieName, referralCode)

      Cookie.set(this.options.cookieName, data.click_id, {
        path: '/',
        domain: this.options.cookieDomain,
        expires: 90
      })
      Cookie.set(this.options.refCookieName, referralCode, {
        path: '/',
        domain: this.options.cookieDomain,
        expires: 90
      })

      return data.click_id
    }).catch(error => {
      if (this.options.debug) {
        console.log('gen click error', error)
      }
    })
  }

  createCustomer (clickId, customer) {
    if (!clickId || !this.axios) {
      return
    }

    return this.axios.post(
      `${this.options.endpoint}/v1/customers`,
      {
        click_id: clickId,
        name: customer.name,
        email: customer.email,
        metadata: customer.metadata
      },
      {
        headers: {
          'X-Silent-Exception': '1'
        }
      }
    ).then(response => {
      const { data: customer } = response

      if (this.options.debug) {
        console.log('create customer', customer)
      }

      return customer
    }).catch(error => {
      if (
        error.response &&
        error.response.data &&
        error.response.data.code === 'customer_already_exist'
      ) {
        return
      }

      if (this.options.debug) {
        console.log('create customer error', error)
      }
    })
  }

  generateConversion () {

  }

  getClickId () {
    return (
      Cookie.get(this.options.cookieName) ||
      (window && window.localStorage.getItem(this.options.cookieName))
    )
  }

  getReferralCode () {
    const search = qs.parse(window && window.location.search, { ignoreQueryPrefix: true })

    if (search.hrc || search.ref) {
      return search.hrc || search.ref
    }

    return (
      Cookie.get(this.options.refCookieName) ||
      (window && window.localStorage.getItem(this.options.refCookieName))
    )
  }
}

export default ReferralsAPI
