import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function ListRowHeader (props) {
  return (
    <div
      className={classnames('hc-list-row__header', {
        'hc-list-row__header--auto': props.auto
      }, props.className)}
    >
      {props.children}
    </div>
  )
}

ListRowHeader.propTypes = {
  className: PropTypes.string,
  auto: PropTypes.bool
}
