import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

import Dropdown from '@components/Dropdown'

import carsonLogo from '@assets/images/carson-logo-blue.svg'
import selectArrow from '@assets/icons/select-arrow.svg'
import avatar from '@assets/images/partners-dashboard/sample-avatar.png'

import './Topbar.scss'

function AdminPartnersDashboardTopbar (props) {
  const { name, onSignOut } = props

  return (
    <div className='pd-admin-topbar'>
      <Link to='/' className='pd-admin-topbar__logo-container'>
        <img className='pd-admin-topbar__logo' src={carsonLogo} alt='HeyCarson' />
      </Link>

      <Dropdown
        activator={({ open, onClick }) => (
          <div className='pd-admin-topbar__profile' onClick={onClick}>
            <div className='pd-admin-topbar__profile__avatar'>
              {false && (
                <div className='pd-admin-topbar__profile__picture'>
                  <img src={avatar} />
                </div>
              )}
            </div>

            <div className='pd-admin-topbar__profile__name'>
              {name}
            </div>

            <div
              className={classnames('pd-admin-topbar__profile__actions', {
                'pd-admin-topbar__profile__actions--open': open
              })}
            >
              <img className='' src={selectArrow} />
            </div>
          </div>
        )}
        body={({ onClose }) => (
          <div className='pd-admin-topbar__profile__options'>
            <a
              href='#'
              className='pd-admin-topbar__profile__option'
              onClick={event => {
                event.preventDefault()
                onSignOut()
                onClose(event)
              }}
            >
              Sign Out
            </a>
          </div>
        )}
      />
    </div>
  )
}

AdminPartnersDashboardTopbar.propTypes = {
  name: PropTypes.string
}

export default AdminPartnersDashboardTopbar
