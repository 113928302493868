import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { deleteThemeStyle } from '@redux/themes.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function DeleteStyleModal ({ show, themeId, style, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    if (!style) {
      return
    }

    dispatch(
      deleteThemeStyle({
        themeId,
        id: style.id
      })
    )
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        console.error('error deleting style', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Delete theme style
      </Header>
      <Body>
        Are you sure you want to delete this style?
      </Body>
      <Footer className='pda-theme-view__style-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteStyleModal.propTypes = {
  themeId: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
