import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchThemes } from '@redux/themes.js'
import { updateReview } from '@redux/themeReviews.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

const initialFields = {
  status: '',
  user_name: '',
  email: '',
  store_url: '',
  theme_name: '',
  themeId: '',
  ease_rating: '',
  support_rating: '',
  overall_rating: '',
  options_rating: '',
  is_highlighted: '',
  review: '',
  recommended: ''
}

export default function ReviewModal ({ show, type, review, onClose, onSuccess, onLoadReview }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const searchTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [themes, setThemes] = useState([])
  const [loadingThemes] = useSelector(({ themes }) => [themes.list.loading])
  const [submitting] = useSelector(({ themeReviews }) => [themeReviews.submitting])
  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    if (!show || !review || type !== 'edit') {
      return
    }
    setFields({
      ...initialFields,
      status: review.status,
      user_name: review.user_name,
      email: review.email,
      store_url: review.store_url || '',
      theme_name: review.theme_name || '',
      themeId: review.theme_id || '',
      ease_rating: review.ease_rating || null,
      support_rating: review.support_rating || null,
      overall_rating: review.overall_rating || null,
      options_rating: review.options_rating || null,
      value_rating: review.value_rating || null,
      review: review.review || '',
      recommended: review.recommended || '',
      is_highlighted: review.is_highlighted ? '1' : '0'
    })

    setSearch(review?.theme_name || '')
  }, [show, review])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setThemes([])
      setSearch('')
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, theme_name: '', themeId: '' })
      setThemes([])

      return
    }

    searchTimeout.current = setTimeout(() => {
      loadThemes(search)
    }, 400)

    return () => {
      clearTimeout(searchTimeout.current)
    }
  }, [search])

  function loadThemes (search) {
    setThemes([])

    dispatch(
      fetchThemes({
        search,
        limit: 10,
        offset: 0
      })
    )
      .then(({ themes }) => {
        setThemes(themes.map(theme => ({
          key: theme.id,
          label: theme.name + ' - ' + theme.developer_name,
          value: theme.id
        })))
      })
      .catch(error => {
        console.log('error fetch @themes: ', error)
      })
  }

  function handleSave () {
    const data = new window.FormData()

    const selectedTheme = themes.find(theme => theme.value === fields.themeId)

    Object.entries({
      status: fields.status,
      user_name: fields.user_name,
      email: fields.email,
      store_url: fields.store_url,
      theme_name: selectedTheme?.label || fields.theme_name,
      theme_id: fields.themeId,
      ease_rating: fields.ease_rating,
      support_rating: fields.support_rating,
      overall_rating: fields.overall_rating,
      options_rating: fields.options_rating,
      value_rating: fields.value_rating,
      review: fields.review,
      recommended: fields.recommended,
      is_highlighted: fields.is_highlighted === '1' ? '1' : '0'
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    dispatch(updateReview({
      id: review?.id,
      data
    }))
      .then(() => {
        onSuccess()

        if (onLoadReview) {
          onLoadReview({
            id: review.id
          })
        }

        onClose()
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Review updated successfully.'
        })
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'theme_not_found'
        ) {
          setErrors(old => ({ ...old, theme_id: error.response.data.message }))
        } else {
          console.log(`error @${type} review`)
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message: error.response?.data?.message || 'try again later.'
        })
      })
  }

  return (
    <Modal show={show} large className='pda-theme__add-modal'>
      <Header onClose={onClose}>
        Edit Review
      </Header>
      <Body className='pda-theme__add-modal__body'>
        <Columns>
          <SelectInput
            label='Status'
            labelClassName='pda-theme__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '2',
              label: 'Pending',
              value: 'pending'
            }, {
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
          <TextInput
            labelClassName='pda-theme__form__label'
            label='User name'
            value={fields.user_name}
            onChange={event => setFields({ ...fields, user_name: event.currentTarget.value })}
            error={errors.user_name}
            subText={errors.user_name}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Email'
            value={fields.email}
            onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
            error={errors.email}
            subText={errors.email}
          />
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Store URL'
            value={fields.store_url}
            onChange={event => setFields({ ...fields, store_url: event.currentTarget.value })}
            error={errors.store_url}
            subText={errors.store_url}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Search Theme By Name'
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />

          <SelectInput
            label='Theme'
            labelClassName='pda-theme__form__label'
            value={fields.themeId}
            placeholder={loadingThemes ? 'Loading...' : !themes.length ? 'No theme found' : 'Select...'}
            onChange={value => setFields({ ...fields, themeId: value })}
            options={themes}
            disabled={!themes.length || loadingThemes}
            error={errors.theme_id}
            subText={errors.theme_id}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='Rate the ease of use *'
            labelClassName='pda-theme__form__label'
            value={fields.ease_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, ease_rating: value })}
            options={[{
              key: '3',
              label: '5 out of 5',
              value: 5
            }, {
              key: '2',
              label: '4 out of 5',
              value: 4
            }, {
              key: '1',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
          <SelectInput
            label='Rate the quality of support and documentation *'
            labelClassName='pda-theme__form__label'
            value={fields.support_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, support_rating: value })}
            options={[{
              key: '3',
              label: '5 out of 5',
              value: 5
            }, {
              key: '2',
              label: '4 out of 5',
              value: 4
            }, {
              key: '1',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='Rate the design and functionality options *'
            labelClassName='pda-theme__form__label'
            value={fields.options_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, options_rating: value })}
            options={[{
              key: '3',
              label: '5 out of 5',
              value: 5
            }, {
              key: '2',
              label: '4 out of 5',
              value: 4
            }, {
              key: '1',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
          <SelectInput
            label='Did the theme provide good value for its price? *'
            labelClassName='pda-theme__form__label'
            value={fields.value_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, value_rating: value })}
            options={[{
              key: '3',
              label: '5 out of 5',
              value: 5
            }, {
              key: '2',
              label: '4 out of 5',
              value: 4
            }, {
              key: '1',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='How likely are you to recommend this theme?'
            labelClassName='pda-theme__form__label'
            value={fields.recommended}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, recommended: value })}
            options={[{
              key: '2',
              label: 'Very Likely',
              value: 'Very Likely'
            }, {
              key: '1',
              label: 'Neutral',
              value: 'Neutral'
            }, {
              key: '0',
              label: 'Not very likely',
              value: 'Not very likely'
            }]}
          />
          <SelectInput
            label='Highlighted Review?'
            labelClassName='pda-theme__form__label'
            value={fields.is_highlighted}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, is_highlighted: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />
        </Columns>
        <TextInput
          labelClassName='pda-theme__form__label'
          multiline={3}
          label='Review: Describe your overall experience with this Shopify theme (50 words or more).'
          value={fields.review}
          onChange={event => setFields({ ...fields, review: event.currentTarget.value })}
          error={errors.review}
          subText={errors.review}
        />
      </Body>
      <Footer>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

ReviewModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onLoadReview: PropTypes.func,
  review: PropTypes.object
}
