import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { updateQuestion } from '@redux/themeReviews.js'
import { useDispatch } from 'react-redux'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

const initialFields = {
  theme_id: '',
  developer_id: '',
  status: '',
  name: '',
  email: '',
  website_url: '',
  question: '',
  private_user: ''
}

function QuestionModal ({ show, type, question, onClose, onSuccess, onLoadQuestion }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const toastr = useToastr()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !question || type !== 'edit') {
      return
    }
    setFields({
      ...initialFields,
      theme_id: question.theme_id,
      developer_id: question.developer_id,
      status: question.status,
      name: question.name,
      email: question.email,
      website_url: question.website_url || '',
      question: question.question || '',
      private_user: question.private_user || ''
    })
  }, [show, question])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])

  function handleSave () {
    const data = {
      theme_id: fields.theme_id,
      developer_id: fields.developer_id,
      status: fields.status,
      name: fields.name,
      email: fields.email,
      website_url: fields.website_url,
      question: fields.question,
      private_user: fields.private_user
    }

    dispatch(updateQuestion({
      id: question?.id,
      data
    }))
      .then(() => {
        onSuccess()

        if (onLoadQuestion) {
          onLoadQuestion({
            id: question.id
          })
        }

        onClose()
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Question updated successfully.'
        })
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404
        ) {
          setErrors(old => ({ ...old, theme_id: error.response.data.message }))
        } else {
          console.log(`error @${type} question`)
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message: error.response?.data?.message || 'try again later.'
        })
      })
  }

  return (
    <Modal show={show} large className='pda-theme__add-modal'>
      <Header onClose={onClose}>
        Edit Question
      </Header>
      <Body className='pda-theme__add-modal__body'>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Name'
            value={fields.name}
            onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
            error={errors.name}
            subText={errors.name}
          />
          <SelectInput
            label='Status'
            labelClassName='pda-theme__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '2',
              label: 'Pending',
              value: 'pending'
            }, {
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
        </Columns>
        <div />
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Email'
            value={fields.email}
            onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
            error={errors.email}
            subText={errors.email}
          />
          <SelectInput
            label='Publish this question anonymously?'
            labelClassName='pda-theme__form__label'
            value={fields.private_user}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, private_user: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: true
            }, {
              key: '0',
              label: 'No',
              value: false
            }]}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            label='Website URL'
            value={fields.website_url}
            onChange={event => setFields({ ...fields, website_url: event.currentTarget.value })}
            error={errors.website_url}
            subText={errors.website_url}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-theme__form__label'
            multiline={3}
            label='Your question'
            value={fields.question}
            onChange={event => setFields({ ...fields, question: event.currentTarget.value })}
            error={errors.question}
            subText={errors.question}
          />
        </Columns>
      </Body>
      <Footer>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

QuestionModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  question: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onLoadQuestion: PropTypes.func
}

export default QuestionModal
