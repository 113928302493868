import React from 'react'
import ShadowBox from '@components/ShadowBox/index.jsx'

import logoSingle from '@assets/images/single-logo-purple.svg'
import avatar1 from '@assets/images/partners-dashboard/sample-avatars/michelle-clarke.png'
import avatar2 from '@assets/images/partners-dashboard/sample-avatars/emily-wang.png'
import checkmark from '@assets/icons/checkmark-white.svg'

export default function SubmitProjectRight () {
  return (
    <div className='submit-project-page__right'>
      <h1 className='submit-project-page__right__title'>
        We're committed to being your leading design and development support experts.
      </h1>
      <p className='submit-project-page__right__description'>
        We've got your clients covered with everything they need, from small to large design, development and theme optimization projects.
      </p>

      <div className='submit-project-page__right__graphic'>
        <ShadowBox className='submit-project-page__right__box submit-project-page__right__box--top'>
          <div className='submit-project-page__right__box__title'>
            We will reply to project submissions within 1 business day with a detailed quote or with follow-up questions.
          </div>
          <div className='submit-project-page__right__box__slider'>
            <div className='submit-project-page__right__box__slider__logo'>
              <img src={logoSingle} alt='heycarson' />
            </div>
            <div className='submit-project-page__right__box__slider__bar'>
              <div className='submit-project-page__right__box__slider__circle' />
              <div className='submit-project-page__right__box__slider__inner' />
            </div>
            <div className='submit-project-page__right__box__slider__avatar'>
              <img src={avatar1} alt='avatar1' />
            </div>
          </div>
        </ShadowBox>

        <ShadowBox className='submit-project-page__right__box submit-project-page__right__box--medium'>
          <div className='submit-project-page__right__box__title'>
            Your clients will get fair quotes and friendly service every time.
          </div>
          <div className='submit-project-page__right__box__quote'>
            <div className='submit-project-page__right__box__quote__top'>
              <div className='submit-project-page__right__box__quote__avatar'>
                <img src={avatar2} alt='avatar2' />
              </div>
              <div className='submit-project-page__right__box__quote__text'>
                Hello, my name is Anna and I will be happy to assess your project request.
              </div>
            </div>
            <div className='submit-project-page__right__box__quote__separator' />
            <div className='submit-project-page__right__box__quote__type'>Project quote:</div>
            <div className='submit-project-page__right__box__quote__amount'>$3200</div>
          </div>
        </ShadowBox>

        <ShadowBox className='submit-project-page__right__box submit-project-page__right__box--bottom'>
          <div className='submit-project-page__right__box__title'>
            You’ll get full transparency on the progress of these projects via your partner area
          </div>
          <div className='submit-project-page__right__box__progress'>
            <div className='submit-project-page__right__box__progress__bar'>
              <div className='submit-project-page__right__box__progress__dot submit-project-page__right__box__progress__dot--hollow submit-project-page__right__box__progress__dot--opacity'>
                <div className='submit-project-page__right__box__progress__inner submit-project-page__right__box__progress__inner--opacity' />
              </div>
              <div className='submit-project-page__right__box__progress__dot submit-project-page__right__box__progress__dot--hollow submit-project-page__right__box__progress__dot--opacity'>
                <div className='submit-project-page__right__box__progress__inner submit-project-page__right__box__progress__inner--opacity' />
              </div>
              <div className='submit-project-page__right__box__progress__dot submit-project-page__right__box__progress__dot--hollow'>
                <div className='submit-project-page__right__box__progress__inner' />
              </div>
              <div className='submit-project-page__right__box__progress__dot'>
                <img className='submit-project-page__right__box__progress__check' src={checkmark} alt='' />
              </div>
            </div>

          </div>
          <div className='submit-project-page__right__box__status'>
            PROJECT STATUS: COMPLETED
          </div>
        </ShadowBox>
      </div>
    </div>
  )
}
