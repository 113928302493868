import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_PARTNER_SETTINGS_PENDING = 'FETCH_PARTNER_SETTINGS_PENDING'
const FETCH_PARTNER_SETTINGS_FULFILLED = 'FETCH_PARTNER_SETTINGS_FULFILLED'
const FETCH_PARTNER_SETTINGS_REJECTED = 'FETCH_PARTNER_SETTINGS_REJECTED'

const ADD_PARTNER_SETTING_PENDING = 'ADD_PARTNER_SETTING_PENDING'
const ADD_PARTNER_SETTING_FULFILLED = 'ADD_PARTNER_SETTING_FULFILLED'
const ADD_PARTNER_SETTING_REJECTED = 'ADD_PARTNER_SETTING_REJECTED'

const UPDATE_PARTNER_SETTING_PENDING = 'UPDATE_PARTNER_SETTING_PENDING'
const UPDATE_PARTNER_SETTING_FULFILLED = 'UPDATE_PARTNER_SETTING_FULFILLED'
const UPDATE_PARTNER_SETTING_REJECTED = 'UPDATE_PARTNER_SETTING_REJECTED'

export const fetchPartnerSettings = () => async dispatch => {
  try {
    dispatch({ type: FETCH_PARTNER_SETTINGS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/notification-settings/partner'))

    const data = {
      total: response.data.total,
      settings: response.data.data,
      available: response.data?.metadata?.available
    }

    dispatch({ type: FETCH_PARTNER_SETTINGS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_PARTNER_SETTINGS_REJECTED })
    throw error
  }
}

export const addPartnerSetting = payload => async dispatch => {
  try {
    dispatch({ type: ADD_PARTNER_SETTING_PENDING })

    const response = await axios.post(getRefApiUrl('v1', '/notification-settings/partner'), {
      name: payload.name,
      type: payload.type,
      status: payload.status
    })

    const data = {
      setting: response.data
    }

    dispatch({ type: ADD_PARTNER_SETTING_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_PARTNER_SETTING_REJECTED })
    throw error
  }
}

export const updatePartnerSetting = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_PARTNER_SETTING_PENDING })

    const response = await axios.post(getRefApiUrl('v1', `/notification-settings/partner/${payload.id}`), {
      status: payload.status
    })

    const data = {
      setting: response.data
    }

    dispatch({ type: UPDATE_PARTNER_SETTING_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_PARTNER_SETTING_REJECTED })
    throw error
  }
}

const initialState = {
  partnerSettings: {
    available: [],
    total: 0,
    data: []
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PARTNER_SETTINGS_PENDING:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: true
        }
      }
    case FETCH_PARTNER_SETTINGS_FULFILLED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false,
          data: action.payload.settings,
          available: action.payload.available
        }
      }
    case FETCH_PARTNER_SETTINGS_REJECTED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false
        }
      }

    case ADD_PARTNER_SETTING_PENDING:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: true
        }
      }
    case ADD_PARTNER_SETTING_FULFILLED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false,
          data: [...state.partnerSettings.data, action.payload.setting]
        }
      }
    case ADD_PARTNER_SETTING_REJECTED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false
        }
      }

    case UPDATE_PARTNER_SETTING_PENDING:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: true
        }
      }
    case UPDATE_PARTNER_SETTING_FULFILLED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false,
          data: state.partnerSettings.data.map(setting => {
            if (setting.id === action.payload.setting.id) {
              return action.payload.setting
            }

            return setting
          })
        }
      }
    case UPDATE_PARTNER_SETTING_REJECTED:
      return {
        ...state,
        partnerSettings: {
          ...state.partnerSettings,
          loading: false
        }
      }

    default:
      return state
  }
}
