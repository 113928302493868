import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'

import { shortAmount } from '@util/currency.js'
import { partnerLevels } from '@util/referrals.js'
import { loadReferralCodes } from '@redux/referrals/refCodes.js'
import { loadPartnerBalance } from '@redux/referrals/dashboard.js'

import ShadowBox from '@components/ShadowBox/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import LevelsTooltip from '@pages/Partners/Partner/Overview/LevelsTooltip.jsx'
import InfoCard from '@components/InfoCard/index.jsx'

import questionMark from '@assets/icons/question-circle-light.svg'
import loadingLevel from '@assets/icons/partner-level-1.svg'
import level1Purple from '@assets/icons/partner-level-1-purple.svg'
import level2Purple from '@assets/icons/partner-level-2-purple.svg'
import level3Purple from '@assets/icons/partner-level-3-purple.svg'
import level4Purple from '@assets/icons/partner-level-4-purple.svg'

function getStats (stats) {
  return stats.reduce((acc, current) => {
    if (
      current.name === 'commission_pending' ||
      current.name === 'conversion_commission' ||
      current.name === 'conversion_amount' ||
      current.name === 'partner_balance'
    ) {
      acc[current.name] = shortAmount(Number(current.value) || 0, 'USD')
    }

    return acc
  }, {})
}

function getPartnerLevel (amount) {
  const current = partnerLevels.reduce((acc, level) => {
    if (!acc) {
      return level
    }

    if (amount >= level.requirement && acc.requirement < level.requirement) {
      return level
    }

    return acc
  }, null)

  if (!current) {
    return null
  }

  const next = partnerLevels.find(l => l.requirement > current?.requirement) || current

  return {
    current,
    next
  }
}

export default function OverviewHero () {
  const [userName, codes] = useSelector(({ account, referrals: { refCodes } }) => [account.user?.name, refCodes.data])
  const [refLink, setRefLink] = useState('')
  const [copied, setCopied] = useState(false)
  const [level, setLevel] = useState(null)
  const [totalReferred, setTotalReferred] = useState(0.00)
  const [commission, setCommission] = useState(shortAmount(0, 'USD'))
  const [openTooltip, setOpenTooltip] = useState(false)

  const copiedTimeout = useRef(null)
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      clearTimeout(copiedTimeout.current)
    }
  }, [])

  useEffect(() => {

  }, [])

  useEffect(() => {
    dispatch(
      loadPartnerBalance({
        start: undefined,
        end: undefined
      })
    )
      .then(({ stats, tieredAmount }) => {
        const parsedStats = getStats(stats)

        setCommission(parsedStats.commission_pending)
        setTotalReferred(stats.find(s => s.name === 'conversion_amount')?.value || 0)
        setLevel(getPartnerLevel(tieredAmount))
      })
      .catch(error => {
        console.log('dash stats error', error)
      })
  }, [])

  useEffect(() => {
    if (!codes?.length || !codes[0]?.custom_code) {
      return
    }

    setRefLink(`${import.meta.env.VITE_SITE_URL}/ambassadors/?ref=${codes[0]?.custom_code}`)
  }, [codes])

  async function handleClickRefLink (event) {
    clearTimeout(copiedTimeout.current)

    const target = event.currentTarget

    target.setSelectionRange(0, target.value.length)

    try {
      await window.navigator.clipboard.writeText(target.value)

      setCopied(true)

      copiedTimeout.current = setTimeout(() => {
        setCopied(false)
      }, 500)
    } catch (error) {
      console.log('navigator error', error)
    }
  }

  function convertToK (num) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'
    } else {
      return num.toString()
    }
  }

  const numLevel = Number(level?.current?.slug.replace('level-', ''))

  function levelObject () {
    let progress = 0
    if (numLevel === 1) {
      progress = ((Number(totalReferred) / 15000) * 100) + '%'
      return {
        image: level1Purple,
        maxAmount: '$15k',
        progressBar: progress
      }
    } else if (numLevel === 2) {
      progress = ((Number(totalReferred) / 25000) * 100) + '%'
      return {
        image: level2Purple,
        maxAmount: '$25k',
        progressBar: progress
      }
    } else if (numLevel === 3) {
      progress = ((Number(totalReferred) / 50000) * 100) + '%'
      return {
        image: level3Purple,
        maxAmount: '$50k',
        progressBar: progress
      }
    } else if (numLevel === 4) {
      return {
        image: level4Purple,
        maxAmount: '',
        progressBar: '100%'
      }
    }
    return {
      image: loadingLevel,
      maxAmount: '$0',
      progressBar: '0%'
    }
  }

  const levelObj = levelObject()
  return (
    <div className='overview-page__hero'>
      <h1 className='overview-page__title'>Good afternoon, {userName}</h1>

      <div className='overview-page__stats'>
        <ShadowBox className='overview-page__link'>
          <TextInput
            label={(
              <div className='overview-page__label'>
                Here is your affiliate page link
                <span
                  className={classnames('overview-page__link__copied', {
                    'overview-page__link__copied--show': copied
                  })}
                >
                  Copied!
                </span>
              </div>
            )}
            readOnly
            value={refLink}
            onClick={handleClickRefLink}
          />
        </ShadowBox>
        <ShadowBox className='overview-page__get-in-touch'>
          <h3>Have a question? Get in touch</h3>
          <a href='mailto:partners@heycarson.com'>partners@heycarson.com</a>
        </ShadowBox>
      </div>
      <div className='overview-page__levels-stats'>
        <InfoCard
          className='overview-page__card overview-page__partner-levels'
          customContent={(
            <div className='overview-page__partner-levels__content'>
              <div className='overview-page__partner-levels__row-container'>
                <div className='overview-page__partner-levels__row'>
                  <img
                    src={levelObj.image}
                    alt='level 4 partner'
                    className='overview-page__partner-levels__level-img'
                  />
                  <div className='overview-page__partner-levels__col'>
                    <div className='overview-page__partner-levels__title'>
                      {level?.current?.name ? `${level.current.name} Partner` : ''}
                      <div
                        className='overview-page__partner-levels__title__mark'
                        onMouseEnter={() => setOpenTooltip(true)}
                        onMouseLeave={() => setOpenTooltip(false)}
                      >
                        <img
                          src={questionMark}
                          alt='More info on partner levels'
                        />
                        <LevelsTooltip levels={partnerLevels} currentLevelSlug={level?.current?.slug}
                                       numLevel={numLevel} openTooltip={openTooltip} />
                      </div>
                    </div>
                    <p className='overview-page__partner-levels__desc'>
                      {`${level?.current?.text || ''}`}
                    </p>
                    <div
                      className='overview-page__partner-levels__amount overview-page__partner-levels__amount--mobile'>
                      {'$' + convertToK(totalReferred)} <span>&nbsp;/&nbsp;{levelObj.maxAmount}</span>
                    </div>
                  </div>
                </div>
                <div className='overview-page__partner-levels__amount overview-page__partner-levels__amount--desktop'>
                  {'$' + convertToK(totalReferred)}
                  {numLevel < 4 && <span>&nbsp;/&nbsp;{levelObj.maxAmount}</span>}
                </div>
              </div>
              <div className='overview-page__partner-levels__progress-bar'>
                <div
                  className='overview-page__partner-levels__progress-bar__progress'
                  style={{ width: levelObj.progressBar }}
                />
              </div>
            </div>
          )}
        />
        <InfoCard
          value={commission || ''}
          description='Commissions pending'
          className='overview-page__card overview-page__commissions'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              Total commission from referred purchases, pending approval
            </span>
          )}
        />
      </div>
    </div>
  )
}
