import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

import { loadPartner } from '@redux/referrals/partners'

import AccountDetails from '@pages/Partners/Admin/Partner/AccountDetails'
import ProgramsDetails from '@pages/Partners/Admin/Partner/Programs'
import UserDetails from '@pages/Partners/Admin/Partner/Users'
import WithdrawalDetails from '@pages/Partners/Admin/Partner/Withdrawals'
import PartnerConversionsDetails from '@pages/Partners/Admin/Partner/Conversions'
import PartnerStatsDetails from '@pages/Partners/Admin/Partner/Stats'

import './Partner.scss'

function PartnerView (props) {
  const params = useParams()
  const [searchParams] = useSearchParams()

  const hasId = searchParams.get('id') === '1' && params.partnerSlug

  useEffect(() => {
    props
      .loadPartner({
        id: hasId ? params.partnerSlug : undefined,
        slug: !hasId ? params.partnerSlug : undefined,
        isSlug: !hasId
      })
      .catch(() => {
        console.log('@loadPartner error')
      })
  }, [])

  return (
    <div className='admin-pview'>
      <div className='admin-pview__title'>
        Account Details
      </div>

      <AccountDetails partner={props.partner} />
      <ProgramsDetails partner={props.partner} />
      <UserDetails partner={props.partner} />
      <WithdrawalDetails partner={props.partner} />
      <PartnerStatsDetails partner={props.partner} />
      <PartnerConversionsDetails partner={props.partner} />
    </div>
  )
}

PartnerView.propTypes = {
  partner: PropTypes.object,
  loadPartner: PropTypes.func
}

const mapStateToProps = ({ referrals: { partners } }) => ({
  partner: partners.partner.data,
  loading: partners.partner.loading
})

const mapDispatchToProps = dispatch => ({
  loadPartner: payload => dispatch(loadPartner(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerView)
