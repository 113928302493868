import React from 'react'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import TextInput from '@components/Inputs/TextInput'
import SelectInput from '@components/Inputs/SelectInput'

class AddCommissionModal extends React.Component {
  render () {
    const { showModal } = this.props

    return (
      <Modal
        show={showModal}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={() => undefined}
        >
          Edit commission type
        </ModalHeader>
        <ModalBody className='pd__modals__body'>
          <TextInput
            label='Title'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
          />

          <TextInput
            label='Identifier'
            type='text'
            className='pd__modals__input'
            labelClassName='pd__modals__label'
          />

          <SelectInput
            label='Tiered?'
            name='tiered'
            className='pd__modals__select'
            labelClassName='pd__modals__label'
            value='0'
            options={[{
              key: '0',
              value: '0',
              label: 'No',
              selectedLabel: 'No'
            }, {
              key: '1',
              value: '1',
              label: 'Yes',
              selectedLabel: 'Yes'
            }]}
          />

          <div className='pd__modals__two-columns'>
            <TextInput
              label='Commission'
              type='text'
              className='pd__modals__input'
              labelClassName='pd__modals__label pd__modals__two-columns__column'
            />

            <SelectInput
              label='Currency'
              name='currency'
              className='pd__modals__select'
              labelClassName='pd__modals__label pd__modals__two-columns__column'
              value='percentage'
              options={[{
                key: 'percentage',
                value: 'percentage',
                label: 'percentage',
                selectedLabel: 'percentage'
              }]}
            />
          </div>

          <div className='pd__modals__two-columns'>
            <Button primary className='pd__modals__two-columns__button'>
              Save changes
            </Button>

            <Button danger className='pd__modals__two-columns__button'>
              Delete
            </Button>
          </div>

        </ModalBody>
      </Modal>
    )
  }
}

export default AddCommissionModal
