import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadAccountStats } from '@redux/referrals/dashboard'

import './Stats.scss'
import InfoCard from '@components/InfoCard'
import { shortAmount } from '@util/currency'
import { DateTime } from 'luxon'
import PeriodPicker from '@components/DatePicker/PeriodPicker'

class PartnerStatsDetails extends React.Component {
  state = {
    ready: false
  }

  constructor (props) {
    super(props)

    const end = DateTime.now().toUTC().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    const start = end.minus({ days: 30 })

    this.state = {
      ...this.state,
      period: {
        start,
        end
      },
      compare: false,
      comparePeriod: {
        start: null,
        end: null
      }
    }

    this.handleChangeDate = this.handleChangeDate.bind(this)
  }

  componentDidMount () {
    if (this.props.partner) {
      this.loadStats()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.partner &&
      this.props.partner.id !== prevProps.partner?.id
    ) {
      this.loadStats()
    }
  }

  loadStats () {
    return this.props.loadAccountStats({
      type: 'base',
      partnerId: this.props.partner.id,
      start: this.state.period.start.toISO(),
      end: this.state.period.end.toISO()
    }).then(() => {
      this.setState(old => ({ ...old, ready: true }))
    }).catch(error => {
      console.warn('dash stats error', error)
    })
  }

  getStats () {
    const { compare } = this.props

    const transformStat = (current) => {
      const s = {
        ...current,
        raw: current.value
      }

      if (
        current.name === 'conversion_amount' ||
        current.name === 'conversion_commission' ||
        current.name === 'first_conversion_amount'
      ) {
        s.value = shortAmount(Number(current.value) || 0, 'USD')
      }

      return s
    }
    const reduceStats = (acc, current) => {
      acc[current.name] = current

      return acc
    }

    const baseStat = this.props.stats.map(transformStat, {})

    if (!compare) {
      return baseStat.reduce(reduceStats, {})
    }

    const compareStat = this.props.compareStats.map(transformStat)

    return baseStat.reduce((acc, current) => {
      const toCompare = compareStat.find(c => c.name === current.name)

      if (
        current.name === 'total_partners' ||
        current.name === 'active_partners'
      ) {
        return { ...acc, [current.name]: current }
      }

      if (!toCompare) {
        return { ...acc, [current.name]: current }
      }

      let comparison
      let positive

      if (toCompare.raw <= 0 && current.raw > 0) {
        comparison = '100%'
        positive = true
      } else if (current.raw <= 0 && toCompare.raw > 0) {
        comparison = '-100%'
      } else if (current.raw > 0 && toCompare.raw > 0 && current.raw !== toCompare.raw) {
        comparison = (((current.raw / toCompare.raw) - 1) * 100).toFixed(2)
        positive = comparison > 0

        comparison = `${comparison}%`
      } else {
        comparison = 'N/A'
      }

      return {
        ...acc,
        [current.name]: {
          ...current,
          value: `${current.value} vs ${toCompare.value}`,
          comparison,
          positive
        }
      }
    }, {})
  }

  handleChangeDate ({ start, end }) {
    this.setState(old => ({
      period: {
        ...old.period,
        start,
        end
      }
    }), () => {
      this.loadStats()
    })
  }

  render () {
    if (!this.state.ready) {
      return null
    }
    const { period } = this.state

    const stats = this.getStats()

    return (
      <div className='admin-pview__stats'>
        <div className='admin-pview__stats__top'>
          <div className='admin-pview__stats__title'>
            Overview
          </div>

          <div className='admin-pview__stats__picker'>
            <PeriodPicker
              onChange={this.handleChangeDate}
              start={period.start.toISO()}
              end={period.end.toISO()}
              right
            />
          </div>
        </div>

        <div className='admin-pview__stats__list'>
          <InfoCard
            value={stats.new_click?.value}
            subValue={stats.new_click?.comparison}
            positive={stats.new_click?.positive}
            compare={this.props.compare}
            description='Total traffic (clicks)'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.new_customer?.value}
            subValue={stats.new_customer?.comparison}
            positive={stats.new_customer?.positive}
            compare={this.props.compare}
            description='Total referral signups'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.task_submitted?.value}
            subValue={stats.task_submitted?.comparison}
            positive={stats.task_submitted?.positive}
            compare={this.props.compare}
            description='Submitted tasks'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.task_submitted_first?.value}
            subValue={stats.task_submitted_first?.comparison}
            positive={stats.task_submitted_first?.positive}
            compare={this.props.compare}
            description='First tasks'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.task_completed?.value}
            subValue={stats.task_completed?.comparison}
            positive={stats.task_completed?.positive}
            compare={this.props.compare}
            description='Completed tasks'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.task_cancelled?.value}
            subValue={stats.task_cancelled?.comparison}
            positive={stats.task_cancelled?.positive}
            compare={this.props.compare}
            description='Canceled tasks'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.new_conversion?.value}
            subValue={stats.new_conversion?.comparison}
            positive={stats.new_conversion?.positive}
            compare={this.props.compare}
            description='Total purchases'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.first_conversion?.value}
            subValue={stats.first_conversion?.comparison}
            positive={stats.first_conversion?.positive}
            compare={this.props.compare}
            description='First purchases'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.conversion_amount?.value}
            subValue={stats.conversion_amount?.comparison}
            positive={stats.conversion_amount?.positive}
            compare={this.props.compare}
            description='Total purchases amount'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />

          <InfoCard
            value={stats.first_conversion_amount?.value}
            subValue={stats.first_conversion_amount?.comparison}
            positive={stats.first_conversion_amount?.positive}
            compare={this.props.compare}
            description='First purchases amount'
            className='admin-pview__stats__stat'
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                tooltip test
              </span>
            )}
          />
        </div>
      </div>
    )
  }
}

PartnerStatsDetails.propTypes = {
  partner: PropTypes.object,
  loadAccountStats: PropTypes.func,
  stats: PropTypes.array,
  loading: PropTypes.bool
}

const mapStateToProps = ({ referrals: { dashboard } }) => ({
  stats: dashboard.accountStats.data,
  compareStats: dashboard.compareAccountStats.data,
  loading: dashboard.accountStats.loading
})

const mapDispatchToProps = dispatch => ({
  loadAccountStats: payload => dispatch(loadAccountStats(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerStatsDetails)
