import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_PA_SETTINGS_PENDING = 'referrals/settings/LOAD_PA_SETTINGS_PENDING'
const LOAD_PA_SETTINGS_FULFILLED = 'referrals/settings/LOAD_PA_SETTINGS_FULFILLED'
const LOAD_PA_SETTINGS_REJECTED = 'referrals/settings/LOAD_PA_SETTINGS_REJECTED'
const UPDATE_PA_SETTINGS_PENDING = 'referrals/settings/UPDATE_PA_SETTINGS_PENDING'
const UPDATE_PA_SETTINGS_FULFILLED = 'referrals/settings/UPDATE_PA_SETTINGS_FULFILLED'
const UPDATE_PA_SETTINGS_REJECTED = 'referrals/settings/UPDATE_PA_SETTINGS_REJECTED'
const CHANGE_PARTNER_FIELD = 'referrals/settings/CHANGE_PARTNER_FIELD'

export const changePartnerField = payload => ({
  type: CHANGE_PARTNER_FIELD,
  payload
})

export const loadPartnerSettings = payload => async dispatch => {
  dispatch({
    type: LOAD_PA_SETTINGS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/partner-settings'), {
      params: {}
    })

    const pl = {
      user: response.data.user,
      partner: response.data.partner,
      account: response.data.account,
      lastFetch: (new Date()).toISOString()
    }

    dispatch({
      type: LOAD_PA_SETTINGS_FULFILLED,
      payload: pl
    })

    return pl
  } catch (error) {
    dispatch({
      type: LOAD_PA_SETTINGS_REJECTED
    })
  }
}

export const updatePartnerSettings = payload => async (dispatch, getState) => {
  const { referrals: { settings } } = getState()

  if (settings.loading) {
    return
  }

  dispatch({
    type: LOAD_PA_SETTINGS_PENDING
  })

  try {
    const { partnerFields: fields, partner, user } = settings

    const response = await axios.post(getRefApiUrl('v1', `/partner-settings/${partner.id}`), {
      company_name: partner.name,
      paypal_email: fields.paypalEmail,
      phone: fields.phone,

      user_name: fields.name,
      email: user.email
    })

    const pl = {
      user: response.data.user,
      partner: response.data.partner,
      account: response.data.account
    }

    dispatch({
      type: LOAD_PA_SETTINGS_FULFILLED,
      payload: pl
    })

    return pl
  } catch (error) {
    let errors = {}

    if (
      error?.response?.status === 400 &&
      error?.response?.data?.params
    ) {
      errors = {
        name: error.response.data.params.user_name,
        email: error.response.data.params.email,
        paypalEmail: error.response.data.params.paypal_email,
        phone: error.response.data.params.phone
      }
    }

    dispatch({
      type: LOAD_PA_SETTINGS_REJECTED,
      payload: {
        errors
      }
    })

    throw error
  }
}

const initialState = {
  loading: false,
  user: null,
  partner: null,
  account: null,

  partnerFields: {
    name: '',
    email: '',
    phone: '',
    paypalEmail: ''
  },
  partnerErrors: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PA_SETTINGS_PENDING:
    case LOAD_PA_SETTINGS_PENDING:
      return {
        ...state,
        loading: true
      }

    case UPDATE_PA_SETTINGS_FULFILLED:
    case LOAD_PA_SETTINGS_FULFILLED: {
      const { user, partner } = action.payload

      return {
        ...state,
        partner: action.payload.partner,
        user: action.payload.user,
        partnerFields: {
          ...state.partnerFields,
          name: user?.name || '',
          email: user?.email || '',
          paypalEmail: partner?.paypal_email || '',
          phone: partner?.phone || ''
        },
        partnerErrors: {},
        loading: false
      }
    }

    case UPDATE_PA_SETTINGS_REJECTED:
    case LOAD_PA_SETTINGS_REJECTED:
      return {
        ...state,
        loading: false,
        partnerErrors: action.payload?.errors || {}
      }

    case CHANGE_PARTNER_FIELD:
      return {
        ...state,
        partnerFields: {
          ...state.partnerFields,
          [action.payload.field]: action.payload.value
        }
      }

    default:
      return state
  }
}
