import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'

import { fetchDashOverview, fetchDashStats } from '@redux/developers.js'

import InfoCard from '@components/InfoCard/index.jsx'

import DashStats from '@components/DashStats/index.jsx'
import PeriodPicker from '@components/DatePicker/PeriodPicker.jsx'

const statsToObject = stats => stats.reduce((acc, stat) => {
  acc[stat.name] = stat.value
  return acc
}, {})

export default function ReviewsHero () {
  const [[start, end], setDates] = useState([])
  const [overview, setOverview] = useState({})
  const [stats, setStats] = useState({})

  const [overviewHash, statsHash] = useSelector(({ developers }) => [
    developers.dashOverview.hash,
    developers.dashStats.hash
  ])

  const dispatch = useDispatch()

  useEffect(() => {
    const end = DateTime.now()
    const start = end.minus({ days: 30 })

    setDates([start.toISO(), end.toISO()])
  }, [])

  useEffect(() => {
    // || statsHash === 'main'
    if (!start || !end) {
      return
    }

    dispatch(fetchDashStats({ start, end, hash: 'main' }))
      .then(({ stats }) => {
        setStats(statsToObject(stats))
      })
      .catch(error => {
        console.log('error fetching dash stats', error)
      })
  }, [start, end, statsHash])
  useEffect(() => {
    // if (overviewHash === 'main') {
    //   return
    // }

    dispatch(fetchDashOverview({ hash: 'main' }))
      .then(({ stats }) => {
        stats = statsToObject(stats)

        if (Number(stats.overall_rank_max) > 0) {
          stats.overall_rank = `${stats.overall_rank} / ${stats.overall_rank_max}`
        }

        setOverview(stats)
      })
      .catch(error => {
        console.log('error fetching dash overview', error)
      })
  }, [overviewHash])

  function handleChangeDates ({ start, end }) {
    setDates([start.toISO(), end.toISO()])
  }

  return (
    <div className='reviews-page__hero'>
      <div className='reviews-page__hero__top'>
        <h1 className='reviews-page__hero__top__title'>Reviews</h1>
        <div className='reviews-page__hero__top__date-range'>
          <PeriodPicker
            start={start}
            end={end}
            right
            onChange={handleChangeDates}
          />
        </div>
      </div>

      <DashStats
        items={[
          <InfoCard
            key='reviews'
            value={overview.theme_review || '0'}
            description='Number of reviews'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Number of reviews indicates the total of times customers
                have independently reviewed one of your listings
              </span>
            )}
          />,
          <InfoCard
            key='rating'
            value={stats.theme_visit || '0'}
            description='Page visits'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Page visits indicates the total of times your listing pages
                have been previewed.
              </span>
            )}
          />,
          <InfoCard
            key='clicks'
            value={stats.theme_click || '0'}
            description='Click-throughs to Shopify'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                Click throughs refers to the total of times a customer has accessed
                the Shopify page of one of your themes through your HeyCarson profile.
              </span>
            )}
          />,
          <InfoCard
            key='rank'
            value={overview.overall_rank || '0'}
            description='Theme Developer rank'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                The Theme Developer Rank is based on the customer experience.
                We rank theme developers using a score which combines total
                number of reviews and replies, average ratings across all themes,
                and % of customers which are ‘’Very Likely’' to recommend their
                themes to others.
              </span>
            )}
          />,
          <InfoCard
            key='overall_rating'
            value={overview.overall_rating || '0'}
            description='Overall rating'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                The overall rating value is the average of all the numeric
                evaluations left by the customers while reviewing your theme(s).
              </span>
            )}
          />,
          <InfoCard
            key='likely_recommend'
            value={overview.likely_recommend > 0 ? `${overview.likely_recommend}%` : ''}
            description='Customers likely to recommend'
            className=''
            toolTip={(
              <span className='pdash__hero__report__tooltip'>
                When leaving a theme review, the customers are asked “How likely
                are you to recommend this theme”. The “customers likely to
                recommend” metric is the percentage of people that have selected
                the “Very likely” option.
              </span>
            )}
          />
        ]}
      />
    </div>
  )
}

ReviewsHero.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string
}
