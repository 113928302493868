import React from 'react'
import PropTypes from 'prop-types'

import './DashStats.scss'

export default function DashStats ({ items }) {
  return (
    <div className='dash-stats'>
      {items}
    </div>
  )
}

DashStats.propTypes = {
  items: PropTypes.array
}
