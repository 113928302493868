import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { clearReviews, fetchReviews } from '@redux/appReviews.js'

import AppReview from '@pages/Partners/Partner/App/Review.jsx'
import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import emptyReviews from '@assets/images/empty-reviews.svg'

const reviewsLimit = 3

export default function ReviewList ({ appId }) {
  const [totalReviews, reviews, loadingReviews] = useSelector(({ appReviews }) => [
    appReviews.list.total,
    appReviews.list.data,
    appReviews.list.loading
  ])
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page') || 1

  const listRef = useRef()
  const dispatch = useDispatch()
  const pagination = makePagination(totalReviews, page, reviewsLimit)

  useEffect(() => {
    return () => {
      dispatch(clearReviews())
    }
  }, [])

  useEffect(() => {
    loadReviews()
  }, [page, appId])

  function loadReviews () {
    if (!appId) {
      return
    }

    dispatch(fetchReviews({
      offset: pagination.offset,
      limit: pagination.limit,
      appId,
      withReply: 1
    }))
  }

  if ((!reviews.length && loadingReviews) || totalReviews === 0) {
    return (
      <div className='par-review__empty'>
        {!loadingReviews && totalReviews === 0 && (
          <img src={emptyReviews} className='par-review__empty__image' alt='empty' />
        )}

        <div className='par-review__empty__title'>
          {loadingReviews ? 'Loading reviews...' : 'Looks like there are no available reviews for this app yet.'}
        </div>
      </div>
    )
  }

  return (
    <div ref={listRef}>
      {reviews.map(review => (
        <AppReview key={review.id} review={review} />
      ))}

      <div className='par-review__pagination'>
        <SoftPagination
          onChange={(page, event) => {
            event.preventDefault()
            setSearchParams({ page })
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}

ReviewList.propTypes = {
  appId: PropTypes.string
}
