import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { fetchApps } from '@redux/apps.js'

import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import ListRow from '@components/ListRow/index.jsx'
import Button from '@components/Button/index.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'
import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'

import star from '@assets/icons/star.svg'

const pageLimit = 15

export default function AppList () {
  const [
    apps,
    totalApps,
    loadingApps
  ] = useSelector(({ apps }) => [
    apps.list.data,
    apps.list.total,
    apps.list.loading
  ])
  const [developerId] = useSelector(({ account }) => [account.workspace.id])

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || 1)

  const pagination = makePagination(totalApps, page, pageLimit)

  useEffect(() => {
    loadApps()
  }, [page])

  function loadApps () {
    dispatch(fetchApps({
      developerId,
      offset: pagination.offset,
      limit: pagination.limit,
      withDeveloper: false,
      withRating: true
    }))
  }

  function handlePageChange (page, event) {
    event.preventDefault()
    setSearchParams({ page })
  }

  return (
    <div className='apps-reviews-page__list'>
      <h1 className='apps-reviews-page__list__title'>
        My app listings <span className='light-gray-text'>({!loadingApps ? totalApps : '...'})</span>
      </h1>

      <ListRow
        empty={!totalApps}
        loading={loadingApps}
        className='apps-reviews-page__list-desktop'
        headers={(
          <>
            <ListRowHeader
              className='apps-reviews-page__list-desktop__name apps-reviews-page__list-desktop__name--image-placeholder'
            >
              APP NAME
            </ListRowHeader>
            <ListRowHeader className='apps-reviews-page__list-desktop__rating'>
              AVERAGE RATING
            </ListRowHeader>
            <ListRowHeader className='apps-reviews-page__list-desktop__review-count'>
              REVIEWS
            </ListRowHeader>
            <ListRowHeader className='apps-reviews-page__list-desktop__actions'>&nbsp;</ListRowHeader>
          </>
        )}
        items={apps.map(app => (
          <ListRowItem key={app.id} className='apps-reviews-page__list-desktop__columns'>
            <ListRowColumn className='apps-reviews-page__list-desktop__name'>
              <Link className='apps-reviews-page__list-desktop__link' to={`/app-reviews/${app.id}`}>
                <div className='apps-reviews-page__list-desktop__thumbnail-container'>
                  <img
                    className='apps-reviews-page__list-desktop__thumbnail-image' src={app.icon_url}
                    alt={app.name}
                  />
                </div>
              </Link>

              <Link className='apps-reviews-page__list-desktop__link' to={`/app-reviews/${app.id}`}>
                {app.name}
              </Link>
            </ListRowColumn>

            <ListRowColumn className='apps-reviews-page__list-desktop__rating'>
              <img src={star} alt='overall rating' />
              <div>{app?.ratings.review_rating.toFixed(1)}</div>
            </ListRowColumn>

            <ListRowColumn className='apps-reviews-page__list-desktop__review-count'>
              <div>{app?.ratings.review_count}</div>
            </ListRowColumn>

            <ListRowColumn className='apps-reviews-page__list-desktop__actions'>
              <Link className='apps-reviews-page__list-desktop__link' to={`/app-reviews/${app.id}`}>
                <Button hollow>
                  Get reviews
                </Button>
              </Link>
              <Link className='apps-reviews-page__list-desktop__link' to={`/app-reviews/${app.id}/list`}>
                <Button hollow>
                  Replies
                </Button>
              </Link>
              <Link className='apps-reviews-page__list-desktop__link' to={`/app-reviews/${app.id}/widgets`}>
                <Button hollow>
                  Get the widget
                </Button>
              </Link>
            </ListRowColumn>
          </ListRowItem>
        ))}
      />

      <div className='apps-reviews-page__list-mobile'>
        {apps.map(app => (
          <ShadowBox key={app.id} className='apps-reviews-page__list-mobile__item'>
            <div className='apps-reviews-page__list-mobile__item__name'>
              <Link to={`/app-reviews/${app.id}`}>
                {app.name}
              </Link>
            </div>

            <div className='apps-reviews-page__list-mobile__item__reviews'>
              <div className='apps-reviews-page__list-mobile__item__rating'>
                <div>Average Rating:</div>
                <div className='apps-reviews-page__list-mobile__item__star'>
                  <img src={star} alt='overall rating' />
                </div>
                <div>{`${app?.ratings.review_rating.toFixed(1)}/5`}</div>
              </div>
              <div className='apps-reviews-page__list-mobile__item__count'>
                Reviews: {app?.ratings.review_count}
              </div>
            </div>
            <div className='apps-reviews-page__list-mobile__item__actions'>
              <Link to={`/app-reviews/${app.id}`}>
                <Button hollow>
                  Get reviews
                </Button>
              </Link>

              <Link to={`/app-reviews/${app.id}/list`}>
                <Button hollow>
                  Manage
                </Button>
              </Link>
            </div>
          </ShadowBox>
        ))}
      </div>

      <div className='apps-reviews-page__pagination'>
        {(pagination.totalResults / pageLimit) > 1 && (
          <SoftPagination
            onChange={handlePageChange}
            pagination={pagination}
            pageButtons={5}
            fixedPageButton
            buildURL={(page) => `/app-reviews?page=${page}`}
          />
        )}
      </div>
    </div>
  )
}
