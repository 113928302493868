import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { DateTime } from 'luxon'

import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import PeriodPicker from '@components/DatePicker/PeriodPicker.jsx'
import ReferralsHero from '@pages/Partners/Partner/Referrals/Hero.jsx'
import ConversionList from '@containers/Partners/ConversionsList/index.jsx'
import ReferralsList from '@containers/Partners/ReferralsList/index.jsx'
import ClicksList from '@containers/Partners/ClicksList/index.jsx'
import ContentPadding from '@components/ContentPadding/index.jsx'

import './Referrals.scss'
import { useSelector } from 'react-redux'

export default function ReferralsPage () {
  const [[start, end], setDates] = useState([])
  const [tab, setTab] = useState('conversions')
	const [partnerSlug] = useSelector(({  referrals: { partners } }) => [
		partners.partner.data.slug,
	])

	useBreadcrumbs({
    label: 'Referrals',
    to: '/referrals'
  })

  useEffect(() => {
    const end = DateTime.now()
    const start = end.minus({ days: 0 })

    setDates([start.toISO(), end.toISO()])
  }, [])

  function handleChangeDates ({ start, end }) {
    setDates([start.toISO(), end.toISO()])
  }

  function tabDescription (tab) {
    switch (tab) {
      case 'conversions':
        return 'Referrals who’ve made a project payment to HeyCarson'
      case 'referrals':
        return 'Referred clients who\'ve signed up for an account or who\'ve submitted a request for a project quote'
      case 'visits':
        return 'Referred visitors sent to our website and service through your affiliate/tracking links and project forms'
    }
  }

  return (
    <>
      <Helmet>
        <title>Referrals</title>
      </Helmet>

      <ContentPadding>
        <div className='referrals-page'>
          <div className='referrals-page__header'>
            <h1 className='referrals-page__header__title'>Conversions</h1>
            <div className='referrals-page__header__date-range'>
              <PeriodPicker
                start={start}
                end={end}
                right
                preset='all_time'
                onChange={handleChangeDates}
              />
            </div>
          </div>

          <ReferralsHero
            start={start}
            end={end}
          />
          <div className='referrals-page__tabs'>
            <Link
              to='#'
              className={classnames('referrals-page__tabs__tab', {
                'referrals-page__tabs__tab--selected': tab === 'conversions'
              })}
              onClick={() => setTab('conversions')}
            >
              Conversions
            </Link>
            <Link
              to='#'
              className={classnames('referrals-page__tabs__tab', {
                'referrals-page__tabs__tab--selected': tab === 'referrals'
              })}
              onClick={() => setTab('referrals')}
            >
              Referrals
            </Link>
						{partnerSlug !== 'archetypethemes' && (
							<Link
								to='#'
								className={classnames('referrals-page__tabs__tab', {
									'referrals-page__tabs__tab--selected': tab === 'visits'
								})}
								onClick={() => setTab('visits')}
							>
								Visits
							</Link>
						)}
          </div>
          <div className='referrals-page__tabs__desc'>{tabDescription(tab)}</div>
          {tab === 'conversions' &&
            <ConversionList
              pageLimit={15}
              hash='referrals'
              showPagination
            />}
          {tab === 'referrals' &&
            <ReferralsList
              pageLimit={15}
              showPagination
            />}
          {tab === 'visits' &&
            <ClicksList
              pageLimit={15}
              showPagination
            />}
        </div>
      </ContentPadding>
    </>
  )
}
