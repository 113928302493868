import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { shortAmount } from '@util/currency'
import { loadAccountStats, loadPartnerCount } from '@redux/referrals/dashboard'

import InfoCard from '@components/InfoCard'

class GeneralView extends React.Component {
  state = {}

  componentDidMount () {
    this.loadStats()
    this.loadPartnerCount()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { period, comparePeriod, compare } = this.props

    if (
      (prevProps.period.start !== period.start || prevProps.period.end !== period.end) ||
      (prevProps.comparePeriod.start !== comparePeriod.start || prevProps.comparePeriod.end !== comparePeriod.end) ||
      (prevProps.compare !== compare)
    ) {
      this.loadStats()
    }
  }

  loadPartnerCount () {
    this.props.loadPartnerCount()
  }

  loadStats () {
    const { period, compare, comparePeriod } = this.props

    this.props.loadAccountStats({
      type: 'base',
      start: period.start.toISO(),
      end: period.end.toISO()
    }).catch(error => {
      console.log('dash stats error', error)
    })

    if (compare) {
      this.props.loadAccountStats({
        type: 'compare',
        start: comparePeriod.start.toISO(),
        end: comparePeriod.end.toISO()
      }).catch(error => {
        console.log('dash compare stats error', error)
      })
    }
  }

  getStats () {
    const { compare } = this.props

    const transformStat = (current) => {
      const s = {
        ...current,
        raw: current.value
      }

      if (
        current.name === 'conversion_amount' ||
        current.name === 'conversion_commission' ||
        current.name === 'first_conversion_amount'
      ) {
        s.value = shortAmount(Number(current.value) || 0, 'USD')
      }

      return s
    }
    const reduceStats = (acc, current) => {
      acc[current.name] = current

      return acc
    }

    const baseStat = this.props.stats.map(transformStat, {})

    if (!compare) {
      return baseStat.reduce(reduceStats, {})
    }

    const compareStat = this.props.compareStats.map(transformStat)

    return baseStat.reduce((acc, current) => {
      const toCompare = compareStat.find(c => c.name === current.name)

      if (
        current.name === 'total_partners' ||
        current.name === 'active_partners'
      ) {
        return { ...acc, [current.name]: current }
      }

      if (!toCompare) {
        return { ...acc, [current.name]: current }
      }

      let comparison
      let positive

      if (toCompare.raw <= 0 && current.raw > 0) {
        comparison = '100%'
        positive = true
      } else if (current.raw <= 0 && toCompare.raw > 0) {
        comparison = '-100%'
      } else if (current.raw > 0 && toCompare.raw > 0 && current.raw !== toCompare.raw) {
        comparison = (((current.raw / toCompare.raw) - 1) * 100).toFixed(2)
        positive = comparison > 0

        comparison = `${comparison}%`
      } else {
        comparison = 'N/A'
      }

      return {
        ...acc,
        [current.name]: {
          ...current,
          value: `${current.value} vs ${toCompare.value}`,
          comparison,
          positive
        }
      }
    }, {})
  }

  render () {
    const stats = this.getStats()

    return (
      <div className='pah__hero__reports'>
        <InfoCard
          value={this.props.totalPartners}
          description='Total registered partners'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={this.props.activePartners}
          description='Total active partners'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.new_click?.value}
          subValue={stats.new_click?.comparison}
          positive={stats.new_click?.positive}
          compare={this.props.compare}
          description='Total traffic (clicks)'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.new_customer?.value}
          subValue={stats.new_customer?.comparison}
          positive={stats.new_customer?.positive}
          compare={this.props.compare}
          description='Total referral signups'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.task_submitted?.value}
          subValue={stats.task_submitted?.comparison}
          positive={stats.task_submitted?.positive}
          compare={this.props.compare}
          description='Submitted tasks'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.task_submitted_first?.value}
          subValue={stats.task_submitted_first?.comparison}
          positive={stats.task_submitted_first?.positive}
          compare={this.props.compare}
          description='First tasks'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.task_completed?.value}
          subValue={stats.task_completed?.comparison}
          positive={stats.task_completed?.positive}
          compare={this.props.compare}
          description='Completed tasks'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.task_cancelled?.value}
          subValue={stats.task_cancelled?.comparison}
          positive={stats.task_cancelled?.positive}
          compare={this.props.compare}
          description='Canceled tasks'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.new_conversion?.value}
          subValue={stats.new_conversion?.comparison}
          positive={stats.new_conversion?.positive}
          compare={this.props.compare}
          description='Total purchases'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.first_conversion?.value}
          subValue={stats.first_conversion?.comparison}
          positive={stats.first_conversion?.positive}
          compare={this.props.compare}
          description='First purchases'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.conversion_amount?.value}
          subValue={stats.conversion_amount?.comparison}
          positive={stats.conversion_amount?.positive}
          compare={this.props.compare}
          description='Total purchases amount'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.first_conversion_amount?.value}
          subValue={stats.first_conversion_amount?.comparison}
          positive={stats.first_conversion_amount?.positive}
          compare={this.props.compare}
          description='First purchases amount'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.theme_visit?.value}
          subValue={stats.theme_visit?.comparison}
          positive={stats.theme_visit?.positive}
          compare={this.props.compare}
          description='Theme visits'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />

        <InfoCard
          value={stats.theme_click?.value}
          subValue={stats.theme_click?.comparison}
          positive={stats.theme_click?.positive}
          compare={this.props.compare}
          description='Theme click throughs'
          className='pah__hero__report'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              tooltip test
            </span>
          )}
        />
      </div>
    )
  }
}

GeneralView.propTypes = {
  period: PropTypes.object,
  comparePeriod: PropTypes.object,
  compare: PropTypes.bool,
  stats: PropTypes.array,
  compareStats: PropTypes.array,
  statsLoading: PropTypes.bool,
  totalPartners: PropTypes.number,
  activePartners: PropTypes.number,
  loadAccountStats: PropTypes.func,
  loadPartnerCount: PropTypes.func
}

const mapStateToProps = ({ referrals: { dashboard } }) => ({
  stats: dashboard.accountStats.data,
  compareStats: dashboard.compareAccountStats.data,
  statsLoading: dashboard.accountStats.loading,
  totalPartners: dashboard.partnerCount.total,
  activePartners: dashboard.partnerCount.active
})

const mapDispatchToProps = dispatch => ({
  loadAccountStats: payload => dispatch(loadAccountStats(payload)),
  loadPartnerCount: payload => dispatch(loadPartnerCount(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralView)
