import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { toggle } from '@redux/sidebar.js'

import Button from '@components/Button/index.jsx'
import Dropdown from '@components/Dropdown/index.jsx'
import Breadcrumbs from '@components/Breadcrumbs/index.jsx'

import trendUp from '@assets/icons/trend-up.svg'
import defaultAvatar from '@assets/images/default-avatar.png'
import hamburger from '@assets/icons/hamburger.svg'

import './Topbar.scss'

function AffiliateTopBar (props) {
  const dispatch = useDispatch()

  const { balance, toggleModal, user, openDropdown } = props
  const { width } = props
  const canWithdraw = balance > 0

  function handleCloseContainer (event) {
    props.onCloseProfile(event)
  }

  function handleClickContent (event) {
    event.stopPropagation()
  }

  if (!user) {
    return null
  }

  return (
    <>
      <div className='pd-partner-topbar'>
        <div className='pd-partner-topbar__menu-and-breadscrumb'>
          {
            width < 1200 && (
              <div className='pd-partner-topbar__burger'>
                <img src={hamburger} alt='menu' onClick={() => dispatch(toggle(true))} />
              </div>
            )
          }
          <Breadcrumbs
            withHome
          />
        </div>
        <div className='pd-partner-topbar__empty-space' />
        {width > 1199 && (
          <>
            <div className='pd-partner-topbar__balance'>
              Your balance is <span className='pd-partner-topbar__balance__money'>${balance}</span>
            </div>
            <Button
              className='pd-partner-topbar__withdraw'
              success
              small
              onClick={() => toggleModal(true)}
              disabled={!canWithdraw}
            >
              <img
                className='pd-partner-topbar__withdraw__icon'
                src={trendUp}
                alt='withdraw'
              />
              Withdraw
            </Button>
          </>
        )}
        <Dropdown
          right
          controlled
          open={openDropdown}
          onClickOutside={props.onCloseProfile}
          activator={() => (
            <div className='pd-partner-topbar__profile' onClick={props.onClickProfile}>
              <div className='pd-partner-topbar__profile__avatar'>
                <div className='pd-partner-topbar__profile__picture'>
                  <img src={user.avatar || defaultAvatar} alt='avatar' />
                </div>
              </div>
            </div>
          )}
          body={() => (
            <div className='pd-partner-topbar__profile__options'>
              <Link
                to='/settings'
                className='pd-partner-topbar__profile__option'
                onClick={props.onCloseProfile}
              >
                Settings
              </Link>
              <a
                href='src/components/Partners/Topbar#'
                className='pd-partner-topbar__profile__option'
                onClick={props.onSignOut}
              >
                Sign Out
              </a>
            </div>
          )}
        />
      </div>

      <div
        className={classnames('pd-partner-topbar__mobile-menu', {
          'pd-partner-topbar__mobile-menu--show': props.openMenu
        })}
      >
        <div
          onClick={props.onCloseProfile}
          className={classnames('pd-partner-topbar__mobile-menu__overlay', {
            'pd-partner-topbar__mobile-menu__overlay--show': props.openMenu
          })}
        />

        <div
          className={classnames('pd-partner-topbar__mobile-menu__container', {
            'pd-partner-topbar__mobile-menu__container--show': props.openMenu
          })}
          onClick={handleCloseContainer}
        >
          <div className='pd-partner-topbar__mobile-menu__content' onClick={handleClickContent}>
            <div className='pd-partner-topbar__mobile-menu__top'>
              <div className='pd-partner-topbar__mobile-menu__user'>
                <div className='pd-partner-topbar__mobile-menu__user__avatar'>
                  <div className='pd-partner-topbar__mobile-menu__user__picture'>
                    <img src={user.avatar} alt='avatar' />
                  </div>
                </div>

                <div className='pd-partner-topbar__mobile-menu__user__name'>
                  {user.name}
                </div>
                <div className='pd-partner-topbar__mobile-menu__user__email'>
                  {user.email}
                </div>
              </div>

              <div className='pd-partner-topbar__mobile-menu__close'>
                <div className='pd-partner-topbar__mobile-menu__close__icon' onClick={props.onCloseProfile}>
                  &times;
                </div>
              </div>
            </div>

            <div className='pd-partner-topbar__mobile-menu__options'>
              <Link
                to='/settings'
                className='pd-partner-topbar__mobile-menu__option'
                onClick={props.onCloseProfile}
              >
                Settings
              </Link>

              <a
                href='src/components/Partners/Topbar#'
                className='pd-partner-topbar__mobile-menu__option pd-partner-topbar__mobile-menu__option--sign-out'
                onClick={props.onSignOut}
              >
                Sign Out
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

AffiliateTopBar.propTypes = {
  balance: PropTypes.string,
  toggleModal: PropTypes.func,
  user: PropTypes.object,
  openDropdown: PropTypes.bool,
  openMenu: PropTypes.bool,
  onClickProfile: PropTypes.func,
  onCloseProfile: PropTypes.func,
  onSignOut: PropTypes.func,
  width: PropTypes.number
}

export default AffiliateTopBar
