import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { fetchProfiles as fetchDevelopers } from '@redux/developers.js'

import SearchBar from '@components/SearchBar'
import SoftPagination, { makePagination } from '@components/Pagination/Soft'
import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'
import Button from '@components/Button/index.jsx'
import DeveloperModal from './DeveloperModal'

import './List.scss'

const DeveloperLimit = 15

export default function DeveloperList () {
  const listRef = useRef()
  const [totalDevelopers, developers] = useSelector(({ developers }) => [
    developers.list.total,
    developers.list.data
  ])
  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page') || 1
  const [capability, setCapability] = useState(searchParams.get('capability') || '')

  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '')
  const [search, setSearch] = useState(searchVal)
  const [selected, setSelected] = useState({})
  const [showModal, setShowModal] = useState('')

  const dispatch = useDispatch()
  const searchTimeout = useRef(null)
  const pagination = makePagination(totalDevelopers, page, DeveloperLimit)

  useEffect(() => {
    setSearchParams({ search, capability })
  }, [search, capability])

  useEffect(() => {
    loadDevelopers()
  }, [page, search, capability])

  function loadDevelopers () {
    dispatch(fetchDevelopers({
      search: search || undefined,
      offset: pagination.offset,
      limit: pagination.limit,
      withCapabilities: true,
      withCapability: capability || undefined
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }
  function handleEdit (developer) {
    setSelected(developer)
    setShowModal('edit')
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }
  function handleDeveloperModalSuccess () {
    setShowModal('')
    setSelected(null)
    loadDevelopers()
  }

  function getDevProfileUrl (capability, slug) {
    if (capability === 'apps') {
      return `${import.meta.env.VITE_SITE_URL}/apps/developer/${slug}`
    }

    if (capability === 'themes') {
      return `${import.meta.env.VITE_SITE_URL}/themes/developer/${slug}`
    }

    return ''
  }

  return (
    <div className='td-page' ref={listRef}>
      <DeveloperModal
        show={showModal !== ''}
        type={showModal}
        developer={selected}
        onClose={() => setShowModal('')}
        onSuccess={handleDeveloperModalSuccess}
      />
      <div className='td-page__title'>
        Shopify Developers
      </div>

      <div className='td-page__list'>
        <div className='td-page__toolbar'>
          <SearchBar
            className='td-page__search'
            placeholder='Search developers'
            value={searchVal}
            onChange={handleSearchChange}
          />
        </div>

        <div className='td-page__capability'>
          <Button
            primary={capability === ''}
            disabled={capability === ''}
            onClick={() => setCapability('')}
          >
            All
          </Button>
          <Button
            primary={capability === 'themes'}
            disabled={capability === 'themes'}
            onClick={() => setCapability('themes')}
          >
            Themes
          </Button>
          <Button
            primary={capability === 'apps'}
            disabled={capability === 'apps'}
            onClick={() => setCapability('apps')}
          >
            Apps
          </Button>
        </div>

        <ListRow
          className='td-page__list__items'
          headers={(
            <>
              <ListRowHeader className='td-page__list__name'>
                Developer
              </ListRowHeader>
              <ListRowHeader className='td-page__list__visibilitity'>
                Capabilities
              </ListRowHeader>
              <ListRowHeader className='td-page__list__developer'>
                Filter Visibility
              </ListRowHeader>
              <ListRowHeader className='td-page__list__thumbnail'>
                Thumbnail
              </ListRowHeader>
              <ListRowHeader className='td-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={developers.map(developer => (
            <ListRowItem key={developer.id}>
              <ListRowColumn className='td-page__list__name'>
                <Link
                  to={`/partners/v/${developer.id}?id=1`}
                >
                  {developer.name}
                </Link>
                <br />
                <small className='td-page__list__slug'>
                  {developer.slug}
                </small>
              </ListRowColumn>
              <ListRowColumn className='td-page__list__capabilities'>
                {developer?.capabilities?.length > 0 && developer.capabilities.map(capability => (
                  <small key={capability} className='td-page__list__slug'>
                    <a
                      href={getDevProfileUrl(capability, developer.slug)}
                      target='_blank'
                      rel='noreferrer noopener'
                    >
                      {capability}
                    </a>
                  </small>
                ))}
              </ListRowColumn>
              <ListRowColumn className='td-page__list__visibilitity'>
                <div>
                  {developer.visible ? 'Yes' : 'No'}
                </div>
              </ListRowColumn>
              <ListRowColumn className='td-page__list__thumbnail'>
                <a
                  href={developer.image_url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img className='pda__theme-img-preview' src={developer.image_url} alt={developer.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='td-page__list__actions'>
                <Link
                  to='#'
                  onClick={() => handleEdit(developer)}
                >
                  Edit
                </Link>
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            event.preventDefault()
            setSearchParams({ search, page })
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}
