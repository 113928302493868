import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_THEMES_PENDING = 'themes/FETCH_THEMES_PENDING'
const FETCH_THEMES_FULFILLED = 'themes/FETCH_THEMES_FULFILLED'
const FETCH_THEMES_REJECTED = 'themes/FETCH_THEMES_REJECTED'

const ADD_THEME_PENDING = 'themes/ADD_THEME_PENDING'
const ADD_THEME_FULFILLED = 'themes/ADD_THEME_FULFILLED'
const ADD_THEME_REJECTED = 'themes/ADD_THEME_REJECTED'

const UPDATE_THEME_PENDING = 'themes/UPDATE_THEME_PENDING'
const UPDATE_THEME_FULFILLED = 'themes/UPDATE_THEME_FULFILLED'
const UPDATE_THEME_REJECTED = 'themes/UPDATE_THEME_REJECTED'

const FETCH_THEME_PENDING = 'themes/FETCH_THEME_PENDING'
const FETCH_THEME_FULFILLED = 'themes/FETCH_THEME_FULFILLED'
const FETCH_THEME_REJECTED = 'themes/FETCH_THEME_REJECTED'
const CLEAR_THEME = 'themes/CLEAR_THEME'

const FETCH_STYLES_PENDING = 'themes/FETCH_STYLES_PENDING'
const FETCH_STYLES_FULFILLED = 'themes/FETCH_STYLES_FULFILLED'
const FETCH_STYLES_REJECTED = 'themes/FETCH_STYLES_REJECTED'

const ADD_STYLE_PENDING = 'themes/ADD_STYLE_PENDING'
const ADD_STYLE_FULFILLED = 'themes/ADD_STYLE_FULFILLED'
const ADD_STYLE_REJECTED = 'themes/ADD_STYLE_REJECTED'
const UPDATE_STYLE_PENDING = 'themes/UPDATE_STYLE_PENDING'
const UPDATE_STYLE_FULFILLED = 'themes/UPDATE_STYLE_FULFILLED'
const UPDATE_STYLE_REJECTED = 'themes/UPDATE_STYLE_REJECTED'
const DELETE_STYLE_PENDING = 'themes/DELETE_STYLE_PENDING'
const DELETE_STYLE_FULFILLED = 'themes/DELETE_STYLE_FULFILLED'
const DELETE_STYLE_REJECTED = 'themes/DELETE_STYLE_REJECTED'

const FETCH_FEATURES_PENDING = 'themes/FETCH_FEATURES_PENDING'
const FETCH_FEATURES_FULFILLED = 'themes/FETCH_FEATURES_FULFILLED'
const FETCH_FEATURES_REJECTED = 'themes/FETCH_FEATURES_REJECTED'

const ADD_FEATURE_PENDING = 'themes/ADD_FEATURE_PENDING'
const ADD_FEATURE_FULFILLED = 'themes/ADD_FEATURE_FULFILLED'
const ADD_FEATURE_REJECTED = 'themes/ADD_FEATURE_REJECTED'
const UPDATE_FEATURE_PENDING = 'themes/UPDATE_FEATURE_PENDING'
const UPDATE_FEATURE_FULFILLED = 'themes/UPDATE_FEATURE_FULFILLED'
const UPDATE_FEATURE_REJECTED = 'themes/UPDATE_FEATURE_REJECTED'
const DELETE_FEATURE_PENDING = 'themes/DELETE_FEATURE_PENDING'
const DELETE_FEATURE_FULFILLED = 'themes/DELETE_FEATURE_FULFILLED'
const DELETE_FEATURE_REJECTED = 'themes/DELETE_FEATURE_REJECTED'

export const fetchThemes = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_THEMES_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/themes'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined,
        developer_id: payload.developerId || undefined,
        with_developer: payload.withDeveloper ? '1' : undefined,
        with_rating: payload.withRating ? '1' : undefined
      }
    })

    const data = {
      total: response.data.total,
      themes: response.data.data
    }

    dispatch({ type: FETCH_THEMES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_THEMES_REJECTED })
    throw error
  }
}

export const addTheme = payload => async dispatch => {
  try {
    dispatch({ type: ADD_THEME_PENDING })

    const response = await axios.post(getRefApiUrl('v1', '/themes'), payload.data)

    const data = {
      theme: response.data
    }

    dispatch({ type: ADD_THEME_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_THEME_REJECTED })
    throw error
  }
}

export const updateTheme = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_THEME_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes/${payload.id}`),
      payload.data
    )

    const data = {
      theme: response.data
    }

    dispatch({ type: UPDATE_THEME_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_THEME_REJECTED })
    throw error
  }
}

export const fetchTheme = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_THEME_PENDING })

    const response = await axios.get(getRefApiUrl('v1', `/themes/${payload.id}`), {
      params: {
        slug: payload.slug ? 1 : undefined,
        filter_expand: payload.filterExpand ? '1' : undefined
      }
    })

    const data = {
      theme: response.data
    }

    dispatch({ type: FETCH_THEME_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_THEME_REJECTED })
    throw error
  }
}

export const clearTheme = () => ({
  type: CLEAR_THEME
})

export const fetchThemeStyles = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_STYLES_PENDING, payload: { clean: payload.clean } })

    const response = await axios.get(
      getRefApiUrl('v1', `/themes/${payload.id}/styles`),
      {
        params: {
          slug: payload.slug ? 1 : undefined
        }
      }
    )

    const data = {
      total: response.data.total,
      styles: response.data.data
    }

    dispatch({ type: FETCH_STYLES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_STYLES_REJECTED })
    throw error
  }
}

export const addThemeStyle = payload => async dispatch => {
  try {
    dispatch({ type: ADD_STYLE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes/${payload.themeId}/styles`),
      payload.data
    )

    const data = {
      style: response.data
    }

    dispatch({ type: ADD_STYLE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_STYLE_REJECTED })
    throw error
  }
}

export const updateThemeStyle = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_STYLE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes/${payload.themeId}/styles/${payload.id}`),
      payload.data
    )

    const data = {
      style: response.data
    }

    dispatch({ type: UPDATE_STYLE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_STYLE_REJECTED })
    throw error
  }
}

export const deleteThemeStyle = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_STYLE_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/themes/${payload.themeId}/styles/${payload.id}`)
    )

    dispatch({ type: DELETE_STYLE_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_STYLE_REJECTED })
    throw error
  }
}

export const fetchThemeFeatures = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_FEATURES_PENDING, payload: { clean: payload.clean } })

    const response = await axios.get(
      getRefApiUrl('v1', `/themes/${payload.id}/features`),
      {
        params: {
          slug: payload.slug ? 1 : undefined
        }
      }
    )

    const data = {
      total: response.data.total,
      features: response.data.data
    }

    dispatch({ type: FETCH_FEATURES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_FEATURES_REJECTED })
    throw error
  }
}

export const addThemeFeature = payload => async dispatch => {
  try {
    dispatch({ type: ADD_FEATURE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes/${payload.themeId}/features`),
      payload.data
    )

    const data = {
      feature: response.data
    }

    dispatch({ type: ADD_FEATURE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_FEATURE_REJECTED })
    throw error
  }
}

export const updateThemeFeature = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_FEATURE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes/${payload.themeId}/features/${payload.id}`),
      payload.data
    )

    const data = {
      feature: response.data
    }

    dispatch({ type: UPDATE_FEATURE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_FEATURE_REJECTED })
    throw error
  }
}

export const deleteThemeFeature = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_FEATURE_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/themes/${payload.themeId}/features/${payload.id}`)
    )

    dispatch({ type: DELETE_FEATURE_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_FEATURE_REJECTED })
    throw error
  }
}

// initial state
const initialState = {
  hydrated: false,
  list: {
    total: 0,
    loading: false,
    data: []
  },
  view: {
    loading: false,
    data: null
  },
  styles: {
    total: 0,
    loading: false,
    data: []
  },
  features: {
    total: 0,
    loading: false,
    data: []
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_THEMES_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_THEMES_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.themes
        }
      }
    case FETCH_THEMES_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }

    case FETCH_THEME_PENDING:
      return {
        ...state,
        view: {
          ...state.view,
          loading: true
        }
      }
    case FETCH_THEME_FULFILLED:
      return {
        ...state,
        view: {
          ...state.view,
          loading: false,
          data: action.payload.theme
        }
      }
    case FETCH_THEME_REJECTED:
      return {
        ...state,
        view: {
          ...state.view,
          loading: false
        }
      }
    case CLEAR_THEME:
      return {
        ...state,
        view: {
          ...state.view,
          ...initialState.view
        }
      }

    case FETCH_STYLES_PENDING:
      return {
        ...state,
        styles: {
          ...state.styles,
          loading: true,
          data: action.payload?.clean ? [] : state.styles.data
        }
      }
    case FETCH_STYLES_FULFILLED:
      return {
        ...state,
        styles: {
          ...state.styles,
          loading: false,
          total: action.payload.total,
          data: action.payload.styles
        }
      }
    case FETCH_STYLES_REJECTED:
      return {
        ...state,
        styles: {
          ...state.styles,
          loading: false
        }
      }

    case FETCH_FEATURES_PENDING:
      return {
        ...state,
        features: {
          ...state.features,
          loading: true,
          data: action.payload?.clean ? [] : state.features.data
        }
      }
    case FETCH_FEATURES_FULFILLED:
      return {
        ...state,
        features: {
          ...state.features,
          loading: false,
          total: action.payload.total,
          data: action.payload.features
        }
      }
    case FETCH_FEATURES_REJECTED:
      return {
        ...state,
        features: {
          ...state.features,
          loading: true
        }
      }

    default:
      return state
  }
}
