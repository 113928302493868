import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { getRefCodeStatus } from '@util/referrals'
import { loadReferralCodes } from '@redux/referrals/refCodes'

import editIcon from '@assets/icons/edit.svg'

import './Programs.scss'
import UpdateReferralModal from '@pages/Partners/Admin/Partners/UpdateReferralModal'

class PartnerProgramsDetails extends React.Component {
  state = {
    ready: false,
    showEditModal: false
  }

  constructor (props) {
    super(props)

    this.handleOpenEdit = this.handleOpenEdit.bind(this)
    this.handleCloseEdit = this.handleCloseEdit.bind(this)
  }

  componentDidMount () {
    if (this.props.partner) {
      this.loadRefCodes()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.partner &&
      this.props.partner?.id !== prevProps.partner?.id
    ) {
      this.loadRefCodes()
    }
  }

  loadRefCodes () {
    this.props.loadCodes({
      partnerId: this.props.partner.id,
      withProgram: true
    }).then(() => {
      this.setState(old => ({ ready: true }))
    })
  }

  handleOpenEdit () {
    this.setState(old => ({
      showEditModal: true
    }))
  }

  handleCloseEdit () {
    this.setState(old => ({
      showEditModal: false
    }))
  }

  render () {
    if (!this.state.ready) {
      return null
    }

    return (
      <div className='admin-pview__details admin-pview__programs'>
        <div className='admin-pview__details__top'>
          <div className='admin-pview__details__title'>
            Programs / Referral Codes
          </div>
          <div className='admin-pview__details__edit' onClick={this.handleOpenEdit}>
            <img src={editIcon} alt='edit' />
          </div>
        </div>

        {this.props.codes.map(c => (
          <div key={c.id} className='admin-pview__details__items'>
            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__header'>
                Program / Codes
              </div>
              <div className='admin-pview__details__item__value'>
                <div className='admin-pview__details__item__main'>
                  {c.program.name}
                </div>
                <div className='admin-pview__details__item__sub'>
                  {c.code} / {c.custom_code}
                </div>
              </div>
            </div>

            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__header'>
                Added
              </div>
              <div className='admin-pview__details__item__value'>
                {DateTime.fromISO(c.created_at).toFormat('LLL d, y')}
              </div>
            </div>
            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__header'>
                Ref. Link
              </div>
              <div className='admin-pview__details__item__value admin-pview__programs__ref-url'>
                <a href={`${import.meta.env.VITE_SITE_URL}/?ref=${c.custom_code}`} rel='noopener noreferrer'>
                  {`?ref=${c.custom_code}`}
                </a>
              </div>
            </div>
            <div className='admin-pview__details__item'>
              <div className='admin-pview__details__item__header'>
                Status
              </div>
              <div className='admin-pview__details__item__value'>
                {getRefCodeStatus(this.props.partner.status)}
              </div>
            </div>
          </div>
        ))}

        <UpdateReferralModal
          show={this.state.showEditModal}
          partner={this.props.partner}
          onClose={this.handleCloseEdit}
        />
      </div>
    )
  }
}

PartnerProgramsDetails.propTypes = {
  partner: PropTypes.object,
  loading: PropTypes.bool,
  loadCodes: PropTypes.func,
  codes: PropTypes.array
}

const mapStateToProps = ({ referrals: { refCodes } }) => ({
  codes: refCodes.data,
  loading: refCodes.loading
})

const mapDispatchToProps = dispatch => ({
  loadCodes: payload => dispatch(loadReferralCodes(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(PartnerProgramsDetails)
