import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { loadPartnerUsers } from '@redux/referrals/partners.js'
import { loadInvites, resendInvite } from '@redux/account.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import ListRow from '@components/ListRow/index.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import DotMenu from '@components/DotMenu/index.jsx'
import DropdownList from '@components/DropdownList/index.jsx'
import Button from '@components/Button/index.jsx'

import UpsertUserModal from '@containers/Partners/Users/UpsertUserModal.jsx'
import DeleteUserModal from '@containers/Partners/Users/DeleteUserModal.jsx'
import SendInviteModal from '@containers/Partners/UserInvites/SendInviteModal.jsx'
import DeleteInviteModal from '@containers/Partners/UserInvites/DeleteInviteModal.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'

const rolesFromScopes = scopes => {
  const roles = []

  if (scopes.includes('partner:write')) {
    roles.push('General (Full)')
  } else if (scopes.includes('partner:read')) {
    roles.push('General (Read-only)')
  }

  if (scopes.includes('partner:billing:write')) {
    roles.push('Billing (Full)')
  } else if (scopes.includes('partner:billing:read')) {
    roles.push('Billing (Read-only)')
  }

  if (scopes.includes('partner:themes:write')) {
    roles.push('Theme Reviews (Full)')
  } else if (scopes.includes('partner:themes:read')) {
    roles.push('Theme Reviews (Read-only)')
  }

  if (scopes.includes('partner:apps:write')) {
    roles.push('App Reviews (Full)')
  } else if (scopes.includes('partner:apps:read')) {
    roles.push('App Reviews (Read-only)')
  }

  return roles
}

const pageLimit = 6

export default function Team () {
  const [invites, setInvites] = useState([])
  const [users, setUsers] = useState([])
  const [tab, setTab] = useState('users')

  const [userEditModal, setUserEditModal] = useState('')
  const [deleteUserModal, setDeleteUserModal] = useState(false)
  const [selectedUser, setSelectedUser] = useState(null)
  const [sendInviteModal, setSendInviteModal] = useState(false)
  const [deleteInviteModal, setDeleteInviteModal] = useState(false)
  const [selectedInvite, setSelectedInvite] = useState(null)

  const [
    partner,
    initialUsers,
    usersLoading,
    initialInvites,
    invitesLoading
  ] = useSelector(({ referrals: { partners }, account }) => [
    partners.partner.data,
    partners.users.data,
    partners.users.loading,
    account.invites.data,
    account.invites.loading
  ])

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    if (tab !== 'users' || !partner?.id) {
      return
    }

    _loadUsers()
  }, [tab, partner?.id])
  useEffect(() => {
    if (tab !== 'invites' || !partner?.id) {
      return
    }

    _loadInvites()
  }, [tab, partner?.id])

  useEffect(() => {
    setInvites(initialInvites.map(invite => ({
      id: invite.id,
      email: invite.email,
      scopes: invite.scopes,
      status: invite.status,
      roles: rolesFromScopes(invite.scopes)
    })))
  }, [partner?.id, initialInvites])

  useEffect(() => {
    setUsers(initialUsers.map(user => {
      const isOwner = user.scopes.includes('partner:owner')

      return {
        id: user.id,
        name: user.name,
        email: user.email,
        scopes: user.scopes,
        is_owner: isOwner,
        role: isOwner ? 'Owner' : 'Member',
        roles: rolesFromScopes(user.scopes)
      }
    }))
  }, [partner?.id, initialUsers])

  function _loadUsers () {
    dispatch(loadPartnerUsers({
      partnerId: partner.id,
      withScopes: true,
      limit: pageLimit,
      offset: 0
    }))
      .catch(error => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Unable to load list of users. Please try again later.'
        })
        console.log('unable load list of users:', error)
      })
  }

  function _loadInvites () {
    dispatch(loadInvites({
      limit: pageLimit,
      offset: 0
    }))
      .catch(error => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Unable to load list of invites. Please try again later.'
        })
        console.log('Unable to load list of invites', error)
      })
  }

  function handleUserOptions ({ option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        setUserEditModal('edit')
        setSelectedUser(option.value)
        break
      case 'remove':
        setDeleteUserModal(true)
        setSelectedUser(option.value)
        break
    }
  }

  function handleCloseEditUserModal () {
    setUserEditModal('')
    setSelectedUser(null)
  }

  function handleCloseDeleteUserModal () {
    setDeleteUserModal(false)
    setSelectedUser(null)
  }

  function handleInviteOptions ({ option, onClose }) {
    onClose()

    switch (option.key) {
      case 'resend':
        handleResendInvite(option.value.id)
        break
      case 'remove':
        setDeleteInviteModal(true)
        setSelectedInvite(option.value)
        break
    }
  }

  function handleCloseSendInviteModal () {
    setSendInviteModal(false)
  }

  function handleCloseDeleteInviteModal () {
    setDeleteInviteModal(false)
    setSelectedInvite(null)
  }

  function handleResendInvite (inviteId) {
    dispatch(resendInvite({
      inviteId
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Invite resent',
          message: 'Invite has been resent successfully.'
        })
      })
      .catch(error => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Unable to resend invite. Please try again later.'
        })
        console.log('unable to resend invite:', error)
      })
  }

  return (
    <div className='settings_page__section'>
      <UpsertUserModal
        open={userEditModal}
        onClose={handleCloseEditUserModal}
        onSuccess={handleCloseEditUserModal}
        user={selectedUser}
      />
      <DeleteUserModal
        open={deleteUserModal}
        onClose={handleCloseDeleteUserModal}
        onSuccess={handleCloseDeleteUserModal}
        user={selectedUser}
        hideNote
      />
      <SendInviteModal
        open={sendInviteModal}
        onClose={handleCloseSendInviteModal}
        onSuccess={handleCloseSendInviteModal}
      />
      <DeleteInviteModal
        open={deleteInviteModal}
        onClose={handleCloseDeleteInviteModal}
        onSuccess={handleCloseDeleteInviteModal}
        invite={selectedInvite}
      />

      <div className='settings_page__section__header'>
        <div className='settings_page__section__title'>
          Team members
        </div>
      </div>

      <div className='settings_page__team__options'>
        <div className='settings_page__team__tabs'>
          <a
            href='#'
            className={classnames('settings_page__team__tab', {
              'settings_page__team__tab--active': tab === 'users'
            })}
            onClick={event => {
              event.preventDefault()
              setTab('users')
            }}
          >
            Members
          </a>
          <a
            href='#'
            className={classnames('settings_page__team__tab', {
              'settings_page__team__tab--active': tab === 'invites'
            })}
            onClick={event => {
              event.preventDefault()
              setTab('invites')
            }}
          >
            Invites
          </a>
        </div>

        <Button
          className='settings_page__team__invite'
          small
          primary
          onClick={event => {
            event.preventDefault()
            setTab('invites')
            setSendInviteModal(true)
          }}
        >
          Send invite
        </Button>
      </div>

      <div
        className={classnames('settings_page__team__boxes', {
          'settings_page__team__boxes--show': tab === 'invites'
        })}
      >
        {invites.map(invite => (
          <ShadowBox key={invite.id} className='settings_page__team__box'>
            <div className='settings_page__team__box__email'>
              <h4>Email</h4>
              {invite.email}
            </div>

            <div className='settings_page__team__box__permissions'>
              <h4>Permissions</h4>
              <div>
                {invite.roles.join(', ')}
              </div>
            </div>

            <div className='settings_page__team__box__status'>
              <h4>Status</h4>
              <span className='settings_page__team__box__sub'>{invite.status}</span>
            </div>

            {invite.status !== 'accepted' && (
              <div className='settings_page__team__box__options'>
                <DropdownList
                  onClickOption={handleInviteOptions}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'resend',
                    label: 'Resend invite',
                    value: invite
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: invite
                  }]}
                />
              </div>
            )}
          </ShadowBox>
        ))}
      </div>

      <ListRow
        empty={invitesLoading && invites.length === 0}
        className={classnames('settings_page__teammates', {
          'settings_page__teammates--show': tab === 'invites'
        })}
        loading={invitesLoading}
        headers={(
          <>
            <ListRowHeader className='settings_page__teammate__info'>
              Email/Status
            </ListRowHeader>
            <ListRowHeader className='settings_page__teammate__permissions'>
              Permissions
            </ListRowHeader>
            <ListRowHeader className='settings_page__teammate__action'>
              &nbsp;
            </ListRowHeader>
          </>
        )}
        items={invites.map(invite => (
          <ListRowItem key={invite.id} className='settings_page__teammate'>
            <ListRowColumn className='settings_page__teammate__info'>
              <div className='settings_page__teammate__name'>{invite.email}</div>
              <div className='settings_page__teammate__email settings_page__teammate__status--cap'>
                {invite.status}
              </div>
            </ListRowColumn>
            <ListRowColumn className='settings_page__teammate__permissions'>
              <div className='settings_page__teammate__scopes'>
                {invite.roles.map(p => (
                  <div key={p}>{p}</div>
                ))}
              </div>
            </ListRowColumn>
            <ListRowColumn className='settings_page__teammate__action'>
              {invite.status !== 'accepted' && (
                <DropdownList
                  onClickOption={handleInviteOptions}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'resend',
                    label: 'Resend invite',
                    value: invite
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: invite
                  }]}
                />
              )}
            </ListRowColumn>
          </ListRowItem>
        ))}
      />

      <div
        className={classnames('settings_page__team__boxes', {
          'settings_page__team__boxes--show': tab === 'users'
        })}
      >
        {users.map(user => (
          <ShadowBox key={user.id} className='settings_page__team__box'>
            <div className='settings_page__team__box__email'>
              <h4>Email</h4>
              {user.name} - <span className='settings_page__team__box__role'>{user.role}</span>
              <br />
              <span className='settings_page__team__box__sub'>{user.email}</span>
            </div>

            <div className='settings_page__team__box__permission'>
              <h4>Permissions</h4>
              <div>
                {user.roles.join(', ')}
              </div>
            </div>

            {!user.is_owner && (
              <div className='settings_page__team__box__options'>
                <DropdownList
                  onClickOption={handleUserOptions}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => {
                    return [{
                      key: 'edit',
                      label: 'Edit',
                      value: user
                    }, {
                      key: 'remove',
                      label: 'Remove',
                      value: user
                    }]
                  }}
                />
              </div>
            )}
          </ShadowBox>
        ))}
      </div>
      <ListRow
        empty={usersLoading && users.length === 0}
        className={classnames('settings_page__teammates', {
          'settings_page__teammates--show': tab === 'users'
        })}
        loading={usersLoading}
        headers={(
          <>
            <ListRowHeader className='settings_page__teammate__info'>
              Teammate
            </ListRowHeader>
            <ListRowHeader className='settings_page__teammate__permissions'>
              Permissions
            </ListRowHeader>
            <ListRowHeader className='settings_page__teammate__action'>
              &nbsp;
            </ListRowHeader>
          </>
        )}
        items={users.map(user => {
          const isOwner = user.scopes.includes('partner:owner')

          return (
            <ListRowItem key={user.id} className='settings_page__teammate'>
              <ListRowColumn className='settings_page__teammate__info'>
                <div className='settings_page__teammate__name'>
                  {user.name} - <span className='settings_page__teammate__role'>{user.role}</span>
                </div>
                <div className='settings_page__teammate__email'>{user.email}</div>
              </ListRowColumn>
              <ListRowColumn className='settings_page__teammate__permissions'>
                <div className='settings_page__teammate__scopes'>
                  {user.roles.map(p => (
                    <div key={p}>{p}</div>
                  ))}
                </div>
              </ListRowColumn>
              <ListRowColumn className='settings_page__teammate__action'>
                {!isOwner && (
                  <DropdownList
                    onClickOption={handleUserOptions}
                    right
                    activator={({ onClick }) => <DotMenu onClick={onClick} />}
                    options={() => {
                      return [{
                        key: 'edit',
                        label: 'Edit',
                        value: user
                      }, {
                        key: 'remove',
                        label: 'Remove',
                        value: user
                      }]
                    }}
                  />
                )}
              </ListRowColumn>
            </ListRowItem>
          )
        })}
      />
    </div>
  )
}
