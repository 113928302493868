export const makePartnerScopes = (workspace, scopes, role, checked) => {
  const values = [role]

  if (role.includes(':write') && checked) {
    values.push(role.replace(':write', ':read'))
  } else if (role.includes(':read') && !checked) {
    values.push(role.replace(':read', ':write'))
  }

  scopes = scopes.filter(f => !values.includes(f))

  if (checked) {
    scopes = scopes.concat(values)
  }

  if (
    workspace?.type === 'partner' && workspace.scopes.includes('partner:owner') &&
    !scopes.includes('partner:read')
  ) {
    scopes = scopes.concat(['partner:read'])
  }

  return scopes
}

export const makePartnerAvailableRoles = (workspace, scopes) => {
  let roles = []
  scopes = [...scopes]

  if (!scopes.includes('partner:read')) {
    scopes = scopes.concat(['partner:read'])
  }

  if (workspace?.type === 'referral_account') {
    roles = roles.concat([{
      name: 'Owner (only one per account.)',
      items: [['partner:owner', 'Yes']]
    }])
  }

  if (
    workspace?.type === 'referral_account' ||
    (workspace?.type === 'partner' && workspace.scopes.includes('partner:owner'))
  ) {
    roles = roles.concat([{
      name: 'General',
      items: [['partner:read', 'Read'], ['partner:write', 'Write']]
    }, {
      name: 'Billing',
      items: [['partner:billing:read', 'Read'], ['partner:billing:write', 'Write']]
    }])
  }

  // account admin or partner admin
  if (
    workspace?.type === 'referral_account' || (
      workspace?.type === 'partner' &&
      workspace.scopes.includes('partner:owner') &&
      workspace.scopes.includes('partner:themes:read')
    )) {
    roles.push({
      name: 'Theme Reviews',
      items: [['partner:themes:read', 'Read'], ['partner:themes:write', 'Write']]
    })
  }

  if (
    workspace?.type === 'referral_account' || (
      workspace?.type === 'partner' &&
      workspace.scopes.includes('partner:owner') &&
      workspace.scopes.includes('partner:apps:read')
    )) {
    roles.push({
      name: 'App Reviews',
      items: [['partner:apps:read', 'Read'], ['partner:apps:write', 'Write']]
    })
  }

  return {
    roles,
    scopes
  }
}
