import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_APP_CATEGORIES_PENDING = 'apps/FETCH_APP_CATEGORIES_PENDING'
const FETCH_APP_CATEGORIES_FULFILLED = 'apps/FETCH_APP_CATEGORIES_FULFILLED'
const FETCH_APP_CATEGORIES_REJECTED = 'apps/FETCH_APP_CATEGORIES_REJECTED'

const ADD_APP_CATEGORY_PENDING = 'apps/ADD_APP_CATEGORY_PENDING'
const ADD_APP_CATEGORY_FULFILLED = 'apps/ADD_APP_CATEGORY_FULFILLED'
const ADD_APP_CATEGORY_REJECTED = 'apps/ADD_APP_CATEGORY_REJECTED'

const UPDATE_APP_CATEGORY_PENDING = 'apps/UPDATE_APP_CATEGORY_PENDING'
const UPDATE_APP_CATEGORY_FULFILLED = 'apps/UPDATE_APP_CATEGORY_FULFILLED'
const UPDATE_APP_CATEGORY_REJECTED = 'apps/UPDATE_APP_CATEGORY_REJECTED'

const DELETE_APP_CATEGORY_PENDING = 'apps/DELETE_APP_CATEGORY_PENDING'
const DELETE_APP_CATEGORY_FULFILLED = 'apps/DELETE_APP_CATEGORY_FULFILLED'
const DELETE_APP_CATEGORY_REJECTED = 'apps/DELETE_APP_CATEGORY_REJECTED'

export const fetchAppCategories = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_APP_CATEGORIES_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/app-category-tree'), {
      params: {
        flat_root: payload.flatRoot ? '1' : '0'
      }
    })

    const data = {
      categories: response.data
    }

    dispatch({ type: FETCH_APP_CATEGORIES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_APP_CATEGORIES_REJECTED })
    throw error
  }
}

export const addAppCategory = payload => async dispatch => {
  try {
    dispatch({ type: ADD_APP_CATEGORY_PENDING })

    const response = await axios.post(getRefApiUrl('v1', '/app-categories'), payload.data)

    const data = {
      category: response.data
    }

    dispatch({ type: ADD_APP_CATEGORY_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_APP_CATEGORY_REJECTED })
    throw error
  }
}

export const updateAppCategory = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_APP_CATEGORY_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/app-categories/${payload.id}`),
      payload.data
    )

    const data = {
      category: response.data
    }

    dispatch({ type: UPDATE_APP_CATEGORY_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_APP_CATEGORY_REJECTED })
    throw error
  }
}

export const deleteAppCategory = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_APP_CATEGORY_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/app-categories/${payload.id}`)
    )

    dispatch({ type: DELETE_APP_CATEGORY_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_APP_CATEGORY_REJECTED })
    throw error
  }
}

const initialState = {
  hydrated: false,
  list: {
    loading: false,
    data: []
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_APP_CATEGORIES_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_APP_CATEGORIES_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.payload.categories
        }
      }
    case FETCH_APP_CATEGORIES_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }
    default:
      return state
  }
}
