import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import checkIcon from '@assets/images/checkbox-check.svg'

import './CheckBox.scss'

const CheckBox = ({ className, checked, onChange, inline, disabled }) => (
  <div
    className={classnames('hc-checkbox', className, {
      'hc-checkbox--checked': checked,
      'hc-checkbox--disabled': disabled,
      'hc-checkbox--inline': inline
    })}
    onClick={() => !disabled && onChange(!checked)}
  >
    <img
      className={classnames('hc-checkbox__icon', {
        'hc-checkbox__icon--hide': !checked
      })}
      src={checkIcon}
      alt='check'
    />
  </div>
)

CheckBox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.any, // preferably bool
  disabled: PropTypes.any, // preferably bool
  onChange: PropTypes.func,
  inline: PropTypes.bool
}

export default CheckBox
