import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

import CheckBox from '@components/Inputs/CheckBox.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'
import Button from '@components/Button/index.jsx'

import ThemeWidget from '@heycarson/widgets/dist/theme.js'
import '@heycarson/widgets/dist/theme.css'

import snippet from './snippet.js'

export default function ReviewWidgets ({ themeSlug }) {
  const themeRel = useRef(null)
  const themeWidget = useRef(null)
  const [publicKey, setPublicKey] = useState('')

  const [dark, setDark] = useState(true)

  useEffect(() => {
    if (!themeRel.current || !themeSlug || themeWidget.current || !publicKey) {
      return
    }

    themeWidget.current = new ThemeWidget({
      endpoint: import.meta.env.VITE_PARTNERS_API_URL,
      element: themeRel.current,
      type: 'theme',
      slug: themeSlug,
      apiKey: publicKey
    })

    return () => {
      if (themeWidget.current) {
        themeWidget.current.destroy()
        themeWidget.current = null
      }
    }
  }, [themeSlug, publicKey])

  useEffect(() => {
    if (!themeWidget.current || !themeRel.current || !publicKey) {
      return
    }

    themeWidget.current.render({
      apiKey: publicKey,
      light: !dark
    })
  }, [themeWidget.current, dark, publicKey])

  useEffect(() => {
    if (!themeSlug) {
      return
    }

    loadApiKey()
  }, [themeSlug])

  async function loadApiKey () {
    try {
      const res = await axios.get(getRefApiUrl('v1', '/widget-api-key'))

      setPublicKey(res.data.public_key)
    } catch (error) {
      console.log('error loading api key', error)

      if (error?.response?.status === 404) {
        createApiKey()
      }
    }
  }

  async function createApiKey () {
    try {
      const res = await axios.post(getRefApiUrl('v1', '/widget-api-key'))
      setPublicKey(res.data.public_key)
    } catch (error) {
      console.log('error creating api key', error)
    }
  }

  function handleCopy () {
    try {
      navigator.clipboard.writeText(output)
    } catch (error) {
      console.log('error copying snippet', error)
    }
  }

  const output = snippet
    .replace('RATING_FROM', 'themes')
    .replace('YOUR_API_KEY', publicKey || 'YOUR_API_KEY')
    .replace('\'LIGHT_THEME\'', dark ? 'false' : 'true')
    .replace('THEME_SLUG', themeSlug)

  return (
    <>
      <div className='pr-review__widgets__dark-mode'>
        <span>Dark mode:</span> <CheckBox checked={dark} onChange={() => setDark(!dark)} />
      </div>

      <div className='pr-review__widgets__body'>
        <div ref={themeRel} />
      </div>

      <ShadowBox className='pr-review__widgets__snippet'>
        <div className='pr-review__widgets__snippet__title'>
          HTML Snippet.
        </div>
        <div className='pr-review__widgets__snippet__copy'>
          <Button small primary onClick={handleCopy}>Copy</Button>
        </div>

        <pre className='pr-review__widgets__snippet__code'>
          {output}
        </pre>

        <div className='pr-review__widgets__snippet__info'>
          Find docs. and more examples&nbsp;
          <a
            href='https://www.npmjs.com/package/@heycarson/widgets'
            target='_blank'
            rel='noreferrer noopener'
          >
            here.
          </a>
        </div>
      </ShadowBox>
    </>
  )
}

ReviewWidgets.propTypes = {
  themeSlug: PropTypes.string
}
