import React from 'react'

import PartnersList from '@pages/Partners/Admin/Partners/List'

class AdminPartners extends React.Component {
  render () {
    return (
      <PartnersList />
    )
  }
}

export default AdminPartners
