import React from 'react'

import ShadowBox from '@components/ShadowBox/index.jsx'

import examples from './examples.jsx'

export default function ExampleSection () {
  return (
    <section className='p-resources-page__section'>
      <div className='p-resources-page__section__top'>
        <div className='p-resources-page__section__sub-title'>A few examples</div>
      </div>

      <div className='p-resources-page__section__description'>
        We value partnerships like these ones, and we go out our way everyday as a team to make sure we drive as
        much or more value back toward our partners.
      </div>

      <div className='p-resources-page__section__split' />

      <div className='p-resources-page__examples'>
        {examples.map(ex => (
          <ShadowBox key={ex.title} className='p-resources-page__example'>
            <div className='p-resources-page__example__top'>
              <div className='p-resources-page__example__logo'>
                <img src={ex.image} alt={ex.title} />
              </div>
              <div className='p-resources-page__example__title'>
                {ex.title}
              </div>
            </div>

            {ex.description}
          </ShadowBox>
        ))}
      </div>
    </section>
  )
}
