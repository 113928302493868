import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import classnames from 'classnames'
import { matchPath, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { clearTheme, fetchTheme } from '@redux/themes.js'
import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import ContentPadding from '@components/ContentPadding/index.jsx'
import ThemeHero from '@pages/Partners/Partner/Theme/Hero.jsx'
import ReviewList from '@pages/Partners/Partner/Theme/List.jsx'
import Questions from '@pages/Partners/Partner/Theme/Questions/index.jsx'
import ReviewInvites from '@pages/Partners/Partner/Theme/Invites.jsx'
import ReviewWidgets from '@pages/Partners/Partner/Theme/Widgets.jsx'

import './Theme.scss'

export default function ThemePage () {
  let tab = 'invites'
  const [theme] = useSelector(({ themes }) => [themes.view.data])

  const params = useParams()
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const location = useLocation()

  const match = matchPath({ path: '/reviews/:id/:tab' }, location.pathname)
  if (match?.params?.tab) {
    tab = match.params.tab
  }

  useBreadcrumbs({
    label: 'Reviews',
    to: '/reviews'
  })
  useBreadcrumbs({
    label: theme?.name || '',
    to: `/reviews/${params.id}`
  })

  useEffect(() => {
    if (!params.id) {
      return
    }

    dispatch(fetchTheme({
      id: params.id
    }))

    return () => {
      dispatch(clearTheme())
    }
  }, [params.id])

  return (
    <>
      <Helmet>
        <title>{theme?.name || ''} | Theme Reviews</title>
      </Helmet>

      <ContentPadding>
        <div className='reviews-page'>
          <ThemeHero
            theme={theme}
            themeId={theme?.id}
          />
          <div className='theme-page__tabs'>
            <button
              className={classnames('theme-page__button', {
                'theme-page__button--active': tab === 'invites'
              })}
              onClick={() => navigate('')}
            >
              Get reviews
            </button>
            <button
              className={classnames('theme-page__button', {
                'theme-page__button--active': tab === 'list'
              })}
              onClick={() => navigate('list')}
            >
              Replies
            </button>
            <button
              className={classnames('theme-page__button', {
                'theme-page__button--active': tab === 'questions'
              })}
              onClick={() => navigate('questions')}
            >
              Q&A
            </button>
            <button
              className={classnames('theme-page__button', {
                'theme-page__button--active': tab === 'widgets'
              })}
              onClick={() => navigate('widgets')}
            >
              Widget
            </button>
          </div>

          <Routes>
            <Route index element={<ReviewInvites themeId={theme?.id} />} />
            <Route path='list' element={<ReviewList themeId={theme?.id} />} />
            <Route path='questions' element={<Questions theme={theme} />} />
            <Route path='widgets' element={<ReviewWidgets themeSlug={theme?.slug} />} />
          </Routes>
        </div>
      </ContentPadding>
    </>
  )
}
