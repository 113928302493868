import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ModalHeader = ({ children, className, onClose }) => (
  <div className={classnames('hc-modal__header', className)}>
    {children}

    {onClose && (
      <div className='hc-modal__close' onClick={onClose}>
        &times;
      </div>
    )}
  </div>
)

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
}

export default React.memo(ModalHeader)
