import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Tooltip.scss'

const pointerSize = 12

const generatePosition = (currentElement) => {
  if (!currentElement || !window) {
    return {
      top: 0,
      left: 0
    }
  }

  const rect = currentElement.getBoundingClientRect()

  return {
    top: rect.top + (window.scrollY || document.documentElement.scrollTop),
    left: rect.left + ((window.scrollX || document.documentElement.scrollLeft))
  }
}

const getScreenSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight
  }
}

class TooltipC extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      hasWindow: false
    }

    this.unmounted = false
    this.resizeTimeout = null

    this.positionInterval = null
    this.intervalCount = 0
    this.positions = generatePosition()
    this.pointerElement = null
    this.listeningResize = false

    this.portalElement = document.createElement('div')
    this.portalElement.classList.toggle('hc-tooltip')

    if (props.customClassName) {
      this.portalElement.classList.toggle(props.customClassName)
    }

    this.onScreenResize = this.onScreenResize.bind(this)
  }

  componentDidMount () {
    if (!window) {
      return
    }

    document.body.append(this.portalElement)

    this.setState(old => ({
      ...old,
      hasWindow: true
    }))

    if (this.props.active) {
      this.setByInterval(20)

      window && window.addEventListener('resize', this.onScreenResize)

      this.listeningResize = true
    }

    this.portalElement.addEventListener('mouseover', this.props.onMouseEnter)
    this.portalElement.addEventListener('mouseout', this.props.onMouseLeave)
  }

  componentDidUpdate (oldProps) {
    if (!window) {
      return
    }

    clearInterval(this.positionInterval)

    if (this.props.active !== oldProps.active) {
      if (this.props.active && !this.listeningResize) {
        window && window.addEventListener('resize', this.onScreenResize)

        this.listeningResize = true
      } else if (this.listeningResize) {
        window && window.removeEventListener('resize', this.onScreenResize)

        this.listeningResize = false
      }
    }

    if (this.props.active) {
      this.setByInterval(10)
    } else if (!this.props.active) {
      this.setDisplay(false)
      this.positions = generatePosition()
    }
  }

  componentWillUnmount () {
    if (!window) {
      return
    }

    this.unmounted = true

    clearInterval(this.positionInterval)

    this.portalElement.removeEventListener('mouseover', this.props.onMouseEnter)
    this.portalElement.removeEventListener('mouseout', this.props.onMouseLeave)

    document.body.removeChild(this.portalElement)

    if (this.listeningResize) {
      window && window.removeEventListener('resize', this.onScreenResize)
      this.listeningResize = false
    }
  }

  setByInterval (intervals = 20) {
    intervals = 0 // TODO: REMOVE
    clearInterval(this.positionInterval)

    if (this.unmounted) {
      return
    }

    this.intervalCount = 0

    if (this.pointerElement && this.portalElement) {
      this.positions = generatePosition(this.props.element)

      this.setDisplay(true)
      this.setPositions(this.positions)
    }

    this.positionInterval = setInterval(() => {
      if (this.unmounted) {
        clearInterval(this.positionInterval)
        return
      }

      if (!this.pointerElement) {
        return
      }

      const oldPos = this.positions
      this.positions = generatePosition(this.props.element)

      this.setDisplay(true)
      this.setPositions(this.positions)

      if (this.intervalCount > 4 && this.isSamePosition(oldPos, this.positions)) {
        clearInterval(this.positionInterval)
      }

      if (this.intervalCount > intervals) {
        clearInterval(this.positionInterval)
      }

      this.intervalCount++
    }, 500)
  }

  onScreenResize () {
    clearTimeout(this.resizeTimeout)

    if (!this.props.active) {
      this.resizeTimeout = setTimeout(() => {
        this.setByInterval(5)
      }, 100)
    }
  }

  setPositions (elementPosition) {
    let top = elementPosition.top
    let left = elementPosition.left
    let maxWidth
    // let maxHeight

    this.portalElement.style.top = 'unset'
    this.portalElement.style.left = 'unset'
    this.portalElement.style.width = 'unset'
    this.portalElement.style.height = 'unset'

    // position tooltip relative to element
    top = top - this.portalElement.offsetHeight - 16

    let clipRight = false
    let clipLeft = false

    const screenSize = getScreenSize()
    const availableLeft = elementPosition.left
    const availableRight = screenSize.width - elementPosition.left

    if (this.portalElement.offsetWidth >= screenSize.width) {
      maxWidth = screenSize.width
    }
    if (this.portalElement.offsetHeight >= screenSize.height) {
      // maxHeight = screenSize.height
    }

    this.portalElement.style.width = maxWidth ? (maxWidth - 24) + 'px' : undefined
    // this.portalElement.style.height = maxHeight ? (maxHeight - 12) + 'px' : undefined

    clipRight = (this.portalElement.offsetWidth / 2) > availableRight
    clipLeft = (this.portalElement.offsetWidth / 2) > availableLeft

    if (clipLeft) {
      left = 12
    } else if (clipRight) {
      left = screenSize.width - this.portalElement.offsetWidth - 12
    } else {
      // center
      left = left - (this.portalElement.offsetWidth / 2) + (this.props.element.offsetWidth / 2)
    }

    // position tooltip
    this.portalElement.style.top = top + 'px'
    this.portalElement.style.left = left + 'px'

    // position pointer relative to tooltip
    this.pointerElement.style.bottom = 'unset'
    this.pointerElement.style.top = 'unset'
    this.pointerElement.style.left = 'unset'
    this.pointerElement.style.right = 'unset'

    if (this.props.top) {
      this.pointerElement.style.bottom = `-${pointerSize / 2}px`
    } else {
      this.pointerElement.style.top = `-${pointerSize / 2}px`
    }

    const pointerLeft = (elementPosition.left - left)

    this.pointerElement.style.left = pointerLeft + 'px'
  }

  isSamePosition (a, b) {
    return a.top === b.top && a.left === b.left
  }

  setDisplay (active) {
    this.portalElement && this.portalElement.classList.toggle('hc-tooltip--active', active || false)
  }

  render () {
    if (!this.state.hasWindow) {
      return null
    }

    const { children, customClassName } = this.props

    return ReactDOM.createPortal(
      <>
        <div
          className={classnames('hc-tooltip__pointer', customClassName + '__pointer')}
          ref={dom => (this.pointerElement = dom)}
        />
        <div
          className={classnames('hc-tooltip__content', customClassName + '__content')}
          ref={dom => (this.contentElement = dom)}
        >
          {children}
        </div>
      </>,
      this.portalElement
    )
  }
}

TooltipC.propTypes = {
  customClassName: PropTypes.string,

  children: PropTypes.node,
  element: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,

  top: PropTypes.bool,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool
}

export default TooltipC
