import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_PROFILES_PENDING = 'developers/FETCH_PROFILES_PENDING'
const FETCH_PROFILES_FULFILLED = 'developers/FETCH_PROFILES_FULFILLED'
const FETCH_PROFILES_REJECTED = 'developers/FETCH_PROFILES_REJECTED'

const UPDATE_PROFILE_PENDING = 'developers/UPDATE_PROFILE_PENDING'
const UPDATE_PROFILE_FULFILLED = 'developers/UPDATE_PROFILE_FULFILLED'
const UPDATE_PROFILE_REJECTED = 'developers/UPDATE_PROFILE_REJECTED'

const FETCH_DASH_OVERVIEW_PENDING = 'developers/FETCH_DASH_OVERVIEW_PENDING'
const FETCH_DASH_OVERVIEW_FULFILLED = 'developers/FETCH_DASH_OVERVIEW_FULFILLED'
const FETCH_DASH_OVERVIEW_REJECTED = 'developers/FETCH_DASH_OVERVIEW_REJECTED'

const FETCH_DASH_STATS_PENDING = 'developers/FETCH_DASH_STATS_PENDING'
const FETCH_DASH_STATS_FULFILLED = 'developers/FETCH_DASH_STATS_FULFILLED'
const FETCH_DASH_STATS_REJECTED = 'developers/FETCH_DASH_STATS_REJECTED'

const FETCH_DASH_APP_OVERVIEW_PENDING = 'developers/FETCH_DASH_APP_OVERVIEW_PENDING'
const FETCH_DASH_APP_OVERVIEW_FULFILLED = 'developers/FETCH_DASH_APP_OVERVIEW_FULFILLED'
const FETCH_DASH_APP_OVERVIEW_REJECTED = 'developers/FETCH_DASH_APP_OVERVIEW_REJECTED'

const FETCH_DASH_APP_STATS_PENDING = 'developers/FETCH_DASH_APP_STATS_PENDING'
const FETCH_DASH_APP_STATS_FULFILLED = 'developers/FETCH_DASH_APP_STATS_FULFILLED'
const FETCH_DASH_APP_STATS_REJECTED = 'developers/FETCH_DASH_APP_STATS_REJECTED'

export const fetchProfiles = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_PROFILES_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/developer-profiles'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        with_capabilities: payload.withCapabilities ? '1' : undefined,
        with_capability: payload.withCapability
      }
    })

    const data = {
      total: response.data.total,
      profiles: response.data.data
    }

    dispatch({ type: FETCH_PROFILES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_PROFILES_REJECTED })
    throw error
  }
}

export const updateProfile = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_PROFILE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/developer-profiles/${payload.id}`),
      payload.data
    )

    const data = {
      profile: response.data
    }

    dispatch({ type: UPDATE_PROFILE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_PROFILE_REJECTED, payload: error })
    throw error
  }
}

export const fetchDashOverview = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_DASH_OVERVIEW_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/dashboard/themes-overview'), {
      params: {
        theme_id: payload.themeId || undefined
      }
    })

    const data = {
      stats: response.data.data,
      hash: payload.hash
    }

    dispatch({ type: FETCH_DASH_OVERVIEW_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_DASH_OVERVIEW_REJECTED })
    throw error
  }
}

export const fetchDashStats = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_DASH_STATS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/dashboard/themes-stats'), {
      params: {
        theme_id: payload.themeId || undefined,
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined
      }
    })

    const data = {
      stats: response.data.data,
      hash: payload.hash
    }

    dispatch({ type: FETCH_DASH_STATS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_DASH_STATS_REJECTED })
    throw error
  }
}

export const fetchDashAppOverview = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_DASH_APP_OVERVIEW_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/dashboard/apps-overview'), {
      params: {
        app_id: payload.appId || undefined
      }
    })

    const data = {
      stats: response.data.data,
      hash: payload.hash
    }

    dispatch({ type: FETCH_DASH_APP_OVERVIEW_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_DASH_APP_OVERVIEW_REJECTED })
    throw error
  }
}

export const fetchDashAppStats = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_DASH_APP_STATS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/dashboard/apps-stats'), {
      params: {
        app_id: payload.appId || undefined,
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined
      }
    })

    const data = {
      stats: response.data.data,
      hash: payload.hash
    }

    dispatch({ type: FETCH_DASH_APP_STATS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_DASH_APP_STATS_REJECTED })
    throw error
  }
}

const initialState = {
  list: {
    loading: false,
    total: 0,
    data: []
  },
  dashOverview: {
    hash: '',
    data: [],
    loading: false
  },
  dashStats: {
    hash: '',
    data: [],
    loading: false
  },
  dashAppOverview: {
    hash: '',
    data: [],
    loading: false
  },
  dashAppStats: {
    hash: '',
    data: [],
    loading: false
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_PROFILES_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_PROFILES_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.profiles
        }
      }
    case FETCH_PROFILES_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }

    case FETCH_DASH_OVERVIEW_PENDING:
      return {
        ...state,
        dashOverview: {
          ...state.dashOverview,
          loading: true
        }
      }
    case FETCH_DASH_OVERVIEW_FULFILLED:
      return {
        ...state,
        dashOverview: {
          ...state.dashOverview,
          loading: false,
          hash: action.payload.hash,
          data: action.payload.stats
        }
      }
    case FETCH_DASH_OVERVIEW_REJECTED:
      return {
        ...state,
        dashOverview: {
          ...state.dashOverview,
          loading: false
        }
      }

    case FETCH_DASH_STATS_PENDING:
      return {
        ...state,
        dashStats: {
          ...state.dashStats,
          loading: true
        }
      }
    case FETCH_DASH_STATS_FULFILLED:
      return {
        ...state,
        dashStats: {
          ...state.dashStats,
          loading: false,
          hash: action.payload.hash,
          data: action.payload.stats
        }
      }
    case FETCH_DASH_STATS_REJECTED:
      return {
        ...state,
        dashStats: {
          ...state.dashStats,
          loading: false
        }
      }

    case UPDATE_PROFILE_PENDING:
      return {
        ...state,
        submitting: true
      }
    case UPDATE_PROFILE_FULFILLED:
      return {
        ...state,
        submitting: false,
        list: {
          ...state.list,
          data: state.list.data.map(profile => {
            if (profile.id === action.payload.profile.id) {
              return { ...profile, ...action.payload.profile }
            }

            return profile
          })
        }
      }
    case UPDATE_PROFILE_REJECTED:
      return {
        ...state,
        submitting: false
      }
    default:
      return state
  }
}
