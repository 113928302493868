import React from 'react'
import PropTypes from 'prop-types'

import TooltipC from '@components/Tooltip/TooltipC'

class Tooltip extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ready: false,
      active: props.active,
      propActive: props.active
    }

    this.element = null
    this.leaveTimeout = null

    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.domRef = this.domRef.bind(this)
  }

  static getDerivedStateFromProps (props, state) {
    if (props.active !== state.propActive) {
      return {
        active: props.active,
        propActive: props.active
      }
    }

    return null
  }

  componentDidMount () {
    this.setState(old => ({ ...old, ready: true }))

    this.startListen()
  }

  componentWillUnmount () {
    this.stopListen()
  }

  startListen () {
    if (!this.element) {
      return
    }

    this.element.addEventListener('mouseover', this.handleMouseEnter)
    this.element.addEventListener('mouseout', this.handleMouseLeave)
  }

  stopListen () {
    if (!this.element) {
      return
    }

    this.element.removeEventListener('mouseover', this.handleMouseEnter)
    this.element.removeEventListener('mouseout', this.handleMouseLeave)
  }

  domRef (dom) {
    this.stopListen()

    this.element = dom

    this.startListen()
  }

  handleMouseEnter () {
    clearTimeout(this.leaveTimeout)

    this.setState(old => ({ ...old, active: true }))
  }

  handleMouseLeave () {
    clearTimeout(this.leaveTimeout)

    this.leaveTimeout = setTimeout(() => {
      this.setState(old => ({ ...old, active: false }))
    }, 50)
  }

  render () {
    const { activator: Activator } = this.props

    return (
      <>
        <Activator
          elRef={this.domRef}
        />

        {this.state.ready && (
          <TooltipC
            customClassName={this.props.className}
            active={this.state.active}
            element={this.element}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            top={this.props.top}
            bottom={this.props.bottom}
            left={this.props.left}
            right={this.props.right}
            center={this.props.center}
          >
            {this.props.children}
          </TooltipC>
        )}
      </>
    )
  }
}

Tooltip.propTypes = {
  active: PropTypes.bool,
  activator: PropTypes.any.isRequired,
  activatorProps: PropTypes.object,
  className: PropTypes.string,

  top: PropTypes.bool,
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  center: PropTypes.bool
}

Tooltip.defaultProps = {
  wrapper: 'div'
}

export default Tooltip
