import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { loadBalance, toggleAffiliateModal } from '@redux/referrals/withdrawals.js'
import { signOut } from '@redux/account.js'

import AffiliateTopBar from '@components/Partners/Topbar/index.jsx'

const AffiliateTopBarContainer = (props) => {
  const { width } = props
  const [open, setOpen] = useState(false)

  useEffect(() => {
    props.loadBalance()
  }, [])

  function handleClickProfile () {
    setOpen(!open)
  }

  function handleCloseProfile (event) {
    setOpen(false)
  }

  function handleSignOut (event) {
    event.preventDefault()
    setOpen(false)

    props.signOut().then(() => {
      window.location.href = '/login'
    })
  }

  return (
    <AffiliateTopBar
      balance={props.balance}
      toggleModal={props.toggleModal}
      user={props.user}
      openDropdown={open && width > 769}
      openMenu={open && width <= 769}
      onClickProfile={handleClickProfile}
      onCloseProfile={handleCloseProfile}
      width={props.width}
      onClickOverlay={handleCloseProfile}
      onSignOut={handleSignOut}
    />
  )
}

AffiliateTopBarContainer.propTypes = {
  balance: PropTypes.string,
  loadBalance: PropTypes.func,
  toggleModal: PropTypes.func,
  user: PropTypes.object,
  width: PropTypes.number
}

const mapStateToProps = ({ referrals: { withdrawals }, account, viewport }) => ({
  balance: withdrawals.balance,
  user: account.user,
  width: viewport.width
})
const mapDispatchToProps = dispatch => ({
  loadBalance: () => dispatch(loadBalance()),
  toggleModal: open => dispatch(toggleAffiliateModal(open)),
  signOut: () => dispatch(signOut())
})

const ConnectedAffiliateTopBarContainer = connect(mapStateToProps, mapDispatchToProps)(AffiliateTopBarContainer)

export default ConnectedAffiliateTopBarContainer
