import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { deleteGalleryItem } from '@redux/apps.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function DeleteGalleryItemModal ({ show, appId, galleryItem, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    if (!galleryItem) {
      return
    }

    dispatch(
      deleteGalleryItem({
        appId,
        id: galleryItem.id
      })
    )
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        console.error('error deleting gallery item', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Delete app gallery item
      </Header>
      <Body>
        Are you sure you want to delete this gallery item?
      </Body>
      <Footer className='pda-app-view__style-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteGalleryItemModal.propTypes = {
  themeId: PropTypes.string,
  style: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
