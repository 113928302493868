import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_APPS_PENDING = 'apps/FETCH_APPS_PENDING'
const FETCH_APPS_FULFILLED = 'apps/FETCH_APPS_FULFILLED'
const FETCH_APPS_REJECTED = 'apps/FETCH_APPS_REJECTED'

const FETCH_APP_PENDING = 'apps/FETCH_APP_PENDING'
const FETCH_APP_FULFILLED = 'apps/FETCH_APP_FULFILLED'
const FETCH_APP_REJECTED = 'apps/FETCH_APP_REJECTED'

const ADD_APP_PENDING = 'apps/ADD_APP_PENDING'
const ADD_APP_FULFILLED = 'apps/ADD_APP_FULFILLED'
const ADD_APP_REJECTED = 'apps/ADD_APP_REJECTED'

const UPDATE_APP_PENDING = 'apps/UPDATE_APP_PENDING'
const UPDATE_APP_FULFILLED = 'apps/UPDATE_APP_FULFILLED'
const UPDATE_APP_REJECTED = 'apps/UPDATE_APP_REJECTED'

const FETCH_FEATURES_PENDING = 'apps/FETCH_FEATURES_PENDING'
const FETCH_FEATURES_FULFILLED = 'apps/FETCH_FEATURES_FULFILLED'
const FETCH_FEATURES_REJECTED = 'apps/FETCH_FEATURES_REJECTED'

const FETCH_GALLERY_ITEMS_PENDING = 'apps/FETCH_GALLERY_ITEMS_PENDING'
const FETCH_GALLERY_ITEMS_FULFILLED = 'apps/FETCH_GALLERY_ITEMS_FULFILLED'
const FETCH_GALLERY_ITEMS_REJECTED = 'apps/FETCH_GALLERY_ITEMS_REJECTED'

const FETCH_PRICING_PLANS_PENDING = 'apps/FETCH_PRICING_PLANS_PENDING'
const FETCH_PRICING_PLANS_FULFILLED = 'apps/FETCH_PRICING_PLANS_FULFILLED'
const FETCH_PRICING_PLANS_REJECTED = 'apps/FETCH_PRICING_PLANS_REJECTED'

const ADD_FEATURE_PENDING = 'apps/ADD_FEATURE_PENDING'
const ADD_FEATURE_FULFILLED = 'apps/ADD_FEATURE_FULFILLED'
const ADD_FEATURE_REJECTED = 'apps/ADD_FEATURE_REJECTED'
const UPDATE_FEATURE_PENDING = 'apps/UPDATE_FEATURE_PENDING'
const UPDATE_FEATURE_FULFILLED = 'apps/UPDATE_FEATURE_FULFILLED'
const UPDATE_FEATURE_REJECTED = 'apps/UPDATE_FEATURE_REJECTED'
const DELETE_FEATURE_PENDING = 'apps/DELETE_FEATURE_PENDING'
const DELETE_FEATURE_FULFILLED = 'apps/DELETE_FEATURE_FULFILLED'
const DELETE_FEATURE_REJECTED = 'apps/DELETE_FEATURE_REJECTED'

const ADD_GALLERY_ITEM_PENDING = 'apps/ADD_GALLERY_ITEM_PENDING'
const ADD_GALLERY_ITEM_FULFILLED = 'apps/ADD_GALLERY_ITEM_FULFILLED'
const ADD_GALLERY_ITEM_REJECTED = 'apps/ADD_GALLERY_ITEM_REJECTED'
const UPDATE_GALLERY_ITEM_PENDING = 'apps/UPDATE_GALLERY_ITEM_PENDING'
const UPDATE_GALLERY_ITEM_FULFILLED = 'apps/UPDATE_GALLERY_ITEM_FULFILLED'
const UPDATE_GALLERY_ITEM_REJECTED = 'apps/UPDATE_GALLERY_ITEM_REJECTED'
const DELETE_GALLERY_ITEM_PENDING = 'apps/DELETE_GALLERY_ITEM_PENDING'
const DELETE_GALLERY_ITEM_FULFILLED = 'apps/DELETE_GALLERY_ITEM_FULFILLED'
const DELETE_GALLERY_ITEM_REJECTED = 'apps/DELETE_GALLERY_ITEM_REJECTED'

const ADD_PRICING_PLAN_PENDING = 'apps/ADD_PRICING_PLAN_PENDING'
const ADD_PRICING_PLAN_FULFILLED = 'apps/ADD_PRICING_PLAN_FULFILLED'
const ADD_PRICING_PLAN_REJECTED = 'apps/ADD_PRICING_PLAN_REJECTED'
const UPDATE_PRICING_PLAN_PENDING = 'apps/UPDATE_PRICING_PLAN_PENDING'
const UPDATE_PRICING_PLAN_FULFILLED = 'apps/UPDATE_PRICING_PLAN_FULFILLED'
const UPDATE_PRICING_PLAN_REJECTED = 'apps/UPDATE_PRICING_PLAN_REJECTED'
const DELETE_PRICING_PLAN_PENDING = 'apps/DELETE_PRICING_PLAN_PENDING'
const DELETE_PRICING_PLAN_FULFILLED = 'apps/DELETE_PRICING_PLAN_FULFILLED'
const DELETE_PRICING_PLAN_REJECTED = 'apps/DELETE_PRICING_PLAN_REJECTED'

const CLEAR_APP = 'apps/CLEAR_APP'

export const fetchApps = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_APPS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/apps'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined,
        developer_id: payload.developerId || undefined,
        with_developer: payload.withDeveloper ? '1' : undefined,
        with_rating: payload.withRating ? '1' : undefined,
        with_similar_apps: payload.withSimilarApps ? '1' : undefined
      }
    })

    const data = {
      total: response.data.total,
      apps: response.data.data
    }

    dispatch({ type: FETCH_APPS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_APPS_REJECTED })
    throw error
  }
}

export const addApp = payload => async dispatch => {
  try {
    dispatch({ type: ADD_APP_PENDING })

    const response = await axios.post(getRefApiUrl('v1', '/apps'), payload.data)

    const data = {
      app: response.data
    }

    dispatch({ type: ADD_APP_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_APP_REJECTED })
    throw error
  }
}

export const updateApp = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_APP_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.id}`),
      payload.data
    )

    const data = {
      app: response.data
    }

    dispatch({ type: UPDATE_APP_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_APP_REJECTED })
    throw error
  }
}

export const fetchApp = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_APP_PENDING })

    const response = await axios.get(getRefApiUrl('v1', `/apps/${payload.id || payload.slug}`), {
      params: {
        slug: payload.slug ? '1' : undefined,
        with_developer: payload.withDeveloper ? '1' : undefined,
        with_rating: payload.withRating ? '1' : undefined,
        with_features: payload.withFeatures ? '1' : undefined,
        with_similar_apps: payload.withSimilarApps ? '1' : undefined,
        with_categories: payload.withCategories ? '1' : undefined
      }
    })

    const data = {
      app: response.data
    }

    dispatch({ type: FETCH_APP_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_APP_REJECTED })
    throw error
  }
}

export const fetchAppFeatures = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_FEATURES_PENDING, payload: { clean: payload.clean } })

    const response = await axios.get(
      getRefApiUrl('v1', `/apps/${payload.id}/features`),
      {
        params: {
          slug: payload.slug ? 1 : undefined
        }
      }
    )

    const data = {
      total: response.data.total,
      features: response.data.data
    }

    dispatch({ type: FETCH_FEATURES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_FEATURES_REJECTED })
    throw error
  }
}

export const addAppFeature = payload => async dispatch => {
  try {
    dispatch({ type: ADD_FEATURE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/features`),
      payload.data
    )

    const data = {
      feature: response.data
    }

    dispatch({ type: ADD_FEATURE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_FEATURE_REJECTED })
    throw error
  }
}

export const updateAppFeature = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_FEATURE_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/features/${payload.id}`),
      payload.data
    )

    const data = {
      feature: response.data
    }

    dispatch({ type: UPDATE_FEATURE_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_FEATURE_REJECTED })
    throw error
  }
}

export const deleteAppFeature = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_FEATURE_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/apps/${payload.appId}/features/${payload.id}`)
    )

    dispatch({ type: DELETE_FEATURE_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_FEATURE_REJECTED })
    throw error
  }
}

export const fetchAppGalleryItems = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_GALLERY_ITEMS_PENDING, payload: { clean: payload.clean } })

    const response = await axios.get(
      getRefApiUrl('v1', `/apps/${payload.id}/gallery`),
      {
        params: {
          slug: payload.slug ? 1 : undefined
        }
      }
    )

    const data = {
      total: response.data.total,
      galleryItems: response.data.data
    }

    dispatch({ type: FETCH_GALLERY_ITEMS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_GALLERY_ITEMS_REJECTED })
    throw error
  }
}

export const addGalleryItem = payload => async dispatch => {
  try {
    dispatch({ type: ADD_GALLERY_ITEM_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/gallery`),
      payload.data
    )

    const data = {
      galleryItem: response.data
    }

    dispatch({ type: ADD_GALLERY_ITEM_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_GALLERY_ITEM_REJECTED })
    throw error
  }
}

export const updateGalleryItem = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_GALLERY_ITEM_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/gallery/${payload.id}`),
      payload.data
    )

    const data = {
      galleryItem: response.data
    }

    dispatch({ type: UPDATE_GALLERY_ITEM_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_GALLERY_ITEM_REJECTED })
    throw error
  }
}

export const deleteGalleryItem = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_GALLERY_ITEM_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/apps/${payload.appId}/gallery/${payload.id}`)
    )

    dispatch({ type: DELETE_GALLERY_ITEM_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_GALLERY_ITEM_REJECTED })
    throw error
  }
}

export const fetchAppPricingPlans = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_PRICING_PLANS_PENDING, payload: { clean: payload.clean } })

    const response = await axios.get(
      getRefApiUrl('v1', `/apps/${payload.id}/pricing`),
      {
        params: {
          slug: payload.slug ? 1 : undefined
        }
      }
    )

    const data = {
      total: response.data.total,
      pricingPlans: response.data.data
    }

    dispatch({ type: FETCH_PRICING_PLANS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_PRICING_PLANS_REJECTED })
    throw error
  }
}

export const addPricingPlan = payload => async dispatch => {
  try {
    dispatch({ type: ADD_PRICING_PLAN_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/pricing`),
      payload.data
    )

    const data = {
      pricingPlan: response.data
    }

    dispatch({ type: ADD_PRICING_PLAN_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_PRICING_PLAN_REJECTED })
    throw error
  }
}

export const updatePricingPlan = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_PRICING_PLAN_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/apps/${payload.appId}/pricing/${payload.id}`),
      payload.data
    )

    const data = {
      pricingPlan: response.data
    }

    dispatch({ type: UPDATE_PRICING_PLAN_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_PRICING_PLAN_REJECTED })
    throw error
  }
}

export const deletePricingPlan = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_PRICING_PLAN_PENDING })

    await axios.delete(
      getRefApiUrl('v1', `/apps/${payload.appId}/pricing/${payload.id}`)
    )

    dispatch({ type: DELETE_PRICING_PLAN_FULFILLED, payload: { id: payload.id } })
  } catch (error) {
    dispatch({ type: DELETE_PRICING_PLAN_REJECTED })
    throw error
  }
}

export const clearApp = () => dispatch => ({
  type: CLEAR_APP
})

// initial state
const initialState = {
  hydrated: false,
  list: {
    total: 0,
    loading: false,
    data: []
  },
  app: {
    data: null,
    loading: false
  },
  features: {
    total: 0,
    loading: false,
    data: []
  },
  galleryItems: {
    total: 0,
    loading: false,
    data: []
  },
  pricingPlans: {
    total: 0,
    loading: false,
    data: []
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_APPS_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_APPS_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.apps
        }
      }
    case FETCH_APPS_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }

    case FETCH_APP_PENDING:
      return {
        ...state,
        app: {
          ...state.app,
          loading: true
        }
      }
    case FETCH_APP_FULFILLED:
      return {
        ...state,
        app: {
          ...state.app,
          loading: false,
          data: action.payload.app
        }
      }
    case FETCH_APP_REJECTED:
      return {
        ...state,
        app: {
          ...state.app,
          loading: false
        }
      }
    case CLEAR_APP:
      return {
        ...state,
        app: {
          ...state.app,
          ...initialState.app
        }
      }
    case FETCH_FEATURES_PENDING:
      return {
        ...state,
        features: {
          ...state.features,
          loading: true,
          data: action.payload?.clean ? [] : state.features.data
        }
      }
    case FETCH_FEATURES_FULFILLED:
      return {
        ...state,
        features: {
          ...state.features,
          loading: false,
          total: action.payload.total,
          data: action.payload.features
        }
      }
    case FETCH_FEATURES_REJECTED:
      return {
        ...state,
        features: {
          ...state.features,
          loading: true
        }
      }
    case FETCH_GALLERY_ITEMS_PENDING:
      return {
        ...state,
        galleryItems: {
          ...state.galleryItems,
          loading: true,
          data: action.payload?.clean ? [] : state.galleryItems.data
        }
      }
    case FETCH_GALLERY_ITEMS_FULFILLED:
      return {
        ...state,
        galleryItems: {
          ...state.galleryItems,
          loading: false,
          total: action.payload.total,
          data: action.payload.galleryItems
        }
      }
    case FETCH_GALLERY_ITEMS_REJECTED:
      return {
        ...state,
        galleryItems: {
          ...state.galleryItems,
          loading: true
        }
      }
    case FETCH_PRICING_PLANS_PENDING:
      return {
        ...state,
        pricingPlans: {
          ...state.pricingPlans,
          loading: true,
          data: action.payload?.clean ? [] : state.pricingPlans.data
        }
      }
    case FETCH_PRICING_PLANS_FULFILLED:
      return {
        ...state,
        pricingPlans: {
          ...state.pricingPlans,
          loading: false,
          total: action.payload.total,
          data: action.payload.pricingPlans
        }
      }
    case FETCH_PRICING_PLANS_REJECTED:
      return {
        ...state,
        features: {
          ...state.pricingPlans,
          loading: true
        }
      }

    default:
      return state
  }
}
