import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleBackLogin, toggleBackWebsite } from '@redux/account.js'

export const useBackToWebsite = () => {
  const dispatch = useDispatch()

  const show = useSelector(({ account }) => account.backToWebsite)
  const setShow = useCallback(payload => {
    dispatch(toggleBackWebsite(payload))
  }, [show])

  return [show, setShow]
}

export const useBackToLogin = () => {
  const dispatch = useDispatch()

  const show = useSelector(({ account }) => account.backToLogin)
  const setShow = useCallback(payload => {
    dispatch(toggleBackLogin(payload))
  }, [show])

  return [show, setShow]
}
