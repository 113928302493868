import React, { useState, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'

import InvitesSent from '@pages/Partners/Partner/App/InvitesSent.jsx'

import timeIcon from '@assets/icons/time.svg'
import giftIcon from '@assets/icons/gift.svg'
import messageIcon from '@assets/icons/message.svg'

const InvitesForm = lazy(() => import('@pages/Partners/Partner/App/InvitesForm.jsx'))

export default function ReviewInvites ({ appId }) {
  const [listHash, setListHash] = useState(nanoid(4))

  function handleSent () {
    setListHash(nanoid(4))
  }

  return (
    <div className='par-invites'>
      <Suspense fallback={null}>
        <InvitesForm appId={appId} onSent={handleSent} />
      </Suspense>
      <div className='par-invites__features'>
        <div className='par-invites__feature'>
          <img src={timeIcon} alt='time' />
          <p className='par-invites__feature__desc'>
            Send the request <strong>within a few hours</strong> of completing a positive support ticket.
          </p>
        </div>
        <div className='par-invites__feature'>
          <img src={giftIcon} alt='gift' />
          <p className='par-invites__feature__desc'>
            Offer a <strong>cash/gift incentive</strong> for the customer’s time. We use&nbsp;
            <a href='https://www.tremendous.com/' rel='noopener noreferrer nofollow' target='_blank'>Tremendous.</a>
          </p>
        </div>
        <div className='par-invites__feature'>
          <img src={messageIcon} alt='message' />
          <p className='par-invites__feature__desc'>
            Reply to all reviews to <strong>show your engagement</strong> and appreciation and future reviewers will
            notice.
          </p>
        </div>
      </div>

      <InvitesSent
        appId={appId}
        listHash={listHash}
      />
    </div>
  )
}

ReviewInvites.propTypes = {
  appId: PropTypes.string
}
