import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import { changeAvatar, updateSelfUser } from '@redux/account.js'

import ShadowBox from '@components/ShadowBox/index.jsx'
import Button from '@components/Button/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import ChangePasswordModal from '@containers/PartnerDashboard/ChangePassword/index.jsx'

import defaultAvatar from '@assets/images/default-avatar.png'
import { loadPartner, updatePartner } from '@redux/referrals/partners.js'

const initialUFields = {
  name: '',
  email: ''
}
const initialCFields = {
  name: '',
  phone: ''
}

export default function UserInfo () {
  const [userFields, setUserFields] = useState(initialUFields)
  const [companyFields, setCompanyFields] = useState(initialCFields)

  const uploadRef = useRef(null)

  const [passwordModal, setPasswordModal] = useState(false)

  const [user, workspace, partner, avatarLoading] = useSelector(({ account, referrals: { partners } }) => [
    account.user,
    account.workspace,
    partners.partner.data,
    account.avatarLoading
  ])
  const avatar = user?.avatar || defaultAvatar

  const dispatch = useDispatch()
  const toastr = useToastr()

  const isOwner = workspace.scopes.includes('partner:owner')

  useEffect(() => {
    if (!workspace?.id) {
      return
    }

    dispatch((loadPartner({
      id: workspace.id
    })))
      .catch(error => {
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Unable to load partner.'
        })
        console.log('load partner error', error)
      })
  }, [workspace?.id])
  useEffect(() => {
    if (!partner?.id) {
      return
    }

    setCompanyFields({
      name: partner.name || '',
      phone: partner.phone || ''
    })
  }, [partner?.id, partner?.updated_at])

  useEffect(() => {
    if (!user?.id) {
      return
    }

    setUserFields({
      ...userFields,
      name: user.name || '',
      email: user.email || ''
    })
  }, [user?.id, user?.updated_at])

  function handleChangeFile (event) {
    const files = event.currentTarget.files

    if (!files.length) {
      return
    }

    const formData = new window.FormData()

    formData.append('avatar', files[0])

    dispatch(changeAvatar({
      data: formData
    }))
      .then(() => {
        uploadRef.current.value = ''
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Avatar has been updated.'
        })
      })
      .catch((error) => {
        uploadRef.current.value = ''
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.message
        ) {
          message = error.response.data.message
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
        console.log('upload avatar error:', error)
      })
  }

  function handleClickUpload () {
    if (!uploadRef.current) {
      return
    }

    uploadRef.current.click()
  }

  function handleSubmitPartner () {
    dispatch(updatePartner({
      id: workspace.id,
      ...partner,
      phone: companyFields.phone
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Partner has been updated.'
        })
      })
      .catch((error) => {
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.message
        ) {
          message = error.response.data.message
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
        console.log('update partner error:', error)
      })
  }

  function handleSubmitUser () {
    dispatch(updateSelfUser({
      id: user.id,
      ...user,
      name: userFields.name
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'User has been updated.'
        })
      })
      .catch((error) => {
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.message
        ) {
          message = error.response.data.message
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
        console.log('update user error:', error)
      })
  }

  return (
    <div className='settings_page__user-info'>
      <ChangePasswordModal
        show={passwordModal}
        onClose={() => setPasswordModal(false)}
      />

      <div className='settings_page__section'>
        <div className='settings_page__section__header'>
          <div className='settings_page__section__title'>
            Account Information
          </div>
          <div className='settings_page__section__action'>
            <Button small primary hollow onClick={handleSubmitPartner}>
              Save
            </Button>
          </div>
        </div>

        <ShadowBox className='settings_page__section__body'>
          <div className='settings_page__input-container'>
            <TextInput
              small
              labelClassName='settings_page__input-label'
              disabled
              label='Company Name'
              value={companyFields.name}
              readOnly
            />

            <TextInput
              small
              labelClassName='settings_page__input-label'
              disabled={!isOwner}
              label='Phone Number'
              placeholder='1234567890'
              value={companyFields.phone}
              onChange={event => setCompanyFields({ ...companyFields, phone: event.currentTarget.value })}
            />
          </div>
        </ShadowBox>
      </div>

      <div className='settings_page__section'>
        <div className='settings_page__section__header'>
          <div className='settings_page__section__title'>
            Personal Information
          </div>
          {isOwner && (
            <div className='settings_page__section__action'>
              <Button small primary hollow onClick={handleSubmitUser}>
                Save
              </Button>
            </div>
          )}
        </div>

        <ShadowBox className='settings_page__section__body'>
          <div className='settings_page__user-info__avatar'>
            <img
              className='settings_page__user-info__avatar__image'
              src={avatar}
              alt='avatar'
            />
            <input
              ref={uploadRef}
              type='file' accept='image/*'
              name='avatar'
              style={{ display: 'none' }}
              onChange={handleChangeFile}
            />
            <Button secondary onClick={handleClickUpload} disabled={avatarLoading}>
              Change avatar
            </Button>
          </div>

          <div className='settings_page__input-container settings_page__input-container--bottom'>
            <TextInput
              small
              labelClassName='settings_page__input-label'
              label='Name'
              placeholder='John'
              value={userFields.name}
              onChange={event => setUserFields({ ...userFields, name: event.currentTarget.value })}
            />
            <TextInput
              small
              disabled
              labelClassName='settings_page__input-label'
              label='Email'
              placeholder='johndoe@gmail.com'
              value={userFields.email}
              readOnly
            />
          </div>

          <div className='settings_page__user-info__password'>
            <div className='settings_page__user-info__password__title'>
              Password
            </div>
            <div className='settings_page__user-info__password__description'>
              Change the password you use to login to PartnerDashboard.
            </div>
            <div className='settings_page__user-info__password__action'>
              <Button
                secondary
                onClick={() => setPasswordModal(true)}
              >
                Change Password
              </Button>
            </div>
          </div>
        </ShadowBox>
      </div>
    </div>
  )
}
