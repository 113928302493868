import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateApp } from '@redux/apps.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function DeleteCategoryModal ({ show, app, category, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    if (!category) {
      return
    }

    // filter out the category
    const categories = app.categories
      .map(c => c.slug)
      .filter(c => c !== category.slug)

    const data = new window.FormData()

    Object.entries({
      categories,

      name: app.name,
      description: app.description,
      tagline: app.tagline || '',
      meta_title: app.app_meta_title || '',
      meta_description: app.app_meta_description || '',
      price: app.price || '',
      developer_id: app.developer_id || '',
      status: app.status || 'unpublished',
      url: app.url || '',
      filters: app.filters.length > 0 ? app.filters.join(',') : '',
      languages: app.languages.length > 0 ? app.languages.join(',') : '',
      similar_apps: app.similar_apps.length > 0 ? app.similar_apps.map(s => s.slug || s).join(',') : '',
      is_free: app.pricing_is_free ? '1' : '0',
      has_free_plan: app.pricing_has_free_plan ? '1' : '0',
      has_trial: app.pricing_has_trial ? '1' : '0',
      trial: app.pricing_trial || ''
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    dispatch(
      updateApp({
        id: app?.id,
        data
      })
    )
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        console.error('error deleting category', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Delete app category
      </Header>
      <Body>
        Are you sure you want to delete [{category?.name}] category?
      </Body>
      <Footer className='pda-app-view__category-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteCategoryModal.propTypes = {
  app: PropTypes.object,
  category: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
