import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchApp, fetchAppFeatures, fetchAppGalleryItems, fetchAppPricingPlans } from '@redux/apps.js'

import Meta from '@pages/Partners/Admin/Apps/Meta'
import ShadowBox from '@components/ShadowBox/index.jsx'
import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'

import './View.scss'

export default function AppView () {
  const [ready, setReady] = useState(false)

  const [
    app,
    features,
    totalFeatures,
    galleryItems,
    totalGalleryItems,
    pricingPlans,
    totalPricingPlans
  ] = useSelector(({ apps }) => [
    apps.app.data,
    apps.features.data,
    apps.features.total,
    apps.galleryItems.data,
    apps.galleryItems.total,
    apps.pricingPlans.data,
    apps.pricingPlans.total
  ])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.id) {
      return
    }

    loadApp({
      id: params.id
    })
  }, [params.id])

  function loadApp ({ id }) {
    dispatch(fetchApp({
      id,
      withSimilarApps: true,
      withCategories: true
    }))
      .then(({ app }) => {
        setReady(true)
        loadFeatures(app.id)
        loadGalleryItems(app.id)
        loadPricingPlans(app.id)
      })
      .catch(error => {
        console.error('error view app: ', error)
      })
  }

  function loadFeatures (appId) {
    dispatch(
      fetchAppFeatures({ id: appId, clean: true })
    )
      .catch(error => {
        console.error('error loading app features: ', error)
      })
  }

  function loadGalleryItems (appId) {
    dispatch(
      fetchAppGalleryItems({ id: appId, clean: true })
    )
      .catch(error => {
        console.error('error loading app gallery items: ', error)
      })
  }

  function loadPricingPlans (appId) {
    dispatch(
      fetchAppPricingPlans({ id: appId, clean: true })
    )
      .catch(error => {
        console.error('error loading app pricing plans: ', error)
      })
  }

  if (!ready || !app) {
    return null
  }

  return (
    <div className='pda-app-view'>
      <div className='pda-app-view__title'>App: {app.name}</div>

      <ShadowBox className='pda-app-view__main'>
        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column'>
            <strong>Thumbnail:</strong>
            <br />
            <a
              href={app.icon_url}
              target='_blank'
              rel='noreferrer noopener'
            >
              <img className='pda__app-img-preview' src={app.icon_url} alt='Thumbnail Image' />
            </a>
          </div>
        </div>

        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column'>
            <strong>Name:</strong>
            <br />
            {app.name}
          </div>
          <div className='pda-app-view__main__column'>
            <strong>Slug:</strong>
            <br />
            {app.slug}
          </div>
        </div>

        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column'>
            <strong>Price:</strong>
            <br />
            {app.pricing_has_free_plan ? 'Free plan available' : 'Paid plans available'}
            {app.pricing_has_trial ? `${app.trial} trial` : ''}
          </div>
          <div className='pda-app-view__main__column'>
            <strong>Visibility:</strong>
            <br />
            {app.status}
          </div>
        </div>

        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column pda-app-view__main__column--full'>
            <strong>Tagline:</strong>
            <br />
            {app.tagline}
          </div>
        </div>

        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column pda-app-view__main__column--full'>
            <strong>Description:</strong>
            <br />
            {app.description}
          </div>
        </div>

        <div className='pda-app-view__main__row'>
          <div className='pda-app-view__main__column'>
            <strong>Free App:</strong>
            <br />
            {app.pricing_is_free ? 'Yes' : 'No'}
          </div>
          <div className='pda-app-view__main__column'>
            <strong>Has Free Plans:</strong>
            <br />
            {app.pricing_has_free_plan ? 'Yes' : 'No'}
          </div>
          <div className='pda-app-view__main__column'>
            <strong>Has Trial:</strong>
            <br />
            {app.pricing_has_trial ? 'Yes' : 'No'}
          </div>
          <div className='pda-app-view__main__column'>
            <strong>Trial Period:</strong>
            <br />
            {app.pricing_trial || 'N/A'}
          </div>
        </div>

      </ShadowBox>

      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Filters (read-only)
        </div>
        <ListRow
          empty={!app.filters.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__filter__name'>Filter</ListRowHeader>
            </>
          )}
          items={app.filters.map((filter, i) => (
            <ListRowItem key={i}>
              <ListRowColumn className='pda-app-view__filter__name'>
                {filter}
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>

      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Languages (read-only)
        </div>
        <ListRow
          empty={!app.languages.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__lanuages__name'>Language</ListRowHeader>
            </>
          )}
          items={app.languages.map((language, i) => (
            <ListRowItem key={i}>
              <ListRowColumn className='pda-app-view__languages__name'>
                {language}
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>

      <Meta
        app={app}
        totalFeatures={totalFeatures}
        features={features}
        galleryItems={galleryItems}
        totalGalleryItems={totalGalleryItems}
        pricingPlans={pricingPlans}
        totalPricingPlans={totalPricingPlans}
        loadFeatures={loadFeatures}
        loadGalleryItems={loadGalleryItems}
        loadPricingPlans={loadPricingPlans}
        loadApp={loadApp}
      />

      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Similar Apps (read-only)
        </div>
        <ListRow
          empty={!app.similar_apps.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__similar-apps__name'>Name</ListRowHeader>
            </>
          )}
          items={app.similar_apps.map((similarApp) => (
            <ListRowItem key={similarApp.id}>
              <ListRowColumn className='pda-app-view__similar-apps__name'>
                {similarApp.name}
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>

    </div>
  )
}
