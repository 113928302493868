import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { loadClicks } from '@redux/referrals/clicks'

import CursorPagination from '@components/Pagination/Cursor'

import './ClickList.scss'

function ClickListContainer (props) {
  const [dates, setDates] = useState({ before: null, after: null })

  useEffect(() => {
    let referralIds = ''

    if (props.referralIds?.length > 0) {
      referralIds = props.referralIds.join(',')
    }

    props.loadClicks({
      limit: props.limit,
      before: dates.before,
      after: dates.after,
      referralIds,
      withPartner: true,
      withCustomer: true
    })
      .catch(error => {
        console.log('@loadClicks error', error)
      })
  }, [dates.before, dates.after, props.referralIds])

  function handlePageChange ({ before, after }) {
    return new Promise(resolve => {
      setDates({ before, after })
      resolve()
    })
  }

  return (
    <div className='custlist'>
      <div className='aclklist__headers'>
        <div className='aclklist__header aclklist__header--partner'>Partner</div>
        <div className='aclklist__header aclklist__header--referral'>Referral</div>
        <div className='aclklist__header aclklist__header--date'>Date</div>
      </div>
      <div className='aclklist__items'>
        {props.items.map(click => (
          <div key={click.id} className='aclklist__item'>
            <div className='aclklist__column aclklist__column--partner'>
              {click.partner.name}
            </div>

            <div className='aclklist__column aclklist__column--referral'>
              {click.customer?.name || 'N/A'}
            </div>
            <div className='aclklist__column aclklist__column--date'>
              {DateTime.fromISO(click.created_at).toFormat('dd MMM, y HH:mm')}
            </div>
          </div>
        ))}
      </div>

      <div className='aclklist__pagination'>
        <CursorPagination
          direction='next'
          items={props.items}
          cursor='created_at'
          onPageChange={handlePageChange}
          showDetails
          pageLimit={props.limit}
          totalResults={props.totalResults}
          remainingResults={props.remainingResults}
        />
      </div>
    </div>
  )
}

ClickListContainer.propTypes = {
  limit: PropTypes.number,
  loadClicks: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number,
  referralIds: PropTypes.array
}

const mapStateToProps = ({ referrals: { clicks } }) => ({
  loading: clicks.loading,
  items: clicks.data,
  totalResults: clicks.totalResults,
  remainingResults: clicks.remainingResults
})

const mapDispatchToProps = dispatch => ({
  loadClicks: payload => dispatch(loadClicks(payload))
})

const ConnectedClickList = connect(mapStateToProps, mapDispatchToProps)(ClickListContainer)

export default ConnectedClickList
