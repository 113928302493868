import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { changeViewport } from '@redux/viewport'

const Viewport = (props) => {
  const timeoutRef = useRef(null)

  useEffect(() => {
    function onResize () {
      clearTimeout(timeoutRef.current)

      timeoutRef.current = setTimeout(changeViewport, 65)
    }

    changeViewport()

    window && window.addEventListener('resize', onResize)

    return () => {
      clearTimeout(timeoutRef.current)
      window && window.removeEventListener('resize', onResize)
    }
  }, [])

  function changeViewport () {
    // assume mobile
    if (!window) {
      props.changeViewport({
        width: 360,
        height: 640
      })

      return
    }

    props.changeViewport({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }

  return props.children || null
}

Viewport.propTypes = {
  changeViewport: PropTypes.func.isRequired,
  children: PropTypes.node
}

const mapDispatchToProps = dispatch => ({
  changeViewport: payload => dispatch(changeViewport(payload))
})

export default connect(null, mapDispatchToProps)(Viewport)
