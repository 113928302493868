import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_PARTNER_COUNT_PENDING = 'referrals/dashboard/LOAD_PARTNER_COUNT_PENDING'
const LOAD_PARTNER_COUNT_FULFILLED = 'referrals/dashboard/LOAD_PARTNER_COUNT_FULFILLED'
const LOAD_PARTNER_COUNT_REJECTED = 'referrals/dashboard/LOAD_PARTNER_COUNT_REJECTED'

const LOAD_ACCOUNT_STATS_PENDING = 'referrals/dashboard/LOAD_ACCOUNT_STATS_PENDING'
const LOAD_ACCOUNT_STATS_FULFILLED = 'referrals/dashboard/LOAD_ACCOUNT_STATS_FULFILLED'
const LOAD_ACCOUNT_STATS_REJECTED = 'referrals/dashboard/LOAD_ACCOUNT_STATS_REJECTED'

const LOAD_AGGREGATED_STATS_PENDING = 'referrals/dashboard/LOAD_AGGREGATED_STATS_PENDING'
const LOAD_AGGREGATED_STATS_FULFILLED = 'referrals/dashboard/LOAD_AGGREGATED_STATS_FULFILLED'
const LOAD_AGGREGATED_STATS_REJECTED = 'referrals/dashboard/LOAD_AGGREGATED_STATS_REJECTED'

const LOAD_PARTNER_BALANCE_PENDING = 'referrals/dashboard/LOAD_PARTNER_BALANCE_PENDING'
const LOAD_PARTNER_BALANCE_FULFILLED = 'referrals/dashboard/LOAD_PARTNER_BALANCE_FULFILLED'
const LOAD_PARTNER_BALANCE_REJECTED = 'referrals/dashboard/LOAD_PARTNER_BALANCE_REJECTED'

const LOAD_PARTNER_STATS_PENDING = 'referrals/dashboard/LOAD_PARTNER_STATS_PENDING'
const LOAD_PARTNER_STATS_FULFILLED = 'referrals/dashboard/LOAD_PARTNER_STATS_FULFILLED'
const LOAD_PARTNER_STATS_REJECTED = 'referrals/dashboard/LOAD_PARTNER_STATS_REJECTED'

export const loadPartnerCount = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNER_COUNT_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/dashboard/account:partner-count'), {
      params: {}
    })

    const total = response.data.data.find(s => s.name === 'total_partners')
    const active = response.data.data.find(s => s.name === 'active_partners')

    dispatch({
      type: LOAD_PARTNER_COUNT_FULFILLED,
      payload: {
        total: Number(total.value) || 0,
        active: Number(active.value) || 0
      }
    })
  } catch (error) {
    dispatch({
      type: LOAD_PARTNER_COUNT_REJECTED
    })
    throw error
  }
}

export const loadAccountStats = payload => async dispatch => {
  const keyName = payload.type === 'compare' ? 'compareAccountStats' : 'accountStats'

  dispatch({
    type: LOAD_ACCOUNT_STATS_PENDING,
    payload: { keyName }
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/dashboard/account'), {
      params: {
        compare: payload.type === 'compare' ? '1' : undefined,
        partner_id: payload.partnerId || undefined,
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined
      }
    })

    dispatch({
      type: LOAD_ACCOUNT_STATS_FULFILLED,
      payload: {
        stats: response.data.data,
        keyName
      }
    })
  } catch (error) {
    dispatch({
      type: LOAD_ACCOUNT_STATS_REJECTED,
      payload: { keyName }
    })
    throw error
  }
}

export const loadAggregatedStats = payload => async dispatch => {
  dispatch({
    type: LOAD_AGGREGATED_STATS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/dashboard/account:aggregate'), {
      params: {
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined,
        aggregation: payload.aggregate
      }
    })

    dispatch({
      type: LOAD_AGGREGATED_STATS_FULFILLED,
      payload: {
        data: response.data.data
      }
    })
  } catch (error) {
    dispatch({
      type: LOAD_AGGREGATED_STATS_REJECTED
    })
    throw error
  }
}

export const loadPartnerStats = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNER_STATS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/dashboard/partner'), {
      params: {
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined
      }
    })

    const data = {
      stats: response.data.data
    }

    dispatch({
      type: LOAD_PARTNER_STATS_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_PARTNER_STATS_REJECTED
    })
    throw error
  }
}

export const loadPartnerBalance = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNER_BALANCE_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/dashboard/partner-balance'), {
      params: {
        start: payload.start ? encodeURIComponent(payload.start) : undefined,
        end: payload.end ? encodeURIComponent(payload.end) : undefined
      }
    })

    const data = {
      stats: response.data.data,
      tieredAmount: Number(response.data.metadata.tiered_amount) || 0.00
    }

    dispatch({
      type: LOAD_PARTNER_BALANCE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_PARTNER_BALANCE_REJECTED
    })
    throw error
  }
}

const initialState = {
  partnerCount: {
    active: 0,
    total: 0,
    loading: false
  },
  accountStats: {
    data: [],
    loading: false
  },
  compareAccountStats: {
    data: [],
    loading: false
  },
  aggregatedStats: {
    data: [],
    loading: false
  },

  partnerBalance: {
    data: [],
    loading: false,
    tieredAmount: 0.00
  },
  partnerStats: {
    data: [],
    loading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARTNER_COUNT_PENDING:
      return {
        ...state,
        partnerCount: {
          ...state.partnerCount,
          active: 0,
          total: 0,
          loading: true
        }
      }
    case LOAD_PARTNER_COUNT_FULFILLED:
      return {
        ...state,
        partnerCount: {
          ...state.partnerCount,
          active: action.payload.active,
          total: action.payload.total,
          loading: false
        }
      }
    case LOAD_PARTNER_COUNT_REJECTED:
      return {
        ...state,
        partnerCount: {
          ...state.partnerCount,
          loading: false
        }
      }

    case LOAD_AGGREGATED_STATS_PENDING:
      return {
        ...state,
        aggregatedStats: {
          ...state.aggregatedStats,
          loading: true
        }
      }
    case LOAD_AGGREGATED_STATS_FULFILLED:
      return {
        ...state,
        aggregatedStats: {
          ...state.aggregatedStats,
          data: action.payload.data,
          loading: false
        }
      }
    case LOAD_AGGREGATED_STATS_REJECTED:
      return {
        ...state,
        aggregatedStats: {
          ...state.aggregatedStats,
          loading: false
        }
      }

    case LOAD_ACCOUNT_STATS_PENDING:
      return {
        ...state,
        [action.payload.keyName]: {
          ...state[action.payload.keyName],
          loading: true
        }
      }
    case LOAD_ACCOUNT_STATS_FULFILLED: {
      return {
        ...state,
        [action.payload.keyName]: {
          ...state[action.payload.keyName],
          data: action.payload.stats,
          loading: false
        }
      }
    }

    case LOAD_ACCOUNT_STATS_REJECTED: {
      return {
        ...state,
        [action.payload.keyName]: {
          ...state[action.payload.keyName],
          loading: false
        }
      }
    }

    case LOAD_PARTNER_STATS_PENDING:
      return {
        ...state,
        partnerStats: {
          ...state.partnerStats,
          loading: true
        }
      }
    case LOAD_PARTNER_STATS_FULFILLED:
      return {
        ...state,
        partnerStats: {
          ...state.partnerStats,
          data: action.payload.stats,
          loading: false
        }
      }
    case LOAD_PARTNER_STATS_REJECTED:
      return {
        ...state,
        partnerStats: {
          ...state.partnerStats,
          loading: false
        }
      }

    case LOAD_PARTNER_BALANCE_PENDING:
      return {
        ...state,
        partnerBalance: {
          ...state.partnerBalance,
          loading: true
        }
      }
    case LOAD_PARTNER_BALANCE_FULFILLED:
      return {
        ...state,
        partnerBalance: {
          ...state.partnerBalance,
          data: action.payload.stats,
          tieredAmount: action.payload.tieredAmount,
          loading: false
        }
      }
    case LOAD_PARTNER_BALANCE_REJECTED:
      return {
        ...state,
        partnerBalance: {
          ...state.partnerBalance,
          loading: false
        }
      }
    default:
      return state
  }
}
