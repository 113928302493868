import React from 'react'

import Button from '@components/Button/index.jsx'

import banner from './images/banner_2.png'

export default function RectSection () {
  function handleClickDownload () {
    const a = document.createElement('a')
    a.href = banner
    a.download = 'rectangular_banner.png'
    a.click()
  }

  return (
    <section className='p-resources-page__section'>
      <div className='p-resources-page__section__top'>
        <div className='p-resources-page__section__sub-title'>Rectangular banner</div>
        <Button
          className='p-resources-page__section__button'
          primary hollow
          onClick={handleClickDownload}
        >
          Download
        </Button>
      </div>

      <div className='p-resources-page__rect'>
        <img src={banner} alt='banner' />
      </div>
    </section>
  )
}
