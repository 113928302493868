const ADD_CRUMBS = 'breadcrumbs/ADD_CRUMBS'
const REMOVE_CRUMBS = 'breadcrumbs/REMOVE_CRUMBS'

export const addCrumbs = crumb => ({
  type: ADD_CRUMBS,
  payload: crumb
})

export const removeCrumbs = id => ({
  type: REMOVE_CRUMBS,
  payload: { id }
})

const initialState = {
  crumbs: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_CRUMBS:
      return {
        ...state,
        crumbs: [...state.crumbs, action.payload]
      }
    case REMOVE_CRUMBS:
      return {
        ...state,
        crumbs: state.crumbs.filter(c => c.id !== action.payload.id)
      }
    default:
      return state
  }
}
