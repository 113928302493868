import React from 'react'
import { Helmet } from 'react-helmet'

import { useBreadcrumbs } from '@hooks/breadcrumbs.js'

import ContentPadding from '@components/ContentPadding/index.jsx'

import ReviewsHero from './Hero.jsx'
import AppList from './List.jsx'

import './Apps.scss'

export default function ReviewsPage () {
  useBreadcrumbs({
    label: 'Reviews',
    to: '/app-reviews'
  })

  return (
    <>
      <Helmet>
        <title>App Reviews</title>
      </Helmet>

      <ContentPadding>
        <div className='apps-reviews-page'>
          <ReviewsHero />

          <AppList />
        </div>
      </ContentPadding>
    </>
  )
}
