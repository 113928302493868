import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'

import './ListRow.scss'

export default function ListRow (props) {
  return (
    <div className={classnames('hc-list-row', props.className)}>
      <div className='hc-list-row__headers'>
        {props.headers}
      </div>
      <div className='hc-list-row__items'>
        {(props.loading || props.empty) && (
          <ListRowItem>
            <ListRowColumn className='hc-list-row__loading' auto>
              {props.loading && 'Loading...'}
              {props.empty && 'No results found.'}
            </ListRowColumn>
          </ListRowItem>
        )}

        {!props.loading && props.items}
      </div>
    </div>
  )
}

ListRow.propTypes = {
  loading: PropTypes.bool,
  empty: PropTypes.bool,
  className: PropTypes.string,
  headers: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.node)
}
