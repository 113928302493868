import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const FETCH_INVITES_PENDING = 'appInvites/FETCH_INVITES_PENDING'
const FETCH_INVITES_FULFILLED = 'appInvites/FETCH_INVITES_FULFILLED'
const FETCH_INVITES_REJECTED = 'appInvites/FETCH_INVITES_REJECTED'

const SEND_INVITES_PENDING = 'appInvites/SEND_INVITES_PENDING'
const SEND_INVITES_FULFILLED = 'appInvites/SEND_INVITES_FULFILLED'
const SEND_INVITES_REJECTED = 'appInvites/SEND_INVITES_REJECTED'

export const fetchInvites = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_INVITES_PENDING })

    const res = await axios.get(getRefApiUrl('v1', '/app-review-invites'), {
      params: {
        app_id: payload.appId,
        search: payload.search || undefined,
        limit: payload.limit || undefined,
        offset: payload.offset || undefined
      }
    })

    const data = {
      data: res.data.data,
      total: res.data.total
    }

    dispatch({ type: FETCH_INVITES_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_INVITES_REJECTED })
    throw error
  }
}

const initialState = {
  isSending: false,
  invites: {
    loading: false,
    data: []
  }
}

export const sendInvites = payload => async dispatch => {
  try {
    dispatch({ type: SEND_INVITES_PENDING })

    // 204 no content
    await axios.post(getRefApiUrl('v1', '/app-review-invites'), {
      app_id: payload.appId,
      emails: payload.emails,
      description: payload.description
    })

    dispatch({ type: SEND_INVITES_FULFILLED })
  } catch (error) {
    dispatch({ type: SEND_INVITES_REJECTED })
    throw error
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_INVITES_PENDING:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: true
        }
      }
    case FETCH_INVITES_FULFILLED:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: false,
          data: action.payload.data,
          total: action.payload.total
        }
      }
    case FETCH_INVITES_REJECTED:
      return {
        ...state,
        invites: {
          ...state.invites,
          loading: false
        }
      }
    case SEND_INVITES_PENDING:
      return {
        ...state,
        isSending: true
      }
    case SEND_INVITES_FULFILLED:
    case SEND_INVITES_REJECTED:
      return {
        ...state,
        isSending: false
      }
    default:
      return state
  }
}
