import React from 'react'
import { Helmet } from 'react-helmet'

import SubmitProjectLeft from '@pages/SubmitProject/Left.jsx'
import SubmitProjectRight from '@pages/SubmitProject/Right.jsx'

import './SubmitProject.scss'


export default function SubmitTaskPage () {
  return (
    <>
      <Helmet>
        <title>Submit a project | HeyCarson</title>
      </Helmet>

      <div className='submit-project-page'>
        <SubmitProjectLeft />
        <SubmitProjectRight />
      </div>
    </>
  )
}
