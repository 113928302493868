import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import qs from 'qs'
import { useLocation } from 'react-router-dom'

import ConversionListContainer from '@containers/PartnerDashboard/ConversionList'

import './List.scss'

function AdminConversionList () {
  const [ready, setReady] = useState(false)
  const [status, setStatus] = useState('pending')
  const [partnerId, setPartnerId] = useState('')
  const [clickId, setClickId] = useState('')

  const location = useLocation()

  useEffect(() => {
    const search = qs.parse(location.search, { ignoreQueryPrefix: true })

    setPartnerId(search.partner_id)
    setClickId(search.click_id)

    setReady(true)
  }, [location.search])

  function handleChangeStatus (status) {
    return () => {
      setStatus(status)
    }
  }

  return (
    <div className='admin-cl'>
      <h2 className='admin-cl__title'>
        Conversions
      </h2>

      <div className='admin-cl__header'>
        <div className='admin-cl__header__tabs'>
          <div
            className={classnames('admin-cl__header__tab', {
              'admin-cl__header__tab--selected': status === 'pending'
            })}
            onClick={handleChangeStatus('pending')}
          >
            Pending
          </div>

          <div
            className={classnames('admin-cl__header__tab', {
              'admin-cl__header__tab--selected': status === 'approved'
            })}
            onClick={handleChangeStatus('approved')}
          >
            Approved
          </div>

          <div
            className={classnames('admin-cl__header__tab', {
              'admin-cl__header__tab--selected': status === 'paid'
            })}
            onClick={handleChangeStatus('paid')}
          >
            Paid
          </div>

          <div
            className={classnames('admin-cl__header__tab', {
              'admin-cl__header__tab--selected': status === 'declined'
            })}
            onClick={handleChangeStatus('declined')}
          >
            Declined
          </div>
        </div>
      </div>

      {ready && (
        <ConversionListContainer
          status={status}
          partnerId={partnerId}
          clickId={clickId}
          showFilters
          limit={15}
        />
      )}
    </div>
  )
}

AdminConversionList.propTypes = {}

export default AdminConversionList
