import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Dropdown.scss'

class Dropdown extends React.Component {
  constructor (props) {
    super(props)

    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.state = {
      open: props.open || false
    }

    this.listening = false
  }

  componentDidMount () {

  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.controlled && this.props.open !== prevProps.open) {
      this.handleControlledBehaviour()
    }
  }

  componentWillUnmount () {
    this.stopListening()
  }

  handleControlledBehaviour () {
    const { open } = this.props

    if (open) {
      setTimeout(() => {
        this.startListening()
      }, 0)
    } else {
      setTimeout(() => {
        this.stopListening()
      }, 0)
    }
  }

  handleClick (event) {
    if (this.props.controlled) {
      this.props.onClick && this.props.onClick(event)
      return
    }

    event.preventDefault()

    const isOpen = this.state.open

    this.setState((old) => ({ ...old, open: !isOpen }))

    if (!isOpen) {
      // send to event loop
      setTimeout(() => {
        this.startListening()
      }, 0)
    }
  }

  handleClose (event) {
    if (this.props.controlled) {
      this.props.onClose && this.props.onClose(event)
      return
    }

    this.setState((old) => ({ ...old, open: false }))

    this.stopListening()
  }

  handleClickOutside (event) {
    let { open } = this.state

    if (this.props.controlled) {
      open = this.props.open
    }

    // is not open
    if (!open) {
      return
    }

    if (!this.container) {
      return
    }

    // clicked element is inside the dropdown
    if (this.container.contains(event.target)) {
      return
    }

    if (this.props.controlled) {
      this.props.onClickOutside && this.props.onClickOutside(event)
      return
    }

    this.setState((old) => ({ ...old, open: false }))

    this.stopListening()

    this.props.onClickOutside && this.props.onClickOutside(event)
  }

  // listeners
  startListening () {
    if (this.listening) {
      return
    }

    this.listening = true
    window && window.document.addEventListener('click', this.handleClickOutside, { capture: true })
  }

  stopListening () {
    window &&
    window.document.removeEventListener('click', this.handleClickOutside, { capture: true })
    this.listening = false
  }

  render () {
    const C = this.props.activator
    const B = this.props.body
    let { open } = this.state
    const { controlled, open: cOpen } = this.props

    if (controlled) {
      open = cOpen
    }

    if (!C || !B) {
      console.warn('Dropdown activator and/or body is empty')
      return null
    }

    return (
      <div
        ref={(dom) => (this.container = dom)}
        className={classnames(
          'usi-dropdown',
          {
            'usi-dropdown--inline': this.props.inline
          },
          this.props.className
        )}
      >
        <C open={open} onClick={this.handleClick} />
        <div
          className={classnames('usi-dropdown__body', this.props.bodyClassName, {
            'usi-dropdown__body--open': open,
            'usi-dropdown__body--left': !this.props.right,
            'usi-dropdown__body--right': this.props.right
          })}
        >
          <B open={this.state.open} onClose={this.handleClose} />
        </div>
      </div>
    )
  }
}

Dropdown.propTypes = {
  right: PropTypes.bool,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  activator: PropTypes.func,
  body: PropTypes.func,
  inline: PropTypes.bool,

  onClickOutside: PropTypes.func,
  // these work together if controlled == 1
  controlled: PropTypes.bool,
  onClick: PropTypes.func,
  open: PropTypes.bool
}

export default Dropdown
