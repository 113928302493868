import React from 'react'
import classnames from 'classnames'

import purpleStar from '@assets/icons/purple-star.svg'
import grayStar from '@assets/icons/gray-star.svg'

export default function LevelsTooltip ({ levels, currentLevelSlug, numLevel, openTooltip }) {
  if (!currentLevelSlug) {
    return
  }
  function levelSubTitle (currentLevel, mappedLevel) {
    if (currentLevel === mappedLevel) {
      return 'Current Level'
    } else if (mappedLevel < currentLevel) {
      return 'Unlocked'
    } else {
      return 'Next'
    }
  }

  return (
    <div className={classnames('overview-page__tooltip-content', {
      'overview-page__tooltip-content--show': openTooltip
    })}
    >
      <div className='overview-page__tooltip-content__title'>
        Commission Levels
      </div>
      {levels.map((item) => (
        <div
          key={item.number}
          className={classnames('overview-page__tooltip-content__row', {
            'overview-page__tooltip-content__row--active': currentLevelSlug === item.slug
          })}
        >
          <div className='overview-page__tooltip-content__left-col'>
            <img
              src={currentLevelSlug === item.slug ? item.imagePurple : item.image} alt={item.name}
              className='overview-page__tooltip-content__img'
            />
            <div className='overview-page__tooltip-content__unlocked'>
              {levelSubTitle(numLevel, item.number)}
            </div>
          </div>
          <div className='overview-page__tooltip-content__desc-container'>
            <div className='overview-page__tooltip-content__special-container'>
              <div className='overview-page__tooltip-content__col-title'>
                {item.name} Partner
              </div>
              {item.slug === 'level-4' && (
                <div className='overview-page__tooltip-content__special'>
                  <img src={currentLevelSlug === 'level-4' ? purpleStar : grayStar} alt='Top level' />
                  <div className='overview-page__tooltip-content__special-text'>
                    Top Level
                  </div>
                </div>
              )}
            </div>
            <div className='overview-page__tooltip-content__col-desc'>
              {item.text}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
