import React from 'react'
import PropTypes from 'prop-types'

import dotMenuIcon from '@assets/icons/dot-menu.svg'

import './DotMenu.scss'

export default function DotMenu (props) {
  return (
    <div className='dot-menu' onClick={props.onClick}>
      <img src={dotMenuIcon} alt='menu' />
    </div>
  )
}

DotMenu.propTypes = {
  onClick: PropTypes.func
}
