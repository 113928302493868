import React, { useState } from 'react'
import classnames from 'classnames'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import Button from '@components/Button'

import General from './General'
import Commissions from './Commissions'

import './ProgramSettings.scss'

const ProgramSettings = () => {
  const [tab, setTab] = useState('general')

  return (
    <>
      <div className='aps'>
        <div className='aps__page-breadcrumbs'>
          Referral programs {'>'} Our affiliate program
        </div>

        <div className='aps__page-title'>
          Program Settings

          <Button danger>
            Delete program
          </Button>
        </div>

        <div className='aps__tabs'>
          <div
            className={classnames('aps__tabs__tab', {
              'aps__tabs__tab--active': tab === 'general'
            })}
            onClick={() => setTab('general')}
          >
            General
          </div>
          <div
            className={classnames('aps__tabs__tab', {
              'aps__tabs__tab--active': tab === 'general'
            })}
            onClick={() => setTab('structure')}
          >
            Commission structure
          </div>
        </div>

        <div style={{ display: tab === 'general' ? 'unset' : 'none' }}>
          <General />
        </div>

        <div style={{ display: tab === 'structure' ? 'unset' : 'none' }}>
          <Commissions />
        </div>
      </div>
    </>
  )
}

export default ProgramSettings
