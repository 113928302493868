import React, { useState } from 'react'

import Button from '@components/Button/index.jsx'
import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import DotMenu from '@components/DotMenu/index.jsx'
import DropdownList from '@components/DropdownList/index.jsx'

import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'

import CategoryModal from '@pages/Partners/Admin/Apps/CategoryModal'
import DeleteCategoryModal from '@pages/Partners/Admin/Apps/DeleteCategoryModal.jsx'
import FeatureModal from '@pages/Partners/Admin/Apps/FeatureModal.jsx'
import DeleteFeatureModal from '@pages/Partners/Admin/Apps/DeleteFeatureModal.jsx'
import GalleryItemModal from '@pages/Partners/Admin/Apps/GalleryItemModal.jsx'
import DeleteGalleryItemModal from '@pages/Partners/Admin/Apps/DeleteGalleryItemModal.jsx'
import PricingPlanModal from '@pages/Partners/Admin/Apps/PricingPlanModal.jsx'
import DeletePricingPlanModal from '@pages/Partners/Admin/Apps/DeletePricingPlanModal.jsx'

export default function Meta ({
  app,
  features,
  totalFeatures,
  pricingPlans,
  totalPricingPlans,
  galleryItems,
  totalGalleryItems,
  loadFeatures,
  loadGalleryItems,
  loadPricingPlans,
  loadApp
}) {
  const [category, setCategory] = useState(null)
  const [showCategoryModal, setShowCategoryModal] = useState('')
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)
  const [feature, setFeature] = useState(null)
  const [showFeatureModal, setShowFeatureModal] = useState('')
  const [deleteFeatureModal, setDeleteFeatureModal] = useState(false)
  const [galleryItem, setGalleryItem] = useState(null)
  const [showGalleryItemModal, setShowGalleryItemModal] = useState('')
  const [deleteGalleryItemModal, setDeleteGalleryItemModal] = useState(false)
  const [pricingPlan, setPricingPlan] = useState(null)
  const [showPricingPlanModal, setShowPricingPlanModal] = useState('')
  const [deletePricingPlanModal, setDeletePricingPlanModal] = useState(false)

  function handleClickCategory ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setCategory(option.value)
        setShowCategoryModal('edit')
        break
      case 'remove':
        setCategory(option.value)
        setDeleteCategoryModal(true)
        break
    }
  }

  function handleClickFeature ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setFeature(option.value)
        setShowFeatureModal('edit')
        break
      case 'remove':
        setFeature(option.value)
        setDeleteFeatureModal(true)
        break
    }
  }

  function handleClickGalleryItem ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setGalleryItem(option.value)
        setShowGalleryItemModal('edit')
        break
      case 'remove':
        setGalleryItem(option.value)
        setDeleteGalleryItemModal(true)
        break
    }
  }

  function handleClickPricingPlan ({ event, option, onClose }) {
    event.preventDefault && event.preventDefault()
    onClose()

    switch (option.key) {
      case 'edit':
        setPricingPlan(option.value)
        setShowPricingPlanModal('edit')
        break
      case 'remove':
        setPricingPlan(option.value)
        setDeletePricingPlanModal(true)
        break
    }
  }

  const featurePagination = makePagination(totalFeatures, 1, 15)
  const galleryItemsPagination = makePagination(totalGalleryItems, 1, 30)
  const pricingPlansPagination = makePagination(totalPricingPlans, 1, 30)

  return (
    <>
      <CategoryModal
        show={showCategoryModal !== ''}
        type={showCategoryModal}
        category={category}
        app={app}
        onSuccess={() => {
          setShowCategoryModal('')
          setCategory(null)
          loadApp({ id: app?.id })
        }}
        onClose={() => {
          setShowCategoryModal('')
          setCategory(null)
        }}
      />
      <DeleteCategoryModal
        show={deleteCategoryModal}
        category={category}
        app={app}
        onClose={() => {
          setCategory(null)
          setDeleteCategoryModal(false)
        }}
        onSuccess={() => {
          setDeleteCategoryModal(false)
          setCategory(null)
          loadApp({ id: app?.id })
        }}
      />
      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Categories

          <Button onClick={() => setShowCategoryModal('add')} primary>
            Add Category
          </Button>
        </div>
        <ListRow
          empty={!app.categories.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__category__name'>Name</ListRowHeader>
              <ListRowHeader className='pda-app-view__category__slug'>Slug</ListRowHeader>
              <ListRowHeader className='pda-app-view__category__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={app.categories.map(category => (
            <ListRowItem key={category.id}>
              <ListRowColumn className='pda-app-view__category__name'>
                {category.name}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__category__slug'>{category.slug}</ListRowColumn>
              <ListRowColumn className='pda-app-view__category__options'>
                <DropdownList
                  onClickOption={handleClickCategory}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'remove',
                    label: 'Remove',
                    value: category
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
      </div>
      <FeatureModal
        show={showFeatureModal !== ''}
        type={showFeatureModal}
        feature={feature}
        appId={app.id}
        onSuccess={() => {
          setShowFeatureModal('')
          setFeature(null)
          loadFeatures(app.id)
        }}
        onClose={() => {
          setShowFeatureModal('')
          setFeature(null)
        }}
      />
      <DeleteFeatureModal
        show={deleteFeatureModal}
        feature={feature}
        appId={app.id}
        onClose={() => {
          setFeature(null)
          setDeleteFeatureModal(false)
        }}
        onSuccess={() => {
          setDeleteFeatureModal(false)
          setFeature(null)
          loadFeatures(app.id)
        }}
      />
      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Features

          <Button onClick={() => setShowFeatureModal('add')} primary>
            Add Feature
          </Button>
        </div>
        <ListRow
          empty={!features.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__feature__type'>Type</ListRowHeader>
              <ListRowHeader className='pda-app-view__feature__name'>Name</ListRowHeader>
              {/* <ListRowHeader className='pda-app-view__feature__description'>Description</ListRowHeader> */}
              <ListRowHeader className='pda-app-view__feature__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={features.map(feature => (
            <ListRowItem key={feature.id}>
              <ListRowColumn className='pda-app-view__feature__type'>
                {feature.type}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__feature__name'>{feature.name}</ListRowColumn>
              {/* <ListRowColumn className='pda-app-view__feature__description'>
                {feature.description && feature.description.length > 70 ? `${feature.description.slice(0, 70)}...` : feature.description}
              </ListRowColumn> */}
              <ListRowColumn className='pda-app-view__feature__options'>
                <DropdownList
                  onClickOption={handleClickFeature}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: feature
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: feature
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
        <SoftPagination
          onChange={() => {}}
          pagination={featurePagination}
        />
      </div>

      <GalleryItemModal
        show={showGalleryItemModal !== ''}
        type={showGalleryItemModal}
        galleryItem={galleryItem}
        appId={app.id}
        onClose={() => {
          setShowGalleryItemModal('')
          setGalleryItem(null)
        }}
        onSuccess={() => {
          setShowGalleryItemModal('')
          setGalleryItem(null)
          loadGalleryItems(app.id)
        }}
      />
      <DeleteGalleryItemModal
        show={deleteGalleryItemModal}
        galleryItem={galleryItem}
        appId={app.id}
        onClose={() => {
          setDeleteGalleryItemModal(false)
          setGalleryItem(null)
        }}
        onSuccess={() => {
          setDeleteGalleryItemModal(false)
          setGalleryItem(null)
          loadGalleryItems(app.id)
        }}
      />
      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Gallery Items

          <Button onClick={() => setShowGalleryItemModal('add')} primary>
            Add Gallery Item
          </Button>
        </div>
        <ListRow
          empty={!galleryItems.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__gallery-item__type'>Type</ListRowHeader>
              <ListRowHeader className='pda-app-view__gallery-item__preview'>Preview</ListRowHeader>
              <ListRowHeader className='pda-app-view__gallery-item__index'>Index</ListRowHeader>
              <ListRowHeader className='pda-app-view__gallery-item__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={galleryItems.map(galleryItem => (
            <ListRowItem key={galleryItem.id}>
              <ListRowColumn className='pda-app-view__gallery-item__type'>
                {galleryItem.type}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__gallery-item__preview'>
                {galleryItem.type === 'youtube' && (
                  <a href={galleryItem.url} target='_blank' rel='noopener noreferrer'>
                    Watch video
                  </a>
                )}
                {galleryItem.type === 'image' && (
                  <a href={galleryItem.url} target='_blank' rel='noopener noreferrer'>
                    <img className='pda__app-img-preview' src={galleryItem.url} />
                  </a>
                )}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__gallery-item__index'>
                {galleryItem.index}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__gallery-item__options'>
                <DropdownList
                  onClickOption={handleClickGalleryItem}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: galleryItem
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: galleryItem
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
        <SoftPagination
          onChange={() => {}}
          pagination={galleryItemsPagination}
        />
      </div>

      <PricingPlanModal
        show={showPricingPlanModal !== ''}
        type={showPricingPlanModal}
        pricingPlan={pricingPlan}
        appId={app.id}
        onClose={() => {
          setShowPricingPlanModal('')
          setPricingPlan(null)
        }}
        onSuccess={() => {
          setShowPricingPlanModal('')
          setPricingPlan(null)
          loadPricingPlans(app.id)
        }}
      />
      <DeletePricingPlanModal
        show={deletePricingPlanModal}
        pricingPlan={pricingPlan}
        appId={app.id}
        onClose={() => {
          setDeletePricingPlanModal(false)
          setPricingPlan(null)
        }}
        onSuccess={() => {
          setDeletePricingPlanModal(false)
          setPricingPlan(null)
          loadPricingPlans(app.id)
        }}
      />
      <div className='pda-app-view__section'>
        <div className='pda-app-view__sub'>
          Pricing Plans

          <Button onClick={() => setShowPricingPlanModal('add')} primary>
            Add Pricing Plan
          </Button>
        </div>
        <ListRow
          empty={!pricingPlans.length}
          headers={(
            <>
              <ListRowHeader className='pda-app-view__pricing-plan__name'>Name</ListRowHeader>
              <ListRowHeader className='pda-app-view__pricing-plan__price'>Price</ListRowHeader>
              <ListRowHeader className='pda-app-view__pricing-plan__options'>&nbsp;</ListRowHeader>
            </>
          )}
          items={pricingPlans.map(pricingPlan => (
            <ListRowItem key={pricingPlan.id}>
              <ListRowColumn className='pda-app-view__pricing-plan__name'>
                {pricingPlan.name}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__pricing-plan__price'>
                {pricingPlan.price}
              </ListRowColumn>
              <ListRowColumn className='pda-app-view__pricing-plan__options'>
                <DropdownList
                  onClickOption={handleClickPricingPlan}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: pricingPlan
                  }, {
                    key: 'remove',
                    label: 'Remove',
                    value: pricingPlan
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />
        <SoftPagination
          onChange={() => {}}
          pagination={pricingPlansPagination}
        />
      </div>

    </>
  )
}
