import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'

import './scss/index.scss'

import configureStore from './redux/configureStore'

const preloadedState = window.__PRELOADED_STATE__ || {}
const store = configureStore(preloadedState)

ReactDOM.hydrateRoot(
  document.getElementById('root'),
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>
)
