import React, { Suspense, lazy, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import sanitizeHtml from 'sanitize-html'

import { formatUrl } from '@util/reviews.js'

import star from '@assets/images/star-orange.svg'
import linkExternal from '@assets/images/link-external-purple.svg'
import defaultAvatar from '@assets/images/default-avatar.png'

const ReplyForm = lazy(() => import('@pages/Partners/Partner/Theme/ReplyForm.jsx'))

function ThemeReview ({ review }) {
  const [devName, user] = useSelector(({ account }) => [account.workspace.name, account.user])
  const [editingReply, setEditingReply] = useState(false)
  const [liveReply, setLiveReply] = useState('')

  const canEditReply = (!review.has_reply || editingReply) && review.status !== 'pending'

  function handleEditReply (event) {
    event.preventDefault && event.preventDefault()

    setEditingReply(true)
    setLiveReply(review.reply.reply)
  }

  function handleCancelReply () {
    setEditingReply(false)
    setLiveReply('')
  }

  const storeUrl = formatUrl(review.store_url)

  return (
    <div className='pr-review-v3'>
      <div className='pr-review-v3__top'>
        <div className='pr-review-v3__profile-container'>
          <div className='pr-review-v3__col'>
            <p className='pr-review-v3__name'>
              {review.user_name}
            </p>
            <p className='pr-review-v3__item pr-review-v3__item--used-one'>
              <span>Theme used on:&nbsp;</span>
              <a href={storeUrl.full} target='_blank' rel='noreferrer noopener nofollow'>
                {storeUrl.clean}&nbsp;
                <img src={linkExternal} alt={storeUrl.clean} />
              </a>
            </p>
            <p className='pr-review-v3__item'>
              <span>Recommend this theme:&nbsp;</span>
              <span className={classnames('pr-review-v3__item__recommend', {
                'pr-review-v3__item__recommend--not': review?.recommended.includes('Not')
              })}
              >
                {review.recommended}
              </span>
            </p>
          </div>
        </div>
        <div className='pr-review-v3__right-col'>
          <div>
            <div className='pr-review-v3__date'>
              {review.status === 'pending'
                ? <div className='pr-review__pending'>Review pending approval</div>
                : (<div><span>Posted: </span>{DateTime.fromISO(review.created_at).toRelative()}</div>)}
            </div>
            <p className='pr-review-v3__overall'>Overall Rating:</p>
          </div>
          <div className='pr-review-v3__rating'>
            <img src={star} alt='overall rating' />
            {review.overall_rating && review.overall_rating.toFixed(2)}
          </div>
        </div>
      </div>

      <div className='pr-review-v3__features'>
        <div className='pr-review-v3__features__item'>
          <p className='pr-review-v3__features__title'>
            Setup & Ease of Use
          </p>
          <p className='pr-review-v3__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.ease_rating} /5
          </p>
        </div>
        <div className='pr-review-v3__features__item'>
          <p className='pr-review-v3__features__title'>
            Features & Functionality
          </p>
          <p className='pr-review-v3__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.options_rating} /5
          </p>
        </div>
        <div className='pr-review-v3__features__item'>
          <p className='pr-review-v3__features__title'>
            Support Quality
          </p>
          <p className='pr-review-v3__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.support_rating} /5
          </p>
        </div>
        <div className='pr-review-v3__features__item'>
          <p className='pr-review-v3__features__title'>
            Value for Money
          </p>
          <p className='pr-review-v3__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.value_rating || 5} /5
          </p>
        </div>
      </div>
      <p className='pr-review-v3__title'>
        Overall experience
      </p>
      <p className='pr-review-v3__desc'>
        {review.review}
      </p>
      {review.has_reply && (
        <>
          <div className='pr-review__reply__title'>
            Your reply
          </div>
          <div className='pr-review__reply__row'>
            <div className='pr-review__reply'>
              <div className='pr-review__reply__avatar'>
                <div className='pr-review__reply__picture'>
                  <img src={user?.avatar || defaultAvatar} alt='avatar' />
                </div>
              </div>
              <div className='pr-review__reply__content'>
                <div className='pr-review__reply__name-row'>
                  <div className='pr-review__reply__name'>
                    <div className='pr-review__reply__name__text'>
                      {devName}
                    </div>
                    <div className='pr-review__reply__name__label'>
                      Theme developer's reply
                    </div>
                  </div>
                  <div className='pr-review__reply__date'>
                    {DateTime.fromISO(review.reply.created_at).toRelative()}
                  </div>
                </div>
                <div
                  className='pr-review__reply__text'
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(editingReply ? (liveReply || '&nbsp;') : review.reply.reply)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='pr-review__reply__edit-row'>
            {editingReply && (<span className='pr-review__reply__edit'>Editing...</span>)}
            {!editingReply && (
              <Link
                to='#'
                className='pr-review__reply__edit'
                onClick={!editingReply ? handleEditReply : undefined}
                disabled={editingReply}
              >
                {editingReply ? 'Editing...' : 'Edit this Reply '}
              </Link>
            )}
          </div>
        </>
      )}

      {canEditReply && (
        <Suspense fallback=''>
          <ReplyForm
            review={review}
            editingReply={editingReply}
            onLiveReply={content => setLiveReply(content)}
            canEditReply={canEditReply}
            onCancelReply={handleCancelReply}
          />
        </Suspense>
      )}
    </div>
  )
}

ThemeReview.propTypes = {
  review: PropTypes.object.isRequired
}

export default ThemeReview
