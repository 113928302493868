import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DropdownList from '@components/DropdownList'
import DotMenu from '@components/DotMenu'

import chevron from '@assets/icons/chevron-dark.svg'


export default function Tree ({ categories, onClickEdit, onClickAdd, onClickDelete, root }) {
  return (
    <ul className='pda-app-categories-page__category-list'>
      {categories.map((category) => (
        <TreeNode
          key={category.id}
          category={category}
          onClickEdit={onClickEdit}
          onClickAdd={onClickAdd}
          onClickDelete={onClickDelete}
        />
      ))}
    </ul>
  )
}

Tree.propTypes = {
  categories: PropTypes.array,
  onClickEdit: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickDelete: PropTypes.func
}

function TreeNode ({ category, onClickEdit, onClickAdd, onClickDelete }) {
  const [open, setOpen] = useState(false)

  const { children, name } = category
  const hasChildren = children.length > 0

  function handleClickOption ({ event, option, onClose }) {
    onClose()
    event?.preventDefault && event.preventDefault()

    switch (option.key) {
      case 'add':
        onClickAdd(option.value)
        break
      case 'edit':
        onClickEdit(option.value)
        break
      case 'delete':
        onClickDelete(option.value)
        break
    }
  }

  function setDropdownOptions (category) {
    const options = [{
      key: 'add',
      label: 'Add Child Category',
      value: category
    }, {
      key: 'edit',
      label: 'Edit',
      value: category
    }]

    if (!hasChildren) {
      options.push({
        key: 'delete',
        label: 'Delete',
        value: category
      })
    }

    return options
  }

  return (
    <li>
      <div className='pda-app-categories-page__category'>
        <div onClick={() => setOpen(hasChildren && !open)} className='pda-app-categories-page__category__meta'>
          {hasChildren > 0 && (
            <div
              className={classnames('pda-app-categories-page__category__chevron', {
                'pda-app-categories-page__category__chevron--open': open
              })}
            >
              <img
                src={chevron}
                alt='toggle'
              />
            </div>
          )}

          <span>{name}</span>
        </div>

        <div className='pda-app-categories-page__category__actions'>
          <DropdownList
            right
            onClickOption={handleClickOption}
            activator={({ onClick }) => <DotMenu onClick={onClick} />}
            options={() => setDropdownOptions(category)}
          />
        </div>
      </div>

      {open && (
        <div className='pda-app-categories-page__category-child'>
          <Tree
            categories={children}
            onClickEdit={onClickEdit}
            onClickAdd={onClickAdd}
            onClickDelete={onClickDelete}
          />
        </div>
      )}
    </li>
  )
}

TreeNode.propTypes = {
  category: PropTypes.object,
  onClickEdit: PropTypes.func,
  onClickAdd: PropTypes.func,
  onClickDelete: PropTypes.func
}
