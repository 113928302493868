import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { fetchGroups, addFilter, updateFilter } from '@redux/themeFilters.js'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  name: '',
  group_id: '',
  visible: '',
  ranking_slug: '',
  slug: '',
  page_title: '',
  page_description: '',
  meta_title: '',
  meta_description: '',
  meta_footer_title: '',
  meta_footer_description: ''
}

export default function FilterModal ({ show, type, filter, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [groups] = useSelector(({ themeFilters }) => [themeFilters.groups.data])

  const dispatch = useDispatch()
  const thumbnailRef = useRef(null)
  const coverRef = useRef(null)

  useEffect(() => {
    if (!show || !filter || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: filter.name || '',
      group_id: filter.group_id || '',
      visible: filter.visible || '',
      ranking_slug: filter.ranking_slug || '',
      slug: filter.slug || '',
      page_title: filter.page_title || '',
      page_description: filter.page_description || '',
      meta_title: filter.meta_title || '',
      meta_description: filter.meta_description || '',
      meta_footer_title: filter.meta_footer_title || '',
      meta_footer_description: filter.meta_footer_description || ''
    })
  }, [show, filter])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      if (thumbnailRef.current) {
        thumbnailRef.current.value = ''
      }
      if (coverRef.current) {
        coverRef.current.value = ''
      }
    }
  }, [show])

  useEffect(() => {
    loadGroups()
  }, [])

  function loadGroups (search) {
    dispatch(
      fetchGroups({
        limit: 15,
        offset: 0
      })
    ).catch(error => {
      console.log('error fetch @themes: ', error)
    })
  }

  function handleSave () {
    setSubmitting(true)
    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      group_id: fields.group_id,
      visible: fields.visible,
      ranking_slug: fields.ranking_slug,
      slug: fields.slug,
      page_title: fields.page_title,
      page_description: fields.page_description,
      meta_title: fields.meta_title,
      meta_description: fields.meta_description,
      meta_footer_title: fields.meta_footer_title,
      meta_footer_description: fields.meta_footer_description
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })
    if (fields.thumbnail) {
      data.set('thumbnail', fields.thumbnail)
    }
    if (fields.cover) {
      data.set('cover', fields.cover)
    }

    let promise

    if (type === 'add') {
      promise = addFilter
    } else {
      promise = updateFilter
    }

    dispatch(promise({
      id: filter?.id,
      data
    }))
      .then(() => {
        setSubmitting(false)
        onSuccess()
        onClose()
      })
      .catch(error => {
        setSubmitting(false)
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'profile_not_found'
        ) {
          setErrors(old => ({ ...old, store_id: error.response.data.message }))
        } else {
          console.log(`error @${type} filter`, error)
        }
      })
  }

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }
  const renderThumbnail = () => {
    if (filter?.thumbnail_url) {
      return (
        <img
          className='pda-filters-img-preview__large'
          src={fields.thumbnail ? URL.createObjectURL(fields.thumbnail) : filter.thumbnail_url}
          alt=''
        />
      )
    }
    if (fields.thumbnail) {
      return (
        <img
          className='pda-filters-img-preview__large'
          src={URL.createObjectURL(fields.thumbnail)}
          alt=''
        />
      )
    }
  }

  const renderCoverImage = () => {
    if (filter?.cover_url) {
      return (
        <img
          className='pda-filters-img-preview__large'
          src={fields.cover ? URL.createObjectURL(fields.cover) : filter.cover_url}
          alt=''
        />
      )
    }
    if (fields.cover) {
      return (
        <img
          className='pda-filters-img-preview__large'
          src={URL.createObjectURL(fields.cover)}
          alt=''
        />
      )
    }
  }

  return (
    <Modal show={show} large className='pda-filters__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Filter
      </Header>
      <Body className='pda-filters__add-modal__body'>
        <Columns>
          <TextInput
            labelClassName='pda-filters__form__label'
            label='Filter Name *'
            value={fields.name}
            placeholder='Store Name'
            onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
            error={errors.name}
            subText={errors.name}
          />
          <SelectInput
            label='Group *'
            labelClassName='pda-filters__form__label'
            value={fields.group_id}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, group_id: value })}
            options={groups.map(group => ({
              key: group.id,
              label: group.name,
              value: group.id
            }))}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-filters__form__label'
            label='Ranking Page Slug'
            value={fields.ranking_slug}
            onChange={event => setFields({ ...fields, ranking_slug: event.currentTarget.value })}
            error={errors.ranking_slug}
            subText={errors.ranking_slug}
          />
          <SelectInput
            label='Visible *'
            labelClassName='pda-filters__form__label'
            value={fields.visible}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, visible: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: true
            }, {
              key: '0',
              label: 'No',
              value: false
            }]}
          />
        </Columns>
        <TextInput
          labelClassName='pda-filters__form__label'
          label='Page title'
          value={fields.page_title}
          onChange={event => setFields({ ...fields, page_title: event.currentTarget.value })}
          error={errors.page_title}
          subText={errors.page_title}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-filters__form__label'
          label='Description'
          value={fields.page_description}
          placeholder='The description of the store'
          onChange={event => setFields({ ...fields, page_description: event.currentTarget.value })}
          error={errors.page_description}
          subText={errors.page_description}
        />

        <TextInput
          labelClassName='pda-filters__form__label'
          label='Meta Title'
          value={fields.meta_title}
          placeholder='meta title'
          onChange={event => setFields({ ...fields, meta_title: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />

        <TextInput
          multiline={3}
          labelClassName='pda-filters__form__label'
          label='Meta Description'
          value={fields.meta_description}
          placeholder='meta description'
          onChange={event => setFields({ ...fields, meta_description: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />
        <TextInput
          labelClassName='pda-filters__form__label'
          label='Meta Footer Title'
          value={fields.meta_footer_title}
          placeholder='Meta footer title'
          onChange={event => setFields({ ...fields, meta_footer_title: event.currentTarget.value })}
          error={errors.meta_footer_title}
          subText={errors.meta_footer_title}
        />

        <TextInput
          multiline={3}
          labelClassName='pda-filters__form__label'
          label='Meta Footer Description'
          value={fields.meta_footer_description}
          placeholder='meta footer description'
          onChange={event => setFields({ ...fields, meta_footer_description: event.currentTarget.value })}
          error={errors.meta_footer_description}
          subText={errors.meta_footer_description}
        />
        <Columns>
          <div className='pda-filters__form__group-image'>
            <TextInput
              inputRef={thumbnailRef}
              type='file'
              labelClassName='pda-filters__form__label'
              label='Thumbnail Image *'
              onChange={handleFileChange('thumbnail')}
              accept='image/*'
            />
            {renderThumbnail()}
          </div>
          <div className='pda-filters__form__group-image'>
            <TextInput
              inputRef={coverRef}
              type='file'
              labelClassName='pda-filters__form__label'
              label='Cover Image'
              onChange={handleFileChange('cover')}
              accept='image/*'
            />
            {renderCoverImage()}

          </div>
        </Columns>
      </Body>
      <Footer className='pda-filters__add-modal__footer'>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

FilterModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  filter: PropTypes.object
}
