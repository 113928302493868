import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { DateTime } from 'luxon'

import Dropdown from '@components/Dropdown'
import TextInput from '@components/Inputs/TextInput'
import MonthGrid from '@components/DatePicker/MonthGrid'

import calendar from '@assets/icons/calendar.svg'
import selectArrow from '@assets/icons/select-arrow.svg'

import './PeriodPicker.scss'

export const presetName = preset => {
  switch (preset) {
    case 'all_time' :
      return 'All time'
    case 'last_90' :
      return 'Last 90 days'
    case 'last_30' :
      return 'Last 30 days'
    case 'last_7' :
      return 'Last 7 days'
    default:
      return 'Custom'
  }
}

class PeriodPicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ready: false,
      month: null,
      start: null,
      end: null,
      picking: false,
      preset: '',
      open: false
    }

    this.renderBody = this.renderBody.bind(this)
    this.handleChangeMonth = this.handleChangeMonth.bind(this)
    this.handleDayChange = this.handleDayChange.bind(this)

    this.handleDropToggle = this.handleDropToggle.bind(this)
    this.handleDropClose = this.handleDropClose.bind(this)
  }

  componentDidMount () {
    const { start, end, preset } = this.props

    const s = DateTime.fromISO(start)
    const e = DateTime.fromISO(end)

    if (s?.isValid && e?.isValid) {
      const now = e.set({ day: 1 })

      this.setState(old => ({
        ...old,
        month: now,
        ready: true,
        start: s,
        end: e,
        preset: 'custom'
      }))

      return
    }

    this.handleClickPreset(preset || 'last_30')(null)
  }

  handleChangeMonth ({ month }) {
    this.setState(old => ({
      ...old,
      month
    }))
  }

  handleDropToggle () {
    const isOpen = this.state.open

    if (isOpen) {
      this.handleDropClose()
    } else {
      this.setState(old => ({
        ...old,
        open: true
      }))
    }
  }

  handleDropClose () {
    const { start, preset, picking } = this.state
    let { end } = this.state

    if (start && (!end || picking)) {
      end = start.set({ hour: 23, minute: 59, second: 59 })
    } else if (!start || !end) {
      return
    }

    this.setState(old => ({
      ...old,
      start,
      end,
      open: false

      // allow picking end after close then
      // picking: false leave picking = true
    }), () => {
      this.props.onChange && this.props.onChange({ start, end, preset })
    })
  }

  handleClickPreset (preset) {
    return event => {
      event?.preventDefault && event.preventDefault()

      // all_time = default
      let start = ''
      const end = DateTime.now()
      const month = end.set({ day: 1 })

      if (preset === 'all_time') {
        start = DateTime.fromFormat('2021-06-01', 'y-M-d')
      } else if (preset === 'last_90') {
        start = end.minus({ days: 90 })
      } else if (preset === 'last_30') {
        start = end.minus({ days: 30 })
      } else if (preset === 'last_7') {
        start = end.minus({ days: 7 })
      }

      this.setState(old => ({
        ...old,
        ready: true,
        start,
        end,
        month,
        preset,
        picking: false
      }), () => {
        this.handleDropClose()
      })
    }
  }

  handleDayChange ({ start, end }) {
    this.setState(old => ({
      ...old,
      start,
      end,
      preset: 'custom'
    }), () => {
      if (end) {
        this.handleDropClose()
      }
    })
  }

  render () {
    const { right } = this.props

    return (
      <div
        className='hc-period-picker-container'
      >
        {right && this.renderCustomRange()}

        <Dropdown
          right={right}
          activator={props => this.renderActivator(props)}
          body={this.renderBody}
          controlled
          open={this.state.open}
          onClickOutside={this.handleDropClose}
        />

        {!right && this.renderCustomRange()}
      </div>
    )
  }

  renderCustomRange () {
    const { preset: curPreset, start, end } = this.state
    const { preset: ogPreset } = this.props

    let range = ''

    if (curPreset !== 'custom') {
      return
    }

    if (
      start && end &&
      start.hasSame(end, 'day')
    ) {
      // show single day if both start/end are the same day
      range = start.toFormat('M/d/y')
    } else {
      range = start ? start.toFormat('M/d/y') : ''

      if (end) {
        range += ' ~ '
      }

      range += end ? end.toFormat('M/d/y') : ''
    }

    if (!range) {
      return null
    }

    return (
      <div className='hc-period-picker__range'>
        {range}

        <div
          className='hc-period-picker__reset'
          onClick={this.handleClickPreset(ogPreset || 'last_30')}
        >
          &times;
        </div>
      </div>
    )
  }

  renderActivator () {
    const { preset } = this.state

    return (
      <div className='hc-period-picker' onClick={this.handleDropToggle}>
        <img className='' src={calendar} />
        <div className='hc-period-picker__text'>
          {presetName(preset)}
        </div>
        <img
          className={classnames('hc-period-picker__drop', {
            'hc-period-picker__drop--open': this.state.open
          })}
          src={selectArrow}
        />
      </div>
    )
  }

  renderBody () {
    if (!this.state.ready) {
      return null
    }

    const { month, start, end } = this.state

    return (
      <div className='hc-period-picker__body'>
        <div className='hc-period-picker__preloaded-assets'>
          <img className='' src={calendar} />
          <img className='' src={selectArrow} />
        </div>
        <div className='hc-period-picker__presets'>
          <a href='#' onClick={this.handleClickPreset('all_time')} className='hc-period-picker__preset'>
            All time
          </a>
          <a href='#' onClick={this.handleClickPreset('last_90')} className='hc-period-picker__preset'>
            Last 90 days
          </a>
          <a href='#' onClick={this.handleClickPreset('last_30')} className='hc-period-picker__preset'>
            Last 30 days
          </a>
          <a href='#' onClick={this.handleClickPreset('last_7')} className='hc-period-picker__preset'>
            Last 7 days
          </a>
        </div>

        <div className='hc-period-picker__inputs'>
          <TextInput
            className='hc-period-picker__input'
            labelClassName='hc-period-picker__label'
            label='From'
            placeholder='mm/dd/yyyy'
            value={start ? start.toFormat('M/d/y') : ''}
            onChange={() => {}}
            disabled
          />
          <TextInput
            className='hc-period-picker__input'
            labelClassName='hc-period-picker__label'
            label='To'
            placeholder='mm/dd/yyyy'
            value={end ? end.toFormat('M/d/y') : ''}
            onChange={() => {}}
            disabled
          />
        </div>

        <MonthGrid
          start={start}
          end={end}
          month={month}
          onChange={this.handleDayChange}
          onChangeMonth={this.handleChangeMonth}
        />

      </div>
    )
  }
}

PeriodPicker.propTypes = {
  // ISO Date
  start: PropTypes.string,
  end: PropTypes.string,
  preset: PropTypes.string,

  onChange: PropTypes.func,

  right: PropTypes.bool
}

export default PeriodPicker
