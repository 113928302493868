import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { DateTime } from 'luxon'

import { loadConversions, updateConversion } from '@redux/referrals/conversions'

import { getSymbol } from '@util/currency'
import { getCommissionInfo, getConversionStatus, getPaymentInfo } from '@util/referrals'

import CursorPagination from '@components/Pagination/Cursor'
import Button from '@components/Button'
import SearchBar from '@components/SearchBar'
import Dropdown from '@components/DropdownList'
import Modal, { Body as ModalBody, Header as ModalHeader } from '@components/Modal'

import editIcon from '@assets/icons/edit.svg'

import './ConversionList.scss'

class ConversionListContainer extends React.Component {
  state = {
    hasPrev: false,
    hasNext: false,

    // extra filter load conversions
    filters: {},

    showStatusChangeModal: false,
    statusChangeContext: {}
  }

  constructor (props) {
    super(props)

    this.renderPendingOptions = this.renderPendingOptions.bind(this)
    this.handleClickOption = this.handleClickOption.bind(this)
    this.handleCloseStatusChangeModal = this.handleCloseStatusChangeModal.bind(this)
    this.handleConfirmChangeStatus = this.handleConfirmChangeStatus.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
  }

  componentDidMount () {
    this.loadConversions()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      prevProps.status !== this.props.status
    ) {
      this.handleChangeFilterStatus()
    }
  }

  handleChangeFilterStatus () {
    // reset filter on filter status change
    this.handleChangeFilter({})
  }

  handleChangeFilter (filters) {
    this.setState(old => ({
      ...old,
      filters
    }), () => {
      this.loadConversions()
    })
  }

  handlePageChange ({ before, after }) {
    return this.loadConversions({ before, after })
  }

  handleChangeStatusOption ({ conversion, status }) {
    this.setState(old => ({
      ...old,
      showStatusChangeModal: true,
      statusChangeContext: {
        conversion,
        status
      }
    }))
  }

  handleCloseStatusChangeModal () {
    this.setState(old => ({
      ...old,
      showStatusChangeModal: false,
      statusChangeContext: {}
    }))
  }

  handleConfirmChangeStatus () {
    const { conversion, status } = this.state.statusChangeContext

    this.props.updateConversion({
      conversionId: conversion.id,
      status
    })
      .then(() => {
        this.setState(old => ({
          ...old,
          showStatusChangeModal: false,
          statusChangeContext: {}
        }))
        this.loadConversions()
      })
      .catch(error => {
        console.log('update conversion error: ', error)
      })
  }

  handleClickOption ({ option, onClose }) {
    onClose()

    switch (option.key) {
      case 'approved':
      case 'declined':
        this.handleChangeStatusOption({ conversion: option.value, status: option.key })
        break
      case 'to_approve':
        this.handleChangeFilter({
          toApprove: true
        })
        break
      case 'no_filter':
        this.handleChangeFilter({})
        break
    }
  }

  loadConversions ({ before, after } = {}) {
    return this.props.loadConversions({
      limit: this.props.limit,
      status: this.props.status,
      partnerId: this.props.partnerId,
      clickId: this.props.clickId,
      before,
      after,
      withCustomer: true,
      withPartner: true,
      withProgram: true,
      ...this.state.filters
    })
      .then(() => {})
      .catch(error => {
        console.log('@loadConversions error', error)
      })
  }

  getSearchFilters () {
    const { status, showFilters } = this.props

    if (!showFilters) {
      return []
    }

    if (status === 'pending') {
      return [{
        key: 'no_filter',
        label: 'All',
        value: 'no_filter'
      }, {
        key: 'to_approve',
        label: 'Ready for approval',
        value: 'to_approve'
      }]
    }

    return []
  }

  render () {
    return (
      <div className='convlist'>
        <div className='convlist__search'>
          <SearchBar
            placeholder='Search conversions'
            onChange={() => {}}
            filters={this.getSearchFilters()}
            onClickFilter={this.handleClickOption}
          />
        </div>

        {this.renderHeaders()}
        {this.renderItems()}
        {this.renderPagination()}
      </div>
    )
  }

  renderHeaders () {
    return (
      <div className='convlist__headers'>
        <div className='convlist__header convlist__header--referral'>Referral</div>
        <div className='convlist__header convlist__header--partner'>Partner</div>
        <div className='convlist__header convlist__header--purchase'>Purchase</div>
        <div className='convlist__header convlist__header--commission'>Commission</div>
        <div className='convlist__header convlist__header--date'>Date</div>
        <div className='convlist__header convlist__header--options'>&nbsp;</div>
      </div>
    )
  }

  renderItems () {
    const { items } = this.props

    return (
      <div className='convlist__items'>
        {items.map(conversion => {
          let renderOptions

          if (conversion.status === 'pending') {
            renderOptions = this.renderPendingOptions
          }

          return (
            <div key={conversion.id} className='convlist__item'>
              <div className='convlist__column convlist__column--referral'>
                {conversion.customer.name}
              </div>
              <div className='convlist__column convlist__column--partner'>
                {conversion.partner.name}
                <div className='convlist__column-info'>
                  {conversion.program.name}
                </div>
              </div>
              <div className='convlist__column convlist__column--purchase'>
                {getSymbol(conversion.program.currency)}{conversion.amount}
                <br />
                <div className='convlist__column-info'>
                  {getPaymentInfo(conversion.metadata)}
                </div>
              </div>
              <div className='convlist__column convlist__column--commission'>
                {getSymbol(conversion.program.currency)}{conversion.commission}

                <div className='convlist__column-info'>
                  {getCommissionInfo(conversion, conversion.program.currency)}
                </div>
              </div>
              <div className='convlist__column convlist__column--date'>
                {DateTime.fromISO(conversion.created_at).toFormat('dd MMM, y')}

                <div className='convlist__column-info'>
                  {getConversionStatus(conversion.status)}
                </div>
              </div>
              <div className='convlist__column convlist__column--options'>
                {renderOptions && (
                  <Dropdown
                    onClose={() => {}}
                    activator={({ open, onClick }) => (
                      <div className='admin__partners__list-option-activator' onClick={onClick}>
                        <img src={editIcon} alt='options' />
                      </div>
                    )}
                    bodyClassName='admin__partners__list-options'
                    options={renderOptions({ conversion })}
                    onClickOption={this.handleClickOption}
                  />
                )}
              </div>
            </div>
          )
        })}

        {this.renderStatusChangeModal()}
      </div>
    )
  }

  renderPagination () {
    return (
      <div className='convlist__pagination'>
        <CursorPagination
          direction='next'
          items={this.props.items}
          cursor='created_at'
          onPageChange={this.handlePageChange}
          showDetails
          pageLimit={this.props.limit}
          totalResults={this.props.totalResults}
          remainingResults={this.props.remainingResults}
        />
      </div>
    )
  }

  renderPendingOptions ({ conversion }) {
    return ({ onClose }) => {
      return [{
        key: 'approved',
        label: 'Approve',
        value: conversion
      }, {
        key: 'declined',
        label: 'Decline',
        value: conversion
      }]
    }
  }

  renderStatusChangeModal () {
    const { conversion, status } = this.state.statusChangeContext
    const { showStatusChangeModal } = this.state

    let actionText = ''

    switch (status) {
      case 'approved':
        actionText = 'Approve'
        break
      case 'declined':
        actionText = 'Decline'
        break
    }

    return (
      <Modal
        show={showStatusChangeModal}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={this.handleCloseStatusChangeModal}
        >
          {actionText} conversion
        </ModalHeader>
        <ModalBody className='pd__modals__body withdrawal-status-modal'>
          {conversion && (
            <>
              <div className='withdrawal-status-modal__text'>
                Are you sure want to <strong>{actionText}</strong> conversion
                of <strong>(${conversion.commission})</strong>
                &nbsp;from <strong>{conversion.partner.name}</strong>
              </div>
            </>
          )}

          <div className='pd__modals__two-columns'>
            <Button primary className='pd__modals__two-columns__button' onClick={this.handleConfirmChangeStatus}>
              Confirm
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.handleCloseStatusChangeModal}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

ConversionListContainer.propTypes = {
  limit: PropTypes.number,
  status: PropTypes.string,
  partnerId: PropTypes.string,
  clickId: PropTypes.string,
  showFilters: PropTypes.bool,
  showSearch: PropTypes.bool,
  loadConversions: PropTypes.func,
  loading: PropTypes.bool,
  items: PropTypes.array,
  totalResults: PropTypes.number,
  remainingResults: PropTypes.number,
  updateConversion: PropTypes.func
}

const mapStateToProps = ({ referrals: { conversions } }) => ({
  loading: conversions.loading,
  items: conversions.data,
  totalResults: conversions.totalResults,
  remainingResults: conversions.remainingResults
})

const mapDispatchToProps = dispatch => ({
  loadConversions: payload => dispatch(loadConversions(payload)),
  updateConversion: payload => dispatch(updateConversion(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConversionListContainer)
