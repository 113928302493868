import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import search from '@assets/icons/search.svg'
import filter from '@assets/icons/filter.svg'

import './SearchBar.scss'

const SearchBar = ({
  searchBarClassName,
  searchBarError,

  inputClassName,
  inputName,
  inputValue,
  inputPlaceHolder,
  inputRequired,
  inputDisabled,

  buttonClass,
  buttonText,

  onChange,
  onClick
}) => (
  <div
    className={classnames('hc-search-bar', searchBarClassName, {
      'hc-search-bar--error': searchBarError
    })}
  >
    <img src={search} className='hc-search-bar__search' />

    <input
      className={classnames('hc-search-bar__input', inputClassName, {
        'hc-search-bar__input--error': searchBarError
      })}
      type='text'
      name={inputName}
      placeholder={inputPlaceHolder}
      value={inputValue}
      required={inputRequired}
      disabled={inputDisabled}
      onChange={onChange}
    />

    <button
      className={classnames('hc-search-bar__button', buttonClass, {
        'hc-search-bar__button--error': searchBarError
      })}
      onClick={onClick}
      type='button'
    >
      <img src={filter} className='hc-search-bar__button__filter' />
      Filter
    </button>
  </div>
)

SearchBar.propTypes = {
  searchBarClassName: PropTypes.string,
  searchBarError: PropTypes.bool,

  inputClassName: PropTypes.string,
  inputName: PropTypes.string,
  inputValue: PropTypes.string,
  inputPlaceHolder: PropTypes.string,
  inputRequired: PropTypes.bool,
  inputDisabled: PropTypes.bool,

  buttonClass: PropTypes.string,
  buttonText: PropTypes.string,

  onChange: PropTypes.func,
  onClick: PropTypes.func
}

export default SearchBar
