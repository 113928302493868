import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default function ListRowColumn (props) {
  return (
    <div
      className={classnames('hc-list-row__column', {
        'hc-list-row__column--auto': props.auto
      }, props.className)}
    >
      {props.children}
    </div>
  )
}

ListRowColumn.propTypes = {
  className: PropTypes.string,
  auto: PropTypes.bool
}
