import React from 'react'

import ModalsCommissionsAdd from '@components/Partners/Admin/Modals/Commissions/Add'

import Button from '@components/Button'
import TextInput from '@components/Inputs/TextInput'

import edit from '@assets/icons/edit.svg'

import './Commissions.scss'

class SettingsCommissions extends React.Component {
  state = {
    showCommissionModal: false
  }

  constructor (props) {
    super(props)

    this.handleToggleModal = this.handleToggleModal.bind(this)
  }

  handleToggleModal () {
    this.setState({ showCommissionModal: !this.state.showCommissionModal })
  }

  render () {
    const { showCommissionModal } = this.state

    return (
      <>
        <div className='aps__section'>
          <div className='aps__section__two-columns'>
            <div className='aps__section__two-columns__column'>
              <TextInput
                label='Recurring Frequency Cap'
                type='text'
                placeholder='1'
                className='aps__input'
                labelClassName='aps__label'
              />
            </div>
            <div className='aps__section__two-columns__column'>
              <TextInput
                label='Recurring Time Cap'
                type='text'
                placeholder='45 days'
                className='aps__input'
                labelClassName='aps__label'
              />
            </div>
          </div>

          <hr />

          <div className='aps__section__title'>
            Standard Commissions
          </div>

          <div className='aps__commissions'>
            <div className='aps__commissions__header'>
              <div className='aps__commissions__header__item aps__commissions__header__item--title'>
                Title
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--identifier'>
                Identifier
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--form'>
                Commission Form
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--amount'>
                Commission
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--actions' />
            </div>

            <div className='aps__commission'>
              <div className='aps__commission__title'>
                Earn 15% commission of the first purchase
              </div>
              <div className='aps__commission__identifier'>
                standard
              </div>
              <div className='aps__commission__form'>
                percentage
              </div>
              <div className='aps__commission__amount'>
                15%
              </div>
              <div className='aps__commission__actions'>
                <a href='#' onClick={this.handleToggleModal}>
                  <img src={edit} />
                </a>
              </div>
            </div>
          </div>

          <hr />

          <div className='aps__section__title'>
            Extra Commissions
          </div>

          <div className='aps__commissions'>
            <div className='aps__commissions__header'>
              <div className='aps__commissions__header__item aps__commissions__header__item--title'>
                Title
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--identifier'>
                Identifier
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--form'>
                Commission Form
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--amount'>
                Commission
              </div>
              <div className='aps__commissions__header__item aps__commissions__header__item--actions' />
            </div>

            <div className='aps__commission'>
              <div className='aps__commission__title'>
                Earn 15% commission of the first purchase
              </div>
              <div className='aps__commission__identifier'>
                single-task
              </div>
              <div className='aps__commission__form'>
                percentage
              </div>
              <div className='aps__commission__amount'>
                15%
              </div>
              <div className='aps__commission__actions'>
                <a href='#' onClick={this.handleToggleModal}>
                  <img src={edit} />
                </a>
              </div>
            </div>

            <div className='aps__commission'>
              <div className='aps__commission__title'>
                Earn 15% commission of the first purchase
              </div>
              <div className='aps__commission__identifier'>
                subscription
              </div>
              <div className='aps__commission__form'>
                percentage
              </div>
              <div className='aps__commission__amount'>
                15%
              </div>
              <div className='aps__commission__actions'>
                <a href='#' onClick={this.handleToggleModal}>
                  <img src={edit} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='aps__save-container'>
          <Button primary className='aps__save'>
            Save changes
          </Button>
        </div>

        <ModalsCommissionsAdd showModal={showCommissionModal} />
      </>
    )
  }
}

export default SettingsCommissions
