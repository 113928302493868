import React, { useEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { checkToken } from '@redux/account.js'

import AppLayout from '@layout/index'
import Viewport from '@containers/Viewport'
import ToastrProvider from '@containers/ToastrProvider/index.jsx'

const App = () => {
  const [ready, setReady] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkToken(null, true))
      .finally(() => setReady(true))
  }, [])

  if (!ready) {
    return null
  }

  return (
    <Viewport>
      <ToastrProvider />
      <AppLayout />
    </Viewport>
  )
}

export default App
