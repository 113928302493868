import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import TextInput from '@components/Inputs/TextInput'

import './ChangePassword.scss'
import { changePassword } from '@redux/account'
import ModalFooter from '@components/Modal/Footer.jsx'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

const initialFields = {
  password: '',
  new_password: '',
  confirm_new_password: ''
}

export default function ChangePasswordModal ({ show, onClose }) {
  const [fields, setFields] = useState(initialFields)
  const [errors, setErrors] = useState({})

  const [loading] = useSelector(({ account }) => [account.passPending])

  const dispatch = useDispatch()
  const toastr = useToastr()

  function handleChange (field) {
    return event => {
      const val = event.currentTarget.value

      setFields(old => ({
        ...old,
        [field]: val
      }))
    }
  }

  function handleSubmit () {
    dispatch(changePassword({
      password: fields.password,
      new_password: fields.new_password,
      confirm_new_password: fields.confirm_new_password
    }))
      .then(() => {
        setFields(initialFields)

        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Password changed successfully'
        })

        return onClose()
      })
      .catch(error => {
        setFields(initialFields)

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Unable to change password. Please try again later.'
        })
        console.log('change password error', error)

        if (!error.response || !error.response.data) {
          return
        }

        const { data } = error.response

        if (
          (
            data.code === 'bad_request' ||
            data.code === 'credentials_mismatch' ||
            data.code === 'invalid_parameters'
          ) &&
          data.params
        ) {
          setErrors(data.params)
        }
      })
  }

  return (
    <Modal
      className='change-password__modal'
      show={show}
      small
    >
      <ModalHeader
        onClose={onClose}
      >
        Change password
      </ModalHeader>
      <ModalBody>
        <TextInput
          label='Current password'
          type='password'
          className=''
          labelClassName='change-password__input-label'
          value={fields.password}
          onChange={handleChange('password')}
          error={errors.password}
          subText={errors.password}
        />

        <TextInput
          label='New password'
          type='password'
          className=''
          labelClassName='change-password__input-label'
          value={fields.new_password}
          onChange={handleChange('new_password')}
          error={errors.new_password}
          subText={errors.new_password}
        />

        <TextInput
          label='Confirm new password'
          type='password'
          className=''
          labelClassName='change-password__input-label'
          value={fields.confirm_new_password}
          onChange={handleChange('confirm_new_password')}
          error={errors.confirm_new_password}
          subText={errors.confirm_new_password}
        />
      </ModalBody>
      <ModalFooter className='change-password__modal__footer'>
        <Button
          danger
          className=''
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          disabled={loading}
          primary
          className=''
          onClick={handleSubmit}
        >
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ChangePasswordModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func
}

// export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
