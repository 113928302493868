import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import './Welcome.scss'

const DashWelcome = ({ name }) => {
  return (
    <div className='pah__welcome'>
      <div className='pah__welcome__message'>
        <div className='pah__welcome__title'>
          Good afternoon, {name}
        </div>
        <div className='pah__welcome__description'>
          Here’s an overview of your partnership program.
        </div>
      </div>
    </div>
  )
}

DashWelcome.propTypes = {
  name: PropTypes.string
}

const mapStateToProps = ({ account }) => ({
  name: account.user?.name
})

export default connect(mapStateToProps)(DashWelcome)
