import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { deleteAppFeature } from '@redux/apps.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function DeleteFeatureModal ({ show, appId, feature, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    if (!feature) {
      return
    }

    dispatch(
      deleteAppFeature({
        appId,
        id: feature.id
      })
    )
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        console.error('error deleting feature', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Delete app feature
      </Header>
      <Body>
        Are you sure you want to delete this feature?
      </Body>
      <Footer className='pda-app-view__feature-modal__footer'>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteFeatureModal.propTypes = {
  appId: PropTypes.string,
  feature: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
