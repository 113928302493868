import React from 'react'

import ReferralListContainer from '@containers/PartnerDashboard/ReferralList'

import './List.scss'

const AdminReferralList = () => (
  <div className='admin-rl'>
    <div className='admin-rl__title'>
      Referrals
    </div>

    <ReferralListContainer
      limit={15}
    />
  </div>
)

export default AdminReferralList
