import React from 'react'
import PropTypes from 'prop-types'

import TextInput from '@components/Inputs/TextInput'
import Modal, { Body as ModalBody, Header as ModalHeader } from '@components/Modal'
import Button from '@components/Button'

import './StatusChangeModal.scss'

class StatusChangeModal extends React.Component {
  getAction (action) {
    switch (action) {
      case 'pay':
        return 'Pay'
      case 'decline':
        return 'Decline'
    }
  }

  render () {
    const { show, action, withdrawal } = this.props

    const actionText = this.getAction(action)

    return (
      <Modal
        show={show && withdrawal}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={this.props.onClose}
        >
          {actionText} withdrawal request
        </ModalHeader>
        <ModalBody className='pd__modals__body withdrawal-status-modal'>
          {withdrawal && withdrawal.partner && (
            <>
              <div className='withdrawal-status-modal__text'>
                Are you sure want to <strong>{actionText}</strong> the withdrawal request
                of <strong>(${withdrawal.commission})</strong>
                &nbsp;from <strong>{withdrawal.partner.name}</strong>
              </div>
            </>
          )}

          {this.props.action === 'pay' && (
            <div className='withdrawal-status-modal__input-container'>
              <TextInput
                label='Transaction ID'
                placeholder='Input PayPal transaction'
                value={this.props.transactionId}
                onChange={this.props.onChangeTransactionId}
              />
            </div>
          )}

          {this.props.error && (
            <div className='withdrawal-status-modal__error'>
              {this.props.error}
            </div>
          )}

          <div className='pd__modals__two-columns'>
            <Button primary className='pd__modals__two-columns__button' onClick={this.props.onConfirm}>
              Confirm
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.props.onClose}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

StatusChangeModal.propTypes = {
  show: PropTypes.bool,
  action: PropTypes.string,
  withdrawal: PropTypes.object,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onChangeTransactionId: PropTypes.func
}

export default StatusChangeModal
