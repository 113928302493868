import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const LOAD_PARTNER_PENDING = 'referrals/partners/LOAD_PARTNER_PENDING'
const LOAD_PARTNER_FULFILLED = 'referrals/partners/LOAD_PARTNER_FULFILLED'
const LOAD_PARTNER_REJECTED = 'referrals/partners/LOAD_PARTNER_REJECTED'

const LOAD_PARTNERS_PENDING = 'referrals/partners/LOAD_PARTNERS_PENDING'
const LOAD_PARTNERS_FULFILLED = 'referrals/partners/LOAD_PARTNERS_FULFILLED'
const LOAD_PARTNERS_REJECTED = 'referrals/partners/LOAD_PARTNERS_REJECTED'

const ADD_PARTNER_PENDING = 'referrals/partners/ADD_PARTNER_PENDING'
const ADD_PARTNER_FULFILLED = 'referrals/partners/ADD_PARTNER_FULFILLED'
const ADD_PARTNER_REJECTED = 'referrals/partners/ADD_PARTNER_REJECTED'

const UPDATE_PARTNER_PENDING = 'referrals/partners/UPDATE_PARTNER_PENDING'
const UPDATE_PARTNER_FULFILLED = 'referrals/partners/UPDATE_PARTNER_FULFILLED'
const UPDATE_PARTNER_REJECTED = 'referrals/partners/UPDATE_PARTNER_REJECTED'

const LOAD_PARTNER_USERS_PENDING = 'referrals/partners/LOAD_PARTNER_USERS_PENDING'
const LOAD_PARTNER_USERS_FULFILLED = 'referrals/partners/LOAD_PARTNER_USERS_FULFILLED'
const LOAD_PARTNER_USERS_REJECTED = 'referrals/partners/LOAD_PARTNER_USERS_REJECTED'

const ADD_PARTNER_USER_PENDING = 'referrals/partners/ADD_PARTNER_USER_PENDING'
const ADD_PARTNER_USER_FULFILLED = 'referrals/partners/ADD_PARTNER_USER_FULFILLED'
const ADD_PARTNER_USER_REJECTED = 'referrals/partners/ADD_PARTNER_USER_REJECTED'

const UPDATE_PARTNER_USER_PENDING = 'referrals/partners/UPDATE_PARTNER_USER_PENDING'
const UPDATE_PARTNER_USER_FULFILLED = 'referrals/partners/UPDATE_PARTNER_USER_FULFILLED'
const UPDATE_PARTNER_USER_REJECTED = 'referrals/partners/UPDATE_PARTNER_USER_REJECTED'

const DELETE_PARTNER_USER_PENDING = 'referrals/partners/DELETE_PARTNER_USER_PENDING'
const DELETE_PARTNER_USER_FULFILLED = 'referrals/partners/DELETE_PARTNER_USER_FULFILLED'
const DELETE_PARTNER_USER_REJECTED = 'referrals/partners/DELETE_PARTNER_USER_REJECTED'

const GEN_DEV_PROFILE_PENDING = 'referrals/partners/GEN_DEV_PROFILE_PENDING'
const GEN_DEV_PROFILE_FULFILLED = 'referrals/partners/GEN_DEV_PROFILE_FULFILLED'
const GEN_DEV_PROFILE_REJECTED = 'referrals/partners/GEN_DEV_PROFILE_REJECTED'

export const loadPartner = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNER_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', `/partners/${(payload.id || payload.slug)}`), {
      params: {
        is_slug: !payload.id ? 1 : undefined
      }
    })

    const p = {
      data: response.data
    }

    dispatch({
      type: LOAD_PARTNER_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: LOAD_PARTNER_REJECTED
    })
    throw error
  }
}

export const loadPartners = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNERS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/partners'), {
      params: {
        search: payload?.search || undefined,
        order: payload?.order || undefined,
        status: payload?.status,
        before: payload.before ? encodeURIComponent(payload.before) : undefined,
        after: payload.after ? encodeURIComponent(payload.after) : undefined,
        limit: payload?.limit,
        with_program: payload.withProgram ? '1' : undefined,
        with_users: (payload.withUser || payload.withOwner) ? '1' : undefined,
        with_owner: payload.withOwner ? '1' : undefined,
        with_level: payload.withLevel ? '1' : undefined,
        with_totals: payload.withTotals ? '1' : undefined,
        with_developer: payload.withDeveloper ? '1' : undefined
      }
    })

    const p = {
      totalResults: response.data.total,
      remainingResults: response.data.remaining,
      hasMore: response.data.has_more,
      data: response.data.data
    }

    dispatch({
      type: LOAD_PARTNERS_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: LOAD_PARTNERS_REJECTED
    })

    throw error
  }
}

export const addPartner = payload => async dispatch => {
  dispatch({
    type: ADD_PARTNER_PENDING
  })

  try {
    const response = await axios.post(getRefApiUrl('v1', '/partners'), {
      company_name: payload.companyName,
      company_url: payload.companyURL,
      email: payload.email,
      user_name: payload.name,
      custom_code: payload.customCode,
      anchored_commission_slug: payload.anchoredCommissionSlug
    }, {
      params: {
        program_slug: payload.programSlug
      }
    })

    dispatch({
      type: ADD_PARTNER_FULFILLED,
      payload: {
        totalResults: response.data.total,
        data: response.data.data
      }
    })
  } catch (error) {
    dispatch({
      type: ADD_PARTNER_REJECTED
    })

    throw error
  }
}

export const updatePartner = payload => async dispatch => {
  dispatch({
    type: UPDATE_PARTNER_PENDING
  })

  try {
    const response = await axios.post(getRefApiUrl('v1', `/partners/${(payload.id || payload.slug)}`), {
      status: payload.status,
      name: payload.name,
      url: payload.url,
      paypal_email: payload.paypal_email,
      phone: payload.phone,
      country: payload.country,
      city: payload.city,
      street: payload.street,
      postal_code: payload.postal_code
    }, {
      params: {
        is_slug: !payload.id ? 1 : undefined,
        with_welcome: payload.withWelcome ? '1' : undefined,
        with_password: payload.withPassword ? '1' : undefined
      }
    })

    dispatch({
      type: UPDATE_PARTNER_FULFILLED,
      payload: {
        totalResults: response.data.total,
        data: response.data.data
      }
    })
  } catch (error) {
    dispatch({
      type: UPDATE_PARTNER_REJECTED
    })

    throw error
  }
}

// TODO: MOVE users to account duck | as worksplace's users
export const loadPartnerUsers = payload => async dispatch => {
  dispatch({
    type: LOAD_PARTNER_USERS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', `/partners/${payload.partnerId}/users`), {
      params: {
        with_scopes: payload.withScopes ? '1' : undefined,
        limit: payload.limit || undefined,
        offset: payload.offset || undefined
      }
    })

    const data = {
      data: response.data.data,
      availableScopes: response.data?.metadata?.available_scopes || []
    }

    dispatch({
      type: LOAD_PARTNER_USERS_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_PARTNER_USERS_REJECTED
    })

    throw error
  }
}

export const addPartnerUser = payload => async dispatch => {
  dispatch({
    type: ADD_PARTNER_USER_PENDING
  })

  try {
    await axios.post(getRefApiUrl('v1', `/partners/${payload.partnerId}/users`), {
      email: payload.email,
      name: payload.name,
      scopes: payload.scopes
    })

    const data = {}

    dispatch({
      type: ADD_PARTNER_USER_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: ADD_PARTNER_USER_REJECTED
    })

    throw error
  }
}

export const updatePartnerUser = payload => async dispatch => {
  dispatch({
    type: UPDATE_PARTNER_USER_PENDING
  })

  try {
    const res = await axios.post(getRefApiUrl('v1', `/partners/${payload.partnerId}/users/${payload.userId}`), {
      email: payload.email,
      name: payload.name,
      scopes: payload.scopes
    })

    const data = {
      user: res.data
    }

    dispatch({
      type: UPDATE_PARTNER_USER_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: UPDATE_PARTNER_USER_REJECTED
    })

    throw error
  }
}

export const deletePartnerUser = payload => async dispatch => {
  dispatch({
    type: DELETE_PARTNER_USER_PENDING
  })

  try {
    await axios.delete(getRefApiUrl('v1', `/partners/${payload.partnerId}/users/${payload.userId}`))

    const data = {
      userId: payload.userId
    }

    dispatch({
      type: DELETE_PARTNER_USER_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: DELETE_PARTNER_USER_REJECTED
    })

    throw error
  }
}

export const generateDevProfile = payload => async dispatch => {
  dispatch({
    type: GEN_DEV_PROFILE_PENDING
  })

  try {
    const response = await axios.post(getRefApiUrl('v1', `/partners/${payload.partnerId}/developer-profile`), {
      capabilities: payload.capabilities
    })

    const data = {
      profile: response.data
    }

    dispatch({
      type: GEN_DEV_PROFILE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: GEN_DEV_PROFILE_REJECTED
    })

    throw error
  }
}

const initialState = {
  totalResults: 0,
  data: [],
  loading: false,
  saveLoading: false,

  partner: {
    data: null,
    loading: false
  },

  users: {
    data: [],
    availableScopes: [],
    loading: false
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PARTNERS_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_PARTNERS_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        remainingResults: action.payload.remainingResults,
        loading: false
      }
    case LOAD_PARTNERS_REJECTED:
      return {
        ...state,
        loading: false
      }

    case LOAD_PARTNER_PENDING:
      return {
        ...state,
        partner: {
          ...state.partner,
          loading: true
        }
      }
    case LOAD_PARTNER_FULFILLED:
      return {
        ...state,
        partner: {
          ...state.partner,
          loading: false,
          data: action.payload.data
        }
      }
    case LOAD_PARTNER_REJECTED:
      return {
        ...state,
        partner: {
          ...state.partner,
          loading: false
        }
      }

    case ADD_PARTNER_PENDING:
    case UPDATE_PARTNER_PENDING:
      return {
        ...state,
        saveLoading: true
      }
    case ADD_PARTNER_FULFILLED:
    case UPDATE_PARTNER_FULFILLED:
      return {
        ...state,
        saveLoading: false
      }
    case ADD_PARTNER_REJECTED:
    case UPDATE_PARTNER_REJECTED:
      return {
        ...state,
        saveLoading: false
      }

    case LOAD_PARTNER_USERS_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true
        }
      }
    case LOAD_PARTNER_USERS_FULFILLED:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.data,
          loading: false
        }
      }
    case LOAD_PARTNER_USERS_REJECTED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false
        }
      }

    case UPDATE_PARTNER_USER_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true
        }
      }
    case UPDATE_PARTNER_USER_FULFILLED:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map(user => {
            if (user.id === action.payload?.user?.id) {
              return {
                ...user,
                ...action.payload.user
              }
            }

            return user
          }),
          loading: false
        }
      }
    case UPDATE_PARTNER_USER_REJECTED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false
        }
      }
    case DELETE_PARTNER_USER_PENDING:
      return {
        ...state,
        users: {
          ...state.users,
          loading: true
        }
      }
    case DELETE_PARTNER_USER_FULFILLED:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter(user => user.id !== action.payload.userId),
          loading: false
        }
      }
    case DELETE_PARTNER_USER_REJECTED:
      return {
        ...state,
        users: {
          ...state.users,
          loading: false
        }
      }

    default:
      return state
  }
}
