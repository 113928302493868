import React from 'react'
import PropTypes from 'prop-types'

import Modal, { Header as ModalHeader, Body as ModalBody } from '@components/Modal'
import Button from '@components/Button'

import './StatusUpdateModal.scss'
import CheckBox from '@components/Inputs/CheckBox'

class PartnerStatusUpdateModal extends React.Component {
  state = {
    withPassword: false,
    withWelcome: false
  }

  handleOptionChange = field => checked => {
    const fields = {}

    if (field === 'withWelcome') {
      fields.withPassword = false
    }

    this.setState(old => ({
      ...old,
      ...fields,
      [field]: checked
    }))
  }

  handleSave = event => {
    const { withWelcome, withPassword } = this.state

    this.props.onSave({ withWelcome, withPassword })
  }

  render () {
    const { show, partner, status } = this.props

    return (
      <Modal
        show={show && partner}
        className='pd__modals'
      >
        <ModalHeader
          className='pd__modals__header'
          onClose={this.props.onClose}
        >
          Update Partner status
        </ModalHeader>
        <ModalBody className='pd__modals__body partner-status-modal'>

          {partner && (
            <>
              <div className='partner-status-modal__text'>
                Change partner <strong>{partner.name}</strong> status from&nbsp;
                <strong>{partner.status}</strong> to <strong>{status}</strong>
              </div>

              {partner.status === 'pending' && status === 'active' && (
                <>
                  <div className='partner-status-modal__options'>
                    <CheckBox
                      className='partner-status-modal__options__checkbox'
                      onChange={this.handleOptionChange('withWelcome')}
                      checked={this.state.withWelcome}
                    />
                    <span className='partner-status-modal__options__text'>Send welcome email</span>
                  </div>
                  <div className='partner-status-modal__options'>
                    <CheckBox
                      className='partner-status-modal__options__checkbox'
                      disabled={!this.state.withWelcome}
                      onChange={this.handleOptionChange('withPassword')}
                      checked={this.state.withWelcome && this.state.withPassword}
                    />
                    <span className='partner-status-modal__options__text'>With password reset link</span>
                  </div>
                </>
              )}
            </>
          )}

          <div className='pd__modals__two-columns'>
            <Button primary className='pd__modals__two-columns__button' onClick={this.handleSave}>
              Save changes
            </Button>

            <Button secondary className='pd__modals__two-columns__button' onClick={this.props.onClose}>
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

PartnerStatusUpdateModal.propTypes = {
  show: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func
}

export default PartnerStatusUpdateModal
