import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_PROGRAMS_PENDING = 'referrals/programs/LOAD_PROGRAMS_PENDING'
const LOAD_PROGRAMS_FULFILLED = 'referrals/programs/LOAD_PROGRAMS_FULFILLED'
const LOAD_PROGRAMS_REJECTED = 'referrals/programs/LOAD_PROGRAMS_REJECTED'
const LOAD_COMMISSIONS_PENDING = 'referrals/programs/LOAD_COMMISSIONS_PENDING'
const LOAD_COMMISSIONS_FULFILLED = 'referrals/programs/LOAD_COMMISSIONS_FULFILLED'
const LOAD_COMMISSIONS_REJECTED = 'referrals/programs/LOAD_COMMISSIONS_REJECTED'

export const loadPrograms = payload => async dispatch => {
  dispatch({
    type: LOAD_PROGRAMS_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/programs'), {
      params: {
        partner_count: '1',

        search: payload?.search,
        status: payload?.status,
        offset: payload?.offset,
        limit: payload?.limit
      }
    })

    dispatch({
      type: LOAD_PROGRAMS_FULFILLED,
      payload: {
        totalResults: response.data.total,
        data: response.data.data
      }
    })
  } catch (error) {
    dispatch({
      type: LOAD_PROGRAMS_REJECTED
    })
  }
}

export const loadCommissions = payload => async dispatch => {
  dispatch({
    type: LOAD_COMMISSIONS_PENDING,
    payload: {
      programSlug: payload.programSlug
    }
  })

  try {
    const res = await axios.get(getRefApiUrl('v1', `/programs/${payload.programSlug}/commissions`), {
      params: {
        limit: 30,
        tier_group: payload.tierGroup
      }
    })

    const response = {
      programSlug: payload.programSlug,
      totalResults: res.data.total,
      data: res.data.data
    }

    dispatch({
      type: LOAD_COMMISSIONS_FULFILLED,
      payload: response
    })

    return response
  } catch (error) {
    dispatch({
      type: LOAD_COMMISSIONS_REJECTED,
      payload: {
        programSlug: payload.programSlug
      }
    })
  }
}

const commissionState = {
  totalResults: 0,
  data: [],
  loading: false
}
const initialState = {
  totalResults: 0,
  data: [],
  loading: false,

  commissions: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROGRAMS_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_PROGRAMS_FULFILLED:
      return {
        ...state,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        loading: false
      }
    case LOAD_PROGRAMS_REJECTED:
      return {
        ...state,
        loading: false
      }

    case LOAD_COMMISSIONS_PENDING:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [action.payload.programSlug]: {
            ...commissionState,
            ...state.commissions[action.payload.programSlug],
            loading: true
          }
        }
      }
    case LOAD_COMMISSIONS_FULFILLED:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [action.payload.programSlug]: {
            ...state.commissions[action.payload.programSlug],
            data: action.payload.data,
            totalResults: action.payload.totalResults,
            loading: false
          }
        }
      }
    case LOAD_COMMISSIONS_REJECTED:
      return {
        ...state,
        commissions: {
          ...state.commissions,
          [action.payload.programSlug]: {
            ...state.commissions[action.payload.programSlug],
            loading: false
          }
        }
      }

    default:
      return state
  }
}
