import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { addGroup, updateGroup } from '@redux/themeFilters.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'

const initialFields = {
  name: '',
  visible: ''
}

export default function FilterGroupModal ({ show, type, group, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!show || !group || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: group.name || '',
      visible: group.visible || ''
    })
  }, [show, group])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
    }
  }, [show])
  function handleSave () {
    setSubmitting(true)
    let promise

    if (type === 'add') {
      promise = addGroup
    } else {
      promise = updateGroup
    }

    dispatch(promise({
      id: group?.id,
      data: fields
    })).then(() => {
      setSubmitting(false)
      onSuccess()
      onClose()
    }).catch(error => {
      setSubmitting(false)
      if (
        error.response && error.response.status === 400 &&
        error.response.data?.params
      ) {
        setErrors(error.response.data.params)
      } else if (
        error.response && error.response.status === 404 &&
        error.response.data?.code === 'profile_not_found'
      ) {
        setErrors(old => ({ ...old, store_id: error.response.data.message }))
      } else {
        console.log(`error @${type} filter group`, error)
      }
    })
  }

  return (
    <Modal show={show} className='pda-filters__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Group
      </Header>
      <Body className='pda-filters__add-modal__body'>
        <TextInput
          labelClassName='pda-filters__form__label'
          label='Group Name *'
          value={fields.name}
          placeholder='Slide Projector'
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />
        <SelectInput
          label='Visible *'
          labelClassName='pda-filters__form__label'
          value={fields.visible}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, visible: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: true
          }, {
            key: '0',
            label: 'No',
            value: false
          }]}
        />
      </Body>
      <Footer className='pda-filters__add-modal__footer'>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

FilterGroupModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  group: PropTypes.object
}
