import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import sanitizeHtml from 'sanitize-html'
import { DateTime } from 'luxon'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchQuestion } from '@redux/themeReviews.js'
import Button from '@components/Button/index.jsx'

import QuestionModal from './QuestionModal.jsx'
import AnswerModal from './AnswerModal.jsx'
import DeleteQuestionModal from './DeteteQuestionModal.jsx'
import DeleteAnswerModal
  from '@pages/Partners/Admin/Themes/Questions/DeleteAnswerModal.jsx'

import './Questions.scss'

function Question () {
  const [showModal, setShowModal] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState('')
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const [showAnswerModal, setShowAnswerModal] = useState(false)
  const [showDeleteAnswerModal, setShowDeleteAnswerModal] = useState(false)

  const [question] = useSelector(({ themeReviews }) => [themeReviews.question.data])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.id) {
      return
    }

    loadQuestion({
      id: params.id
    })
  }, [params.id])

  function loadQuestion ({ id }) {
    dispatch(fetchQuestion({
      id,
      withAnswer: 1
    }))
      .catch(error => {
        console.error('error fetching question: ', error)
      })
  }

  function handleQuestionModalSuccess () {
    setShowModal('')
  }

  function handleDeleteModalSuccess () {
    setShowDeleteModal('')
  }

  function handleShowEditAnswerModal (answer) {
    setSelectedAnswer(answer)
    setShowAnswerModal(true)
  }

  function handleShowDeleteAnswerModal (answer) {
    setSelectedAnswer(answer)
    setShowDeleteAnswerModal(true)
  }

  function handleCloseAnswerModal () {
    setSelectedAnswer(null)
    setShowAnswerModal(false)
  }

  function handleEditAnswerSuccessful () {
    setShowModal('')
  }

  if (!question) {
    return
  }

  return (
    <>
      <div className='theme-qa__title theme-qa__title--question'>
        Question
      </div>
      <div className='theme-qa'>
        <QuestionModal
          show={showModal !== ''}
          type={showModal}
          question={question}
          onClose={() => setShowModal('')}
          onSuccess={handleQuestionModalSuccess}
          onloadQuestion={loadQuestion}
        />
        <DeleteQuestionModal
          show={showDeleteModal !== ''}
          id={question.id}
          onClose={() => setShowDeleteModal('')}
          onSuccess={handleDeleteModalSuccess}
        />
        <div className='theme-qa__row theme-qa__row--top'>
          <div className='theme-qa__row__status'>
            <strong>Status:</strong> {question.status}
          </div>
          <div className='theme-qa__row__date'>
            <strong>Date: </strong>{DateTime.fromISO(question.created_at).toFormat('dd MMM, y')}
          </div>
          <div className='theme-qa__cta'>
            <Button onClick={() => setShowModal('edit')} primary>
              Edit Question
            </Button>
            <Button onClick={() => setShowDeleteModal('delete')} danger>
              Delete Question
            </Button>
          </div>
        </div>
        <div className='theme-qa__row'>
          <div className='theme-qa__item'>
            <strong>Asked by</strong>
            <div>{question.name}</div>
          </div>
          <div className='theme-qa__item'>
            <strong>Email</strong>
            <div> {question.email} </div>
          </div>
        </div>
        <div className='theme-qa__row'>
          <div className='theme-qa__item'>
            <strong>Shopify theme name</strong>
            <div>{question.theme_name}</div>
          </div>
        </div>
        <div className='theme-qa__row'>
          <div>
            <strong>Question</strong>
            <div>
              {question.question}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='theme-qa__title theme-qa__title--answer'>
          Answers
        </div>
        <AnswerModal
          show={showAnswerModal}
          answer={selectedAnswer}
          onClose={() => handleCloseAnswerModal('')}
          onSuccess={handleEditAnswerSuccessful}
        />
        <DeleteAnswerModal
          show={showDeleteAnswerModal}
          id={selectedAnswer?.id}
          onClose={() => setShowDeleteAnswerModal('')}
          onSuccess={handleDeleteModalSuccess}
        />
        {question?.answers?.map(answer => (
          <div
            key={answer.id}
            className={classnames('theme-qa__answer', {
              'theme-qa__answer--developer': answer.developer_id
            })}
          >
            <div className='theme-qa__row theme-qa__row--top'>
              <div className='theme-qa__row__status'>
                {answer.developer_id
                  ? (
                    <strong>Developer answer</strong>)
                  : (
                    <>
                      <strong>Status:</strong>&nbsp;{answer.status}
                    </>)}
              </div>
              <div className='theme-qa__row__date'>
                <strong>Date: </strong>{DateTime.fromISO(answer.created_at).toFormat('dd MMM, y')}
              </div>
              {answer.developer_id
                ? (
                  <strong>To edit, login as developer</strong>)
                : (
                  <div className='theme-qa__cta'>
                    <Button onClick={() => handleShowEditAnswerModal(answer)} primary>
                      Edit Answer
                    </Button>
                    <Button onClick={() => handleShowDeleteAnswerModal(answer)} danger>
                      Delete Answer
                    </Button>
                  </div>)}
            </div>
            <div className='theme-qa__row'>
              <div className='theme-qa__item theme-qa__item--by'>
                <strong>Answered by</strong>
                <div>{answer.name}</div>
              </div>
              {!answer.developer_id && (
                <div className='theme-qa__item'>
                  <strong>Email</strong>
                  <div> {answer.email} </div>
                </div>
              )}
            </div>
            <div className='theme-qa__row'>
              <div>
                <strong>Answer</strong>
                {answer.developer_id && (
                  <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(answer.answer) }} />
                )}
                {!answer.developer_id && (
                  <div>{answer.answer}</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

Question.prototype = {}

export default Question
