import React from 'react'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import Dropdown from '@components/Dropdown'
import TextInput from '@components/Inputs/TextInput'
import MonthGrid from '@components/DatePicker/MonthGrid'

import calendar from '@assets/icons/calendar.svg'

import './ComparePicker.scss'
import SelectInput from '@components/Inputs/SelectInput'
import CheckBox from '@components/Inputs/CheckBox'

const rangePresets = [
  'all_time',
  'last_90',
  'last_30',
  'last_7'
]
const compareOptions = [{
  key: 'previous_period',
  value: 'previous_period',
  label: 'Previous period'
}]

export const presetName = preset => {
  switch (preset) {
    case 'all_time':
      return 'All time'
    case 'last_90':
      return 'Last 90 days'
    case 'last_30':
      return 'Last 30 days'
    case 'last_7':
      return 'Last 7 days'
    default:
      return 'Custom'
  }
}

class ComparePicker extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      ready: false,
      current: {
        month: null,
        start: null,
        end: null,
        picking: false
      },
      preset: '',
      open: false,

      compare: false,
      comparePeriod: {
        month: null,
        start: null,
        end: null,
        picking: false
      },
      comparePreset: 'previous_period'
    }

    this.renderBody = this.renderBody.bind(this)
    this.handleChangeMonth = this.handleChangeMonth.bind(this)
    this.handleDayChange = this.handleDayChange.bind(this)

    this.handleDropToggle = this.handleDropToggle.bind(this)
    this.handleDropClose = this.handleDropClose.bind(this)
    this.handleClickPreset = this.handleClickPreset.bind(this)
    this.handleCompareChange = this.handleCompareChange.bind(this)
    this.handleClickComparePreset = this.handleClickComparePreset.bind(this)
  }

  componentDidMount () {
    const { start, end, preset } = this.props

    let s = DateTime.fromISO(start)
    let e = DateTime.fromISO(end)

    if (s.isValid && e.isValid) {
      s = s.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      e = e.set({ hour: 23, minute: 59, second: 59 })
      const month = e.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })

      this.setState(old => ({
        ...old,
        ready: true,
        current: {
          ...old.current,
          month: month,
          start: s,
          end: e
        },
        preset: 'custom'
      }))

      return
    }

    this.handleClickPreset(preset || 'last_30', true)
  }

  handleDropToggle () {
    const isOpen = this.state.open

    if (isOpen) {
      this.handleDropClose()
    } else {
      this.setState(old => ({
        ...old,
        open: true
      }))
    }
  }

  handleDropClose () {
    const { preset, current, compare, comparePeriod } = this.state
    const cStart = current.start
    let cEnd = current.end

    const coStart = comparePeriod.start
    let coEnd = comparePeriod.end

    if (current.start && (!current.end || current.picking)) {
      cEnd = current.start.set({ hour: 23, minute: 59, second: 59 })
    } else if (!current.start || !current.end) {
      return
    }

    if (compare && comparePeriod.start && (!comparePeriod.end || comparePeriod.picking)) {
      coEnd = comparePeriod.start.set({ hour: 23, minute: 59, second: 59 })
    } else if (compare && (!comparePeriod.start || !comparePeriod.end)) {
      return
    }

    this.setState(old => ({
      ...old,
      current: {
        ...old.current,
        start: cStart,
        end: cEnd
      },
      comparePeriod: {
        ...old.current,
        start: coStart,
        end: coEnd
      },
      open: false

      // allow picking end after close then
      // picking: false leave picking = true
    }), () => {
      this.props.onChange && this.props.onChange({
        period: {
          start: cStart,
          end: cEnd
        },
        compare,
        comparePeriod: {
          start: coStart,
          end: coEnd
        },
        preset
      })
    })
  }

  handleClickPreset (preset, force = false) {
    // all_time = default
    const end = DateTime.now().toUTC().set({ hour: 23, minute: 59, second: 59 })
    const month = end.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
    let start = end.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    if (preset === 'all_time') {
      start = DateTime.fromFormat('01-01-2022', 'd-M-y').toUTC()
    } else if (preset === 'last_90') {
      start = end.minus({ days: 90 })
    } else if (preset === 'last_30') {
      start = end.minus({ days: 29 })
    } else if (preset === 'last_7') {
      start = end.minus({ days: 6 })
    }

    start = start.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

    this.setState(old => ({
      ...old,
      ready: true,
      current: {
        ...old.current,
        start,
        end,
        month,
        picking: false
      },
      preset
    }), () => {
      this.handleCompareChange(this.state.compare)

      if (force) {
        this.handleDropClose()
      }
    })
  }

  handleChangeMonth (type) {
    return ({ month }) => {
      this.setState(old => ({
        ...old,
        [type]: {
          ...old[type],
          month
        }
      }))
    }
  }

  handleDayChange (type) {
    return ({ start, end }) => {
      this.setState(old => ({
        ...old,
        [type]: {
          ...old[type],
          start,
          end
        },
        preset: 'custom'
      }))
    }
  }

  async handleCompareChange (checked) {
    await this.handleClickComparePreset('previous_period')

    this.setState(old => ({
      ...old,
      compare: checked
    }))
  }

  handleClickComparePreset (preset) {
    return new Promise(resolve => {
      switch (preset) {
        case 'previous_period': {
          const diff = this.state.current.start.diff(this.state.current.end, ['days'])
          const cEnd = this.state.current.start.plus({ seconds: -1 })
          const cStart = cEnd.plus({ days: Math.abs(diff.days) * -1 })

          this.setState(old => ({
            ...old,
            comparePeriod: {
              ...old.comparePeriod,
              start: cStart,
              end: cEnd,
              month: cEnd.set({ day: 1, hour: 0, minute: 0, second: 0, millisecond: 0 })
            }
          }), () => resolve())
        }
          break
        default:
          // default to same period
          this.setState(old => ({
            ...old,
            comparePeriod: {
              ...old.comparePeriod,
              start: old.current.start,
              end: old.current.end,
              month: old.current.month
            }
          }), () => resolve())
      }
    })
  }

  render () {
    const { right } = this.props

    return (
      <div
        className='hc-compare-picker-container'
      >
        <Dropdown
          activator={props => this.renderActivator(props)}
          body={this.renderBody}
          controlled
          open={this.state.open}
          onClickOutside={this.handleDropClose}
          right={right}
        />
      </div>
    )
  }

  renderCustomRange () {
    const { preset } = this.state
    const { start, end } = this.state.current

    let range = ''

    if (preset !== 'custom') {
      return
    }

    if (
      start && end &&
      start.hasSame(end, 'day')
    ) {
      // show single day if both start/end are the same day
      range = start.toFormat('M/d/y')
    } else {
      range = start ? start.toFormat('M/d/y') : ''

      if (end) {
        range += ' ~ '
      }

      range += end ? end.toFormat('M/d/y') : ''
    }

    if (!range) {
      return null
    }

    return range
  }

  renderActivator () {
    const { preset } = this.state

    return (
      <div className='hc-compare-picker' onClick={this.handleDropToggle}>
        <img className='hc-compare-picker__calendar' alt='date picker' src={calendar} />
        <div className='hc-compare-picker__text'>
          {preset === 'custom' ? this.renderCustomRange() : presetName(preset)}
        </div>
      </div>
    )
  }

  renderBody () {
    if (!this.state.ready) {
      return null
    }

    const { month, start, end } = this.state.current

    return (
      <div className='hc-compare-picker__body'>
        {this.state.compare && (
          <div className='hc-compare-picker__month-grid'>
            <MonthGrid
              start={this.state.comparePeriod.start}
              end={this.state.comparePeriod.end}
              month={this.state.comparePeriod.month}
              onChange={this.handleDayChange('comparePeriod')}
              onChangeMonth={this.handleChangeMonth('comparePeriod')}
            />
          </div>
        )}
        <div className='hc-compare-picker__month-grid'>
          <MonthGrid
            start={start}
            end={end}
            month={month}
            onChange={this.handleDayChange('current')}
            onChangeMonth={this.handleChangeMonth('current')}
          />
        </div>

        <div className='hc-compare-picker__info'>
          <div className='hc-compare-picker__presets'>
            <SelectInput
              label='Date Range'
              className='hc-compare-picker__range-select'
              options={rangePresets.map(o => ({
                key: o,
                value: o,
                label: presetName(o)
              }))}
              value={this.state.preset}
              placeholder='Custom'
              onChange={this.handleClickPreset}
            />
          </div>

          <div className='hc-compare-picker__inputs'>
            <TextInput
              className='hc-compare-picker__input'
              labelClassName='hc-compare-picker__label'
              placeholder='MMM d, Y'
              value={start ? start.toFormat('LLL d, y') : ''}
              onChange={() => {}}
              disabled
            /> -&nbsp;
            <TextInput
              className='hc-compare-picker__input'
              labelClassName='hc-compare-picker__label'
              placeholder='MMM d, Y'
              value={end ? end.toFormat('LLL d, y') : ''}
              onChange={() => {}}
              disabled
            />
          </div>

          <div className='hc-compare-picker__compare'>
            <SelectInput
              label={(
                <div className='hc-compare-picker__compare__check'>
                  <CheckBox
                    className='hc-compare-picker__compare__checkbox'
                    checked={this.state.compare}
                    onChange={this.handleCompareChange}
                    inline
                  />
                  <span>compare to</span>
                </div>
              )}
              className='hc-compare-picker__range-select'
              options={compareOptions}
              value={this.state.comparePreset}
              placeholder='Custom'
              onChange={this.handleClickComparePreset}
              disabled={!this.state.compare}
            />
          </div>
        </div>
      </div>
    )
  }
}

ComparePicker.propTypes = {
  // ISO Date
  start: PropTypes.string,
  end: PropTypes.string,
  preset: PropTypes.string,

  onChange: PropTypes.func,

  left: PropTypes.bool
}

export default ComparePicker
