import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { shortAmount } from '@util/currency.js'
import { loadPartnerStats } from '@redux/referrals/dashboard.js'

import InfoCard from '@components/InfoCard/index.jsx'

const parseStats = (stats) => {
  return stats.reduce((acc, current) => {
    if (current.name === 'conversion_amount') {
      acc[current.name] = shortAmount(Number(current.value) || 0, 'USD')
    } else {
      acc[current.name] = current.value
    }

    return acc
  }, {})
}

export default function ReferralsHero ({ start, end }) {
  const dispatch = useDispatch()

  const [visits, setVisits] = useState(0)
  const [projects, setProjects] = useState(0)
  const [purchases, setPurchases] = useState(0)
  const [sales, setSales] = useState(shortAmount(0, 'USD'))

  useEffect(() => {
    if (!start && !end) {
      return
    }

    dispatch(loadPartnerStats({
      start,
      end
    }))
      .then(({ stats }) => {
        const parsed = parseStats(stats)

        setVisits(parsed.new_click || 0)
        setProjects(parsed.task_submitted_first || 0)
        setPurchases(parsed.new_conversion || 0)
        setSales(parsed.conversion_amount)
      })
      .catch(error => {
        console.error('referrals stats error', error)
      })
  }, [start, end])

  return (
    <div className='referrals-page__stats'>
      <InfoCard
        value={visits || ''}
        description='Unique visits'
        className=''
        toolTip={(
          <span className='pdash__conversions__report__tooltip'>
            Once a referred client clicks your partner URL, we track it to associate their visit with your partner account, if they become our customer.
          </span>
        )}
      />

      <InfoCard
        value={projects || ''}
        description='Submitted projects'
        className=''
        toolTip={(
          <span className='pdash__conversions__report__tooltip'>
            Once a referred client submits a project request, we track it to  ensure the commission is added to your balance, when they make a purchase.
          </span>
        )}
      />

      <InfoCard
        value={purchases || ''}
        description='Purchases'
        className=''
        toolTip={(
          <span className='pdash__conversions__report__tooltip'>
            Once a referred client makes a purchase, the commission is added to your partner balance.
          </span>
        )}
      />

      <InfoCard
        value={sales || ''}
        description='Total referred volume (excl. Pending)'
        className=''
        toolTip={(
          <span className='pdash__conversions__report__tooltip'>
            The total amount of purchases your referred clients placed with us, not counting pending commissions.
          </span>
        )}
      />
    </div>
  )
}

ReferralsHero.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string
}
