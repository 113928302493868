import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'
import { acceptInvite, checkInvite } from '@redux/account.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, TOASTR_WARNING, useToastr } from '@hooks/toastr.js'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import './WorkspaceInvite.scss'

const initialFields = {
  password: '',
  name: '',
  confirmPassword: ''
}

const WorkspaceInvite = () => {
  const [fields, setFields] = useState(initialFields)
  const [errors, setErrors] = useState({})
  const [searchParams] = useSearchParams()

  const [invite] = useSelector(({ account }) => [account.invite])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toastr = useToastr()
  const [, setBackLogin] = useBackToLogin()
  const [, setBackWebsite] = useBackToWebsite()

  const email = searchParams.get('email')
  const token = searchParams.get('token')

  useEffect(() => {
    setBackLogin(true)
    setBackWebsite(false)
  }, [])

  useEffect(() => {
    if (!email || !token) {
      setInvalidToken()
      return
    }

    dispatch(checkInvite({
      email,
      token: decodeURIComponent(token)
    }))
      .catch(error => {
        console.log('check invite error', error)
        setInvalidToken()
      })
  }, [email, token])

  function setInvalidToken () {
    toastr({
      type: TOASTR_WARNING,
      title: 'Invalid invitation',
      message: 'The invitation link is invalid'
    })

    setTimeout(() => navigate('/login', { replace: true }), 1000)
  }

  function onSubmitAcceptInvite (event) {
    event.preventDefault()

    if (invite.loading) {
      return
    }

    setErrors({})

    if (!invite.userExist) {
      const validate = {
        name: value => !value.length ? 'Name is required' : '',
        password: value => !value.length ? 'Password is required' : '',
        confirmPassword: value => !value.length ? 'Confirm password is required' : ''
      }

      const errors = Object.keys(validate).reduce((errors, key) => {
        const error = validate[key](fields[key])

        if (error) {
          errors[key] = error
        }

        return errors
      }, {})

      if (fields.password !== fields.confirmPassword) {
        errors.confirmPassword = 'Confirm password does not match'
      }

      if (Object.keys(errors).length) {
        setErrors(errors)
        return
      }
    }

    dispatch(acceptInvite({
      email,
      token: decodeURIComponent(token),
      ...({
        name: fields.name,
        password: fields.password
      })
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'You have accepted the invitation successfully. please login to continue.'
        })

        setTimeout(() => navigate('/login', { replace: true }), 1000)
      })
      .catch(error => {
        let type = TOASTR_DANGER
        let message = 'Please try again later.'

        if ((error.response?.status === 400 || error.response?.status === 403) && error.response.data?.message) {
          message = error.response.data.message

          if (error.response.data.code === 'user_invite_accepted') {
            type = TOASTR_WARNING
            message = 'You have already accepted the invitation. please login to continue.'
          }
        }

        toastr({
          type: type,
          title: 'Something went wrong',
          message
        })
      })
  }

  return (
    <div className='ws-invite'>
      <h1 className='ws-invite__title'>
        Hello!
      </h1>

      <div className='ws-invite__desc'>
        You have been invited to join the <strong>[{invite.workspaceName || '...'}]</strong> workspace.
      </div>
      <form
        action='#'
        onSubmit={onSubmitAcceptInvite}
        className='ws-invite__form'
      >
        <div
          className={classnames('ws-invite__form-group', {
            'ws-invite__form-group--show': !invite.userExist
          })}
        >
          <TextInput
            disabled={invite.userExist}
            label='Name'
            placeholder='Enter your name'
            type='text' value={fields.name} onChange={event => setFields({ ...fields, name: event.target.value })}
            error={errors.password}
            subText={errors.password}
          />
        </div>
        <div className='ws-invite__form-group ws-invite__form-group--show'>
          <TextInput
            disabled
            label='Email address'
            placeholder='you@example.com'
            type='email'
            value={email}
          />
        </div>
        <div
          className={classnames('ws-invite__form-group', {
            'ws-invite__form-group--show': !invite.userExist
          })}
        >
          <TextInput
            label='Password'
            placeholder='Enter your password'
            type='password' value={fields.password}
            onChange={event => setFields({ ...fields, password: event.target.value })}
            error={errors.password}
            subText={errors.password}
          />
        </div>
        <div
          className={classnames('ws-invite__form-group', {
            'ws-invite__form-group--show': !invite.userExist
          })}
        >
          <TextInput
            label='Confirm password'
            placeholder='Enter your password'
            type='password' value={fields.confirmPassword}
            onChange={event => setFields({ ...fields, confirmPassword: event.target.value })}
            error={errors.confirmPassword}
            subText={errors.confirmPassword}
          />
        </div>

        <Button
          type='submit' primary expanded
          className='ws-invite__sign-in'
          disabled={invite.loading}
        >
          {invite.loading ? 'Loading...' : 'Accept invitation'}
        </Button>
      </form>
    </div>
  )
}

export default WorkspaceInvite
