import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import ShadowBox from '@components/ShadowBox/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

export default function LinkSection () {
  const copiedTimeout = useRef(null)
  const [copied, setCopied] = useState('')
  const [refCode, setRefCode] = useState('')
  const [vReady, setVReady] = useState(false)

  const [width, refCodes] = useSelector(({ viewport, referrals: { refCodes } }) => [
    viewport.width,
    refCodes.data
  ])

  useEffect(() => {
    if (!refCodes?.length) {
      return
    }

    setRefCode(refCodes[0].custom_code)
  }, [refCodes?.length])

  useEffect(() => {
    if (!width) {
      return
    }

    setVReady(true)
  }, [])

  async function handleClickRefLink (event) {
    let target = event.currentTarget

    if (target.tagName.toLowerCase() === 'button') {
      target = target.previousElementSibling

      if (target) {
        target.focus()
      }

      return
    }

    const link = target.value
    target.setSelectionRange(0, target.value.length)

    try {
      await window.navigator.clipboard.writeText(target.value)

      clearTimeout(copiedTimeout.current)

      setCopied(link)

      copiedTimeout.current = setTimeout(() => {
        setCopied('')
      }, 800)
    } catch (error) {
      console.log('navigator error', error)
    }
  }

  return (
    <section className='p-resources-page__section'>
      <div className='p-resources-page__links'>
        <div className='p-resources-page__links__column'>
          <div className='p-resources-page__section__sub-title'>
            Your referral link&nbsp;
            {copied.includes('ambassadors') && <span className='p-resources-page__links__copied'>- Copied!</span>}
          </div>
          <ShadowBox className='p-resources-page__links__body'>
            <TextInput
              small
              multiline={vReady && width < 650 ? 2 : 1}
              readOnly
              className='p-resources-page__links__input'
              placeholder='loading...'
              value={refCode ? `${import.meta.env.VITE_SITE_URL}/ambassadors/?ref=${refCode}` : ''}
              onFocus={handleClickRefLink}
              resize={false}
            />
            <Button className='p-resources-page__links__button' primary onClick={handleClickRefLink}>
              Copy Link
            </Button>
          </ShadowBox>
        </div>
      </div>
    </section>
  )
}
