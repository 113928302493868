import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { fetchApps } from '@redux/apps.js'
import { updateReview } from '@redux/appReviews.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'

const initialFields = {
  status: '',
  app_id: '',
  app_name: '',
  user_name: '',
  email: '',
  store_url: '',
  ease_rating: '',
  features_rating: '',
  support_rating: '',
  overall_experience: '',
  value_rating: '',
  recommended: '',
  is_highlighted: ''
}

export default function ReviewModal ({ show, type, review, onClose, onSuccess, onLoadReview }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const searchTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [apps, setApps] = useState([])
  const [loadingApps] = useSelector(({ apps }) => [apps.list.loading])
  const [submitting] = useSelector(({ appReviews }) => [appReviews.submitting])
  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    if (!show || !review || type !== 'edit') {
      return
    }
    setFields({
      ...initialFields,
      status: review.status,
      app_id: review.app_id || '',
      app_name: review.app_name || '',
      user_name: review.user_name,
      email: review.email,
      store_url: review.store_url || '',
      ease_rating: review.ease_rating || null,
      features_rating: review.features_rating || null,
      support_rating: review.support_rating || null,
      overall_experience: review.overall_experience || '',
      value_rating: review.value_rating || null,
      recommended: review.recommended || '',
      is_highlighted: review.is_highlighted ? '1' : '0'
    })

    setSearch(review?.app_name || '')
  }, [show, review])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setApps([])
      setSearch('')
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, app_name: '', app_id: '' })
      setApps([])

      return
    }

    searchTimeout.current = setTimeout(() => {
      loadApps(search)
    }, 400)

    return () => {
      clearTimeout(searchTimeout.current)
    }
  }, [search])

  function loadApps (search) {
    setApps([])

    dispatch(
      fetchApps({
        search,
        limit: 10,
        offset: 0
      })
    )
      .then(({ apps }) => {
        setApps(apps.map(app => ({
          key: app.id,
          label: app.name + ' - ' + app.developer_name,
          value: app.id
        })))
      })
      .catch(error => {
        console.log('error fetch @apps: ', error)
      })
  }

  function handleSave () {
    const data = new window.FormData()

    const selectedApp = apps.find(app => app.value === fields.app_id)

    Object.entries({
      status: fields.status,
      app_id: fields.app_id,
      app_name: selectedApp?.label || fields.app_name,
      user_name: fields.user_name,
      email: fields.email,
      store_url: fields.store_url,
      ease_rating: fields.ease_rating,
      features_rating: fields.features_rating,
      support_rating: fields.support_rating,
      overall_experience: fields.overall_experience,
      value_rating: fields.value_rating,
      recommended: fields.recommended,
      is_highlighted: fields.is_highlighted === '1' ? '1' : '0'
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    dispatch(updateReview({
      id: review?.id,
      data
    }))
      .then(() => {
        onSuccess()
        if (onLoadReview) {
          onLoadReview({
            id: review.id
          })
        }
        onClose()
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Review updated successfully.'
        })
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'app_not_found'
        ) {
          setErrors(old => ({ ...old, app_id: error.response.data.message }))
        } else {
          console.log(`error @${type} review`, error)
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message: error.response?.data?.message || 'Please try again later.'
        })
      })
  }

  return (
    <Modal show={show} large className='pda-app__add-modal'>
      <Header onClose={onClose}>
        Edit Review
      </Header>
      <Body className='pda-app__add-modal__body'>
        <Columns>
          <SelectInput
            label='Status'
            labelClassName='pda-app__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '2',
              label: 'Pending',
              value: 'pending'
            }, {
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
          <TextInput
            labelClassName='pda-app__form__label'
            label='User Name'
            value={fields.user_name}
            onChange={event => setFields({ ...fields, user_name: event.currentTarget.value })}
            error={errors.user_name}
            subText={errors.user_name}
          />
        </Columns>
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Email'
            value={fields.email}
            onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
            error={errors.email}
            subText={errors.email}
          />
          <TextInput
            labelClassName='pda-app__form__label'
            label='Store URL'
            value={fields.store_url}
            onChange={event => setFields({ ...fields, store_url: event.currentTarget.value })}
            error={errors.store_url}
            subText={errors.store_url}
          />
        </Columns>

        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Search App By Name'
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />

          <SelectInput
            label='App'
            labelClassName='pda-app__form__label'
            value={fields.app_id}
            placeholder={loadingApps ? 'Loading...' : !apps.length ? 'No app found' : 'Select...'}
            onChange={value => setFields({ ...fields, app_id: value })}
            options={apps}
            disabled={!apps.length || loadingApps}
            error={errors.app_id}
            subText={errors.app_id}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='Rate the ease of use *'
            labelClassName='pda-app__form__label'
            value={fields.ease_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, ease_rating: value })}
            options={[{
              key: '4',
              label: '5 out of 5',
              value: 5
            }, {
              key: '3',
              label: '4 out of 5',
              value: 4
            }, {
              key: '2',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
          <SelectInput
            label='Rate the quality of support and documentation *'
            labelClassName='pda-app__form__label'
            value={fields.support_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, support_rating: value })}
            options={[{
              key: '4',
              label: '5 out of 5',
              value: 5
            }, {
              key: '3',
              label: '4 out of 5',
              value: 4
            }, {
              key: '2',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='Rate the design and functionality options *'
            labelClassName='pda-app__form__label'
            value={fields.features_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, features_rating: value })}
            options={[{
              key: '4',
              label: '5 out of 5',
              value: 5
            }, {
              key: '3',
              label: '4 out of 5',
              value: 4
            }, {
              key: '2',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
          <SelectInput
            label='Did the app provide good value for its price? *'
            labelClassName='pda-app__form__label'
            value={fields.value_rating}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, value_rating: value })}
            options={[{
              key: '4',
              label: '5 out of 5',
              value: 5
            }, {
              key: '3',
              label: '4 out of 5',
              value: 4
            }, {
              key: '2',
              label: '3 out of 5',
              value: 3
            }, {
              key: '1',
              label: '2 out of 5',
              value: 2
            }, {
              key: '0',
              label: '1 out of 5',
              value: 1
            }]}
          />
        </Columns>
        <Columns>
          <SelectInput
            label='How likely are you to recommend this app to other users?'
            labelClassName='pda-app__form__label'
            value={fields.recommended}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, recommended: value })}
            options={[{
              key: '2',
              label: 'Very Likely',
              value: 'Very Likely'
            }, {
              key: '1',
              label: 'Neutral',
              value: 'Neutral'
            }, {
              key: '0',
              label: 'Not very likely',
              value: 'Not very likely'
            }]}
          />
          <SelectInput
            label='Highlighted Review?'
            labelClassName='pda-app__form__label'
            value={fields.is_highlighted}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, is_highlighted: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />
        </Columns>
        <TextInput
          labelClassName='pda-app__form__label'
          multiline={3}
          label='Review: Describe your overall experience with the app.'
          value={fields.overall_experience}
          onChange={event => setFields({ ...fields, overall_experience: event.currentTarget.value })}
          error={errors.overall_experience}
          subText={errors.overall_experience}
        />
      </Body>
      <Footer>
        <Button onClick={handleSave} disabled={submitting} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

ReviewModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  onLoadReview: PropTypes.func,
  review: PropTypes.object
}
