import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { deleteAppCategory } from '@redux/appCategories.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import Button from '@components/Button/index.jsx'

export default function DeleteCategoryModal ({ show, category, onClose, onSuccess }) {
  const dispatch = useDispatch()

  function handleConfirm () {
    if (!category) {
      return
    }

    dispatch(
      deleteAppCategory({
        id: category.id
      })
    )
      .then(() => {
        onSuccess()
      })
      .catch(error => {
        console.error('error deleting category', error)
      })
  }

  return (
    <Modal show={show}>
      <Header onClose={onClose}>
        Delete app category
      </Header>
      <Body>
        Are you sure you want to delete this category?
      </Body>
      <Footer>
        <Button onClick={handleConfirm} primary>
          Delete
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

DeleteCategoryModal.propTypes = {
  category: PropTypes.object,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
}
