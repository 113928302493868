import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import TextInput from '@components/Inputs/TextInput'

import searchIcon from '@assets/icons/search.svg'
import filterIcon from '@assets/icons/filter.svg'

import './SearchBar.scss'
import Dropdown from '@components/DropdownList'

class SearchBar extends React.Component {
  inputElm = null

  componentDidMount () {

  }

  handleClickSearchIcon = () => {
    if (!this.inputElm) {
      return
    }

    this.inputElm.focus()
  }

  render () {
    const { value, placeholder, filters, onChange, className } = this.props

    return (
      <div className={classnames('search_bar', className)}>
        <div className='search_bar__icon' onClick={this.handleClickSearchIcon}>
          <img src={searchIcon} alt='search' />
        </div>

        <div className='search_bar__input-container'>
          <TextInput
            inputRef={dom => (this.inputElm = dom)}
            className='search_bar__input'
            placeholder={placeholder}
            onChange={onChange}
            value={value}
          />
        </div>

        {filters.length > 0 && (
          <Dropdown
            onClose={() => {}}
            activator={this.renderFiltersActivator}
            options={this.renderFiltersBody}
            onClickOption={this.props.onClickFilter}
          />
        )}
      </div>
    )
  }

  renderFiltersActivator = ({ onClick }) => {
    return (
      <div className='search_bar__filters' onClick={onClick}>
        <div className='search_bar__filters__separator' />
        <img src={filterIcon} className='search_bar__filters__icon' alt='filter' />
        <div className='search_bar__filters__title'>
          Filters
        </div>
      </div>
    )
  }

  renderFiltersBody = () => {
    return this.props.filters
  }
}

SearchBar.defaultProps = {
  filters: []
}

SearchBar.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.array,
  onChange: PropTypes.func,
  onClickFilter: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string
}

export default SearchBar
