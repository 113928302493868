import React, { useEffect, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { toggle } from '@redux/sidebar.js'

import SidebarItem from '@components/Sidebar/Item.jsx'
import Sidebar from '@components/Sidebar/index.jsx'
import Button from '@components/Button/index.jsx'

import overview from '@assets/icons/overview-black.svg'
import referral from '@assets/icons/referrals-black.svg'
import reviews from '@assets/icons/reviews-black.svg'
import overviewPurple from '@assets/icons/overview-purple.svg'
import referralPurple from '@assets/icons/referrals-purple.svg'
import reviewsPurple from '@assets/icons/reviews-purple.svg'
import resources from '@assets/icons/resources-black.svg'
import resourcesPurple from '@assets/icons/resources-purple.svg'

import './Sidebar.scss'

export default function PartnerSidebar () {
  const location = useLocation()
  const [refCode, setRefCode] = useState(null)

  const [
    showSidebar,
    workspace,
    refCodes
  ] = useSelector(({ sidebar, account, referrals: { refCodes } }) => [
    sidebar.show,
    account.workspace,
    refCodes.data
  ])
  const themeAccess = (workspace?.scopes || []).includes('partner:themes:read')
  const appAccess = (workspace?.scopes || []).includes('partner:apps:read')

  const dispatch = useDispatch()

  useEffect(() => {
    if (!refCodes.length) {
      return
    }

    setRefCode(refCodes[0]?.custom_code)
  }, [refCodes?.length])

  const homeActive = matchPath({ path: '/', end: true }, location.pathname)
  const referralsActive = matchPath({ path: '/referrals', end: true }, location.pathname)
  const reviewsActive = matchPath({ path: '/reviews', end: true }, location.pathname)
  const appReviewsActive = matchPath({ path: '/app-reviews', end: true }, location.pathname)
  const resourceActive = matchPath({ path: '/resources', end: true }, location.pathname)

  return (
    <Sidebar
      open={showSidebar}
      onClose={() => dispatch(toggle(false))}
      showHelp
      helpUrl='mailto:partners@heycarson.com'
    >
      {refCode && (
        <div className='main-sidebar__partner-submit-project'>
          <Link to={`/submit-project/${refCode}`} target='_blank'>
            <Button expanded primary>Submit a Project</Button>
          </Link>
        </div>
      )}

      <SidebarItem
        to='/'
        active={homeActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={homeActive ? overviewPurple : overview}
          alt='overview' className='main-sidebar__item__icon'
        />
        Overview
      </SidebarItem>
      <SidebarItem
        to='/referrals'
        active={referralsActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={referralsActive ? referralPurple : referral}
          alt='referral' className='main-sidebar__item__icon'
        />
        Conversions
      </SidebarItem>
      <SidebarItem
        to='/resources'
        active={resourceActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={resourceActive ? resourcesPurple : resources}
          alt='referral' className='main-sidebar__item__icon'
        />
        Resources
      </SidebarItem>

      {themeAccess && (
        <SidebarItem
          to='/reviews'
          active={reviewsActive}
          onClick={() => dispatch(toggle(false))}
        >
          <img
            src={reviewsActive ? reviewsPurple : reviews}
            alt='reviews' className='main-sidebar__item__icon'
          />
          Theme Reviews
        </SidebarItem>
      )}
      {appAccess && (
        <SidebarItem
          to='/app-reviews'
          active={appReviewsActive}
          onClick={() => dispatch(toggle(false))}
        >
          <img
            src={appReviewsActive ? reviewsPurple : reviews}
            alt='reviews' className='main-sidebar__item__icon'
          />
          App Reviews
        </SidebarItem>
      )}
    </Sidebar>
  )
}
