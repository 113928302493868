const TOGGLE_SIDEBAR = 'sidebar/TOGGLE_SIDEBAR'

export const toggle = show => ({
  type: TOGGLE_SIDEBAR,
  payload: show
})

const initialState = {
  show: false
}

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...state,
        show: action.payload
      }
    default:
      return state
  }
}
