import React from 'react'
import PropTypes from 'prop-types'

const ModalColumns = ({ children }) => {
  return (
    <div className='hc-modal__columns'>
      {children}
    </div>
  )
}

ModalColumns.propTypes = {
  children: PropTypes.node
}
export default React.memo(ModalColumns)
