import React from 'react'
import PropTypes from 'prop-types'

import ConversionListContainer from '@containers/PartnerDashboard/ConversionList'

import './Conversions.scss'

class PartnerConversionsDetails extends React.Component {
  state = {
    ready: false
  }

  componentDidMount () {
    if (this.props.partner) {
      this.setState(old => ({ ...old, ready: true }))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (
      this.props.partner &&
      this.props.partner?.id !== prevProps.partner?.id
    ) {
      this.setState(old => ({ ...old, ready: true }))
    }
  }

  render () {
    if (!this.state.ready) {
      return null
    }

    return (
      <div className='admin-pview__conversions'>
        <div className='admin-pview__conversions__title'>
          Conversions
        </div>

        <ConversionListContainer
          limit={9}
          partnerId={this.props.partner.id}
        />
      </div>
    )
  }
}

PartnerConversionsDetails.propTypes = {
  partner: PropTypes.object
}

export default PartnerConversionsDetails
