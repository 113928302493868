import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const ScrollTop = (props) => {
  const [first, setFirst] = useState(true)
  const location = useLocation()

  useEffect(() => {
    setFirst(false)

    if (first && location.hash) {
      scrollTop()
      return
    }

    scrollTop()
  }, [location.state?.scrollTo, location.hash])

  function scrollTop ({ offset = 0 } = {}) {
    if (!window) {
      return
    }

    const { scrollOffset } = props
    const hashOffset = getHashOffset(location.hash)

    setTimeout(() => {
      window.scroll({
        top: 0 + hashOffset + scrollOffset,
        left: 0,
        behavior: 'smooth'
      })
    }, 100)
  }

  // scroll top if fails to find element
  function getHashOffset (hash) {
    if (!hash) {
      return 0
    }

    try {
      const el = window && window.document.querySelector(hash)

      if (!el || !window) {
        return
      }

      return el.getBoundingClientRect().top + window.scrollY
    } catch (e) {
      return 0
    }
  }

  return null
}

ScrollTop.propTypes = {
  by: PropTypes.string,
  scrollOffset: PropTypes.number
}

ScrollTop.defaultProps = {
  by: '',
  scrollOffset: 0
}

export default ScrollTop
