import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'
import { checkToken } from '@redux/account.js'
import { loadPartnerUsers } from '@redux/referrals/partners'

import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import DotMenu from '@components/DotMenu/index.jsx'
import DropdownList from '@components/DropdownList/index.jsx'
import UpsertUserModal from '@containers/Partners/Users/UpsertUserModal.jsx'

import editIcon from '@assets/icons/edit.svg'

import './Users.scss'
import DeleteUserModal from '@containers/Partners/Users/DeleteUserModal.jsx'

function PartnerUserDetails ({ partner }) {
  const [ready, setReady] = useState(false)
  const [upsertModal, setUpsertModal] = useState('')
  const [contextUser, setContextUser] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)

  const [users, loading] = useSelector(({ referrals: { partners } }) => [
    partners.users.data,
    partners.users.loading
  ])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!partner?.id) {
      return
    }

    loadUsers()
  }, [partner?.id])

  function loadUsers () {
    dispatch(loadPartnerUsers({
      partnerId: partner.id
    })).then(() => {
      setReady(true)
    })
  }

  async function handleClickLoginAs ({ user }) {
    try {
      const response = await axios.get(getRefApiUrl('v1', `/partners/${partner.id}/login-as`), {
        params: {
          user_id: user.id
        }
      })

      await dispatch(checkToken(response.data.token))

      // force reload to home
      window.location.href = '/'
    } catch (error) {
      console.error('@partner LoginAs error:', error)
    }
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()
    event.preventDefault && event.preventDefault()

    switch (option.key) {
      case 'login-as':
        handleClickLoginAs({ user: option.value })
        break
      case 'add':
        setUpsertModal('add')
        break
      case 'edit':
        setUpsertModal('edit')
        setContextUser(option.value)
        break
      case 'delete':
        setDeleteModal(true)
        setContextUser(option.value)
        break
    }
  }

  function handleCloseUpsertModal () {
    setUpsertModal('')
    setContextUser(null)
  }

  function handleSuccessUpsertModal () {
    handleCloseUpsertModal()

    loadUsers()
  }

  function handleCloseDeleteModal () {
    setDeleteModal(false)
    setContextUser(null)
  }

  function handleSuccessDeleteModal () {
    handleCloseDeleteModal()

    loadUsers()
  }

  if (!ready) {
    return null
  }

  return (
    <div className='admin-pview__details admin-pview__programs'>
      <UpsertUserModal
        open={upsertModal}
        onClose={handleCloseUpsertModal}
        onSuccess={handleSuccessUpsertModal}
        partnerId={partner?.id}
        user={contextUser}
      />
      <DeleteUserModal
        open={deleteModal}
        user={contextUser}
        partnerId={partner?.id}
        onClose={handleCloseDeleteModal}
        onSuccess={handleSuccessDeleteModal}
      />

      <div className='admin-pview__details__top'>
        <div className='admin-pview__details__title'>
          Users / Permissions
        </div>
        <DropdownList
          onClickOption={handleClickOption}
          right
          activator={({ onClick }) => (
            <div className='admin-pview__details__edit' onClick={onClick}>
              <img src={editIcon} alt='edit' />
            </div>
          )}
          options={() => [{
            key: 'add',
            label: 'Add User',
            value: 'add'
          }]}
        />
      </div>

      <div className='admin-pview__users__list'>
        <ListRow
          loading={loading}
          headers={(
            <>
              <ListRowHeader className='admin-pview__users__name'>Name</ListRowHeader>
              <ListRowHeader className='admin-pview__users__email'>Email</ListRowHeader>
              <ListRowHeader className='admin-pview__users__role'>Owner</ListRowHeader>
              <ListRowHeader className='admin-pview__users__last-login'>Last Login</ListRowHeader>
              <ListRowHeader className='admin-pview__users__actions'>&nbsp;</ListRowHeader>
            </>
          )}
          items={users.map(user => {
            const isOwner = user.is_owner

            return (
              <ListRowItem key={user.id}>
                <ListRowColumn className='admin-pview__users__name'>
                  {user.name}
                </ListRowColumn>
                <ListRowColumn className='admin-pview__users__email'>
                  {user.email}
                </ListRowColumn>
                <ListRowColumn className='admin-pview__users__role'>
                  {user.is_owner ? 'Yes' : '-'}
                </ListRowColumn>
                <ListRowColumn className='admin-pview__users__last-login'>
                  {user.last_login ? (DateTime.fromISO(user.last_login).toFormat('LLL d, y HH:mm')) : 'N/A'}
                </ListRowColumn>
                <ListRowColumn className='admin-pview__users__actions'>
                  <DropdownList
                    onClickOption={handleClickOption}
                    right
                    activator={({ onClick }) => <DotMenu onClick={onClick} />}
                    options={() => [{
                      key: 'login-as',
                      label: 'Login as',
                      value: user
                    }, {
                      key: 'edit',
                      label: 'Edit',
                      value: user
                    }, (
                      !isOwner ? { key: 'delete', label: 'Delete', value: user } : null
                    )].filter(f => f)}
                  />
                </ListRowColumn>
              </ListRowItem>
            )
          })}
        />
      </div>
    </div>
  )
}

PartnerUserDetails.propTypes = {
  partner: PropTypes.object,
  loading: PropTypes.bool,
  loadUsers: PropTypes.func,
  users: PropTypes.array
}

export default PartnerUserDetails
