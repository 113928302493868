import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'

import { createAppProfileClaim, updateAppProfileClaim } from '@redux/appProfileClaims.js'
import { loadPartners } from '@redux/referrals/partners'

const initialFields = {
  company_name: '',
  company_url: '',
  dev_profile_capability: '',
  email: '',
  status: 'pending',
  user_name: '',
  partner_id: ''
}

export default function ClaimModal ({ show, type, claim, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const partnerSearchTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [partners, setPartners] = useState([])

  const loadingPartners = useSelector(({ referrals }) => referrals.partners.loading)

  useEffect(() => {
    if (!show || !claim || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      company_name: claim.company_name,
      company_url: claim.company_url,
      dev_profile_capability: claim.dev_profile_capability || '',
      email: claim.email,
      user_name: claim.user_name,
      status: claim.status,
      partner_id: claim.partner_id
    })

    setSearch(claim?.partner_name || '')
  }, [show, claim])

  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setPartners([])
      setSearch('')
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, partner_id: '' })
      setPartners([])

      return
    }

    partnerSearchTimeout.current = setTimeout(() => {
      fetchPartners({ search })
    }, 400)

    return () => {
      clearTimeout(partnerSearchTimeout.current)
    }
  }, [search])

  function handleSave () {
    const data = {
      company_name: fields.company_name,
      company_url: fields.company_url,
      dev_profile_capability: fields.dev_profile_capability,
      email: fields.email,
      user_name: fields.user_name,
      status: fields.status,
      partner_id: fields.partner_id
    }

    let promise

    if (type === 'add') {
      promise = createAppProfileClaim
    } else {
      promise = updateAppProfileClaim
    }

    dispatch(promise({
      id: claim?.id,
      data
    }))
      .then((res) => {
        onSuccess()
        onClose()
        if (res.profileClaim && res.profileClaim.status === 'approved' && res.profileClaim.partner_id) {
          navigate(`/partners/v/${res.profileClaim.partner_id}?id=1`)
        }
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else {
          console.log('error claim')
        }
      })
  }

  function fetchPartners (payload) {
    dispatch(loadPartners({
      ...payload,
      order: 'newest',
      status: 'active,paused,pending',
      before: '',
      after: ''
    })).then(({ data }) => {
      setPartners(data.map(partner => ({
        key: partner.id,
        label: partner.name,
        value: partner.id
      })))
    })
      .catch(error => {
        console.log('error fetch @developers:', error)
        setPartners([])
      })
  }

  return (
    <Modal show={show} className='pda-app-profile-claims__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Profile Claim
      </Header>

      <Body className='pda-app-profile-claims__add-modal__body'>
        <SelectInput
          disabled={type === 'add'}
          label='Status'
          labelClassName='pda-app-profile-claims__form__label'
          value={fields.status}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, status: value })}
          options={[{
            key: '2',
            label: 'Pending',
            value: 'pending'
          }, {
            key: '1',
            label: 'Approved',
            value: 'approved'
          }, {
            key: '0',
            label: 'Declined',
            value: 'declined'
          }
          ]}
        />
        <TextInput
          labelClassName='pda-app-profile-claims__form__label'
          label='Search Partner By Name'
          value={search}
          onChange={event => setSearch(event.currentTarget.value)}
        />
        <SelectInput
          label='Partner'
          labelClassName='pda-app-profile-claims__form__label'
          value={fields.partner_id}
          placeholder={loadingPartners ? 'Loading...' : !partners.length ? 'No partner found' : 'Select...'}
          onChange={value => setFields({ ...fields, partner_id: value })}
          options={partners}
          disabled={!partners.length || loadingPartners}
          error={errors.partner_id}
          subText={errors.partner_id}
        />
        <TextInput
          labelClassName='pda-app-profile-claims__form__label'
          label='User Name *'
          value={fields.user_name}
          onChange={event => setFields({ ...fields, user_name: event.currentTarget.value })}
          error={errors.user_name}
          subText={errors.user_name}
        />

        <TextInput
          labelClassName='pda-app-profile-claims__form__label'
          label='E-mail *'
          value={fields.email}
          onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
          error={errors.email}
          subText={errors.email}
        />

        <TextInput
          labelClassName='pda-app-profile-claims__form__label'
          label='Company Name *'
          value={fields.company_name}
          onChange={event => setFields({ ...fields, company_name: event.currentTarget.value })}
          error={errors.company_name}
          subText={errors.company_name}
        />

        <TextInput
          labelClassName='pda-app-profile-claims__form__label'
          label='Company URL *'
          value={fields.company_url}
          onChange={event => setFields({ ...fields, company_url: event.currentTarget.value })}
          error={errors.company_url}
          subText={errors.company_url}
        />
      </Body>

      <Footer className='pda-app-profile-claims__add-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}
