import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useSearchParams } from 'react-router-dom'

import { fetchThemes } from '@redux/themes.js'

import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import ListRow from '@components/ListRow/index.jsx'
import Button from '@components/Button/index.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'
import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'

import star from '@assets/icons/star.svg'

const pageLimit = 15

export default function ThemeList () {
  const [
    themes,
    totalThemes,
    loadingThemes
  ] = useSelector(({ themes }) => [
    themes.list.data,
    themes.list.total,
    themes.list.loading
  ])
  const [developerId] = useSelector(({ account }) => [account.workspace.id])

  const dispatch = useDispatch()
  const [searchParams, setSearchParams] = useSearchParams()

  const page = Number(searchParams.get('page') || 1)

  const pagination = makePagination(totalThemes, page, pageLimit)

  useEffect(() => {
    loadThemes()
  }, [page])

  function loadThemes () {
    dispatch(fetchThemes({
      developerId,
      offset: pagination.offset,
      limit: pagination.limit,
      withDeveloper: false,
      withRating: true
    }))
  }

  function handlePageChange (page, event) {
    event.preventDefault()
    setSearchParams({ page })
  }

  return (
    <div className='reviews-page__list'>
      <h1 className='reviews-page__list__title'>
        My theme listings <span className='light-gray-text'>({!loadingThemes ? totalThemes : '...'})</span>
      </h1>

      <ListRow
        empty={!totalThemes}
        loading={loadingThemes}
        className='reviews-page__list-desktop'
        headers={(
          <>
            <ListRowHeader
              className='reviews-page__list-desktop__name reviews-page__list-desktop__name--image-placeholder'
            >
              THEME NAME
            </ListRowHeader>
            <ListRowHeader className='reviews-page__list-desktop__rating'>
              AVERAGE RATING
            </ListRowHeader>
            <ListRowHeader className='reviews-page__list-desktop__review-count'>
              REVIEWS
            </ListRowHeader>
            <ListRowHeader className='reviews-page__list-desktop__actions'>&nbsp;</ListRowHeader>
          </>
        )}
        items={themes.map(theme => (
          <ListRowItem key={theme.id} className='reviews-page__list-desktop__columns'>
            <ListRowColumn className='reviews-page__list-desktop__name'>
              <Link className='reviews-page__list-desktop__link' to={`/reviews/${theme.id}`}>
                <div className='reviews-page__list-desktop__thumbnail-container'>
                  <img
                    className='reviews-page__list-desktop__thumbnail-image' src={theme.thumbnail_url}
                    alt={theme.name}
                  />
                </div>
              </Link>

              <Link className='reviews-page__list-desktop__link' to={`/reviews/${theme.id}`}>
                {theme.name}
              </Link>
            </ListRowColumn>

            <ListRowColumn className='reviews-page__list-desktop__rating'>
              <img src={star} alt='overall rating' />
              <div>{theme?.ratings.review_rating.toFixed(1)}</div>
            </ListRowColumn>

            <ListRowColumn className='reviews-page__list-desktop__review-count'>
              <div>{theme?.ratings.review_count}</div>
            </ListRowColumn>

            <ListRowColumn className='reviews-page__list-desktop__actions'>
              <Link className='reviews-page__list-desktop__link' to={`/reviews/${theme.id}`}>
                <Button hollow>
                  Get reviews
                </Button>
              </Link>
              <Link className='reviews-page__list-desktop__link' to={`/reviews/${theme.id}/list`}>
                <Button hollow>
                  Replies
                </Button>
              </Link>
              <Link className='reviews-page__list-desktop__link' to={`/reviews/${theme.id}/widgets`}>
                <Button hollow>
                  Get the widget
                </Button>
              </Link>
            </ListRowColumn>
          </ListRowItem>
        ))}
      />

      <div className='reviews-page__list-mobile'>
        {themes.map(theme => (
          <ShadowBox key={theme.id} className='reviews-page__list-mobile__item'>
            <div className='reviews-page__list-mobile__item__name'>
              <Link to={`/reviews/${theme.id}`}>
                {theme.name}
              </Link>
            </div>

            <div className='reviews-page__list-mobile__item__reviews'>
              <div className='reviews-page__list-mobile__item__rating'>
                <div>Average Rating:</div>
                <div className='reviews-page__list-mobile__item__star'>
                  <img src={star} alt='overall rating' />
                </div>
                <div>{`${theme?.ratings.review_rating.toFixed(1)}/5`}</div>
              </div>
              <div className='reviews-page__list-mobile__item__count'>
                Reviews: {theme?.ratings.review_count}
              </div>
            </div>
            <div className='reviews-page__list-mobile__item__actions'>
              <Link to={`/reviews/${theme.id}`}>
                <Button hollow>
                  Get reviews
                </Button>
              </Link>

              <Link to={`/reviews/${theme.id}/list`}>
                <Button hollow>
                  Manage
                </Button>
              </Link>
            </div>
          </ShadowBox>
        ))}
      </div>

      <div className='reviews-page__pagination'>
        {(pagination.totalResults / pageLimit) > 1 && (
          <SoftPagination
            onChange={handlePageChange}
            pagination={pagination}
            pageButtons={5}
            fixedPageButton
            buildURL={(page) => `/reviews?page=${page}`}
          />
        )}
      </div>
    </div>
  )
}
