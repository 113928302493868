import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchReview } from '@redux/themeReviews'
import Button from '@components/Button'
import classnames from 'classnames'
import { DateTime } from 'luxon'
import { formatUrl } from '@util/reviews.js'

import ReviewModal from './ReviewModal'
import DeleteReviewModal from './DeteteReviewModal.jsx'

import linkExternal from '@assets/images/link-external-purple.svg'
import star from '@assets/images/star-orange.svg'

import './Review.scss'

export default function Review () {
  const [showModal, setShowModal] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState('')
  const [review] = useSelector(({ themeReviews }) => [themeReviews.review.data])

  const params = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!params.id) {
      return
    }

    loadReview({
      id: params.id
    })
  }, [params.id])

  function loadReview ({ id }) {
    dispatch(fetchReview({
      id: id
    }))
      .catch(error => {
        console.error('error fetching review: ', error)
      })
  }

  function handleReviewModalSuccess () {
    setShowModal('')
  }

  function handleDeleteModalSuccess () {
    setShowDeleteModal('')
  }

  const storeUrl = formatUrl(review.store_url)

  return (
    <div className='theme-review'>
      <ReviewModal
        show={showModal !== ''}
        type={showModal}
        review={review}
        onClose={() => setShowModal('')}
        onSuccess={handleReviewModalSuccess}
        onloadReview={loadReview}
      />
      <DeleteReviewModal
        show={showDeleteModal !== ''}
        id={review.id}
        onClose={() => setShowDeleteModal('')}
        onSuccess={handleDeleteModalSuccess}
      />
      <div className='theme-review__row'>
        <div className='theme-review__cta'>
          <Button onClick={() => setShowModal('edit')} primary>
            Edit Review
          </Button>
        </div>
        <Button onClick={() => setShowDeleteModal('delete')} danger>
          Delete Review
        </Button>
        <div className='theme-review__date'>
          {review.status === 'pending'
            ? <div className='pr-review__pending'>Review pending approval</div>
            : (<div><span>Posted: </span>{DateTime.fromISO(review.created_at).toRelative()}</div>)}
        </div>
      </div>
      <div className='theme-review__top'>
        <div className='theme-review__profile-container'>
          <div className='theme-review__col'>
            <p className='theme-review__name'>
              {review.user_name}
            </p>
            <p className='theme-review__item theme-review__item--used-one'>
              <span>Theme name:&nbsp;</span>
              <a href={`${import.meta.env.VITE_SITE_URL}/themes/reviews/${review.theme_slug}`} target='_blank' rel='noreferrer noopener nofollow'>
                {review.theme_name}&nbsp;
                <img src={linkExternal} alt={storeUrl.clean} />
              </a>
            </p>
            <p className='theme-review__item theme-review__item--used-one'>
              <span>Theme used on:&nbsp;</span>
              <a href={storeUrl.full} target='_blank' rel='noreferrer noopener nofollow'>
                {storeUrl.clean}&nbsp;
                <img src={linkExternal} alt={storeUrl.clean} />
              </a>
            </p>
            <p className='theme-review__item'>
              <span>Recommend this theme:&nbsp;</span>
              <span className={classnames('theme-review__item__recommend', {
                'theme-review__item__recommend--not': review?.recommended?.includes('Not')
              })}
              >
                {review.recommended}
              </span>
            </p>
          </div>
        </div>
        <div className='theme-review__right-col'>
          <p className='theme-review__overall'>
            Overall Rating:
          </p>
          <div className='theme-review__rating'>
            <img src={star} alt='overall rating' />
            {review.overall_rating && review.overall_rating.toFixed(2)}
          </div>
        </div>
      </div>

      <div className='theme-review__features'>
        <div className='theme-review__features__item'>
          <p className='theme-review__features__title'>
            Setup & Ease of Use
          </p>
          <p className='theme-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.ease_rating} /5
          </p>
        </div>
        <div className='theme-review__features__item'>
          <p className='theme-review__features__title'>
            Features & Functionality
          </p>
          <p className='theme-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.options_rating} /5
          </p>
        </div>
        <div className='theme-review__features__item'>
          <p className='theme-review__features__title'>
            Support Quality
          </p>
          <p className='theme-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.support_rating} /5
          </p>
        </div>
        <div className='theme-review__features__item'>
          <p className='theme-review__features__title'>
            Value for Money
          </p>
          <p className='theme-review__features__value'>
            <img src={star} alt=' Setup & Ease of Use' />
            {review.value_rating || 5} /5
          </p>
        </div>
      </div>
      <p className='theme-review__title'>
        Overall experience
      </p>
      <p className='theme-review__desc'>
        {review.review}
      </p>
    </div>
  )
}
