import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const TOGGLE_AFFILIATE_MODAL = 'referrals/withdrawals/TOGGLE_AFFILIATE_MODAL'

const LOAD_BALANCE_PENDING = 'referrals/withdrawals/LOAD_BALANCE_PENDING'
const LOAD_BALANCE_FULFILLED = 'referrals/withdrawals/LOAD_BALANCE_FULFILLED'
const LOAD_BALANCE_REJECTED = 'referrals/withdrawals/LOAD_BALANCE_REJECTED'

const LOAD_WITHDRAWALS_PENDING = 'referrals/withdrawals/LOAD_WITHDRAWALS_PENDING'
const LOAD_WITHDRAWALS_FULFILLED = 'referrals/withdrawals/LOAD_WITHDRAWALS_FULFILLED'
const LOAD_WITHDRAWALS_REJECTED = 'referrals/withdrawals/LOAD_WITHDRAWALS_REJECTED'

const LOAD_DRAFT_PENDING = 'referrals/withdrawals/LOAD_DRAFT_PENDING'
const LOAD_DRAFT_FULFILLED = 'referrals/withdrawals/LOAD_DRAFT_FULFILLED'
const LOAD_DRAFT_REJECTED = 'referrals/withdrawals/LOAD_DRAFT_REJECTED'
const REQUEST_WITHDRAWAL_PENDING = 'referrals/withdrawals/REQUEST_WITHDRAWAL_PENDING'
const REQUEST_WITHDRAWAL_FULFILLED = 'referrals/withdrawals/REQUEST_WITHDRAWAL_FULFILLED'
const REQUEST_WITHDRAWAL_REJECTED = 'referrals/withdrawals/REQUEST_WITHDRAWAL_REJECTED'

const PAY_WITHDRAWAL_PENDING = 'referrals/withdrawals/PAY_WITHDRAWAL_PENDING'
const PAY_WITHDRAWAL_FULFILLED = 'referrals/withdrawals/PAY_WITHDRAWAL_FULFILLED'
const PAY_WITHDRAWAL_REJECTED = 'referrals/withdrawals/PAY_WITHDRAWAL_REJECTED'

const DECLINE_WITHDRAWAL_PENDING = 'referrals/withdrawals/DECLINE_WITHDRAWAL_PENDING'
const DECLINE_WITHDRAWAL_FULFILLED = 'referrals/withdrawals/DECLINE_WITHDRAWAL_FULFILLED'
const DECLINE_WITHDRAWAL_REJECTED = 'referrals/withdrawals/DECLINE_WITHDRAWAL_REJECTED'

const LOAD_WITHDRAWAL_PENDING = 'referrals/withdrawals/LOAD_WITHDRAWAL_PENDING'
const LOAD_WITHDRAWAL_FULFILLED = 'referrals/withdrawals/LOAD_WITHDRAWAL_FULFILLED'
const LOAD_WITHDRAWAL_REJECTED = 'referrals/withdrawals/LOAD_WITHDRAWAL_REJECTED'

const ADD_WITHDRAWAL_ITEM_PENDING = 'referrals/withdrawals/ADD_WITHDRAWAL_ITEM_PENDING'
const ADD_WITHDRAWAL_ITEM_FULFILLED = 'referrals/withdrawals/ADD_WITHDRAWAL_ITEM_FULFILLED'
const ADD_WITHDRAWAL_ITEM_REJECTED = 'referrals/withdrawals/ADD_WITHDRAWAL_ITEM_REJECTED'
const UPDATE_WITHDRAWAL_ITEM_PENDING = 'referrals/withdrawals/UPDATE_WITHDRAWAL_ITEM_PENDING'
const UPDATE_WITHDRAWAL_ITEM_FULFILLED = 'referrals/withdrawals/UPDATE_WITHDRAWAL_ITEM_FULFILLED'
const UPDATE_WITHDRAWAL_ITEM_REJECTED = 'referrals/withdrawals/UPDATE_WITHDRAWAL_ITEM_REJECTED'
const REMOVE_WITHDRAWAL_ITEM_PENDING = 'referrals/withdrawals/REMOVE_WITHDRAWAL_ITEM_PENDING'
const REMOVE_WITHDRAWAL_ITEM_FULFILLED = 'referrals/withdrawals/REMOVE_WITHDRAWAL_ITEM_FULFILLED'
const REMOVE_WITHDRAWAL_ITEM_REJECTED = 'referrals/withdrawals/REMOVE_WITHDRAWAL_ITEM_REJECTED'

export const toggleAffiliateModal = (open = false) => ({
  type: TOGGLE_AFFILIATE_MODAL,
  payload: {
    open
  }
})

export const loadBalance = payload => async (dispatch, getState) => {
  try {
    const { referrals: { withdrawals } } = getState()

    if (withdrawals.loadingBalance) {
      return
    }

    dispatch({
      type: LOAD_BALANCE_PENDING
    })

    const res = await axios.get(getRefApiUrl('v1', '/balance'), {
      params: {
        partner_id: payload?.partnerId
      }
    })

    const data = {
      balance: res.data?.balance || '0.00'
    }

    dispatch({
      type: LOAD_BALANCE_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_BALANCE_REJECTED
    })

    throw error
  }
}

export const loadWithdrawalList = payload => async (dispatch, getState) => {
  try {
    const { referrals: { withdrawals: base } } = getState()
    const { withdrawals } = base

    if (withdrawals.loading) {
      return
    }

    dispatch({
      type: LOAD_WITHDRAWALS_PENDING
    })

    const res = await axios.get(getRefApiUrl('v1', '/withdrawals'), {
      params: {
        limit: payload?.limit,
        before: payload.before ? encodeURIComponent(payload.before) : undefined,
        after: payload.after ? encodeURIComponent(payload.after) : undefined,
        status: payload?.status,
        with_partner: payload.withPartner ? '1' : undefined,
        partner_id: payload.partnerId || undefined
      }
    })

    const response = {
      total: res.data?.total || 0,
      remaining: res.data?.remaining || 0,
      data: res.data?.data || []
    }

    dispatch({
      type: LOAD_WITHDRAWALS_FULFILLED,
      payload: response
    })

    return response
  } catch (error) {
    dispatch({
      type: LOAD_WITHDRAWALS_REJECTED
    })

    throw error
  }
}

export const loadWithdrawalDraft = payload => async dispatch => {
  try {
    dispatch({
      type: LOAD_DRAFT_PENDING
    })

    const res = await axios.get(getRefApiUrl('v1', '/withdrawals:draft'), {
      params: {
        partner_id: payload?.partner_id
      }
    })

    const response = {
      items: res.data.data
    }

    dispatch({
      type: LOAD_DRAFT_FULFILLED,
      payload: response
    })

    return response
  } catch (error) {
    dispatch({
      type: LOAD_DRAFT_REJECTED
    })

    throw error
  }
}

export const requestWithdrawal = payload => async dispatch => {
  try {
    dispatch({
      type: REQUEST_WITHDRAWAL_PENDING
    })

    await axios.post(
      getRefApiUrl('v1', '/withdrawals'), {
        // conversion ids
        conversions: (payload?.items || []).map(c => c.id)
      },
      {
        params: {
          partner_id: payload?.partner_id
        }
      })

    const response = {}

    dispatch({
      type: REQUEST_WITHDRAWAL_FULFILLED,
      payload: response
    })

    return response
  } catch (error) {
    dispatch({
      type: REQUEST_WITHDRAWAL_REJECTED
    })

    throw error
  }
}

export const payWithdrawal = payload => async dispatch => {
  try {
    dispatch({ type: PAY_WITHDRAWAL_PENDING })

    const res = await axios.post(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}:pay`), {
      transaction_id: payload.transactionId || undefined
    })

    const p = {
      withdrawal: res.data
    }

    dispatch({ type: PAY_WITHDRAWAL_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: PAY_WITHDRAWAL_REJECTED })
    throw error
  }
}

export const declineWithdrawal = payload => async dispatch => {
  try {
    dispatch({ type: DECLINE_WITHDRAWAL_PENDING })

    const res = await axios.post(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}:decline`))

    const p = {
      withdrawal: res.data
    }

    dispatch({ type: DECLINE_WITHDRAWAL_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: DECLINE_WITHDRAWAL_REJECTED })
    throw error
  }
}

export const loadWithdrawal = payload => async dispatch => {
  try {
    dispatch({ type: LOAD_WITHDRAWAL_PENDING })

    const res = await axios.get(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}`), {
      params: {
        with_partner: payload.withPartner ? '1' : undefined,
        with_items: payload.withItems ? '1' : undefined
      }
    })

    const p = {
      data: res.data
    }

    dispatch({ type: LOAD_WITHDRAWAL_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: LOAD_WITHDRAWAL_REJECTED })
    throw error
  }
}

export const addWithdrawalItem = payload => async dispatch => {
  try {
    dispatch({ type: ADD_WITHDRAWAL_ITEM_PENDING })

    const res = await axios.post(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}/items`), {
      source_type: payload.source_type,
      source_id: payload.source_id,
      amount: payload.amount,
      title: payload.title,
      description: payload.description
    })

    const p = {
      data: res.data
    }

    dispatch({ type: ADD_WITHDRAWAL_ITEM_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: ADD_WITHDRAWAL_ITEM_REJECTED })
    throw error
  }
}

export const updateWithdrawalItem = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_WITHDRAWAL_ITEM_PENDING })

    const res = await axios.post(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}/items/${payload.itemId}`), {
      source_type: payload.source_type || undefined,
      source_id: payload.source_id || undefined,
      amount: payload.amount,
      title: payload.title,
      description: payload.description
    })

    const p = {
      data: res.data
    }

    dispatch({ type: UPDATE_WITHDRAWAL_ITEM_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: UPDATE_WITHDRAWAL_ITEM_REJECTED })
    throw error
  }
}

export const removeWithdrawalItem = payload => async dispatch => {
  try {
    dispatch({ type: REMOVE_WITHDRAWAL_ITEM_PENDING })

    const res = await axios.delete(getRefApiUrl('v1', `/withdrawals/${payload.withdrawalId}/items/${payload.itemId}`))

    const p = {
      data: res.data
    }

    dispatch({ type: REMOVE_WITHDRAWAL_ITEM_FULFILLED, payload: p })

    return p
  } catch (error) {
    dispatch({ type: REMOVE_WITHDRAWAL_ITEM_REJECTED })
    throw error
  }
}

const initialState = {
  affiliateModalOpen: false,

  currency: 'USD',
  balance: '0.00',
  loadingBalance: false,

  statusChangeLoading: false,

  // draft
  draft: {
    loading: false,
    items: []
  },

  // resources list
  withdrawals: {
    total: 0,
    remaining: 0,
    loading: false,
    data: []
  },

  // resource detail
  withdrawal: {
    loading: false,
    data: null,
    items: []
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_AFFILIATE_MODAL:
      return {
        ...state,
        affiliateModalOpen: action.payload.open
      }

    case LOAD_BALANCE_PENDING:
      return {
        ...state,
        loadingBalance: true
      }
    case LOAD_BALANCE_FULFILLED:
      return {
        ...state,
        loadingBalance: false,
        balance: action.payload.balance
      }
    case LOAD_BALANCE_REJECTED:
      return {
        ...state,
        loadingBalance: false
      }

    case LOAD_WITHDRAWALS_PENDING:
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: true
        }
      }
    case LOAD_WITHDRAWALS_FULFILLED:
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: false,
          data: action.payload.data,
          total: action.payload.total,
          remaining: action.payload.remaining
        }
      }
    case LOAD_WITHDRAWALS_REJECTED:
      return {
        ...state,
        withdrawals: {
          ...state.withdrawals,
          loading: false
        }
      }

    case LOAD_DRAFT_PENDING:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: true
        }
      }
    case LOAD_DRAFT_FULFILLED:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: false,
          items: action.payload.items
        }
      }
    case LOAD_DRAFT_REJECTED:
      return {
        ...state,
        draft: {
          ...state.draft,
          loading: false
        }
      }

    // use .withdrawal as result
    case REQUEST_WITHDRAWAL_PENDING:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: true
        }
      }
    case REQUEST_WITHDRAWAL_FULFILLED:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: false,
          data: action.payload.data,
          total: action.payload.total
        }
      }
    case REQUEST_WITHDRAWAL_REJECTED:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: false
        }
      }

    case PAY_WITHDRAWAL_PENDING:
    case DECLINE_WITHDRAWAL_PENDING:
      return {
        ...state,
        statusChangeLoading: true
      }
    case PAY_WITHDRAWAL_FULFILLED:
    case DECLINE_WITHDRAWAL_FULFILLED:
    case PAY_WITHDRAWAL_REJECTED:
    case DECLINE_WITHDRAWAL_REJECTED:
      return {
        ...state,
        statusChangeLoading: false
      }

    case LOAD_WITHDRAWAL_PENDING:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: true
        }
      }
    case LOAD_WITHDRAWAL_FULFILLED:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: false,
          data: action.payload.data,
          items: action.payload.data?.items || []
        }
      }
    case LOAD_WITHDRAWAL_REJECTED:
      return {
        ...state,
        withdrawal: {
          ...state.withdrawal,
          loading: false
        }
      }

    default:
      return state
  }
}
