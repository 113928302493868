import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ModalFooter = ({ children, className }) => (
  <div className={classnames('hc-modal__footer', className)}>
    {children}
  </div>
)

ModalFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default React.memo(ModalFooter)
