import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const LOAD_REFCODES_PENDING = 'referrals/partners/LOAD_REFCODES_PENDING'
const LOAD_REFCODES_FULFILLED = 'referrals/partners/LOAD_REFCODES_FULFILLED'
const LOAD_REFCODES_REJECTED = 'referrals/partners/LOAD_REFCODES_REJECTED'

const UPDATE_REFERRAL_PENDING = 'referrals/partners/UPDATE_REFERRAL_PENDING'
const UPDATE_REFERRAL_FULFILLED = 'referrals/partners/UPDATE_REFERRAL_FULFILLED'
const UPDATE_REFERRAL_REJECTED = 'referrals/partners/UPDATE_REFERRAL_REJECTED'

export const loadReferralCodes = payload => async dispatch => {
  dispatch({
    type: LOAD_REFCODES_PENDING
  })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/referral-codes'), {
      params: {
        partner_id: payload.partnerId || undefined,
        with_program: payload?.withProgram ? 1 : undefined,
        limit: payload?.limit
      }
    })

    const data = {
      approvedPartner: response.data?.metadata?.approved_partner,
      totalResults: response.data.total,
      data: response.data.data
    }

    dispatch({
      type: LOAD_REFCODES_FULFILLED,
      payload: data
    })

    return data
  } catch (error) {
    dispatch({
      type: LOAD_REFCODES_REJECTED
    })
  }
}

export const updateReferralCode = payload => async dispatch => {
  try {
    dispatch({
      type: UPDATE_REFERRAL_PENDING
    })

    const res = await axios.post(getRefApiUrl('v1', `/referral-codes/${payload.referralId}`), {
      custom_code: payload.customCode,
      anchored_commission_slug: payload.anchoredCommissionSlug
    })

    const p = {
      data: res.data
    }

    dispatch({
      type: UPDATE_REFERRAL_FULFILLED,
      payload: p
    })

    return p
  } catch (error) {
    dispatch({
      type: UPDATE_REFERRAL_REJECTED
    })

    throw error
  }
}

const initialState = {
  approvedPartner: false,
  totalResults: 0,
  data: [],
  loading: false,
  updateLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_REFCODES_PENDING:
      return {
        ...state,
        loading: true
      }
    case LOAD_REFCODES_FULFILLED:
      return {
        ...state,
        approvedPartner: action.payload.approvedPartner,
        data: action.payload.data,
        totalResults: action.payload.totalResults,
        loading: false
      }
    case LOAD_REFCODES_REJECTED:
      return {
        ...state,
        loading: false
      }

    case UPDATE_REFERRAL_PENDING:
      return {
        ...state,
        updateLoading: true
      }
    case UPDATE_REFERRAL_FULFILLED:
    case UPDATE_REFERRAL_REJECTED:
      return {
        ...state,
        updateLoading: false
      }

    default:
      return state
  }
}
