import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { fetchApps } from '@redux/apps.js'

import SearchBar from '@components/SearchBar'
import Button from '@components/Button'

import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import AppModal from './AppModal'

import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'
import DropdownList from '@components/DropdownList'
import DotMenu from '@components/DotMenu'

import './List.scss'

const appLimit = 15

export default function AppList () {
  const listRef = useRef()
  const searchTimeout = useRef(null)
  const [totalApps, apps] = useSelector(({ apps }) => [apps.list.total, apps.list.data])

  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '')
  const [search, setSearch] = useState(searchVal)
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')

  const dispatch = useDispatch()

  const pagination = makePagination(totalApps, page, appLimit)

  useEffect(() => {
    setSearchParams({ page: 1, search, status })
  }, [search, status])

  useEffect(() => {
    loadApps()
  }, [page, search, status])

  function loadApps () {
    dispatch(fetchApps({
      search,
      status,
      offset: pagination.offset,
      limit: pagination.limit,
      withDeveloper: true,
      withRating: true
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
      case 'view':
        break
    }
  }

  function handleAppModalSuccess () {
    setShowModal('')
    setSelected(null)
    loadApps()
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page, search, status })
  }

  return (
    <div className='pda-app-page' ref={listRef}>
      <AppModal
        show={showModal !== ''}
        type={showModal}
        app={selected}
        onClose={() => {
          setShowModal('')
          setSelected(null)
        }}
        onSuccess={handleAppModalSuccess}
      />

      <div className='pda-app-page__title'>
        Shopify Apps
      </div>

      <div className='pda-app-page__list'>
        <div className='pda-app-page__toolbar'>
          <SearchBar
            className='pda-app-page__search'
            placeholder='Search apps'
            value={searchVal}
            onChange={handleSearchChange}
          />

          <div className='pda-app-page__action'>
            <Button onClick={() => setShowModal('add')} primary>
              Add App
            </Button>
          </div>
        </div>
        <div className='pda-app-page__status'>
          <Button
            primary={status === ''}
            disabled={status === ''}
            onClick={() => setStatus('')}
          >
            All
          </Button>
          <Button
            primary={status === 'published'}
            disabled={status === 'published'}
            onClick={() => setStatus('published')}
          >
            Published
          </Button>
          <Button
            primary={status === 'unpublished'}
            disabled={status === 'unpublished'}
            onClick={() => setStatus('unpublished')}
          >
            Unpublished
          </Button>
          <Button
            primary={status === 'pending'}
            disabled={status === 'pending'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
        </div>

        <div>
          Total results: {totalApps}
        </div>

        <ListRow
          className='pda-app-page__list__items'
          headers={(
            <>
              <ListRowHeader className='pda-app-page__list__name'>
                App
              </ListRowHeader>
              <ListRowHeader className='pda-app-page__list__developer'>
                Developer
              </ListRowHeader>
              <ListRowHeader className='pda-app-page__list__thumbnail'>
                Thumbnail
              </ListRowHeader>
              <ListRowHeader className='pda-app-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={apps.map(app => (
            <ListRowItem key={app.id}>
              <ListRowColumn className='pda-app-page__list__name'>
                <Link
                  to={`/apps/${app.id}`}
                >
                  {app.name}
                </Link>
                <br />
                <small className='pda-app-page__list__slug'>
                  <a
                    href={`${import.meta.env.VITE_SITE_URL}/apps/reviews/${app.slug}`}
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {app.slug}
                  </a>
                </small>
              </ListRowColumn>
              <ListRowColumn className='pda-app-page__list__developer'>
                {app.developer
                  ? (
                    <Link to={`/developers?id=${app.developer.id}`}>
                      {app.developer.name}
                    </Link>)
                  : 'N/A'}
              </ListRowColumn>
              <ListRowColumn className='pda-app-page__list__thumbnail'>
                <a
                  href={app.icon_url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img className='pda__app-img-preview' src={app.icon_url} alt={app.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='pda-app-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'view',
                    label: 'View',
                    value: app,
                    to: `/apps/${app.id}`
                  }, {
                    key: 'edit',
                    label: 'Edit',
                    value: app
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            handlePageChange(page, event)
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}
