import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { fetchQuestions } from '@redux/appReviews.js'
import SoftPagination, { makePagination } from '@components/Pagination/Soft.jsx'
import emptyQuestions from '@assets/images/empty-questions.svg'
import AppQuestionCard from '@pages/Partners/Partner/App/Questions/Question.jsx'

const questionsLimit = 15

export default function AppQuestions ({ app }) {
  if (!app) {
    return
  }
  const [questions, totalQuestions, loadingQuestions] = useSelector(({ appReviews }) => [
    appReviews.questions.data,
    appReviews.questions.total,
    appReviews.questions.loading
  ])

  const dispatch = useDispatch()
  const listRef = useRef()
  const [searchParams, setSearchParams] = useSearchParams()

  const page = searchParams.get('page') || '1'
  const pagination = makePagination(totalQuestions, page, questionsLimit)

  useEffect(() => {
    loadQuestions()
  }, [page])

  function loadQuestions () {
    dispatch(fetchQuestions({
      offset: pagination.offset,
      limit: pagination.limit,
      appId: app?.id,
      withAnswers: 1
    }))
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page })
  }

  if ((!questions.length && loadingQuestions) || totalQuestions === 0) {
    return (
      <div className='pr-review__empty'>
        {!loadingQuestions && totalQuestions === 0 && (
          <img src={emptyQuestions} className='pr-review__empty__image' />
        )}

        <div className='pr-review__empty__title'>
          {loadingQuestions ? 'Loading questions...' : 'Users haven\'t asked questions for this app yet.'}
        </div>
      </div>
    )
  }

  return (
    <div className='app-reviews-qa__qa' ref={listRef}>
      {questions.map(question => (
        <AppQuestionCard key={question.id} question={question} />
      ))}
      <SoftPagination
        onChange={(page, event) => {
          handlePageChange(page, event)
        }}
        pagination={pagination}
        pageButtons={5}
        fixedPageButton
      />
    </div>
  )
}

AppQuestions.propTypes = {
  app: PropTypes.object
}
