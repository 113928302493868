import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { addTheme, updateTheme } from '@redux/themes.js'

import Modal, { Header, Body, Footer } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'
import { fetchProfiles } from '@redux/developers.js'

const initialFields = {
  name: '',
  description: '',
  summaryDescription: '',
  metaTitle: '',
  metaDescription: '',
  price: '',
  developerId: '',
  status: 'unpublished',
  pageUrl: '',
  demoUrl: '',
  thumbnail: null,
  cover: null,
  onShopify: '',
  inService: '',
  is_claimed: null
}

export default function ThemeModal ({ show, type, theme, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const devSearchTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [developers, setDevelopers] = useState([])
  const loadingDevs = useSelector(({ developers }) => developers.list.loading)

  const thumbnailRef = useRef(null)
  const coverRef = useRef(null)

  useEffect(() => {
    if (!show || !theme || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: theme.name,
      description: theme.description,
      summaryDescription: theme.summary_description || '',
      metaTitle: theme.meta_title || '',
      metaDescription: theme.meta_description || '',
      price: theme.price || '',
      developerId: theme.developer_id || '',
      status: theme.status || 'unpublished',
      pageUrl: theme.page_url || '',
      demoUrl: theme.demo_url || '',
      onShopify: theme.on_shopify ? '1' : '0',
      inService: theme.in_service ? '1' : '0',
      is_claimed: theme.is_claimed ? '1' : '0'
    })

    setSearch(theme.developer?.name || '')
  }, [show, theme])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setDevelopers([])
      setSearch('')

      if (thumbnailRef.current) {
        thumbnailRef.current.value = ''
      }
      if (coverRef.current) {
        coverRef.current.value = ''
      }
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, developerId: '' })
      setDevelopers([])

      return
    }

    devSearchTimeout.current = setTimeout(() => {
      loadDevelopers(search)
    }, 400)

    return () => {
      clearTimeout(devSearchTimeout.current)
    }
  }, [search])

  function loadDevelopers (search) {
    dispatch(
      fetchProfiles({
        search,
        limit: 10,
        offset: 0
      })
    )
      .then(({ profiles }) => {
        setDevelopers(profiles.map(profile => ({
          key: profile.id,
          label: profile.name,
          value: profile.id
        })))
      })
      .catch(error => {
        console.log('error fetch @developers:', error)
        setDevelopers([])
      })
  }

  function handleSave () {
    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      description: fields.description,
      summary_description: fields.summaryDescription,
      meta_title: fields.metaTitle,
      meta_description: fields.metaDescription,
      price: fields.price,
      developer_id: fields.developerId,
      status: fields.status,
      page_url: fields.pageUrl,
      demo_url: fields.demoUrl,
      on_shopify: fields.onShopify === '1' ? '1' : '0',
      in_service: fields.inService === '1' ? '1' : '0',
      is_claimed: fields.is_claimed === '1' ? '1' : '0'
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    if (fields.thumbnail) {
      data.set('thumbnail', fields.thumbnail)
    }
    if (fields.cover) {
      data.set('cover', fields.cover)
    }

    let promise

    if (type === 'add') {
      promise = addTheme
    } else {
      promise = updateTheme
    }

    dispatch(promise({
      id: theme?.id,
      data
    }))
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'profile_not_found'
        ) {
          setErrors(old => ({ ...old, developer_id: error.response.data.message }))
        } else {
          console.log(`error @${type} theme`)
        }
      })
  }

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  return (
    <Modal show={show} className='pda-theme__add-modal'>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Theme
      </Header>
      <Body className='pda-theme__add-modal__body'>
        <TextInput
          labelClassName='pda-theme__form__label'
          label='Search Developer By Name'
          value={search}
          onChange={event => setSearch(event.currentTarget.value)}
        />

        <SelectInput
          label='Theme Developer'
          labelClassName='pda-theme__form__label'
          value={fields.developerId}
          placeholder={loadingDevs ? 'Loading...' : !developers.length ? 'No developer found' : 'Select...'}
          onChange={value => setFields({ ...fields, developerId: value })}
          options={developers}
          disabled={!developers.length || loadingDevs}
          error={errors.developer_id}
          subText={errors.developer_id}
        />

        <SelectInput
          label='Claimed Profile'
          labelClassName='pda-theme__form__label'
          value={fields.is_claimed}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, is_claimed: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: '1'
          }, {
            key: '0',
            label: 'No',
            value: '0'
          }]}
        />

        <SelectInput
          disabled={type === 'add'}
          label='Status'
          labelClassName='pda-theme__form__label'
          value={fields.status}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, status: value })}
          options={[{
            key: '1',
            label: 'Published',
            value: 'published'
          }, {
            key: '0',
            label: 'Unpublished',
            value: 'unpublished'
          }]}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Description *'
          value={fields.description}
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
          subText={errors.description}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Summary Description'
          value={fields.summaryDescription}
          onChange={event => setFields({ ...fields, summaryDescription: event.currentTarget.value })}
          error={errors.summary_description}
          subText={errors.summary_description}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Meta Title'
          value={fields.metaTitle}
          onChange={event => setFields({ ...fields, metaTitle: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-theme__form__label'
          label='Meta Description'
          value={fields.metaDescription}
          onChange={event => setFields({ ...fields, metaDescription: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Price'
          value={fields.price}
          onChange={event => setFields({ ...fields, price: event.currentTarget.value })}
          placeholder='Free or $240 USD'
          error={errors.price}
          subText={errors.price}
        />

        <TextInput
          labelClassName='pda-theme__form__label'
          label='Page URL'
          value={fields.pageUrl}
          onChange={event => setFields({ ...fields, pageUrl: event.currentTarget.value })}
          placeholder='https://themes.shopify.com/themes/...'
          error={errors.page_url}
          subText={errors.page_url}
        />
        <TextInput
          labelClassName='pda-theme__form__label'
          label='Demo URL'
          value={fields.demoUrl}
          onChange={event => setFields({ ...fields, demoUrl: event.currentTarget.value })}
          placeholder='https://themes.shopify.com/themes/.../preview'
          error={errors.demo_url}
          subText={errors.demo_url}
        />
        <SelectInput
          label='On Shopify (marketplace https://themes.shopify.com)'
          labelClassName='pda-theme__form__label'
          value={fields.onShopify}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, onShopify: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: '1'
          }, {
            key: '0',
            label: 'No',
            value: '0'
          }]}
        />
        <SelectInput
          label='In Service (wether the theme is "available" on or off Shopify)'
          labelClassName='pda-theme__form__label'
          value={fields.inService}
          placeholder='Select...'
          onChange={value => setFields({ ...fields, inService: value })}
          options={[{
            key: '1',
            label: 'Yes',
            value: '1'
          }, {
            key: '0',
            label: 'No',
            value: '0'
          }]}
        />

        <div className='pda-theme__form__group'>
          <TextInput
            inputRef={thumbnailRef}
            type='file'
            labelClassName='pda-theme__form__label'
            label='Thumbnail Image * (preferred 500w x 649h or ratio)'
            onChange={handleFileChange('thumbnail')}
            accept='image/*'
            error={errors.thumbnail}
            subText={errors.thumbnail ? errors.thumbnail : ''}
          />

          {theme?.thumbnail_url && (
            <img
              className='pda__theme-img-preview'
              src={theme?.thumbnail_url}
              alt=''
            />
          )}
        </div>

        <div className='pda-theme__form__group'>
          <TextInput
            inputRef={coverRef}
            type='file'
            labelClassName='pda-theme__form__label'
            label='Cover Image (preferred 500w x 649h or ratio)'
            onChange={handleFileChange('cover')}
            accept='image/*'
            error={errors.cover}
            subText={errors.cover ? errors.cover : ''}
          />

          {theme?.cover_url && (
            <img className='pda__theme-img-preview' src={theme?.cover_url} alt='' />
          )}
        </div>
      </Body>
      <Footer className='pda-theme__add-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

ThemeModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  theme: PropTypes.object
}
