import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'

const FETCH_GROUPS_PENDING = 'themeFilters/FETCH_GROUPS_PENDING'
const FETCH_GROUPS_FULFILLED = 'themeFilters/FETCH_GROUPS_FULFILLED'
const FETCH_GROUPS_REJECTED = 'themeFilters/FETCH_GROUPS_REJECTED'

const ADD_GROUP_PENDING = 'themeFilters/ADD_GROUP_PENDING'
const ADD_GROUP_FULFILLED = 'themeFilters/ADD_GROUP_FULFILLED'
const ADD_GROUP_REJECTED = 'themeFilters/ADD_GROUP_REJECTED'

const UPDATE_GROUP_PENDING = 'themeFilters/UPDATE_GROUP_PENDING'
const UPDATE_GROUP_FULFILLED = 'themeFilters/UPDATE_GROUP_FULFILLED'
const UPDATE_GROUP_REJECTED = 'themeFilters/UPDATE_GROUP_REJECTED'

const DELETE_GROUP_PENDING = 'themeFilters/DELETE_GROUP_PENDING'
const DELETE_GROUP_FULFILLED = 'themeFilters/DELETE_GROUP_FULFILLED'
const DELETE_GROUP_REJECTED = 'themeFilters/DELETE_GROUP_REJECTED'

const FETCH_FILTERS_PENDING = 'themeFilters/FETCH_FILTERS_PENDING'
const FETCH_FILTERS_FULFILLED = 'themeFilters/FETCH_FILTERS_FULFILLED'
const FETCH_FILTERS_REJECTED = 'themeFilters/FETCH_FILTERS_REJECTED'

const ADD_FILTER_PENDING = 'themeFilters/ADD_FILTER_PENDING'
const ADD_FILTER_FULFILLED = 'themeFilters/ADD_FILTER_FULFILLED'
const ADD_FILTER_REJECTED = 'themeFilters/ADD_FILTER_REJECTED'

const UPDATE_FILTER_PENDING = 'themeFilters/UPDATE_FILTER_PENDING'
const UPDATE_FILTER_FULFILLED = 'themeFilters/UPDATE_FILTER_FULFILLED'
const UPDATE_FILTER_REJECTED = 'themeFilters/UPDATE_FILTER_REJECTED'

const DELETE_FILTER_PENDING = 'themeFilters/DELETE_FILTER_PENDING'
const DELETE_FILTER_FULFILLED = 'themeFilters/DELETE_FILTER_FULFILLED'
const DELETE_FILTER_REJECTED = 'themeFilters/DELETE_FILTER_REJECTED'

export const fetchGroups = payload => async dispatch => {
  dispatch({ type: FETCH_GROUPS_PENDING })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/theme-filter-groups'), {
      params: {
        limit: payload.limit || 15,
        offset: payload.offset || 0
      }
    })

    const data = {
      total: response.data.total,
      groups: response.data.data
    }

    dispatch({ type: FETCH_GROUPS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_GROUPS_REJECTED, payload: error })
  }
}

export const addGroup = payload => async dispatch => {
  dispatch({ type: ADD_GROUP_PENDING })

  try {
    const response = await axios.post(getRefApiUrl('v1', '/theme-filter-groups'), payload.data)

    const data = {
      group: response.data
    }

    dispatch({ type: ADD_GROUP_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_GROUP_REJECTED, payload: error })
  }
}

export const updateGroup = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_GROUP_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/theme-filter-groups/${payload.id}`),
      payload.data
    )

    const data = {
      group: response.data
    }

    dispatch({ type: UPDATE_GROUP_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_GROUP_REJECTED, payload: error })
    throw error
  }
}

export const deleteGroup = payload => async dispatch => {
  dispatch({ type: DELETE_GROUP_PENDING })

  try {
    await axios.delete(getRefApiUrl('v1', `/theme-filter-groups/${payload.id}`))

    dispatch({ type: DELETE_GROUP_FULFILLED, payload: payload.id })

    return payload.id
  } catch (error) {
    dispatch({ type: DELETE_GROUP_REJECTED, payload: error })
  }
}

export const fetchFilters = payload => async dispatch => {
  dispatch({ type: FETCH_FILTERS_PENDING })

  try {
    const response = await axios.get(getRefApiUrl('v1', '/theme-filters'), {
      params: {
        search: payload.search || undefined,
        limit: payload.limit || 15,
        offset: payload.offset || 0,
        group_id: payload.groupId || undefined,
        with_ranking: payload.withRanking ? '1' : undefined
      }
    })

    const data = {
      total: response.data.total,
      filters: response.data.data
    }

    dispatch({ type: FETCH_FILTERS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_FILTERS_REJECTED, payload: error })
  }
}

export const addFilter = payload => async dispatch => {
  dispatch({ type: ADD_FILTER_PENDING })

  try {
    const response = await axios.post(getRefApiUrl('v1', '/theme-filters'), payload.data)

    const data = {
      filter: response.data
    }

    dispatch({ type: ADD_FILTER_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_FILTER_REJECTED, payload: error })
  }
}

export const updateFilter = payload => async dispatch => {
  dispatch({ type: UPDATE_FILTER_PENDING })

  try {
    const response = await axios.post(getRefApiUrl('v1', `/theme-filters/${payload.id}`), payload.data)

    const data = {
      filter: response.data
    }

    dispatch({ type: UPDATE_FILTER_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_FILTER_REJECTED, payload: error })
  }
}

export const deleteFilter = payload => async dispatch => {
  dispatch({ type: DELETE_FILTER_PENDING })

  try {
    await axios.delete(getRefApiUrl('v1', `/theme-filters/${payload.id}`))

    dispatch({ type: DELETE_FILTER_FULFILLED, payload: payload.id })

    return payload.id
  } catch (error) {
    dispatch({ type: DELETE_FILTER_REJECTED, payload: error })
  }
}

const initialState = {
  groups: {
    loading: false,
    total: 0,
    data: []
  },
  filters: {
    loading: false,
    total: 0,
    data: []
  }
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_PENDING:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: true
        }
      }
    case FETCH_GROUPS_FULFILLED:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false,
          total: action.payload.total,
          data: action.payload.groups
        }
      }
    case FETCH_GROUPS_REJECTED:
      return {
        ...state,
        groups: {
          ...state.groups,
          loading: false
        }
      }

    case FETCH_FILTERS_PENDING:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: true
        }
      }
    case FETCH_FILTERS_FULFILLED:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false,
          total: action.payload.total,
          data: action.payload.filters
        }
      }
    case FETCH_FILTERS_REJECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          loading: false
        }
      }

    default:
      return state
  }
}
