import React, { useEffect, useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import { fetchFilters } from '@redux/themeFilters'
import SearchBar from '@components/SearchBar'
import Button from '@components/Button'

import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import FilterModal from '@pages/Partners/Admin/Filters/FilterModal.jsx'
import DeleteFilterModal from '@pages/Partners/Admin/Filters/DeleteFilterModal.jsx'
import DropdownList from '@components/DropdownList/index.jsx'
import DotMenu from '@components/DotMenu/index.jsx'
import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'

import './List.scss'

const filterLimit = 15

export default function FiltersList () {
  const listRef = useRef()
  const [totalFilters, filters] = useSelector(({ themeFilters }) => [
    themeFilters.filters.total, themeFilters.filters.data
  ])

  const [searchParams, setSearchParams] = useSearchParams()
  const page = searchParams.get('page') || 1

  const [search, setSearch] = useState('')
  const [searchVal, setSearchVal] = useState('')
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState('')

  const dispatch = useDispatch()
  const searchTimeout = useRef(null)

  const pagination = makePagination(totalFilters, page, filterLimit)

  useEffect(() => {
    loadFilters()
  }, [page, search])

  function loadFilters () {
    dispatch(fetchFilters({
      search: search || undefined,
      offset: pagination.offset,
      limit: pagination.limit,
      withRanking: true
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }
  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
      case 'delete':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowDeleteModal('delete')
        break
    }
  }

  function handleAdd () {
    setSelected(null)
    setShowModal('add')
  }
  function handleFilterModalSuccess () {
    setShowModal('')
    setSelected(null)
    loadFilters()
  }
  function handleDeleteModalSuccess () {
    setShowDeleteModal('')
    loadFilters()
  }

  return (
    <div className='pda-filters-page' ref={listRef}>
      <FilterModal
        show={showModal !== ''}
        type={showModal}
        filter={selected}
        onClose={() => setShowModal('')}
        onSuccess={handleFilterModalSuccess}
      />
      <DeleteFilterModal
        show={showDeleteModal !== ''}
        id={selected?.id}
        onClose={() => setShowDeleteModal('')}
        onSuccess={handleDeleteModalSuccess}
      />
      <div className='pda-filters-page__header'>
        <div className='pda-filters-page__title'>
          Theme Filters
        </div>
        <Link to='/theme-filter-groups'>
          <Button hollow>
            Manage Filter Groups
          </Button>
        </Link>
      </div>
      <div className='pda-filters-page__list'>
        <div className='pda-filters-page__toolbar'>
          <SearchBar
            className='pda-filters-page__search'
            placeholder='Search filters...'
            value={searchVal}
            onChange={handleSearchChange}
          />
          <div className='pda-filters-page__action'>
            <Button primary onClick={handleAdd}>
              Add filter
            </Button>
          </div>
        </div>

        <ListRow
          className='pda-filters-page__list__items'
          headers={(
            <>
              <ListRowHeader className='pda-filters-page__list__name'>
                Filter
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__group'>
                Group
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__visible'>
                visible
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__rank'>
                Ranking Page
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__thumbnail'>
                Thumbnail/Cover(OG)
              </ListRowHeader>
              <ListRowHeader className='pda-filters-page__list__actions'>
                Action
              </ListRowHeader>
            </>
          )}
          items={filters.map(filter => (
            <ListRowItem key={filter.id}>
              <ListRowColumn className='pda-filters-page__list__name'>
                <Link
                  to='#'
                >
                  {filter.name}
                </Link>
                <br />
                <small className='pda-filters-page__list__slug'>
                  <a
                    href='#'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    {filter.slug}
                  </a>
                </small>
              </ListRowColumn>
              <ListRowColumn className='pda-filters-page__list__group'>
                <div>
                  {filter.group}
                </div>
              </ListRowColumn>
              <ListRowColumn className='pda-filters-page__list__visible'>
                <div>
                  {filter.visible ? 'Yes' : 'No'}
                </div>
              </ListRowColumn>
              <ListRowColumn className='pda-filters-page__list__rank'>
                <div>
                  {filter.ranking_slug}
                </div>
              </ListRowColumn>
              <ListRowColumn className='tss-page__list__thumbnail'>
                <a
                  href={filter.thumbnail_url}
                  target='_blank'
                  rel='noreferrer noopener'
                >
                  <img className='pda__theme-img-preview' src={filter.thumbnail_url} alt={filter.name} />
                </a>
              </ListRowColumn>
              <ListRowColumn className='trs-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: filter
                  }, {
                    key: 'delete',
                    label: 'Delete',
                    value: filter
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            event.preventDefault()
            setSearchParams({ page })
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />
      </div>
    </div>
  )
}
