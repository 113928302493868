import React from 'react'
import { Helmet } from 'react-helmet'

import ContentPadding from '@components/ContentPadding/index.jsx'

import BestSection from '@pages/Partners/Partner/Resources/Best.jsx'
import LinkSection from '@pages/Partners/Partner/Resources/Links.jsx'
import RectSection from '@pages/Partners/Partner/Resources/Rect.jsx'
import SquareSection from '@pages/Partners/Partner/Resources/Square.jsx'
import ExampleSection from '@pages/Partners/Partner/Resources/Example.jsx'

import './Resources.scss'

export default function ResourcesPage () {
  return (
    <>
      <Helmet>
        <title>Resources</title>
      </Helmet>

      <ContentPadding>
        <div className='p-resources-page'>
          <BestSection />
          <LinkSection />
          <RectSection />
          <SquareSection />
          <ExampleSection />
        </div>
      </ContentPadding>
    </>
  )
}
