import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'
import { signIn } from '@redux/account.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import './Login.scss'

const PartnersLogin = () => {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [password, setPassword] = useState('')
  const dispatch = useDispatch()
  const [, setBackLogin] = useBackToLogin()
  const [, setBackWebsite] = useBackToWebsite()

  const [authPending] = useSelector(({ account }) => [account.authPending])

  const toastr = useToastr()

  useEffect(() => {
    setBackLogin(false)
    setBackWebsite(true)
  }, [])

  function onClickSignIn (event) {
    event.preventDefault()

    setErrors({})

    dispatch(signIn({
      email,
      password,
      isLogin: true,
      withCookie: true
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'You have successfully logged in.'
        })

        setTimeout(() => {
          window.location.href = '/'
        }, 500)
      })
      .catch(error => {
        let message = 'Please try again later.'

        if (
          (error?.response?.status === 400 || error?.response?.status === 401) &&
          error?.response?.data?.message
        ) {
          message = error.response.data.message

          if (error.response.data?.params) {
            setErrors(error.response.data?.params)
          } else if (error.response.data?.code === 'credentials_mismatch') {
            setErrors({ password: message })
          }
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message
        })
      })
  }

  function onChange (set) {
    return event => {
      set(event.target.value)
    }
  }

  return (
    <div className='login'>
      <h1 className='login__title'>
        Welcome back!
      </h1>
      <form
        tabIndex={0}
        action='/#'
        onSubmit={onClickSignIn}
        className='login__form'
      >
        <div className='login__form-group'>
          <TextInput
            tabIndex={1}
            label='Email address'
            placeholder='you@example.com'
            type='email' value={email} onChange={onChange(setEmail)}
            error={errors.email}
            subText={errors.email}
          />
        </div>
        <div className='login__form-group'>
          <div className='login__forget-password'>
            <label className='login__label'>Password</label>
            <p>
              <Link tabIndex={4} to='/reset-password'>Forgot password?</Link>
            </p>
          </div>
          <TextInput
            tabIndex={2}
            placeholder='Enter your password'
            type='password' value={password} onChange={onChange(setPassword)}
            error={errors.password}
            subText={errors.password}
          />
        </div>
        <Button
          tabIndex={3}
          type='submit' primary expanded
          className='login__sign-in'
          disabled={authPending}
        >
          {authPending ? 'Signing in...' : 'Sign in'}
        </Button>
      </form>
      <p className='login__sign-up'>
        Don't have an account?&nbsp;
        <Link to='/sign-up'>Sign up</Link>
      </p>
    </div>
  )
}

export default PartnersLogin
