export default `
<div id='hc-theme-widget'></div>
<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@heycarson/widgets@0.0.x/dist/theme.css">
<script type='module'>
  import ThemeWidget from 'https://cdn.jsdelivr.net/npm/@heycarson/widgets@0.0.x/dist/theme.js'

  window.addEventListener('DOMContentLoaded', function () {
    const widget = new ThemeWidget({
      element: document.querySelector('#hc-theme-widget'),
      apiKey: 'YOUR_API_KEY', 
      slug: 'THEME_SLUG',
    })

    widget.render({
      light: 'LIGHT_THEME' // optional (default: true)
    })
    
    // use widget.destroy() to unmount it
  })
</script>
`.trim()
