import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchAppCategories } from '@redux/appCategories.js'

import Button from '@components/Button'
import Tree from './Tree'
import CategoryModal from './CategoryModal'
import DeleteCategoryModal from './DeleteCategoryModal.jsx'

import './Categories.scss'

export default function CategoriesList () {
  const dispatch = useDispatch()
  const [categoryModal, setCategoryModal] = useState('')
  const [selected, setSelected] = useState(null)
  const [parent, setParent] = useState(null)
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false)

  const [categories] = useSelector(({ appCategories }) => [appCategories.list.data])

  useEffect(() => {
    loadCategories()
  }, [])

  function loadCategories () {
    dispatch(fetchAppCategories({
      flatRoot: false
    }))
  }

  function handleCloseCategoryModal () {
    setCategoryModal('')
    setSelected(null)
    setParent(null)
  }

  function handleSuccessCategoryModal () {
    setCategoryModal('')
    setSelected(null)
    setParent(null)

    loadCategories()
  }

  function handleCloseDeleteCategoryModal () {
    setDeleteCategoryModal(false)
    setSelected(null)
  }

  function handleSuccessDeleteCategoryModal () {
    setDeleteCategoryModal(false)
    setSelected(null)
    loadCategories()
  }

  function handleClickAdd (category = null) {
    setParent(category)
    setCategoryModal('add')
  }

  function handleClickEdit (category) {
    setSelected(category)
    setCategoryModal('edit')
  }

  function onClickDelete (category) {
    setSelected(category)
    setDeleteCategoryModal(true)
  }

  return (
    <div className='pda-app-categories-page'>
      <CategoryModal
        show={categoryModal !== ''}
        type={categoryModal}
        category={selected}
        parent={parent}
        onClose={handleCloseCategoryModal}
        onSuccess={handleSuccessCategoryModal}
      />
      <DeleteCategoryModal
        show={deleteCategoryModal}
        category={selected}
        onClose={handleCloseDeleteCategoryModal}
        onSuccess={handleSuccessDeleteCategoryModal}
      />

      <div className='pda-app-categories-page__title'>
        Shopify App Categories
        <Button onClick={() => handleClickAdd(null)} primary>
          + Add Root Category
        </Button>
      </div>

      <div className='pda-app-categories-page__list'>
        <Tree
          categories={categories}
          onClickAdd={handleClickAdd}
          onClickEdit={handleClickEdit}
          onClickDelete={onClickDelete}
        />
      </div>
    </div>
  )
}
