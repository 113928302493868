import React, { useRef, useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { adminTopOffset, scrollToElement } from '@util/scrolls.js'
import SearchBar from '@components/SearchBar'
import Button from '@components/Button'
import ListRow from '@components/ListRow'
import ListRowHeader from '@components/ListRow/Header'
import ListRowColumn from '@components/ListRow/Column'
import ListRowItem from '@components/ListRow/Item'
import DropdownList from '@components/DropdownList'
import DotMenu from '@components/DotMenu'
import SoftPagination, { makePagination } from '@components/Pagination/Soft'

import { fetchAppProfileClaims } from '@redux/appProfileClaims.js'

import ClaimModal from './ClaimModal'

import './List.scss'

const resultsLimit = 15

export default function ProfileClaimsList () {
  const listRef = useRef()
  const searchTimeout = useRef(null)
  const [totalResults, claims] = useSelector(({ appProfileClaims }) => [
    appProfileClaims.list.total, appProfileClaims.list.data
  ])

  const [searchParams, setSearchParams] = useSearchParams()
  const dispatch = useDispatch()

  const page = searchParams.get('page') || '1'
  const [status, setStatus] = useState(searchParams.get('status') || '')
  const [searchVal, setSearchVal] = useState(searchParams.get('search') || '')

  const pagination = makePagination(totalResults, page, resultsLimit)

  const [search, setSearch] = useState(searchVal)
  const [selected, setSelected] = useState(null)
  const [showModal, setShowModal] = useState('')

  useEffect(() => {
    setSearchParams({ page: 1, search, status })
  }, [search, status])

  useEffect(() => {
    loadAppProfileClaims()
  }, [page, search, status])

  function loadAppProfileClaims () {
    dispatch(fetchAppProfileClaims({
      search,
      status,
      offset: pagination.offset,
      limit: pagination.limit
    }))
      .then(() => {
        scrollToElement(listRef.current, {
          offset: -adminTopOffset - 40
        })
      })
  }

  function handleSearchChange (event) {
    clearTimeout(searchTimeout.current)

    const val = event.target.value
    setSearchVal(val)

    searchTimeout.current = setTimeout(() => {
      setSearch(val)
    }, 400)
  }

  function handleClickOption ({ event, option, onClose }) {
    onClose()

    switch (option.key) {
      case 'edit':
        event.preventDefault && event.preventDefault()
        setSelected(option.value)
        setShowModal('edit')
        break
    }
  }

  function handleClaimModalSuccess () {
    setShowModal('')
    setSelected(null)
    loadAppProfileClaims()
  }

  function handlePageChange (page, event) {
    event?.preventDefault() && event.preventDefault()
    setSearchParams({ page, search, status })
  }

  return (
    <div className='pda-app-profile-claims-page' ref={listRef}>
      <ClaimModal
        show={showModal !== ''}
        type={showModal}
        claim={selected}
        onClose={() => {
          setShowModal('')
          setSelected(null)
        }}
        onSuccess={handleClaimModalSuccess}
      />
      <div className='pda-app-profile-claims-page__title'>
        App Profile Claims
      </div>

      <div className='pda-app-profile-claims-page__list'>
        <div className='pda-app-profile-claims-page__toolbar'>
          <SearchBar
            className='pda-app-profile-claims-page__search'
            placeholder='Search app profile claims'
            value={searchVal}
            onChange={handleSearchChange}
          />
          <div className='pda-app-profile-claims-page__action'>
            <Button onClick={() => setShowModal('add')} primary>
              Add Profile Claim
            </Button>
          </div>
        </div>
        <div className='pda-app-profile-claims-page__status'>
          <Button
            primary={status === ''}
            disabled={status === ''}
            onClick={() => setStatus('')}
          >
            All
          </Button>
          <Button
            primary={status === 'pending'}
            disabled={status === 'pending'}
            onClick={() => setStatus('pending')}
          >
            Pending
          </Button>
          <Button
            primary={status === 'approved'}
            disabled={status === 'approved'}
            onClick={() => setStatus('approved')}
          >
            Approved
          </Button>
          <Button
            primary={status === 'declined'}
            disabled={status === 'declined'}
            onClick={() => setStatus('declined')}
          >
            Declined
          </Button>
        </div>

        <div>
          Total results: {totalResults}
        </div>

        <ListRow
          className='pda-app-profile-claims-page__list__items'
          headers={(
            <>
              <ListRowHeader className='pda-app-profile-claims-page__list__partner-name'>
                Partner Name
              </ListRowHeader>
              <ListRowHeader className='pda-app-profile-claims-page__list__status'>
                Status
              </ListRowHeader>
              <ListRowHeader className='pda-app-profile-claims-page__list__company-name'>
                Company Name
              </ListRowHeader>
              <ListRowHeader className='pda-app-profile-claims-page__list__email'>
                E-mail
              </ListRowHeader>
              <ListRowHeader className='pda-app-profile-claims-page__list__actions'>
                Actions
              </ListRowHeader>
            </>
          )}
          items={claims.map(claim => (
            <ListRowItem key={claim.id}>
              <ListRowColumn className='pda-app-profile-claims-page__list__partner-name'>
                {claim.partner_name}
              </ListRowColumn>
              <ListRowColumn className='pda-app-profile-claims-page__list__status'>
                {claim.status}
              </ListRowColumn>
              <ListRowColumn className='pda-app-profile-claims-page__list__company-name'>
                {claim.company_name}
              </ListRowColumn>
              <ListRowColumn className='pda-app-profile-claims-page__list__email'>
                {claim.email}
              </ListRowColumn>
              <ListRowColumn className='pda-app-profile-claims-page__list__actions'>
                <DropdownList
                  onClickOption={handleClickOption}
                  right
                  activator={({ onClick }) => <DotMenu onClick={onClick} />}
                  options={() => [{
                    key: 'edit',
                    label: 'Edit',
                    value: claim
                  }]}
                />
              </ListRowColumn>
            </ListRowItem>
          ))}
        />

        <SoftPagination
          onChange={(page, event) => {
            handlePageChange(page, event)
          }}
          pagination={pagination}
          pageButtons={5}
          fixedPageButton
        />

      </div>
    </div>
  )
}
