import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { addApp, updateApp } from '@redux/apps.js'

import Modal, { Header, Body, Footer, Columns } from '@components/Modal/index.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import Button from '@components/Button/index.jsx'
import { fetchProfiles } from '@redux/developers.js'

const initialFields = {
  name: '',
  description: '',
  tagline: '',
  metaTitle: '',
  metaDescription: '',
  developerId: '',
  status: 'unpublished',
  url: '',
  filters: '',
  languages: '',
  similar_apps: '',
  icon: null,
  pricing_is_free: '0',
  pricing_has_free_plan: '0',
  pricing_has_trial: '0',
  pricing_trial: '',
  is_claimed: null
}

export default function AppModal ({ show, type, app, onSuccess, onClose }) {
  const [fields, setFields] = useState({ ...initialFields })
  const [errors, setErrors] = useState({})
  const dispatch = useDispatch()

  const devSearchTimeout = useRef(null)
  const [search, setSearch] = useState('')
  const [developers, setDevelopers] = useState([])

  const loadingDevs = useSelector(({ developers }) => developers.list.loading)

  const iconRef = useRef(null)

  useEffect(() => {
    if (!show || !app || type !== 'edit') {
      return
    }

    setFields({
      ...initialFields,
      name: app.name,
      description: app.description,
      tagline: app.tagline || '',
      metaTitle: app.app_meta_title || '',
      metaDescription: app.app_meta_description || '',
      price: app.price || '',
      developerId: app.developer_id || '',
      status: app.status || 'unpublished',
      url: app.url || '',
      filters: app.filters.length > 0 ? app.filters.join(',') : '',
      languages: app.languages.length > 0 ? app.languages.join(',') : '',
      similar_apps: app.similar_apps.length > 0 ? app.similar_apps.map(s => s.slug || s).join(',') : '',
      pricing_is_free: app.pricing_is_free ? '1' : '0',
      pricing_has_free_plan: app.pricing_has_free_plan ? '1' : '0',
      pricing_has_trial: app.pricing_has_trial ? '1' : '0',
      pricing_trial: app.pricing_trial || '',
      is_claimed: app.is_claimed ? '1' : '0'
    })

    setSearch(app.developer?.name || '')
  }, [show, app])

  // clean fields
  useEffect(() => {
    if (!show) {
      return
    }

    return () => {
      setFields({ ...initialFields })
      setErrors({})
      setDevelopers([])
      setSearch('')

      if (iconRef.current) {
        iconRef.current.value = ''
      }
    }
  }, [show])

  useEffect(() => {
    if (!search) {
      setFields({ ...fields, developerId: '' })
      setDevelopers([])

      return
    }

    devSearchTimeout.current = setTimeout(() => {
      loadDevelopers(search)
    }, 400)

    return () => {
      clearTimeout(devSearchTimeout.current)
    }
  }, [search])

  function loadDevelopers (search) {
    dispatch(
      fetchProfiles({
        search,
        limit: 10,
        offset: 0,
        withCapability: 'apps'
      })
    )
      .then(({ profiles }) => {
        setDevelopers(profiles.map(profile => ({
          key: profile.id,
          label: profile.name,
          value: profile.id
        })))
      })
      .catch(error => {
        console.log('error fetch @developers:', error)
        setDevelopers([])
      })
  }

  function handleSave () {
    const data = new window.FormData()

    Object.entries({
      name: fields.name,
      description: fields.description,
      tagline: fields.tagline,
      meta_title: fields.metaTitle,
      meta_description: fields.metaDescription,
      developer_id: fields.developerId,
      status: fields.status,
      url: fields.url,
      filters: fields.filters,
      languages: fields.languages,
      similar_apps: fields.similar_apps,
      is_free: fields.pricing_is_free,
      has_free_plan: fields.pricing_has_free_plan,
      has_trial: fields.pricing_has_trial,
      trial: fields.pricing_trial,
      is_claimed: fields.is_claimed === '1' ? '1' : '0'
    }).forEach(([key, value]) => {
      if (value) {
        data.set(key, value)
      }
    })

    if (fields.icon) {
      data.set('icon', fields.icon)
    }

    let promise

    if (type === 'add') {
      promise = addApp
    } else {
      promise = updateApp
    }

    dispatch(promise({
      id: app?.id,
      data
    }))
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(error => {
        if (
          error.response && error.response.status === 400 &&
          error.response.data?.params
        ) {
          setErrors(error.response.data.params)
        } else if (
          error.response && error.response.status === 404 &&
          error.response.data?.code === 'profile_not_found'
        ) {
          setErrors(old => ({ ...old, developer_id: error.response.data.message }))
        } else {
          console.log('error app')
        }
      })
  }

  function handleFileChange (field) {
    return event => {
      const file = event.currentTarget.files[0]

      setFields({ ...fields, [field]: file })
    }
  }

  return (
    <Modal show={show} className='pda-app__add-modal' large>
      <Header onClose={onClose}>
        {type === 'add' ? 'Add' : 'Edit'} Apps
      </Header>
      <Body className='pda-app__add-modal__body'>
        <Columns>
          <TextInput
            labelClassName='pda-app__form__label'
            label='Search Developer By Name'
            value={search}
            onChange={event => setSearch(event.currentTarget.value)}
          />
          <SelectInput
            label='App Developer'
            labelClassName='pda-app__form__label'
            value={fields.developerId}
            placeholder={loadingDevs ? 'Loading...' : !developers.length ? 'No developer found' : 'Select...'}
            onChange={value => setFields({ ...fields, developerId: value })}
            options={developers}
            disabled={!developers.length || loadingDevs}
            error={errors.developer_id}
            subText={errors.developer_id}
          />
          <SelectInput
            label='Claimed Profile'
            labelClassName='pda-app__form__label'
            value={fields.is_claimed}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, is_claimed: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />
        </Columns>
        <TextInput
          labelClassName='pda-app__form__label'
          label='Name *'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />
        <Columns>
          <SelectInput
            disabled={type === 'add'}
            label='Status'
            labelClassName='pda-app__form__label'
            value={fields.status}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, status: value })}
            options={[{
              key: '1',
              label: 'Published',
              value: 'published'
            }, {
              key: '0',
              label: 'Unpublished',
              value: 'unpublished'
            }]}
          />
          <TextInput
            labelClassName='pda-app__form__label'
            label='App URL *'
            value={fields.url}
            onChange={event => setFields({ ...fields, url: event.currentTarget.value })}
            error={errors.url}
            subText={errors.url}
          />
        </Columns>
        <TextInput
          multiline={2}
          labelClassName='pda-app__form__label'
          label='Tagline'
          value={fields.tagline}
          onChange={event => setFields({ ...fields, tagline: event.currentTarget.value })}
          error={errors.tagline}
          subText={errors.tagline}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-app__form__label'
          label='Description'
          value={fields.description}
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
          subText={errors.description}
        />
        <Columns>
          <TextInput
            multiline={3}
            labelClassName='pda-app__form__label'
            label='Filters'
            value={fields.filters}
            onChange={event => setFields({ ...fields, filters: event.currentTarget.value })}
            error={errors.filters}
            subText={errors.filters}
          />
          <TextInput
            multiline={3}
            labelClassName='pda-app__form__label'
            label='Languages'
            value={fields.languages}
            onChange={event => setFields({ ...fields, languages: event.currentTarget.value })}
            error={errors.languages}
            subText={errors.languages}
          />
          <TextInput
            multiline={3}
            labelClassName='pda-app__form__label'
            label='Similar Apps'
            value={fields.similar_apps}
            onChange={event => setFields({ ...fields, similar_apps: event.currentTarget.value })}
            error={errors.similar_apps}
            subText={errors.similar_apps}
          />
        </Columns>
        <TextInput
          labelClassName='pda-app__form__label'
          label='Meta Title'
          value={fields.metaTitle}
          onChange={event => setFields({ ...fields, metaTitle: event.currentTarget.value })}
          error={errors.meta_title}
          subText={errors.meta_title}
        />
        <TextInput
          multiline={3}
          labelClassName='pda-app__form__label'
          label='Meta Description'
          value={fields.metaDescription}
          onChange={event => setFields({ ...fields, metaDescription: event.currentTarget.value })}
          error={errors.meta_description}
          subText={errors.meta_description}
        />
        <div className='pda-app__form__group'>
          <TextInput
            inputRef={iconRef}
            type='file'
            labelClassName='pda-app__form__label'
            label='Icon * (preferred 500w x 500w or ratio)'
            onChange={handleFileChange('icon')}
            accept='image/*'
            error={errors.icon}
            subText={errors.icon ? errors.icon : ''}
          />

          {app?.icon_url && (
            <img
              className='pda__app-img-preview'
              src={app?.icon_url}
              alt=''
            />
          )}
        </div>
        <Columns>
          <SelectInput
            label='Is Free App?'
            labelClassName='pda-app__form__label'
            value={fields.pricing_is_free}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, pricing_is_free: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />

          <SelectInput
            label='Has Free Pricing Plan?'
            labelClassName='pda-app__form__label'
            value={fields.pricing_has_free_plan}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, pricing_has_free_plan: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />

          <SelectInput
            label='Has Trial?'
            labelClassName='pda-app__form__label'
            value={fields.pricing_has_trial}
            placeholder='Select...'
            onChange={value => setFields({ ...fields, pricing_has_trial: value })}
            options={[{
              key: '1',
              label: 'Yes',
              value: '1'
            }, {
              key: '0',
              label: 'No',
              value: '0'
            }]}
          />

          <TextInput
            disabled={fields.pricing_has_trial !== '1'}
            labelClassName='pda-app__form__label'
            label='Trial Period (if applicable)'
            value={fields.pricing_trial}
            onChange={event => setFields({ ...fields, pricing_trial: event.currentTarget.value })}
            error={errors.pricing_trial}
            subText={errors.pricing_trial}
            placeholder='7-day'
          />
        </Columns>
      </Body>
      <Footer className='pda-app__add-modal__footer'>
        <Button onClick={handleSave} primary>
          Save
        </Button>
        <Button onClick={onClose} secondary>
          Cancel
        </Button>
      </Footer>
    </Modal>
  )
}

AppModal.propTypes = {
  show: PropTypes.bool,
  type: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  app: PropTypes.object
}
