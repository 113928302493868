import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { loadReferralCodes } from '@redux/referrals/refCodes.js'

import ScrollTop from '@containers/ScrollTop'
import Topbar from '@containers/Partners/Topbar/index.jsx'
import AffiliateWithdrawalModal from '@containers/AffiliateWithdrawalModal'
import PartnerSidebar from '@containers/Partners/Sidebar/index.jsx'

import './Partner.scss'

const PartnerLayout = () => {
  const [workspace] = useSelector(({ account }) => [account.workspace])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadReferralCodes({
      limit: 6
    }))
      .catch(error => {
        console.log('ref code error', error)
      })
  }, [workspace?.id])

  return (
    <>
      <div className='pd-layout'>
        <div className='pd-layout__left'>
          <PartnerSidebar />
        </div>

        <div className='pd-layout__right'>
          <Topbar />

          <div className='pd-layout__content'>
            <Outlet />
          </div>
        </div>

        <AffiliateWithdrawalModal />

        <ScrollTop scrollOffset={-80} />
      </div>
    </>
  )
}

export default PartnerLayout
