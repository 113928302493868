import React from 'react'
import classnames from 'classnames'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'

import { Link } from 'react-router-dom'

import heycarsonLogo from '@assets/images/carson-logo.svg'
import back from '@assets/icons/back-circle.svg'

function LeftPanel (props) {
  const [showWebsite] = useBackToWebsite()
  const [showLogin] = useBackToLogin()

  return (
    <div className='partners-auth__left'>
      <div>
        <a href={import.meta.env.VITE_SITE_URL} rel='noopener'>
          <img
            className='partners-auth__left__logo'
            src={heycarsonLogo}
            alt='return to HeyCarson'
          />
        </a>
      </div>

      <div className='partners-auth__left__content'>
        <a
          href={import.meta.env.VITE_SITE_URL}
          rel='noopener'
          className={classnames('partners-auth__left__back', {
            'partners-auth__left__back--show': showWebsite
          })}
        >
          <img src={back} alt='Back to website' />
          Back to website
        </a>
        <Link
          to='/login'
          className={classnames('partners-auth__left__back', {
            'partners-auth__left__back--show': showLogin
          })}
        >
          <img src={back} alt='Back to website' />
          Back to login
        </Link>
        {props.children}
      </div>
    </div>
  )
}

export default LeftPanel
