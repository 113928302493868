import axios from 'axios'

import { getRefApiUrl } from '@util/api.js'

const FETCH_REVIEWS_PENDING = 'themeReviews/FETCH_REVIEWS_PENDING'
const FETCH_REVIEWS_FULFILLED = 'themeReviews/FETCH_REVIEWS_FULFILLED'
const FETCH_REVIEWS_REJECTED = 'themeReviews/FETCH_REVIEWS_REJECTED'

const FETCH_REVIEW_PENDING = 'themeReviews/FETCH_REVIEW_PENDING'
const FETCH_REVIEW_FULFILLED = 'themeReviews/FETCH_REVIEW_FULFILLED'
const FETCH_REVIEW_REJECTED = 'themeReviews/FETCH_REVIEW_REJECTED'

const UPDATE_REVIEW_PENDING = 'themeReviews/UPDATE_REVIEW_PENDING'
const UPDATE_REVIEW_FULFILLED = 'themeReviews/UPDATE_REVIEW_FULFILLED'
const UPDATE_REVIEW_REJECTED = 'themeReviews/UPDATE_REVIEW_REJECTED'

const DELETE_REVIEW_PENDING = 'themeReviews/DELETE_REVIEW_PENDING'
const DELETE_REVIEW_FULFILLED = 'themeReviews/DELETE_REVIEW_FULFILLED'
const DELETE_REVIEW_REJECTED = 'themeReviews/DELETE_REVIEW_REJECTED'

const ADD_REPLY_PENDING = 'themeReviews/ADD_REPLY_PENDING'
const ADD_REPLY_FULFILLED = 'themeReviews/ADD_REPLY_FULFILLED'
const ADD_REPLY_REJECTED = 'themeReviews/ADD_REPLY_REJECTED'

const EDIT_REPLY_PENDING = 'themeReviews/EDIT_REPLY_PENDING'
const EDIT_REPLY_FULFILLED = 'themeReviews/EDIT_REPLY_FULFILLED'
const EDIT_REPLY_REJECTED = 'themeReviews/EDIT_REPLY_REJECTED'

const FETCH_QUESTIONS_PENDING = 'themeReviews/FETCH_QUESTIONS_PENDING'
const FETCH_QUESTIONS_FULFILLED = 'themeReviews/FETCH_QUESTIONS_FULFILLED'
const FETCH_QUESTIONS_REJECTED = 'themeReviews/FETCH_QUESTIONS_REJECTED'

const FETCH_QUESTION_PENDING = 'themeReviews/FETCH_QUESTION_PENDING'
const FETCH_QUESTION_FULFILLED = 'themeReviews/FETCH_QUESTION_FULFILLED'
const FETCH_QUESTION_REJECTED = 'themeReviews/FETCH_QUESTION_REJECTED'

const UPDATE_QUESTION_PENDING = 'themeReviews/UPDATE_QUESTION_PENDING'
const UPDATE_QUESTION_FULFILLED = 'themeReviews/UPDATE_QUESTION_FULFILLED'
const UPDATE_QUESTION_REJECTED = 'themeReviews/UPDATE_QUESTION_REJECTED'

const DELETE_QUESTION_PENDING = 'themeReviews/DELETE_QUESTION_PENDING'
const DELETE_QUESTION_FULFILLED = 'themeReviews/DELETE_QUESTION_FULFILLED'
const DELETE_QUESTION_REJECTED = 'themeReviews/DELETE_QUESTION_REJECTED'

const ADD_ANSWER_PENDING = 'themeReviews/ADD_ANSWER_PENDING'
const ADD_ANSWER_FULFILLED = 'themeReviews/ADD_ANSWER_FULFILLED'
const ADD_ANSWER_REJECTED = 'themeReviews/ADD_ANSWER_REJECTED'

const EDIT_ANSWER_PENDING = 'themeReviews/EDIT_ANSWER_PENDING'
const EDIT_ANSWER_FULFILLED = 'themeReviews/EDIT_ANSWER_FULFILLED'
const EDIT_ANSWER_REJECTED = 'themeReviews/EDIT_ANSWER_REJECTED'

const DELETE_ANSWER_PENDING = 'themeReviews/DELETE_ANSWER_PENDING'
const DELETE_ANSWER_FULFILLED = 'themeReviews/DELETE_ANSWER_FULFILLED'
const DELETE_ANSWER_REJECTED = 'themeReviews/DELETE_ANSWER_REJECTED'

const CLEAN_REVIEWS = 'themes/CLEAN_REVIEWS'
const CLEAN_QUESTIONS = 'themes/CLEAN_QUESTIONS'

export const fetchReviews = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_REVIEWS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/theme-reviews'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined,
        theme_id: payload.themeId || undefined,
        with_reply: payload.withReply || undefined
      }
    })

    const data = {
      total: response.data.total,
      reviews: response.data.data
    }

    dispatch({ type: FETCH_REVIEWS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_REVIEWS_REJECTED })
    throw error
  }
}

export const fetchReview = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_REVIEW_PENDING })

    const response = await axios.get(getRefApiUrl('v1', `/theme-reviews/${payload.id}`), {
      params: {}
    })

    const data = {
      reviews: response.data
    }

    dispatch({ type: FETCH_REVIEW_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_REVIEW_REJECTED })
    throw error
  }
}

export const updateReview = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_REVIEW_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/theme-reviews/${payload.id}`),
      payload.data
    )

    const data = {
      review: response.data
    }

    dispatch({ type: UPDATE_REVIEW_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_REVIEW_REJECTED })
    throw error
  }
}

export const deleteReview = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_REVIEW_PENDING })

    const response = await axios.delete(getRefApiUrl('v1', `/theme-reviews/${payload.id}`))

    const data = {
      review: response.data
    }

    dispatch({ type: DELETE_REVIEW_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: DELETE_REVIEW_REJECTED })
    throw error
  }
}

export const addReply = payload => async dispatch => {
  try {
    dispatch({ type: ADD_REPLY_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/theme-reviews/${payload.id}/replies`),
      payload.data
    )

    const data = {
      reply: response.data
    }

    dispatch({ type: ADD_REPLY_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_REPLY_REJECTED })
    throw error
  }
}

export const editReply = payload => async dispatch => {
  try {
    dispatch({ type: EDIT_REPLY_PENDING })
    const response = await axios.post(
      getRefApiUrl('v1', `/theme-reviews/${payload.id}/replies/${payload.replyId}`),
      payload.data
    )

    const data = {
      reply: response.data
    }

    dispatch({ type: EDIT_REPLY_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: EDIT_REPLY_REJECTED })
    throw error
  }
}

export const clearReviews = () => ({
  type: CLEAN_REVIEWS
})

export const clearQuestions = () => ({
  type: CLEAN_QUESTIONS
})

export const fetchQuestions = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_QUESTIONS_PENDING })

    const response = await axios.get(getRefApiUrl('v1', '/themes-qa/questions'), {
      params: {
        search: payload.search || undefined,
        offset: payload.offset || 0,
        limit: payload.limit || 15,
        status: payload.status || undefined,
        theme_id: payload.themeId || undefined,
        with_answers: payload.withAnswer || undefined
      }
    })

    const data = {
      total: response.data.total,
      questions: response.data.data
    }

    dispatch({ type: FETCH_QUESTIONS_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_QUESTIONS_REJECTED })
    throw error
  }
}

export const fetchQuestion = payload => async dispatch => {
  try {
    dispatch({ type: FETCH_QUESTION_PENDING })

    const response = await axios.get(getRefApiUrl('v1', `/themes-qa/questions/${payload.id}`), {
      params: {
        with_answers: payload.withAnswer || undefined
      }
    })

    const data = {
      question: response.data
    }

    dispatch({ type: FETCH_QUESTION_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: FETCH_QUESTION_REJECTED })
    throw error
  }
}

export const updateQuestion = payload => async dispatch => {
  try {
    dispatch({ type: UPDATE_QUESTION_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', `/themes-qa/questions/${payload.id}`),
      payload.data
    )

    const data = {
      question: response.data
    }

    dispatch({ type: UPDATE_QUESTION_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: UPDATE_QUESTION_REJECTED })
    throw error
  }
}

export const deleteQuestion = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_QUESTION_PENDING })

    const response = await axios.delete(getRefApiUrl('v1', `/themes-qa/questions/${payload.id}`))

    const data = {
      question: response.data
    }

    dispatch({ type: DELETE_QUESTION_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: DELETE_QUESTION_REJECTED })
    throw error
  }
}

export const addAnswer = payload => async dispatch => {
  try {
    dispatch({ type: ADD_ANSWER_PENDING })

    const response = await axios.post(
      getRefApiUrl('v1', '/themes-qa/answers'),
      payload.data
    )

    const data = {
      answer: response.data
    }

    dispatch({ type: ADD_ANSWER_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: ADD_ANSWER_REJECTED })
    throw error
  }
}

export const editAnswer = payload => async dispatch => {
  try {
    dispatch({ type: EDIT_ANSWER_PENDING })
    const response = await axios.post(
      getRefApiUrl('v1', `/themes-qa/answers/${payload.id}`),
      payload.data
    )

    const data = {
      answer: response.data
    }

    dispatch({ type: EDIT_ANSWER_FULFILLED, payload: data })

    return data
  } catch (error) {
    dispatch({ type: EDIT_ANSWER_REJECTED })
    throw error
  }
}

export const deleteAnswer = payload => async dispatch => {
  try {
    dispatch({ type: DELETE_ANSWER_PENDING })

    const response = await axios.delete(getRefApiUrl('v1', `/themes-qa/answers/${payload.id}`))

    const data = {
      answer: response.data
    }

    dispatch({ type: DELETE_ANSWER_FULFILLED, payload: payload.id })

    return data
  } catch (error) {
    dispatch({ type: DELETE_ANSWER_REJECTED })
    throw error
  }
}

const initialState = {
  hydrated: false,
  list: {
    total: 0,
    loading: false,
    data: []
  },
  review: {
    loading: false,
    data: {}
  },
  questions: {
    total: 0,
    loading: false,
    submittingAnswer: false,
    data: []
  },
  question: {
    loading: false,
    data: {}
  }
}

export default (state = initialState, action) => {
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true
    }
  }

  switch (action.type) {
    case FETCH_REVIEWS_PENDING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true
        }
      }
    case FETCH_REVIEWS_FULFILLED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          total: action.payload.total,
          data: action.payload.reviews
        }
      }
    case FETCH_REVIEWS_REJECTED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false
        }
      }
    case FETCH_REVIEW_PENDING:
      return {
        ...state,
        review: {
          ...state.review,
          loading: true
        }
      }
    case FETCH_REVIEW_FULFILLED:
      return {
        ...state,
        review: {
          ...state.review,
          loading: false,
          data: action.payload.reviews
        }
      }
    case FETCH_REVIEW_REJECTED:
      return {
        ...state,
        review: {
          ...state.review,
          loading: false
        }
      }

    case UPDATE_REVIEW_PENDING:
      return {
        ...state,
        submitting: true
      }
    case UPDATE_REVIEW_FULFILLED:
      return {
        ...state,
        submitting: false,
        list: {
          ...state.list,
          data: state.list.data.map(review => {
            if (review.id === action.payload.review?.id) {
              return {
                ...review,
                ...action.payload.review
              }
            }

            return review
          })
        }
      }
    case UPDATE_REVIEW_REJECTED:
      return {
        ...state,
        submitting: false
      }
    case CLEAN_REVIEWS:
      return {
        ...state,
        list: {
          ...state.list,
          ...initialState.list
        }
      }
    case ADD_REPLY_PENDING:
    case EDIT_REPLY_PENDING:
      return {
        ...state,
        submitting: true
      }
    case ADD_REPLY_FULFILLED:
    case EDIT_REPLY_FULFILLED:
      return {
        ...state,
        submitting: false,
        list: {
          ...state.list,
          data: state.list.data.map(review => {
            if (review.id === action.payload.reply?.review_id) {
              return {
                ...review,
                has_reply: true,
                reply: action.payload.reply
              }
            }

            return review
          })
        }
      }
    case ADD_REPLY_REJECTED:
    case EDIT_REPLY_REJECTED:
      return {
        ...state,
        submitting: false
      }
    case FETCH_QUESTIONS_PENDING:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: true
        }
      }
    case FETCH_QUESTIONS_FULFILLED:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false,
          total: action.payload.total,
          data: action.payload.questions
        }
      }
    case FETCH_QUESTIONS_REJECTED:
      return {
        ...state,
        questions: {
          ...state.questions,
          loading: false
        }
      }
    case FETCH_QUESTION_PENDING:
      return {
        ...state,
        question: {
          ...state.question,
          loading: true
        }
      }
    case FETCH_QUESTION_FULFILLED:
      return {
        ...state,
        question: {
          ...state.question,
          loading: false,
          data: action.payload.question
        }
      }
    case FETCH_QUESTION_REJECTED:
      return {
        ...state,
        question: {
          ...state.question,
          loading: false
        }
      }
    case CLEAN_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          ...initialState.questions
        }
      }
    case UPDATE_QUESTION_PENDING:
      return {
        ...state,
        submitting: true
      }
    case UPDATE_QUESTION_FULFILLED:
      return {
        ...state,
        submitting: false,
        question: {
          ...state.question,
          data: action.payload.question
        }
      }

    case UPDATE_QUESTION_REJECTED:
      return {
        ...state,
        submitting: false
      }

    case DELETE_QUESTION_PENDING:
      return {
        ...state,
        submitting: true
      }
    case DELETE_QUESTION_FULFILLED:
      return {
        ...state,
        submitting: false,
        questions: {
          ...state.questions,
          data: state.questions.data.filter(question => question.id !== action.payload.question?.id)
        }
      }
    case DELETE_QUESTION_REJECTED:
      return {
        ...state,
        submitting: false
      }

    case ADD_ANSWER_PENDING:
    case EDIT_ANSWER_PENDING:
      return {
        ...state,
        questions: {
          ...state.questions,
          submittingAnswer: true
        }
      }
    case ADD_ANSWER_FULFILLED:
      return {
        ...state,
        questions: {
          ...state.questions,
          submittingAnswer: false,
          data: state.questions.data.map(question => {
            if (question.id === action.payload.answer?.question_id) {
              return {
                ...question,
                answers: [...question.answers, action.payload.answer]
              }
            }
            return question
          })
        }
      }
    case EDIT_ANSWER_FULFILLED:
      return {
        ...state,
        questions: {
          ...state.questions,
          submittingAnswer: false,
          data: state.questions.data.map(question => {
            if (question.id === action.payload.answer?.question_id) {
              return {
                ...question,
                answers: question.answers.map((answer) => {
                  if (answer.id === action.payload.answer?.id) {
                    return {
                      ...answer,
                      answer: action.payload.answer.answer
                    }
                  }
                  return answer
                })
              }
            }
            return question
          })
        },
        question: {
          ...state.question,
          data: {
            ...state.question.data,
            answers: state.question.data.answers?.map((answer) => {
              if (answer.id === action.payload.answer?.id) {
                return action.payload.answer
              }
              return answer
            })
          }
        }
      }
    case ADD_ANSWER_REJECTED:
    case EDIT_ANSWER_REJECTED:
      return {
        ...state,
        questions: {
          ...state.questions,
          submittingAnswer: false
        }
      }
    case DELETE_ANSWER_PENDING:
      return {
        ...state,
        submitting: true
      }
    case DELETE_ANSWER_FULFILLED:
      return {
        ...state,
        submitting: false,
        question: {
          ...state.question,
          data: {
            ...state.question.data,
            answers: state.question.data.answers.filter((answer) => answer.id !== action.payload)
          }
        }
      }
    case DELETE_ANSWER_REJECTED:
      return {
        ...state,
        submitting: false
      }
    default:
      return state
  }
}
