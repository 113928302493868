import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Label from '@components/Inputs/Label.jsx'

import arrowIcon from '@assets/icons/select-arrow.svg'

import './SelectInput.scss'

class SelectInput extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      open: false
    }

    this.handleClick = this.handleClick.bind(this)
    this.handleOption = this.handleOption.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount () {

  }

  componentWillUnmount () {
    this.stopListening()
  }

  handleClick (event) {
    event.preventDefault()

    const isOpen = this.state.open

    this.setState(old => ({ ...old, open: !isOpen }))

    if (!isOpen) {
      // send to event loop
      setTimeout(() => {
        this.startListening()
      }, 0)
    }
  }

  handleOption (value) {
    return event => {
      this.setState(old => ({ ...old, open: false }))

      this.stopListening()

      this.props.onChange && this.props.onChange(value, event)
    }
  }

  handleClickOutside (event) {
    // is not open
    if (!this.state.open) {
      return
    }

    if (!this.container) {
      return
    }

    // clicked element is inside the select
    if (this.container.contains(event.target)) {
      return
    }

    this.setState(old => ({ ...old, open: false }))

    this.stopListening()
  }

  // listeners
  startListening () {
    window.document.addEventListener('click', this.handleClickOutside, { capture: true })
  }

  stopListening () {
    window.document.removeEventListener('click', this.handleClickOutside, { capture: true })
  }

  render () {
    const { className, value, placeholder, disabled } = this.props
    const { error, subText } = this.props
    const { label, labelClassName } = this.props
    const { options = [] } = this.props
    const selected = options.find(option => option.value === value)

    let selectedLabel
    if (!selected) {
      selectedLabel = placeholder
    } else if (selected.selectedLabel) {
      selectedLabel = selected.selectedLabel
    } else {
      selectedLabel = selected.label
    }

    const select = (
      <div
        ref={dom => (this.container = dom)}
        className={classnames('hc-select__container', {
          'hc-select__container--disabled': disabled,
          'hc-select__container--error': error

        })}
      >
        <div className='hc-select' onMouseUp={!disabled ? this.handleClick : undefined}>
          <div
            className={classnames(
              'hc-select__input',
              {
                'hc-select__input--placeholder': !selected && placeholder
              },
              className
            )}
          >
            {selectedLabel || <span>&nbsp;</span>}
          </div>

          <div
            className={classnames('hc-select__icon', {
              'hc-select__icon--open': this.state.open
            })}
          >
            <img src={arrowIcon} alt='arrow' />
          </div>
        </div>

        <div
          className={classnames('hc-select__options', {
            'hc-select__options--open': this.state.open
          })}
        >
          {options.map(option => (
            <div
              className='hc-select__option'
              onClick={this.handleOption(option.value)}
              key={option.key || option.value}
            >
              {option.label}
            </div>
          ))}
        </div>
      </div>
    )

    let sub = null
    if (subText) {
      sub = (
        <span
          className={classnames('hc-select__sub', {
            'hc-select__sub--error': error
          })}
        >
          {subText}
        </span>
      )
    }

    if (label) {
      return (
        <Label className={labelClassName}>
          <span>{label}</span>
          {select}
          {sub}
        </Label>
      )
    }

    return (
      <>
        {select}
        {sub}
      </>
    )
  }
}

SelectInput.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.node,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.array,

  label: PropTypes.node,
  labelClassName: PropTypes.string,

  subText: PropTypes.node,
  error: PropTypes.any
}

export default SelectInput
