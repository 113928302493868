import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Link from '@components/Link/index.jsx'

import './Item.scss'

export default function SidebarItem ({ to, children, active, onClick, disabled }) {
  return (
    <Link
      to={to}
      onClick={onClick}
      className={classnames('main-sidebar__item', {
        'main-sidebar__item--active': active,
        'main-sidebar__item--disabled': disabled
      })}
    >
      {children}
    </Link>
  )
}

SidebarItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
  active: PropTypes.any,
  onClick: PropTypes.func
}
