import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './Modal.scss'

export { default as Header } from './Header'
export { default as Body } from './Body'
export { default as Footer } from './Footer'
export { default as Columns } from './Columns.jsx'

class Modal extends Component {
  state = {
    index: 1,
    ready: false
  }

  componentDidMount () {
    this.targetElement = window && window.document.createElement('div')

    window.document.body.appendChild(this.targetElement)

    this.handleBodyChange()

    this.setState(() => ({ ready: true }))
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.show !== this.props.show) {
      this.handleBodyChange()
    }
  }

  componentWillUnmount () {
    window.document.body.removeChild(this.targetElement)
  }

  handleBodyChange () {
    const overlays = window.document.querySelectorAll('.hc-modal__overlay--show')

    if (this.props.show) {
      window.document.body.classList.toggle('body--modal-open', true)

      const length = overlays.length

      this.targetElement.style.zIndex = (length + 1).toString()

      return
    }

    this.targetElement.style.zIndex = ''

    if (overlays.length > 1) {
      return
    }

    window.document.body.classList.toggle('body--modal-open', false)
  }

  render () {
    if (!this.state.ready) {
      return null
    }

    const { className, children } = this.props
    const { show } = this.props
    const { large, medium, small } = this.props

    return ReactDOM.createPortal(
      <>
        <div className={classnames('hc-modal__overlay', { 'hc-modal__overlay--show': show })} />
        <div className={classnames('hc-modal__container', className, { 'hc-modal__container--show': show })}>
          <div
            className={classnames('hc-modal__content', {
              'hc-modal__content--large': large,
              'hc-modal__content--medium': medium,
              'hc-modal__content--small': small
            })}>
            {children}
          </div>
        </div>
      </>,
      this.targetElement
    )
  }
}

Modal.propTypes = {
  show: PropTypes.any,
  className: PropTypes.string,
  large: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool
}

export default Modal
